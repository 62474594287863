import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, Input, Checkbox, notification } from "antd";
import NftList from '../NftList';
import { useNftApi } from "../../hooks/api/nftApiHook";
import DisplayFile from "../Common/DisplayFile";
import MysteryboxRevealForm from "./MysteryboxRevealForm";

const MysteryboxRevealModal = (props) => {
  const { getNftListing, getOwnerList, getRevealedList } = useNftApi();
  const { isOpen, hideModal, appLoaderStatus, contractAddress, tokenId, selectedNetwork, nft, auth } =
    props;
  const [mysteryboxDetails, setMysteryboxDetails] = useState(null);
  const [ownerListResp, setOwnerListResp] = useState({});
  const [revealedList, setRevealedList] = useState({list: [], total:0, page:1, last_page: 0});
  const [ loading, setLoading ] = useState(false)
  const [owner, setOwner] = useState(null);
  const [summary, setSummary] = useState({
    totalQuantity: 0,
    totalRevealed: 0,
    maxCanBeRevealed: 0,
  });

  // console.log("ownerListResp", {
  //   summary,
  //   ownerListResp,
  //   owner,
  //   mysteryboxDetails,
  // });

  useEffect(() => {
    if (contractAddress && tokenId) {
      fetchMysteryBoxDetails();
      fetchOwnerList();
      revealList(revealedList['page']);
    }
    return () => {
      setMysteryboxDetails(null);
    };
  }, [contractAddress, tokenId]);
  useEffect(() => {
    if (
      ownerListResp?.owner_list &&
      ownerListResp?.owner_list?.length &&
      auth?.user
    ) {
      let allOwners = ownerListResp.owner_list;
      let crntuser = auth.user;
      const foudOwner =
        allOwners.find(
          (item) =>
            item?.wallet_address?.toLowerCase() ===
            crntuser?.wallet_address?.toLowerCase()
        ) || null;
      setOwner(foudOwner);
      let tempSummary = { ...summary };
      tempSummary.totalQuantity = foudOwner?.quantity || 0;
      tempSummary.totalRevealed = foudOwner?.revealed_count || 0;
      tempSummary.maxCanBeRevealed =
        tempSummary.totalQuantity - tempSummary.totalRevealed;
      setSummary(tempSummary);
    }
  }, [ownerListResp]);

  const revealList = (page) => {
    setLoading(true)
    getRevealedList(
      { urlParams: { selectedNetwork, contractAddress, tokenId }, queryParams: {page} },
      (message, resp) => {
        setLoading(false)
        // setRevealedList(resp);
        setRevealedList(prevState => {
          return {
            ...resp,
            list: [ ...prevState['list'], ...resp['list'] ]
          }
        })
      },
      (message, error) => {
        setLoading(false)
      }
    );
  };

  const fetchOwnerList = () => {
    getOwnerList(
      { urlParams: { selectedNetwork, contractAddress, tokenId } },
      (message, resp) => {
        setOwnerListResp(resp);
      },
      (message, error) => {}
    );
  };

  const fetchMysteryBoxDetails = () => {
    getNftListing(
      { urlParams: { selectedNetwork, contractAddress, tokenId } },
      (message, resp) => {
        if (resp?.nft_items?._id) {
          setMysteryboxDetails(resp.nft_items);
        } else {
          setMysteryboxDetails(null);
        }
      },
      (message, error) => {}
    );
  };

  const handleCancel = () => {
    hideModal && hideModal();
  };
  const successHandler = () => {
    handleCancel();
  };
  return (
    <>
      <Modal
        title="Reveal Mystery Box"
        open={isOpen}
        visible={isOpen}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
        width={1000}
      >
        <div className="connect-wallet-box-main2 make-offer-main p-4">
          {/* <h2 className="main-heading">Reveal Mystery Box</h2> */}

          {
            summary.totalRevealed < summary.totalQuantity ? 
              <div className="row col-sm-12 reverse-row">
                  <div className="col-sm-6">
                    <div className="buynft-poprow">
                      <h5 className="text-center">
                        {mysteryboxDetails?.item_name || null} ({summary.totalRevealed}/
                        {summary.totalQuantity})
                      </h5>
                    </div>
                    <div className="buynft-img">
                      <DisplayFile
                        mimeType={mysteryboxDetails?.file_extension}
                        link={mysteryboxDetails?.item_image}
                        className={"nft-detail-img w-30"}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <MysteryboxRevealForm
                      summary={summary}
                      successHandler={successHandler}
                      tokenId={tokenId}
                      contractAddress={contractAddress}
                      selectedNetwork={selectedNetwork}
                      walletAddresss={auth?.user?.wallet_address}
                      itemChainId={mysteryboxDetails?.network_chain_id}
                      itemTokenStandard={mysteryboxDetails?.token_standard}
                      itemNetwork={mysteryboxDetails?.network}
                      nft={mysteryboxDetails}
                    />
                  </div>
              </div> : ''
          }
          
          <div className="col-sm-12 mt-5">
              <h5 className="text-center">
                Revealed items
              </h5>
              <div className="reveal-list-wrap">
              { revealedList.list.length ? <NftList allnft={revealedList.list} /> : ''}
              </div>
              {(revealedList.page < revealedList.last_page) ? (
                  <div className="d-flex justify-content-center">
                      <button className="create-btn mt-3 mt-sm-4 mb-5" disabled={loading} onClick={(e) => { e.preventDefault(); revealList(revealedList.page+1); }}>
                          {!loading ? 'Load More' : 'Please Wait...'}</button>
                  </div>
              ) : null}
          </div>
          
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    islogin: state.auth.isLogin,
    auth: state.auth.auth,
    appLoaderStatus: state.comman.appLoaderStatus,
  };
};

export default connect(mapStateToProps)(MysteryboxRevealModal);

import React from "react";

export default function ViewUnlockableContent(props) {
  const { content } = props;
  return (
    <div className="connect-wallet-box-main make-offer-main">
      <h2 className="main-heading">Unlockable Content</h2>
      <div className="wallet-infobox px-4">
        <div className="buynft-poprow no-border">
          {content.loading ? (
            <h3>Please sign the transaction to view the unlockable content...</h3>
          ) : !content.isOwner ? (
            <h5>
              {content?.msg ||
                "This content can only be unlocked and revealed by the owner of this item"}
            </h5>
          ) : content.data ? (
            <div>
              <h5>{content?.data}</h5>
            </div>
          ) : null}

          {/*data?.loading ? (
            <h3>Please wait ..</h3>
          ) : !data?.success ? (
            <h5>
              This content can only be unlocked and revealed by the owner of
              this item.
            </h5>
          ) : (
            <div>
              <h5>{data?.data}</h5>
            </div>
          )*/}
        </div>
      </div>
    </div>
  );
}

import axios from "./config";
import cookies from "js-cookie";
var token = cookies.get("token");

class MediaService {
  async FileUpload(file) {
    const reponse = await axios.post(`/media`,file)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return reponse;
  }

  async feedbackFileUpload(file) {
    const response = await axios.post(`/media/feedback`,file)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  }
}

export default new MediaService();

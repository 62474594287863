import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import useComman from "../../hooks/useComman";
import { Form, Button } from "antd";
import previewIcon from '../../image/previewIcon.png';
import Loader from '../../image/loader.gif';
import useMedia from '../../hooks/useMedia';
import { useImportApi } from "../../hooks/api/importApiHook";
import GrouppopUpplus from "../../image/nft-form/GrouppopUpplus.png";
import { notification } from "antd";
import { SmileOutlined, CloseOutlined } from "@ant-design/icons";
import * as address from "../../abi/address.json";
import { TokenStandard } from "../../utils/app.constant";
import $ from 'jquery';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@mui/material/Pagination";
import dummyCsv from "../../image/item.csv";


function ImportNft(props, appLoaderStatus) {

    const { collectionsOfUser, transaction } = useComman();
    const { isLogin, auth } = props;
    const [collection, setCollection] = useState([]);
    const [collectionSelected, setCollectionSelected] = useState(false);
    const [featurSrc, setFeaturSrc] = useState(previewIcon);
    const [featureAdd, setFeatureAdd] = useState(false);
    const [logoSrc, setLogoSrc] = useState(previewIcon)
    const [fileType, setFileTypeError] = useState('')
    const [allFile, setAllFile] = useState(null);
    const [selectFormData, setSelectFormData] = useState();
    const [showConfirmButton, setShowConfirmButton] = useState(false)
    const [jobId, setJobId] = useState(null)
    const [nftResult, setNftResult] = useState([])
    const [activePage, setActivePage] = useState(1);
    const [pageCount, setPageCount] = useState(0);

    const { FileUpload } = useMedia();
    const { importNft, confirmBulkUpload, csvList } = useImportApi();

    useEffect(() => {
        fetchCollection();
    }, [isLogin]);

    const fetchCollection = async () => {
        var res = await collectionsOfUser();
        setCollection(res);
    };
    var formData = new FormData();

    const fileUpload = async (event) => {
        const reader = new FileReader();
        if (event.target.files && event.target.files.length > 0) {
            if (!event.target.files[0].name.match(/.(csv)$/i)) {
                setFileTypeError('Not valid file!, Please upload .csv file type')
                return
            }
            setFeaturSrc(Loader);
            setSelectFormData(event.target.files[0]);
            setFeaturSrc(event.target.files[0].name)
            setFeatureAdd(true)
        }
    }

    const saveNFT = async () => {
        var formData = new FormData();
        const inputNetwork = collection.find(e => e["_id"] == collectionSelected)
        const contractNetwork = inputNetwork['network'] + '_' + inputNetwork['network_chain_id']
        const contractAddress = address["1155"][contractNetwork]['mint_address']
        formData.append('csvFile', selectFormData)
        formData.append('collectionId', collectionSelected)
        formData.append('contractAddress', contractAddress)
        formData.append('networkChainId', inputNetwork['network_chain_id'])
        // formData.append('uploadImageToS3', false)
        // console.log('nftParam', inputNetwork, 'fgeftge', contractNetwork);

        importNft(formData,
            (message, response) => {
                // console.log('message & response', message, response);
                setPageCount(response?.totalPage ? response?.totalPage : 0);
                setShowConfirmButton(true);
                setJobId(response.jobId)
               // setCollection([]);
                setNftResult(response.data)
                document.getElementById("inputGroupFile03").value = "";
                notification.open({
                    message: "Bulk NFT Upload",
                    description: "Bulk NFT Upload Successfully...!",
                    icon: <SmileOutlined style={{ color: "#108ee9" }} />,
                });
            },
            (message, error) => {
                notification.open({
                    message: "Error",
                    description: error.message,
                    icon: <CloseOutlined style={{ color: "#f4364c" }} />,
                });
            }
        )
    }

    const confirmNFT = async () => {
        const uploadParam = {
            jobId: jobId
        }
        confirmBulkUpload(uploadParam,
            (message, response) => {
                // console.log('message & response bulk', message, response);
                setShowConfirmButton(false)
               // setCollection([])
                notification.open({
                    message: "Success!",
                    description: "Bulk Import Successfully...!",
                    icon: <SmileOutlined style={{ color: "#108ee9" }} />,
                });
            },
            (message, error) => {
                // console.log('message & response bulk error', message, error);
                notification.open({
                    message: "Error",
                    description: "Something Went Wrong!",
                    icon: <CloseOutlined style={{ color: "#f4364c" }} />,
                });
            })
    }

    const closeModal = async () => {
        setShowConfirmButton(false)
        //setCollection([])
        document.getElementById("inputGroupFile03").value = "";
    }

    const generateLevels = (arr = []) => {
        let string = '';
        let length = arr.length - 1;
        arr.map((val, i) => {
            for (let key in val) {
                if (val.hasOwnProperty(key)) {
                    string += `${key}: ${val[key]}, `;
                }
            }
            if (length !== i) {
                string += " | "
            }

        })
        return string;
    }

    const changePageHandler = (event, page) => {
        // console.log('page', page);
        const paginationParam = {
            jobId: jobId,
            page: page,
            withCategory: true
        }
        csvList(paginationParam,
            (message, response) => {
                // console.log('message & response', message, response);
                setPageCount(response?.totalPage ? response?.totalPage : 0);
                setNftResult(response.data)
            },
            (message, error) => {
                notification.open({
                    message: "Error",
                    description: error,
                    icon: <CloseOutlined style={{ color: "#f4364c" }} />,
                });
            }
        )
        if (activePage === page) {
            return;
        }
        setActivePage(page);
    };

    return (
        <>
            <div className="import-modal-wrap NftmodelsPopupWrap">
                <div className="modal fade" id="nftImportModal" tabIndex={-1} role="dialog" aria-labelledby="nftModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg nft-modal" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="nftModalLabel">Bulk NFT Upload</h5>
                                {/* <button type="button" className="close closeModalicon" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button> */}
                            </div>

                            <div className="modal-body">
                                {/* <Form> */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="input-group importnft-fileupload form-group mt-4">
                                            <div className="row w-100">
                                                <div className="col-6 pl-0">
                                                    <div className="custom-file-old">
                                                        <label className="image-upload-lbl">Upload CSV *</label>
                                                        <input
                                                            name="upload_csv"
                                                            type="file"
                                                            onChange={(event) => fileUpload(event, "feature")}
                                                            className="custom-file-input-none d-block"
                                                            id="inputGroupFile03"
                                                            accept=".csv"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6 pr-0 pt-4">
                                                    <p className="small-text-wrap">Please click here to download a sample .csv file</p>
                                                        <a className="btn-download" href={dummyCsv} download={dummyCsv}>Download</a>
                                                </div>
                                            </div>
                                            {/* <p>{featurSrc}</p> */}
                                        </div>
                                        {fileType !== '' ? <div role="alert" className="ant-form-item-explain-error">{fileType}</div> : ''}

                                    </div>

                                    {/* <div className=" col-12 d-flex collection-dropdown">
                                    <label className="image-upload-lbl">COLLECTION *</label>
                                </div>
                                <div className=" col-12 d-flex collection-dropdown">
                                    <select
                                        onChange={(event) =>
                                            setCollectionSelected(event.target.value)
                                        }
                                    >
                                        <option value="">Select Collection</option>
                                        {collection.length > 0
                                            ? collection.map((value, key) => (
                                                <option
                                                    selected={
                                                        collectionSelected === value.id ? true : false
                                                    }
                                                    value={value._id}
                                                >
                                                    {value.collection_name}
                                                </option>
                                            ))
                                            : ""}
                                    </select>
                                </div> */}

                                    <div className="flex-column w-100 px-3 mb-4">
                                        <div className="row">
                                            <div className="col-10 pl-0">
                                                <label className="image-upload-lbl">COLLECTION *</label>
                                            </div>
                                            <div className="col-1 d-flex justify-content-end pr-0">
                                                <div onClick={() => fetchCollection()} className="rounded-circle-bg d-flex align-items-center justify-content-center">
                                                    {/* <img
                                                    className="imgw-30 cursor-pointer"
                                                    src='/img/Grouprefresh.png'
                                                /> */}
                                                    <i className="fa fa-undo cursor-pointer"></i>
                                                </div>
                                            </div>

                                            <div className="col-1 d-flex justify-content-end">
                                                <div onClick={() => window.open("/create-collections", "_blank")} className="rounded-circle-bg d-flex align-items-center justify-content-center">
                                                    <img
                                                        className="imgw-30 cursor-pointer"
                                                        src={GrouppopUpplus}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <p className="mb-3 mt-1 d-flex">
                                            This is the collection where your item will appear.
                                            <div className="tooltip-wrapper">
                                                {/* <img src="/img/info-icon.png"></img> */}
                                                <i className="fa  fa-info-circle"></i>
                                                <div className="custom-tooltip">You can organize your items in a collection by selecting the
                                                    <a href="#" onClick={(e) => { e.preventDefault(); window.open(`/profile/${auth.user.username}`, "_blank") }}> collection here.</a>
                                                </div>
                                            </div>
                                        </p>
                                        <div className="d-flex justify-content-start align-items-start gap-10 cstom-select-box">
                                            <select
                                                onChange={(event) =>
                                                    setCollectionSelected(event.target.value)
                                                }
                                            >
                                                <option value="">Select Collection</option>
                                                {collection.length > 0
                                                    ? collection.map((value, key) => (
                                                        <option key={key}
                                                            selected={
                                                                collectionSelected === value.id
                                                                    ? true
                                                                    : false
                                                            }
                                                            value={value._id}
                                                        >
                                                            {value.collection_name}
                                                        </option>
                                                    ))
                                                    : ""}
                                            </select>
                                        </div>
                                        {/* <div style={{ color: "red" }}>{selectCollectionErr}</div> */}
                                    </div>
                                    {showConfirmButton && nftResult.length > 0 ?

                                        <><p>** Please confirm to proceed the creation of NFT that you have uploaded in CSV.</p>
                                            <TableContainer className="table-outer-wrapper custom-scrollbar">
                                                <Table className="table table-bordered" aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="left">Serial No.</TableCell>
                                                            <TableCell align="left">Name</TableCell>
                                                            <TableCell align="left">Description</TableCell>
                                                            <TableCell align="left">Categories</TableCell>
                                                            <TableCell align="left">Sensitive Content</TableCell>
                                                            <TableCell align="left">Level</TableCell>
                                                            <TableCell align="left">Supply</TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {nftResult.map((row, i) => (
                                                            <TableRow key={i}>
                                                                <TableCell align="left">{row.serial_no || ''}</TableCell>
                                                                <TableCell align="left">{row.name || ''}</TableCell>
                                                                <TableCell align="left">{row.description || ''}</TableCell>
                                                                <TableCell align="left">{row.categories_id.map(val => val.name).join(' | ')}</TableCell>
                                                                <TableCell align="left">{row.sensitive_content ? 'True' : 'False'}</TableCell>

                                                                <TableCell align="left">{generateLevels(row.levels)}</TableCell>
                                                                <TableCell align="left">{row.supply || ''}</TableCell>

                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <Pagination page={activePage} count={pageCount} onChange={changePageHandler} variant="outlined" shape="rounded" />
                                        </> : ''

                                    }


                                </div>
                                {/* </Form> */}
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button type="button" onClick={() => closeModal()} className="btn btn-secondary" data-dismiss="modal">Close</button>
                                {
                                    !showConfirmButton && 
                                    (<button type="button" onClick={() => saveNFT()} className="btn btn-primary">SAVE</button> )
                                }
                                
                                {showConfirmButton && (
                                    <button type="button" onClick={() => confirmNFT()} className="btn btn-primary" data-dismiss="modal">CONFIRM</button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    islogin: state.auth.isLogin,
    auth: state.auth.auth,
    appLoaderStatus: state.comman.appLoaderStatus,

});

export default connect(mapStateToProps)(ImportNft)
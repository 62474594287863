import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function CollectionSkeleton() {
  const dummyArr = [1, 2, 3, 4, 5, 6];

  return (
    <div className="games__wrap  CollectionsPageComponent">
      <div className="container-fluid">
        <div className="row">
          <div className="col-3 mt-10">
            <div className="category-mainmenu">
              <ul className="list-unstyled games-sidebar-main sidebarStyle1">
                <Skeleton
                  style={{
                    width: "100%",
                    height: "40px",
                    objectFit: "cover",
                    borderRadius: "20px",
                  }}
                  count={4}
                />
              </ul>
              <div className="category-sidebar">
                <Skeleton
                  style={{
                    width: "100%",
                    height: "40px",
                    objectFit: "cover",
                    borderRadius: "20px",
                  }}
                  count={3}
                />
              </div>
            </div>
            <div className="subcategories-filters mt-4">
              <ul className="list-unstyled categoryFilter">
                <Skeleton
                  style={{
                    width: "100%",
                    height: "40px",
                    objectFit: "cover",
                    borderRadius: "20px",
                  }}
                  count={13}
                />
              </ul>
            </div>
          </div>
          <div className="col-9">
            <h3 className="oracleTextClass" style={{ width: "100%"}}> <Skeleton /></h3>
            <div className="row">
              {dummyArr.map((el) => {
                return (
                  <div key={el} className="col-4 p-0 collection-cards skeleton_wrp">
                    <div
                      className="game-box mr-3 pb-3"
                      style={{
                        backgroundColor: "unset",
                        minHeight: "520px",
                      }}
                    >
                      <div
                        className="inner-game-box"
                        style={{
                          width: "100%",
                          height: "370px",
                          objectFit: "cover",
                          borderRadius: "20px",
                          borderBottomLeftRadius: "0px",
                          borderBottomRightRadius: "0px",
                          backgroundColor: "black",
                        }}
                      >
                        {/* <Skeleton
                          style={{
                            width: "100%",
                            height: "500px",
                            objectFit: "cover",
                            borderRadius: "20px",
                            
                          }}
                          height={500}
                          width={"100%"}
                        /> */}
                        {/*  <img className="bannerImg" src={banner} /> */}
                        <div
                          className="profile-circle"
                          style={{ zIndex: "1" }}
                        >
                          <Skeleton
                            animation="wave"
                            variant="circular"
                            width={200}
                            height={200}
                          />
                        </div>
                      </div>

                      <div className="game-box-dark row flex-column align-items-center" style={{ backgroundColor: "#333" , margin: "0 auto" ,borderBottomLeftRadius: "30px",
                          borderBottomRightRadius: "30px",}}>
                        <Skeleton 
                        style={{
                          position: "absolute",
                          transform: "translatex(-50%)",
                          left: "50%",
                          top: "-40%",
                          width: "80px",
                          borderRadius: "30px",
                        }}  height={40} ></Skeleton>
                        <h2 className="nft-heading-1 m-0" style={{ width: "100%", margin: "0 auto", textAlign: "center !important"}}>
                          <Skeleton
                            animation="wave"
                            height={30}
                            width="100%"
                            style={{ marginTop: 6 ,marginBottom: 6 , textAlign: "center !important"}}
                          />
                        </h2>
                        <h3 className="nft-subheading m-0"  style={{ width: "100%" , margin: "0 auto", textAlign: "center !important"}}>
                          <Skeleton
                            animation="wave"
                            height={20}
                            width="100%"
                            style={{ marginBottom: 6 ,  textAlign: "center !important"}}
                          />
                        </h3>
                        <p className="nft-box-list-desc "  style={{ width: "100%" , margin: "0 auto", textAlign: "center !important"}}>
                          <Skeleton
                            animation="wave"
                            height={10}
                            width="100%"
                            style={{ marginBottom: 6 ,  textAlign: "center !important"}}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { Component } from 'react';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Help from '../components/HelpCenter/HelpCenter';
import Faq from '../components/Faq/Faq';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';

class HelpCenter extends Component {
    render() {
        return (
            <div className="main" id="helpcenter">
                <Breadcrumb title="Terms Of Service" subpage="Community" page="Terms of Service" />
                {/* <Help /> */}
                <div class="container">
                    <div className='cms-wrap'>
                        {/* <h2>TERMS OF USE</h2>    */}
                        <h3>Last Updated: September 23, 2022</h3> 
                        <p>EverGrow Marketing Ltd is dedicated to acting as a supervisory body for the website www.lunasky.com. By accessing this content you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law. </p>
                        <h4>Use License</h4>
                        <p>These Terms of Use permit you to use the Website for your personal, noncommercial use only. You may not: modify or copy the materials; use the materials for any commercial purpose, or for any public display (commercial or noncommercial); remove any copyright or other proprietary notations from the materials; This license shall automatically terminate if you violate any of these restrictions and may be terminated by EverGrow Marketing Ltd at any time. Upon termination of this license, you must destroy any materials in your possession whether in electronic or printed format. </p>

                       
                        <h4>Disclaimer</h4>
                        <p>The materials at www.lunasky.com website are provided on an 'as is' basis. EverGrow Marketing Ltd makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or noninfringement of intellectual property or other violation of rights. Further, EverGrow Marketing Ltd does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site. </p>

                        <h4>Limitations</h4>
                        <p>In no event shall EverGrow Marketing Ltd or its subcontractors be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on this website, even if EverGrow Marketing Ltd or an EverGrow Marketing Ltd authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you. </p>
                        <h4>Accuracy of Materials</h4>
                        <p>The materials appearing on the www.lunasky.com website could include technical, typographical, or photographic errors. EverGrow Marketing Ltd does not warrant that any of the materials on its website are accurate, complete or current. EverGrow Marketing Ltd may make changes to the materials contained on its website at any time without notice. However, EverGrow Marketing Ltd does not make any commitment to update the materials. </p>

                        <h4>Links</h4>
                        <p>EverGrow Marketing Ltd has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by EverGrow Marketing Ltd of the site. Use of any such linked website is at the user's own risk. </p>


                        <h4>Modifications</h4>
                        <p>EverGrow Marketing Ltd may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service. </p>


                        <h4>Investment Risk and Local Law/Regulation</h4>
                        <p>Investing in cryptocurrencies and NFTs is high risk and the value of your investments can go down as well as up. The purchase of Cryptocurrencies and NFTs is not legal in all countries/states. Please take legal/tax advice before considering any investment. EverGrow Marketing Ltd cannot be held responsible for any breaches in local law or regulation as a result of you purchasing NFTs on www.lunasky.com. Nothing stated on this website or on any content produced by EverGrow Marketing Ltd should be considered as a recommendation to purchase.</p>
                    </div>
                </div>
                <Footer />
                <ModalSearch />
                <ModalMenu />
                <Scrollup />
            </div>
        );
    }
}

export default HelpCenter;
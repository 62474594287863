import React from "react";

export const ModelViewer = ({ link, poster }) => (
  <div id="card">
    <model-viewer
      src={link}
      ios-src=""
      poster={poster}
      alt="A 3D model"
      shadow-intensity="1"
      camera-controls
      auto-rotate
      ar
    ></model-viewer>
  </div>
);

import { useEffect, useState } from 'react';
import React from 'react';

const ViewPrice = ({ network, price, priceUnit = '' }) => {
  return (
    <>
      <span className={network + "-icon"}>{price ? parseFloat(parseFloat(price).toFixed(8)) : ''} {priceUnit}</span>
    </>
  );
};

export default ViewPrice;

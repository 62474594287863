import { call, put, takeEvery,takeLatest } from 'redux-saga/effects'
import cookies from "js-cookie";
import NetworkService from '../../api/NetworkService'
import { actionTypes } from './action';

async function getNetworks() {
  return await NetworkService.NetworkList()
  .then((user) => user)
  .catch((error) => error);
}

function* fetchNetworks() {
   try {
      let networkListResp = yield call(getNetworks);
      let networkList = networkListResp?.data?.data?.networks
      // console.log('nftDetails',nftDetails)
      yield put({type: actionTypes.GET_NETWORKS, payload:{networkList}});
   } catch (e) {
      console.log('e',e)
      yield put({type: 'GET_USERS_FAILED', message: e.message});
   }
}

function* NetworkSaga() {
   yield takeEvery(actionTypes.GET_NETWORKS, fetchNetworks);
}

export default NetworkSaga;
import { useDispatch } from 'react-redux';
import { getcollections, loading, getSinglecollections } from '../store/collections/action';
import CollectionsService from '../api/CollectionsServices';
import { useCookies } from "react-cookie";

export default function useAdmin() {
    const dispatch = useDispatch();
    return {
        create: async (data) => {
            var responseData = await CollectionsService.Create(data);
            if (responseData.status === 200) {
                return responseData.data;
            }
            return false;
        },
        getWithPagination: async (data) => {
            // dispatch(loading())    
            var responseData = await CollectionsService.CollectionWithPagination(data);
            if (responseData.status === 200) {
                dispatch(getcollections(responseData.data.data.collections))
                return responseData.data.data.collections;
            }
            return false;
        },
        collectionFilter: async (data) => {
            var responseData = await CollectionsService.CollectionFilter(data);
            if (responseData.status === 200) {
                dispatch(getcollections(responseData.data.data.collections))
                return responseData.data.data.collections;
            }
            return false;
        },
        singleCollectionFilter: async (data) => {
            var responseData = await CollectionsService.SingleCollectionFilter(data);
            if (responseData.status === 200) {
                dispatch(getSinglecollections(responseData.data.data.collections))
                return responseData.data.data.collections;
            }
            return false;
        },
        collectionNftsData: async (data) => {
            var responseData = await CollectionsService.CollectionNftsData(data);
            if (responseData.status === 200) {
                return responseData.data.data.nftDetails;
            }
            return false;
        },
        collectionUpdate: async (data, id) => {
            var responseData = await CollectionsService.CollectionUpdate(data, id);
            if (responseData.status === 200) {
                return responseData.data.data;
            }
            return false;
        }
    }
}

import { useApiCall } from "./appApiCallHook";
import { SAGA_ACTIONTYPES } from "../../store/mysterybox/action";

export function useMysteryBoxApi() {
    const callApi = useApiCall();

    const createMystery = (data, onSuccess, onError) => {
        callApi(SAGA_ACTIONTYPES.MYSTERY_BOX.CREATE_MYSTERY, data, onSuccess, onError);
    }

    const updateMysteryBox = (data, onSuccess, onError) => {
        callApi(SAGA_ACTIONTYPES.MYSTERY_BOX.GET_MYSTERY_DETAILS, data, onSuccess, onError);
    }

    return { createMystery, updateMysteryBox }
}
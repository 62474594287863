import { useDispatch } from 'react-redux';
import { getCategory } from '../store/comman/action';
import ActivityServices from '../api/ActivityServices';
import { useCookies } from "react-cookie";
import * as moment from 'moment'

export default function useActivity() {
    const dispatch = useDispatch();

    return {
        getActivityList: async (data, query) => {
            var responseData = await ActivityServices.FetchActivities(data, query)
            if (responseData.status === 200) {
                return responseData.data.data.activities;
            }
        }
    }
}

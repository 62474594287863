import React from "react";
import Imgcopy from "./copy.png";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  VKShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  PinterestIcon,
  VKIcon,
} from "react-share";
import { notification } from "antd";
import { SmileOutlined } from "@ant-design/icons";

const size = "2.5rem";

export default function ShareButton(props) {
  let urlToShare = props.urlToShare ? props.urlToShare : window.location;

  const copyLink = () => {
    navigator.clipboard.writeText(urlToShare);
    notification.open({
      message: "Copy",
      description: "Link Copied Successfully!",
      icon: <SmileOutlined style={{ color: "#108ee9" }} />,
    });
  };

  return (
    <>
      <div className="nft_quick_share_inner">
        <FacebookShareButton url={urlToShare}>
          <FacebookIcon size={size} />
        </FacebookShareButton>

        <TwitterShareButton url={urlToShare}>
          <TwitterIcon size={size} />
        </TwitterShareButton>

        <WhatsappShareButton url={urlToShare}>
          <WhatsappIcon size={size} />
        </WhatsappShareButton>

        <LinkedinShareButton
          windowWidth={750}
          windowHeight={600}
          url={urlToShare}
        >
          <LinkedinIcon size={size} />
        </LinkedinShareButton>
        <span onClick={() => copyLink()}>
          <img className="copy_share_img" src={Imgcopy}></img>
        </span>
      </div>
    </>
  );
}

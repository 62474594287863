import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import SidebarFiltersAccordion from '../components/Filters/SidebarFiltersAccordion'
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import FollowerList from '../components/Modal/FollowerList'
import Scrollup from '../components/Scrollup/Scrollup';
import { NavLink, useLocation } from "react-router-dom";
import profileBanner from '../image/nft-detail/profileBanner.png'
import profileHeart from '../image/nft-detail/profileHeart.png'
import profileImageNFT from '../image/nft-detail/profileImageNFT.png'
import profileShare from '../image/nft-detail/profileShare.png'
import eatherIcon from '../image/nft-detail/ether-icon.png'
import TwitterIcon from '../image/nft-detail/Twitter.png'
import FacebookIcon from '../image/nft-detail/FB.png'
import InstaIcon from '../image/nft-detail/IG.png'
import SunGlass from '../image/nft-detail/sunGlassesImage.png'
import ActivityImage from '../image/nft-detail/ActivityImage.png'
import collectedImage from '../image/nft-detail/collectedImage.svg'
import NftList from '../components/NftList';
import SelectArrow from '../image/SelectArrow.png'
import List from '../components/Activity/List'
import CollectionLoader from '../components/Skeletons/ProfileSkeleton'
import PageNotFound from './PageNotFound';
import NFTloader from "../components/Loader/NftLoader";
import { Modal } from 'antd'

//custom hooks
import useCollection from '../hooks/useCollection'
import useNFT from '../hooks/useNFT'
import useFollewer from '../hooks/useFollwers'
import useComman from '../hooks/useComman';
import ShareContentModal from '../components/Modal/ShareContentModal';
import { URLS } from '../utils/app.constant';
import { concatHostUrl } from '../utils/helper';
import FollowUnfollowBtn from '../components/Follow/FollowUnfollowBtn';
import { useCollectionApi } from '../hooks/api/collectionApiHook';
import * as qs from "query-string";
import { addHttps } from '../utils/helper';
import debounce from "lodash/debounce";
import ReadMoreContent from '../components/Common/ReadMoreContent';
import ReportCollectionModal from '../components/Report/ReportCollectionModal';

function NftDetailPage3({ props, islogin, auth, loading, collection, nft, total, page }) {

    const { name: collectionSlug } = useParams();
    const [nftList, setNftList] = useState([]);
    const [totalOfNft, setTotalNft] = useState(0)
    const [totalOwnerOfNft, setTotalNftOwner] = useState(0)
    const [collectionLists, setcollectionLists] = useState(false)
    const [activatedTab, setactivatedTab] = useState('collected');
    const [isOwner, setOwner] = useState(true)
    const [activePage, setActivePage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [status, setStatus] = useState('buynow')
    const history = useHistory()
    const [transction, setTransction] = useState(false)
    const [pagination, setPagination] = useState(1)
    const ref = useRef(false)
    const { getWithPagination, singleCollectionFilter, collectionNftsData } = useCollection()
    const { nftWithPagination } = useNFT()
    const { activityOfCollection } = useComman()
    const [showShareModal, setShowShareModal] = useState(false)
    const { getCollectionBasicDetails } = useCollectionApi();
    const { getFollowerFilter } = useFollewer()
    const [collectionDetails, setCollectionDetails] = useState(null)
    const { search: urlQueryString, pathname } = useLocation();
    const sidebarFilterRef = useRef(null);
    const [callNftApi, setCallNftApi] = useState(false);
    const [followerListPopup, setFollowerListPopup] = useState(false);
    const [searchByName, setSeachByname] = useState(null);
    const [followerList, setFollowList] = useState([]);
    const [collectionReportPopup, setCollectionReportPopup] = useState(false)

    
    useEffect(() => {
        fetchCollectionDetails();
        // GetCollections();
        ref.current = true
        return () => {
            ref.current = false
        }
    }, [props.match.params?.name])

    useEffect(() => {
        if (Object.keys(collection).length > 0 && collection['slug'] == collectionSlug && callNftApi) {
            getNftAndOtherData(1, true);
            // getFollowers();
        }
    }, [collection, urlQueryString, callNftApi]);

    const filterItems = (params, resetWholeFilte=false) => {

        if(resetWholeFilte){
            history.push(`${pathname}`); 
            return 
        }
        let parsed = qs.parse(urlQueryString, { arrayFormat: "comma" });
        
        let filteredParam = { ...parsed, ...params};
        if('name' in filteredParam && !filteredParam?.name?.length){
            delete filteredParam.name;
        }
        let urlFilter = qs.stringify(
          { ...filteredParam},
          { arrayFormat: "comma" }
        );
        history.push(`${pathname}?` + urlFilter);   
    };

    const searchNameHandler = debounce((val) => {
        val = val ? val.trim() : ''
        
        filterItems({name:val });
    }, 1000);

    const GetCollection = async () => {
        let user = auth?.user;
        let filter = {
            slug: collectionSlug
        }
        await singleCollectionFilter(filter)
    }

    const fetchCollectionDetails = () => {
        if (props.match.params?.name) {
            GetCollection();
        }
        getCollectionBasicDetails(
            { urlParams: { slug: props.match.params?.name } },
            (message, resp) => {
                setCollectionDetails(resp?.data);
            },
            (message, error) => {
            }
        );
    }

    const handlePage = async () => {
        getNftAndOtherData(activePage + 1)
    }

    const getNftAndOtherData = async (pageNumber = 1, resetData = false) => {
        let user = auth?.user;
        let queryParam = { 
            page: pageNumber, 
            collection_id: collection._id ,
            child_collection: user && user?._id.toString() === collection?.user_id.toString() ? true : false,
            explore: 1
        }
        let parsed = qs.parse(urlQueryString, { arrayFormat: "comma" });
        
        if(parsed.name){
            if(!searchByName){
                setSeachByname(parsed.name);
                let element =  document.getElementById("searchByName");
                if(element){
                    element.value = parsed.name;
                }
            }
            queryParam['search'] = parsed.name;
        }

        if (parsed.status && parsed.status.length) {
            queryParam['status'] = parsed.status.toString()
        }
        if (parsed.categories && parsed.categories.length) {

            if (sidebarFilterRef?.current?.selectedCategories?.length) {
                queryParam['categories'] = sidebarFilterRef?.current?.selectedCategories.map((v) => v.value).join(',')

            }
        }

        let { total, latestNFT_items, page, last_page } = await nftWithPagination({ ...queryParam })
        setActivePage(page);
        setLastPage(last_page)
        setPagination(total === 0 ? 1 : total)
        if (resetData) {
            setNftList(latestNFT_items)
        } else {
            setNftList(nftListPrev => {
                return [...nftListPrev, ...latestNFT_items]
            })
        }
    }

    const GetCollections = async () => {
        let filter = {
            slug: collectionSlug
        }

        const response = await singleCollectionFilter(filter)
        if (response) {
            await setcollectionLists(response)
        }
    }

    const getMonthName = (date) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const d = new Date(date);
        return monthNames[d.getMonth()] + ' ' + d.getFullYear()
    }

    const SelctedCollection = (collectionName) => {
        history.push(`collection/${collectionName}`)
    }

    const activity = async (type) => {
        if (collection) {
            var tran = await activityOfCollection({ collection_id: collection._id })
        }
        setTransction(tran)
        setactivatedTab(type)
    }

    const GetNftBycateory = async (categoryId) => {
        // if (collection) {       
        //     var cat = []
        //     cat.push(categoryId)
        //     const nftresponse = await GetNftByCategory(cat, collection)
        //     await setNft(nftresponse)
        // }
    }

    const filterByStatus = async (status) => {
        // if (collection) {        
        //     if(status === 'buynow'){
        //         var filters = [
        //             {'column':'ready_for_sell','value':true},
        //             {'column':'type','value':'FIXED'},
        //             {'column':'collection_id','value':collection}
        //         ]
        //         var collected = await findMany('NFT_Items',filters) 
        //         setNft(collected)
        //     }

        //     if(status === 'all'){
        //         GetCollection()             
        //     }

        //     if(status === 'onauction'){
        //         var filters = [
        //             {'column':'ready_for_sell','value':true},
        //             {'column':'type','value':'AUCTION'},
        //             {'column':'collection_id','value':collection}
        //         ]
        //         var collected = await findMany('NFT_Items',filters) 
        //         setNft(collected)
        //     }  
        // }

        // await setStatus(status)
    }
    const shareCollectionHandler = (e) => {
        e.preventDefault();
        setShowShareModal(true)


    }
    const getFollowers = async () => {
        var data = {
            collection_id: collectionDetails._id,
        }
        let response = await getFollowerFilter(data)
        setFollowList(response.offer)
    }


    if (loading) {
        return <CollectionLoader />
    }

    if (!loading && !collection) {
        return <PageNotFound text={'Collection Not Found'} />
    }

    return (
        <div id="nftdetailspage3" className="games__wrap  NftDetailpage NftDetailPageComponent">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-2"></div>
                    <div className="col-10">
                    {
                        collection?.is_spam_reported ?
                        <div className="alert alert-danger" role="alert">
                        <i className="fas fa-exclamation-triangle"></i> &nbsp;
                        Reported for suspicious activity!
                        </div> : ''
                    }
                        <div className="NftprofileWrap position-relative">
                            <div className="wide-collection-bg">
                                <img className="profileBannerImage" src={collection ? collection.banner_image : profileBanner} />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-3">
                        <div className="category-mainmenu mt-10">
                            <ul className="list-unstyled games-sidebar-main sidebarStyle1">
                                <li className="nft-game-go-gtn mt-0 mb-2">
                                    <NavLink to="/creators"><i className='fa fa-user'></i> <span>Creators</span></NavLink>
                                </li>
                                <li className="nft-game-go-gtn mt-0 mb-2">
                                    <NavLink to="/collections"><i className='fa fa-th-large'></i> <span>Collections</span></NavLink>
                                </li>
                                <li className="nft-game-go-gtn mt-0 mb-2">
                                    <NavLink to="/assets"><i className='fa fa-cubes'></i> <span>All NFTs</span></NavLink>
                                </li>
                                {islogin ?
                                    <li className="nft-game-go-gtn mt-0 mb-2">
                                        <NavLink to="/mystery?isMystery=true"><i className='fa fa-user-secret'></i> <span>Mystery</span></NavLink>
                                    </li> : ''
                                }
                                
                            </ul>
                        </div>
                        <div className="filter-options-nft-details">
                            {collection ? <SidebarFiltersAccordion filterActionHandler={filterItems} collection={collection} ref={sidebarFilterRef} setCallNftApi={setCallNftApi} /> : ''}
                            {/* <div className="details-info nft-properties d-flex justify-content-between align-items-center my-3">
                                <div className="d-flex align-items-center justify-content-between gap-18 ml-3">
                                    <h4>Status</h4></div>
                                <img src={SelectArrow} className="mr-3" />
                            </div> */}
                            {/* <div className="collection-extrafilters">
                                <div className="custom-filters-head">
                                    <h3>ATTRIBUTES</h3>
                                   
                                </div>
                                <div className="SidebarAccordionFilters">
                                    <div className="card">
                                        <div className="card-header" id="headingCategories">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed"><img src={SunGlass} /> Background</button>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingCategories">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed"><img src={SunGlass} /> Primary Color</button>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingCategories">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed"><img src={SunGlass} /> Jewel</button>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingCategories">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed"><img src={SunGlass} /> Sword</button>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingCategories">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed"><img src={SunGlass} /> Shield</button>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingCategories">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed"><img src={SunGlass} /> Crown</button>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingCategories">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed"><img src={SunGlass} /> Magic Item</button>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingCategories">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed"><img src={SunGlass} /> Power</button>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-9">
                        {/* <h3 className="oracleTextClass">COLLECTIONS</h3> */}

                        <div className="row">
                            <div className="col-4">
                                <div className="collection-img-wrap">
                                    <div className="collection-profile">
                                        <img className="profilePictureImage" src={collection ? collection.featured_image : profileImageNFT} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 p-0">
                                <div className="profile-infobox-details d-flex justify-content-between">
                                    <div className="profile-tabs profile-description collection-dtls-wrap">
                                        <h3 className="nft-auther-heading m-0 mb-2">{collection ? collection.collection_name : ''}</h3>
                                        {/* <p className="nft-auther-para">{collection ? collection.description : ''} */}
                                        <ReadMoreContent content={collection ? collection.description : ''} maxCharac={100}  className={"nft-auther-para"} />

                                        <p className="nft-auther-para">Joined {getMonthName(collection ? collection.createdAt : '')}</p>

                                        {/* {collection?.royalty_fee ?
                                        <p className="nft-auther-para"> <b>Royalty Fee: </b> {collection.royalty_fee}%  </p>
                                        : null
                                    } */}
                                        {/* {collection?.royalty_wallet ?
                                        <p className="nft-auther-para"> <b>Royalty Wallet: </b> {collection.royalty_wallet} </p>
                                        : null
                                    } */}
                                        <p className="nft-auther-para">
                                            <b>Followers: </b> {collection?.total_followers}
                                            {/* <a href="#" onClick={event => { event.preventDefault(); setFollowerListPopup(true) }}><b>Followers: </b> {collection?.total_followers} </a> */}
                                        </p>
                                        {/* {collection ?
                                            collection.username ? 
                                                <h3 className="nft-auther-heading m-0 mb-2">
                                                    @<NavLink
                                                        className="nft-game-go-gtn"
                                                        to={"/profile/" + collection.username}
                                                    >{collection.username}
                                                    </NavLink>
                                                </h3>:''
                                        :''} */}
                                    </div>
                                    <div className="profile-tabs">
                                        <div className="auth-wrap">
                                            {islogin ? 
                                            <div className="report-nft-wrap">
                                                <span onClick={() => setCollectionReportPopup(true)}><i className="fa fa-flag"></i> Report this Collection</span>
                                            </div>
                                            : null}
                                            <div className="author-history-item-box">
                                                <h2>{collectionDetails?.totalNft || 0}</h2>
                                                <h5>Items</h5>
                                            </div>
                                            <div className="author-history-item-box">
                                                <h2>{collectionDetails?.totalOwner || 0}</h2>
                                                <h5>Owners</h5>
                                            </div>
                                        </div>
                                        <ul className="nav justify-content-end gap-18 m-0">
                                            <li><a href="#" className='share-text' onClick={(event) => shareCollectionHandler(event)}><i className='fa fa-share-alt'></i></a></li>
                                            {/* <li><a><img src={profileEmail} /></a></li> */}
                                            {!isOwner ? <li className="favorite-heart"><a className="follow-btn "><i className='fa fa-heart'></i>Follow</a></li> : ''}
                                            {islogin && collection?._id ?
                                                <li>
                                                    <FollowUnfollowBtn type={'collection'} followToId={collection?._id} successHandler={GetCollection} />
                                                </li>

                                                : null
                                            }

                                        </ul>
                                        <div className="profile-tabs social-media-icons">
                                            {collection ?
                                                <ul className="nav justify-content-start gap-18 m-0">
                                                    {collection?.allLinks?.link1 ? <li><a href={addHttps(collection?.allLinks?.link1)} target="_blank"><img src='/img/browser-icon.png' /></a></li> : ''}
                                                    {collection?.allLinks?.link2 ? <li><a href={addHttps(collection?.allLinks?.link2)} target="_blank"><img src='/img/discord-icon.png' /></a></li> : ''}
                                                    {collection?.allLinks?.link3 ? <li><a href={addHttps(collection?.allLinks?.link3)} target="_blank"><img src='/img/insta-icon.png' /></a></li> : ''}
                                                    {collection?.allLinks?.link4 ? <li><a href={addHttps(collection?.allLinks?.link4)} target="_blank"><img src='/img/tiktok.png' /></a></li> : ''}
                                                    {collection?.allLinks?.link5 ? <li><a href={addHttps(collection?.allLinks?.link5)} target="_blank"><img src='/img/twitter-icon.png' /></a></li> : ''}
                                                </ul>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="ownerInfowindow d-none">

                                    {/* <div className="author-history-container d-flex justify-content-center align-items-center"> */}
                                    {/* <div className="author-history-item justify-content-start align-items-center flex-column py-3">
                                            <h2>{collectionDetails?.totalNft || 0}</h2>
                                            <h5>Items</h5>
                                        </div>
                                        <div className="author-history-item justify-content-start align-items-center flex-column py-3">
                                            <h2>{collectionDetails?.totalOwner || 0}</h2>
                                            <h5>Owners</h5>
                                        </div> */}
                                    {/*<div className="author-history-item d-flex justify-content-start align-items-center py-3">
                                            <img src={eatherIcon} />
                                            <div className="flex-column justify-content-start align-items-baseline">
                                                <h2>0.8809</h2>
                                                <h5>Floor price</h5>
                                            </div>
                                        </div>
                                        <div className="author-history-item d-flex justify-content-start align-items-center py-3">
                                            <img src={eatherIcon} />
                                            <div className="flex-column d-flex justify-content-start align-items-baseline">
                                                <h2>789</h2>
                                                <h5>Volume traded</h5>
                                            </div>
                                        </div> */}
                                    {/* </div> */}

                                </div>
                                {/*<div className="filter-nft-details">
                                    <ul className="nav justify-content-end">
                                        <li className="nft-filter-lables">
                                            <a onClick={() => setactivatedTab('collected')} className="d-flex justify-content-around align-items-center"><i className="fa fa-tasks mr-2"></i> Items</a>
                                        </li>
                                        <li className="nft-filter-lables">
                                            <a onClick={() => activity('activity')} className="d-flex justify-content-around align-items-center"><i className="fa fa-heartbeat mr-2"></i> Activity</a>
                                        </li>
                                    </ul>
                                </div> */}
                                <div className="nft-details-filter-inputs">
                                    <div className="input-filter-item d-flex align-items-end justify-content-end">
                                        <div className="details-info nft-properties d-flex justify-content-between align-items-center">
                                            <div className="w-100 align-items-center justify-content-between position-relative">
                                                <input id="searchByName"  className="w-100 border-0 p-0 pl-4" type="text" name="name" onChange={(e)=>{searchNameHandler(e.target.value); }}/>
                                                <div className="position-absolute top-14">
                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="details-info nft-properties d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h4>Recently Listed</h4>
                                            </div>
                                            <i className="fa fa-angle-down" aria-hidden="true"></i>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="nft-detail-hr my-3"></div> */}
                        {
                            activatedTab == 'collected' &&
                            <>
                                <div className="mt-3 nft-list-items">
                                    {
                                        !loading ?
                                            nftList && nftList.length ?
                                                <NftList allnft={nftList} /> : 'Items Not Available!'
                                            : <NFTloader />
                                    }
                                </div>
                                {(activePage < lastPage) ? (

                                    <div className="d-flex justify-content-center">
                                        <button className="create-btn mt-3 mt-sm-4 mb-5" disabled={loading} onClick={(e) => { e.preventDefault(); handlePage(); }}>
                                            {!loading ? 'Load More' : 'Please Wait...'}</button>
                                    </div>
                                ) : null}
                            </>
                        }
                        {
                            activatedTab === 'collections' && <div className="row mt-5 collections-list-items">
                                {

                                    collectionLists ? collectionLists.map((collectionItem, index) => {
                                        // const banner = collectionItem.banner_image || 'https://via.placeholder.com/326x333.png?text=My+collection';
                                        const banner = collectionItem.banner_resized_image? collectionItem.banner_resized_image : collectionItem.banner_image;
                                        const profile = collectionItem.featured_resized_image? collectionItem.featured_resized_image :collectionItem.featured_image;      // 'https://via.placeholder.com/326x333.png?text=My+collection'

                                        return (
                                            <div className="col-4 p-0 collection-cards" key={index}>
                                                <div className="game-box mr-3 pb-3">
                                                    <div className="inner-game-box">
                                                        <img src={banner} />
                                                        <div className="profile-circle">
                                                            <img src={profile} />
                                                        </div>
                                                    </div>
                                                    <div className="game-box-dark row flex-column align-items-center">
                                                        <NavLink className="nft-game-go-gtn" to={"/collection/" + collectionItem.slug}>Go</NavLink>
                                                        <h2 className="nft-heading-1 m-0">{collectionItem.collection_name}</h2>
                                                        <h3 className="nft-subheading m-0">{collectionItem.collection_name}</h3>
                                                        <p className="nft-box-list-desc mt-3">{collectionItem.description.substr(0, 120)}...</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                        : 'No Recoed Found!'}
                            </div>
                        }
                        {
                            activatedTab === 'follow' && <div className="row mt-5 follow-list-items">
                                <h3>Follow content here</h3>
                            </div>
                        }
                        {
                            activatedTab === 'activity' && <div className="row mt-5 activity-list-items">
                                <List activity={transction} />
                            </div>
                        }
                        {
                            activatedTab === 'offers' && <div className="row mt-5 table-responsive offers-list-items">
                                <h3>offers content here</h3>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <Scrollup />
            <Modal
                title="Follower List"
                visible={followerListPopup}
                onCancel={() => setFollowerListPopup(false)}
                footer={null}
            >
                <FollowerList
                    list={followerList}
                />
            </Modal>
            {collectionSlug ?
                <ShareContentModal
                    visible={showShareModal}
                    handleCancel={() => { setShowShareModal(false) }}
                    urlToShare={concatHostUrl(`${URLS.COLLECTION_DETAILS}/${collectionSlug}`)}
                />
                : null
            }
            {collection?._id ? 
            <ReportCollectionModal collection={{collection_id: collection?._id}} isOpen={collectionReportPopup} hideModal={() => setCollectionReportPopup(false)} getCollectionDetails={fetchCollectionDetails} />
            : null}
        </div>
    );
}

const mapStateToProps = state => ({
    islogin: state.auth.isLogin,
    auth: state.auth.auth,
    collection: state.collections.collectionDetails,
    loading: state.collections.isloading,
    nft: state.NFTDetails.nfts?.latestNFT_items,
    page: state.NFTDetails.nfts?.page,
    total: state.NFTDetails.nfts?.total

})

export default connect(mapStateToProps)(NftDetailPage3);

import React from 'react'

const CountDownTimer = ({hoursMinSecs,bidover,isBidOver}) => {
   
    const { day = 0 , hours = 0, minutes = 0, seconds = 60 } = hoursMinSecs;
    const [[days,hrs, mins, secs], setTime] = React.useState([day,hours, minutes, seconds]);
    const tick = () => {
   
        if (hrs === 0 && mins === 0 && secs === 0) {
            setTime([day - 1,59, 59, 59]);
            // reset()
        }            
        else if (mins === 0 && secs === 0) {
            setTime([day,hrs - 1, 59, 59]);
        }
        else if (secs === 0 ){
            setTime([day,hrs, mins - 1, 59]);
        }
        else {
            setTime([day,hrs, mins, secs - 1]);
        }
        
    };
    if((!isBidOver && days === 0 && hrs === 0 && secs === 0 && mins ===0)){
        bidover(true)
    }
    if(!isBidOver && secs < 0){
        bidover(true)
    }
    // const reset = () => setTime([parseInt(day),parseInt(hours), parseInt(minutes), parseInt(seconds)]);
  
    React.useEffect(() => {
        
        const timerId = setInterval(() => tick(), 1000);
        return () => clearInterval(timerId);
    });

    
    return (
        <div>
            <div className="nft-sale-end-info">
                <h5>Sale ends</h5>
                {!isBidOver ?
                    <>
                        <b>{`${days.toString().padStart(2, '0')} Day ${hrs.toString().padStart(2, '0')} Hours ${mins
                        .toString()
                        .padStart(2, '0')} Minutes ${secs.toString().padStart(2, '0')} Seconds`}</b>
                    </>
                    :''}
            </div>

        </div>
    );
}

export default CountDownTimer;
import React from 'react';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import ListForSalePageComponent from '../components/ListFor/ListForSalePageComponent'




export default function ListForSalePage(props) {
    return (
        <div id="listforsale" className="games__wrap m-0 NftDetailPageComponent">
            <ListForSalePageComponent props={props}/>
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <Scrollup />
        </div>
    );
}

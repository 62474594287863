import React from 'react'
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import Games_Logo from '../../image/Games_Logo.png'
import SidebarmenuComponent from '../../themes/SidebarmenuComponent';

import CreateIcon from '../../image/GroupcreateIcon.png'
import LendingIcon from '../../image/GrouplendingIcon.png'
import MarketIcon from '../../image/GroupmarketIcon.png'
import RewardsIcon from '../../image/GrouprewardsIcon.png'
import CratorIcon from './LOGO_Crator.png'
import FeedbackIcon from '../../image/feedback-icon-small.png'

export default function MobileMenuHeader({ mToggleMenuHandler, mMenuVisibility, menuToggle }) {
    //assigning location variable
    const location = useLocation();
    const history = useHistory()
    //destructuring pathname from location
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    const changeRoute = (url) => {
        mToggleMenuHandler()
        history.push(url)
    }

    return (
        <div className={`mobile-menu-items ${mMenuVisibility}`}>
            <span className="closeCats mobile" onClick={menuToggle}></span>
            <div className="sidebar-Menu">
                <div className="category-mainmenu mt-10">
                    <ul className="list-unstyled games-sidebar-main sidebarStyle1">
                        <li className="nft-game-go-gtn mt-0 mb-2">
                            <a onClick={() => changeRoute("/creators")}>Creators</a>
                        </li>
                        <li className="nft-game-go-gtn mt-0 mb-2">
                            <a onClick={() => changeRoute("/collections")}>Collections</a>
                        </li>
                        <li className="nft-game-go-gtn mt-0 mb-2">
                            <a onClick={() => changeRoute("/assets")}>Top NFTs</a>
                        </li>
                        <li className="nft-game-go-gtn mt-0 mb-2">
                            <a onClick={() => changeRoute("/mystery?isMystery=true")}>Mystery</a>
                        </li>
                    </ul>
                    <div className="category-sidebar" style={splitLocation[1] === 'activity-page' ? { width: 153 } : {}}>
                        <ul className="list-unstyled games-sidebar-main sidebarStyle2">
                            <li className="nft-game-go-gtn mt-0 mb-2">
                                <a onClick={() => changeRoute("/assets?trending=true")}>TRENDING</a>
                            </li>
                            <li className="nft-game-go-gtn mt-0 mb-2">
                                <a onClick={() => changeRoute("/assets?upcoming_auctions=true")}>AUCTIONS</a>
                            </li>
                            <li className="nft-game-go-gtn mt-0 mb-2">
                                <a onClick={() => changeRoute("/assets?upcoming_drops=true")}>DROPS</a>
                            </li>
                            {/* <li className="nft-game-go-gtn mt-0 mb-2">
                                <NavLink onClick={()=>changeRoute("activity-page">ACTIVITY</NavLink>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
            <ul className="list-unstyled row mobilenftMenulist px-3">
                <li className={`nav-link ${splitLocation[1] === "assets" ? "active" : ""}`}>
                    <img src={MarketIcon} />
                    <a className="nav-link" onClick={() => changeRoute("/assets")}>Explore</a>
                </li>
                {/* <li className={`nav-link ${splitLocation[1] === "games-oracle" ? "active" : ""}`} >
                    <img src={Games_Logo} />
                    <a className="nav-link" onClick={()=>changeRoute("/games-oracle")}>Games</a>
                </li>
                <li className={`nav-link ${splitLocation[1] === "crator" ? "active" : ""}`}>
                    <img src={CratorIcon} />
                    <a className="nav-link" onClick={()=>changeRoute("/crator")}>Crator</a>
                </li>
                <li className={`nav-link ${splitLocation[1] === "lending-page" ? "active" : ""}`}>
                    <img src={LendingIcon} />
                    <a className="nav-link" onClick={()=>changeRoute("/lending-page")}>Lending</a>
                </li> 
                <li className={`nav-link ${splitLocation[1] === "rewards" ? "active" : ""}`}>
                    <img src={RewardsIcon} />
                    <a className="nav-link" onClick={()=>changeRoute("/")}>Rewards</a>
                </li>*/}
                <li className={`nav-item dropdown nav-link ${splitLocation[1] === "create" || splitLocation[1] === "create-collections" ? "active" : ""}`}>
                    <img src={CreateIcon} />
                    <a className="nav-link" onClick={() => changeRoute("/create-nft")}>
                        Create
                    </a>
                </li>
                <li data-toggle="modal" data-target="#feedbackModal" className='nav-item nav-link'>
                    <img src={FeedbackIcon} />
                    <a className="nav-link feedback-color" href='javascript:void(0)'>Suggestions</a>
                </li>
            </ul>

        </div>
    )
}

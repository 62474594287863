import { useDispatch } from 'react-redux';
import { getCategory } from '../store/comman/action';
import CommanServices from '../api/CommanServices';
import { useCookies } from "react-cookie";
import * as moment from 'moment'

export default function useComman() {
    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = useCookies();

    return {
        categories: async (data) => {          
            var responseData = await CommanServices.Categories();
            if (responseData.status === 200) {
                dispatch(getCategory(responseData.data.data.categories))
                return responseData.data.data.categories;
            }
            return false;
        },
        collectionsOfUser: async (data) => {          
            var responseData = await CommanServices.CollectionOfUser();
            if (responseData.status === 200) {
                return responseData.data.data.collections;
            }
            return false;
        },
        transaction: async (data) => {          
            var responseData = await CommanServices.Transaction(data);
            if (responseData.status === 200) {
                // return responseData.data.data.collections;
                return responseData.data;
            }
            return false;
        },
        categorieIds: async (data) => {          
            var responseData = await CommanServices.CategorieIds(data);
            if (responseData.status === 200) {
                return responseData.data.data.categoryIds;
            }
            return false;
        },
        activityOfCollection: async (data) => {          
            var responseData = await CommanServices.ActivityOfCollection(data);
            if (responseData.status === 200) {
                return responseData.data.data.activits;
            }
            return false;
        },
        getSlider: async () => {          
            var responseData = await CommanServices.GetSlider();
            if (responseData.status === 200) {
                return responseData.data.data.sliders;
            }
            return false;
        },
        timeLeft: (time) => {
            const years = moment(time).diff(moment(), 'years');
            const months = moment(time).diff(moment(), 'months');
            const days = moment(time).diff(moment(), 'days');
            const hours = moment(time).diff(moment(), 'hours');
        }
    }
}

import React, { Component } from 'react';

const initData = {
    pre_heading: "Help Center",
    heading: "How can we help you?",
    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit."
}

const data = [
    {
        id: "1",
        icon: "icon icon-plane text-effect",
        title: "LunaSky",
        content: "LunaSky NFT Marketplace, the world's most beautiful NFT Marketplace!"
    }
]

class HelpCenter extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section className="help-center-area">
                <div className="container">
                    <div className="row justify-content-center">
                    <h3 className="comming_soon_text mt-3 mb-0 text-center">Coming Soon </h3>
                    </div>
                   
                </div>
            </section>
        );
    }
}

export default HelpCenter;
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import cookies from "js-cookie";
import NFTService from '../../api/NFTServices'
import { actionTypes, SAGA_ACTIONTYPES } from './action';
import { CallApi } from '../../api/CallApi';
import { API_URLS } from '../../utils/app.constant';


async function getNft(params) {
  return await NFTService.GetNft(params)
    .then((user) => user)
    .catch((error) => error);
}

function* fetchNft({ payload }) {
  try {
    let nft = yield call(getNft, payload);
    let nftDetails = nft?.data?.data?.nft_items
    // console.log('nftDetails',nftDetails)
    yield put({ type: actionTypes.GET_NFT_SUCCESS, payload: { nftDetails } });
  } catch (e) {
    console.log('e', e)
    yield put({ type: 'GET_USERS_FAILED', message: e.message });
  }
}

function* importNft(action) {
  // console.log('action', action);
  try {
    const data = action.payload;
    const response = yield call(CallApi.POST, API_URLS.USER.IMPORT.NFT, data);
    // console.log('nft/saga', action, response);
    if (response.status >= 200 && response.status < 300 && response.data && response.data.success) {
      action && action.callbackSuccess && action.callbackSuccess(response.data);
    } else {
      // console.log('else block=>', action, response);
      action && action.callbackError && action.callbackError(response.data)
    }
  } catch (err) {
    console.log('catch', err);
    action && action.callbackError && action.callbackError(err && err.data ? err.data : err);
    yield put({ type: 'GET_USERS_FAILED', message: err.message });
  }
}

function* addFeedback(action) {
  try {
    const data = action.payload;
    const response = yield call(CallApi.POST, API_URLS.USER.ADD_FEEDBACK, data);

    if (response.status >= 200 && response.status < 300 && response.data && response.data.success) {
      action && action.callbackSuccess && action.callbackSuccess(response.data);
    } else {
      action && action.callbackError && action.callbackError(response.data)
    }

  } catch (err) {
    console.log('catch', err);
    action && action.callbackError && action.callbackError(err && err.data ? err.data : err);
    yield put({ type: 'ADD_FEEDBACK_FAILED', message: err.message });
  }
}

function* bulkUpload(action) {
  try {
    const data = action.payload;
    const response = yield call(CallApi.POST, API_URLS.USER.IMPORT.BULK_UPLOAD, data)
    if (response.status >= 200 && response.status < 300 && response.data.success) {
      action && action.callbackSuccess && action.callbackSuccess(response.data)
    } else {
      action && action.callbackError && action.callbackError(response.data)
    }
  } catch (err) {
    action && action.callbackError && action.callbackError(err && err.data ? err.data : err);
    yield put({ type: 'GET_USERS_FAILED', message: err.message });
  }
}

function* getCsvRecord(action) {
  try {
    const data = action.payload;
    const response = yield call(CallApi.GET, API_URLS.USER.IMPORT.CSV_LIST_PAGINATION, data);
    if (response.status >= 200 && response.status < 300 && response.data.success) {
      action && action.callbackSuccess && action.callbackSuccess(response.data)
    } else {
      action && action.callbackError && action.callbackError(response.data)
    }
  } catch (err) {
    console.log('paginate catch', err);

  }
}

function* getNftListing(action) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URLS.NFTS.LISTING, data);

    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.success
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (err) {
    action &&
      action.callbackError &&
      action.callbackError(err && err.data ? err.data : err);
  }
}

function* revealMysteryBox(action) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URLS.NFTS.REVEAL_MYSTERY_BOX, data);

    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.success
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (err) {
    action &&
      action.callbackError &&
      action.callbackError(err && err.data ? err.data : err);
  }
}

function* getOwnerList(action) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URLS.NFTS.GET_OWNER_LIST, data);

    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.success
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (err) {
    action &&
      action.callbackError &&
      action.callbackError(err && err.data ? err.data : err);
  }
}

function* getRevealedList(action) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URLS.NFTS.GET_REVEALED_LIST, data);

    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.success
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (err) {
    action &&
      action.callbackError &&
      action.callbackError(err && err.data ? err.data : err);
  }
}

function* getMysteryBoxToken(action) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URLS.NFTS.GET_MYSTERY_BOX_TOKEN, data);

    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.success
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (err) {
    action &&
      action.callbackError &&
      action.callbackError(err && err.data ? err.data : err);
  }
}

function* reportItem(action) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URLS.NFTS.REPORT_ITEM, data);

    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.success
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (err) {
    action &&
      action.callbackError &&
      action.callbackError(err && err.data ? err.data : err);
  }
}

function* nftUnlockableContent(action) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URLS.NFTS.UNLOCKABLE_CONTENT, data);

    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.success
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (err) {
    action &&
      action.callbackError &&
      action.callbackError(err && err.data ? err.data : err);
  }
}

function* NFTSaga() {
  yield takeLatest(actionTypes.GET_NFT, fetchNft);
  yield takeLatest(SAGA_ACTIONTYPES.IMPORT.IMPORT_NFT, importNft);
  yield takeLatest(SAGA_ACTIONTYPES.IMPORT.BULK_UPLOAD, bulkUpload);
  yield takeLatest(SAGA_ACTIONTYPES.IMPORT.CSV_LIST, getCsvRecord);
  yield takeLatest(SAGA_ACTIONTYPES.NFT_LISTING, getNftListing);
  yield takeLatest(SAGA_ACTIONTYPES.REVEAL_MYSTERY_BOX, revealMysteryBox);
  yield takeLatest(SAGA_ACTIONTYPES.GET_OWNER_LIST, getOwnerList);
  yield takeLatest(SAGA_ACTIONTYPES.GET_REVEALED_LIST, getRevealedList);
  yield takeLatest(SAGA_ACTIONTYPES.GET_MYSTERY_BOX_TOKEN, getMysteryBoxToken);
  yield takeLatest(SAGA_ACTIONTYPES.REPORT_ITEM, reportItem);
  yield takeLatest(SAGA_ACTIONTYPES.UNLOCKABLE_CONTENT, nftUnlockableContent);
  yield takeLatest(SAGA_ACTIONTYPES.FEEDBACK_ADD, addFeedback)
}

export default NFTSaga;
import React from "react";
import { Form } from "react-bootstrap";

function TextInput({
  onChange,
  onBlur,
  onPaste,
  value,
  name,
  inputRef,
  type,
  placeholder,
  error,
  id,
  pattern,
  onFocus,
  maxLength,
  className,
  step,
  appendText,
  readOnly,
  disabled,
}) {
  return (
    <>
      <Form.Control
        className={className?className : "nft-form-control"}
        type={type}
        name={name}
        value={value || ""}
        id={id}
        step={step}
        onFocus={onFocus ? onFocus : () => {}}
        maxLength={maxLength}
        pattern={pattern}
        onWheel={(evt) => evt.target.blur()}
        onBlur={onBlur}
        placeholder={placeholder}
        onChange={(evt) => onChange(evt.target.value)}
        ref={inputRef}
        autoComplete={"off"}
        onPaste={onPaste}
        readOnly={readOnly}
        disabled={disabled}
        bsPrefix="no-class"
      />
      {error && error.message ? (
        <div className="text-danger">{error.message}</div>
      ) : null}
    </>
  );
}

export default TextInput;

import React, { useState } from "react";

const ReadMoreContent = (props) => {
  const [showMore, setShowMore] = useState(false);
  const { content, className } = props;
  const maxCharac = props?.maxCharac ? parseInt(props.maxCharac) : 25;

  const contentShowHandler = () => {
    if (!content) {
      return null;
    }
    if (showMore || content.length < maxCharac) {
      return content;
    } else {
      let modifyContent = content;
      modifyContent = modifyContent.length
        ? `${modifyContent.slice(0, maxCharac)}...`
        : null;
      return modifyContent;
    }
  };

  return (
    <>
      <p className={className}>
        {contentShowHandler()}{" "}
        {content && content.length && content.length > maxCharac ? (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setShowMore(!showMore);
            }}
            className="read-more-link"
          >
            {showMore ? "Read less" : "Read more"}
          </a>
        ) : null}
      </p>
    </>
  );
};

export default ReadMoreContent;

import React, { useEffect, useState } from 'react'
import useMoralisService from '../hooks/useMoralisService'
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import Footer from '../components/Footer/Footer'
import $ from 'jquery';



export default function PageNotFound(props) {
   
    return (
        <div id="nftdetailspage3" className="games__wrap  NftDetailpage NftDetailPageComponent">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 page_notfond">
                    <h1 className="text-center ">404</h1>
                        <h1 className="text-center ">{props.text}</h1>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

import React, { useState, useEffect } from 'react';
import { Form, Button, Modal } from "antd";
import Web3 from 'web3';

export const BurnNft = ({authUser, nftBurn, loading}) => {
    const { confirm } = Modal;

    const burn = (data) => {
        confirm({
            title: 'Do you want to burn this item?',
            content:
              'When clicked the OK button, this item will be burned.',
            async onOk() {
              try {
                nftBurn(data)
            } catch (e) {
                return console.log('Oops errors!');
              }
            },
            onCancel() {},
          });
      };

    return (
        <>
            <div className="connect-wallet-box-main make-offer-main">
                <h2 className="main-heading">NFT Burn</h2>
                <div className="wallet-infobox px-4">
                    <div>
                        <div className="container">
                            <div className="row justify-content-center">

                                <div className="col-12 p-0 res">
                                    <Form
                                        className="item-form createNftForm  no-hover"
                                        onFinish={burn}
                                        initialValues={{
                                            to_wallet_address: "",
                                            quantity: 1,
                                        }}
                                    >
                                        <div className="row row-default">
                                            <div className="col-6">
                                                <div className="form-group mt-3 mb-0">
                                                    <label>
                                                        Amount
                                                        <span className="required-class">*</span>{" "}
                                                    </label>
                                                    <Form.Item
                                                        name='quantity'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please input amount!",
                                                            },
                                                        ]}
                                                    >
                                                        <input
                                                            type="number"
                                                            className="nft-form-control mb-1"
                                                            name="quantity"
                                                            placeholder="Amount"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="col-6">
                                                <div className="form-group mt-3 mb-0">
                                                    <label>
                                                        Address
                                                        <span className="required-class">*</span>{" "}
                                                    </label>
                                                    <Form.Item
                                                        name="to_wallet_address"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please input address!",
                                                            },
                                                            {
                                                                message: 'Invalid wallet address!',
                                                                validator: (_, value) => {
                                                                    if (!Web3.utils.isAddress(value)) {
                                                                        return Promise.reject();
                                                                    } else {
                                                                        return Promise.resolve();
                                                                    }
                                                                }
                                                            },
                                                            // {
                                                            //     message: 'Cannot burn to self!',
                                                            //     validator: (_, value) => {
                                                            //       if (value.toLowerCase() === authUser['wallet_address'].toLowerCase()) {
                                                            //         return Promise.reject();
                                                            //       } else {
                                                            //         return Promise.resolve();
                                                            //       }
                                                            //     }
                                                            //   }
                                                        ]}
                                                    >
                                                        <input
                                                            type="text"
                                                            className="nft-form-control mb-1"
                                                            name="to_wallet_address"
                                                            placeholder="Wallet address"
                                                            autoComplete='off'
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="col-12 text-center">
                                                <button className="create-btn burn-color w-100" type="submit">
                                                    {!loading ? "Burn" : "Please Wait..."}
                                                </button>
                                            </div>

                                        </div>

                                    </Form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

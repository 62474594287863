import MediaService from '../api/MediaService';

export default function useAdmin() {
    return {
        FileUpload: async (data) => {          
            var responseData = await MediaService.FileUpload(data);
            if (responseData.status === 200) {                
                // return responseData.data.data.FilePath;
                return responseData.data.data;
            }
            return false;
        },
        feedbackFileUpload: async (data) => {
            var responseData = await MediaService.feedbackFileUpload(data);
            if (responseData.status === 200) {                
                // return responseData.data.data.FilePath;
                return responseData.data.data;
            }
            return false;
        }
    }
}

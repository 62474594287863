import React, { useRef, useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import HomeLoading from '../components/Skeletons/HomePageSkeleton';
import { useSwipeable } from "react-swipeable"
import '../css/carousel.css'
import SliderArrowNext from '../image/slider-arrow-next.png'
import SliderArrowPrev from '../image/slider-arrow-prev.png'


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const Carousel = ({ imgList, nameList, naviNames }) => {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [lableName, setLableName] = useState(imgList[0].name);
  const [loading, setLoading] = useState(true)
  const carouselLength = imgList.length;
  const [windowDimensions, setWindowDimensions] = useState();
  let touchstartX = useRef(0);
  let touchstartY = useRef(0);
  let touchendX = useRef(0);
  let touchendY = useRef(0);
  const one = useRef();
  const two = useRef();
  const main = useRef();
  const four = useRef();
  const five = useRef();
  const refList = [one, two, main, four, five];

  useEffect(() => {
    getSlider()
    handleResize()
  }, [nameList, lableName])

  // console.log('name list', nameList, imgList);

  const handleResize = () => {
    var res = getWindowDimensions()
    setWindowDimensions(res.width > 1199 ? false : true);
  }
  window.addEventListener('resize', handleResize);
  const getIndex = (index) => {
    var cons = (index % carouselLength + carouselLength) % carouselLength;

    return cons
  }

  const getSlider = () => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)

  }

  const setIndex = (index) => {
    refList.map((img) => {
      img.current.style.opacity = 0.2;
      return true;
    })
    setTimeout(() => {
      setCarouselIndex(index);
      setLableName(imgList[index].name)
      refList.map((img) => {
        if (img.current) {
          img.current.style.opacity = 1;
          return true;
        }
      })
    }, 300);
  }

  const handleGesure = () => {
    if (touchendX.current < touchstartX.current) {
      setIndex(getIndex(carouselIndex - 1))
    }
    if (touchendX.current > touchstartX.current) {
      setIndex(getIndex(carouselIndex + 1))
    }
    if (touchendX.current == touchstartX.current) {
      setIndex(getIndex(carouselIndex + 1))
    }
  }

  const ontouchstart = (event) => {
    touchstartX.current = event.screenX;
    touchstartY.current = event.screenY;
  }

  const ontouchend = (event) => {
    touchendX.current = event.screenX;
    touchendY.current = event.screenY;
    handleGesure();
  }

  // mobile swipe handle
  const handlers = useSwipeable({
    onSwipedLeft: () => { setIndex(getIndex(carouselIndex + 1)) },
    onSwipedRight: () => { setIndex(getIndex(carouselIndex - 1)) },
    onTap: () => {
      setIndex(getIndex(carouselIndex + 1))
    }
  });

  const prevButton = () => {
    if (carouselIndex === 0) {
      setIndex(nameList.length - 1)
    } else {
      setIndex(carouselIndex - 1)
    }
  }

  const nextButton = () => {
    if ((nameList.length - 1) === carouselIndex) {
      setIndex(0)
    } else {
      setIndex(carouselIndex + 1)
    }
  }

  return (
    <div className={"carousel_container"} id="homeSlider" >
      <h3 className="oracleTextClass hometext_start">TOP CREATORS</h3>

      <img style={windowDimensions ? { borderRadius: '14px', visibility: 'hidden' } : { borderRadius: '14px' }} ref={one} src={imgList[getIndex(carouselIndex - 2)].img} alt="carousel" className="first" onClick={() => setIndex(getIndex(carouselIndex - 2))} />
      <img style={windowDimensions ? { borderRadius: '14px', visibility: 'hidden' } : { borderRadius: '14px' }} ref={five} src={imgList[getIndex(carouselIndex + 2)].img} alt="carousel" className="fivth" onClick={() => setIndex(getIndex(carouselIndex + 2))} />
      <img style={windowDimensions ? { borderRadius: '14px', visibility: 'hidden' } : { borderRadius: '14px' }} ref={two} src={imgList[getIndex(carouselIndex - 1)].img} alt="carousel" className="second" onClick={() => setIndex(getIndex(carouselIndex - 1))} />

      <img style={windowDimensions ? { borderRadius: '14px', visibility: 'hidden' } : { borderRadius: '14px' }} ref={four} src={imgList[getIndex(carouselIndex + 1)].img} alt="carousel" className="fourth" onClick={() => setIndex(getIndex(carouselIndex + 1))} />
      <div onMouseDown={ontouchstart} onMouseUp={ontouchend} ref={main} style={{ backgroundImage: `url(${imgList[getIndex(carouselIndex)].img})` }} alt="carousel" className="main" >
        {/* {imgList[getIndex(carouselIndex )]?.nfts && imgList[getIndex(carouselIndex )]?.nfts.map((value,key)=>(
              ))} */}
        <div {...handlers} className="slider-image-grid-boxes gap-14">
          {imgList[getIndex(carouselIndex)]?.nfts && imgList[getIndex(carouselIndex)]?.nfts.map((value, key) => (
            <div className="slider-item-collection" key={key}>
              <img src={value.item_image} />
              <div className="flex-column justify-content-center align-items-center image-hover-info">
                <div className="__collections_info_1">
                  <h2>{value.item_name}</h2>
                  <h5>{value?.collection_name}</h5>
                </div>
                <div className="__collections_info_2">
                  <NavLink to={`/assets/${value.chain_name}/${value.contract_address}/${value.token_id}`}>Go</NavLink>
                  <h2>{value?.followers}</h2>
                  <h5>Items</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <button className="home-slider-prev" onClick={() => prevButton()}><img src={SliderArrowPrev}></img></button>
      <button className="home-slider-next" onClick={() => nextButton()}><img src={SliderArrowNext}></img></button>
      <div className="itemName">{lableName}</div>
      <div className="navigatorCircles">
        {nameList.map((item, index) =>
          <div key={index} id={item} className={index === carouselIndex ? "active navCircle" : "navCircle"} onClick={() => setIndex(index)}>
          </div>)}
      </div>
      <div className="naviNames">
        {naviNames.map((item, index) => (
          <div key={index} className={index === carouselIndex ? "active naviName" : "naviName"} onClick={() => setIndex(index)}>{item.display}</div>
        ))}
      </div>
    </div>
  );
};


export default Carousel;
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { Formik, Form, Field, FieldArray } from 'formik'
import { useHistory } from 'react-router-dom'
import categoryIcon from '../../image/nft-form/Groupnft-form-icon-1.png'
import sensitiveIcon from '../../image/nft-form/Groupnft-form-icon-5.png'
import GrouppopUpplus from '../../image/nft-form/GrouppopUpplus.png'
import NftFormPopups from '../../themes/NftFormPopups'
import previewIcon from '../../image/previewIcon.png'
import Userimage from '../../image/userImage_wallet.png'
import private_wallet from '../../image/private_wallet.png'
import metamask__wallet from '../../image/metamask__wallet.png'
import EGC_Logo_wallet from '../../image/EGC_Logo_wallet.png'
import coinBase_wallet from '../../image/coinBase_wallet.png'
import Loader from '../../image/loader.gif'
import { Input, Button, Checkbox, notification } from "antd";
import { SmileOutlined, CloseOutlined } from "@ant-design/icons";
import { CollectionSchema } from '../../utils/Validation'
import { get } from 'lodash';
import delete_icon from '../../image/delete-icon.png';
import Web3 from 'web3';
import InputMask from "react-input-mask"; 
import Select from "react-select";

import twitter_icon from '../../image/twitter-icon.png';
import fb_icon from '../../image/fb-icon.png';
import discord_icon from '../../image/discord-icon.png';
import insta_icon from '../../image/insta-icon.png';
import search_link_icon from '../../image/search-link-icon.png';
import binancePriceImage from '../../image/networkPrice/binance.jpg';
import maticPriceImage from '../../image/networkPrice/matic.jpg';
import ethereumPriceImage from '../../image/networkPrice/ethereum.jpg';

//custom hooks
import useCollection from '../../hooks/useCollection'
import useMedia from '../../hooks/useMedia';
import useNetworks from '../../hooks/useNetwork';
import { PERCENTAGE_VALUE } from '../../utils/app.constant';
import useComman from '../../hooks/useComman';
import { splitUrl, chainImageHandler } from '../../utils/helper';

function EditCollectionsPage({ props, islogin, auth }) {
    const [walletVisibility, setwalletVisibility] = useState(false);
    const [allFile, setAllFile] = useState(null);
    const [featureImage, setFeatureImage] = useState(null);
    const [bannerImage, setBannerImage] = useState(null);
    const [logoSrc, setLogoSrc] = useState(previewIcon)
    const [featurSrc, setFeaturSrc] = useState(previewIcon)
    const [bannerSrc, setBannerSrc] = useState(previewIcon)
    const [isSxplicity, setIsSxplicity] = useState(false);
    const [categoryError, setCategoryError] = useState('')
    // const [category, setCategory] = useState([])
    const [isSaveProperty, setIsSaveProperty] = useState(false)
    const [isSaveLevel, setIsSaveLevel] = useState(false)
    // const [categoryName, setCategoryName] = useState([])
    const [loading, setLoading] = useState(false)
    const [collectionData, setCollectionData] = useState(false)
    const [allLinks, setAllLinks] = useState({
        link1: "",
        link2: "",
        link3: "",
        link4: "",
        link5: "",
    });
    const [selectedNetwork, setSelectedNetwork] = useState('')
    const [networkList, setNetworkList] = useState([])
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [walletDetails, setWalletDetails] = useState([]);
    const [errorIndex, setErrorIndex] = React.useState([]);
    const [percentageError, setPercentageError] = useState(false);
    const [showPercentahe, setShowPercentage] = useState(0);
    const [comingCategoryList, setComingCategoryList] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    ///custom hooks
    const { singleCollectionFilter, collectionUpdate } = useCollection()
    const { FileUpload } = useMedia()
    const history = useHistory()
    const { getNetworkList } = useNetworks()
    const { categories } = useComman()
    const formatCharsAdd = {
        "*": "^[A-Za-z0-9_@./#&+-]*$"
    }

    useEffect(() => {
        GetCollectionData()
        if (!islogin) {
            // history.push('/')
        }
    }, [isSaveProperty, isSaveLevel])


    const explicitData = async (data) => {
        await setIsSxplicity(data);
    };


    // const selectCategory = async (newCate, status, name) => {
    //     console.log('incoming status=>', newCate, status, name);
    //     console.log('category, categoryName =>', category, categoryName);
    //     setLoading(true)
    //     if (!status) {
    //         var old = category.filter((value) => value !== newCate)
    //         var cate = categoryName.filter((valueName) => valueName !== name)
    //         console.log('status-false =>', category, categoryName, old, cate);
    //         await setCategory(old)
    //         setCategoryName(cate)
    //     } else {
    //         var old = category
    //         var cateName = categoryName
    //         cateName.push(name)
    //         old.push(newCate)
    //         console.log('status-true =>', category, categoryName);
    //         await setCategory(old)
    //         await setCategoryName(cateName)
    //     }
    //     setLoading(false)
    // }


    /*const selectCategory = async (cat, status) => {
        const gotIndex = selectedCategories.findIndex(
            (e) => e["_id"] == cat["_id"]
        );
        if (gotIndex != -1) {
            if (!status) {
                const latestCats = selectedCategories.splice(gotIndex, 1);
                console.log('latest cats', latestCats);
                setSelectedCategories(selectedCategories);
            }
        } else {
            if (status) {
                setSelectedCategories((prevCats) => {
                    var a = [
                        ...prevCats,
                        { _id: cat["_id"], name: cat["name"] },
                    ]
                    console.log('aaa=>', a);
                    return a
                });
            }
        }
    };*/

    const selectCategory = async (categories) => {
        setSelectedCategories(categories)
    };

    const reInitModal = () => {
        setSelectedCategories(JSON.parse(JSON.stringify(selectedCategories)))
    }


    const GetCollectionData = async () => {
        var res = await singleCollectionFilter({ _id: props.match.params.id })
        var crnt = auth?.user;
        if (res) {
            // console.log('collection data', res);
            var pro = crnt
            let categoryList = await categories();
            await setCollectionData(res)
            await setLogoSrc(res.logo_resized_image || res.logo_image)
            await setFeaturSrc(res.featured_resized_image || res.featured_image)
            await setBannerSrc(res.banner_resized_image || res.banner_image)
            await setAllLinks(res.allLinks)
            await setIsSxplicity(res.explicity)
            // await setCategory(res.categories_id)
            await getNetwork(res.network);
            await setWalletDetails(res.payout)
            await setComingCategoryList(res.categories_id)

            // if (res.categories_id && res.categories_id.length > 0) {
            //     categoryList.filter((cat => res.categories_id.some(s => s === cat._id))).map((item, id) => {
            //         categoryName.push(item.name)
            //     });
            // }

            /*res && res.categories_id && res?.categories_id.map((cat) => {
                selectCategory(cat, true);
            });*/
            const selectedCats = res && res.categories_id && res?.categories_id.map((cat) => ({ _id: cat['_id'], name: cat['name'] }));
            // console.log('1. categories: ', selectedCats, ' res.categories_id: ', res.categories_id)
            setSelectedCategories(selectedCats)

            // console.log('fetch categories edit page=>', categoryList, comingCategoryList);

            if (crnt) {
                if (res.user_id._id !== crnt._id) {
                    history.push(`/profile/${res.user_id.username}`)
                }
            }
        }
    }

    const getLiks = async (event, type) => {
        let oldLink = {...allLinks};
        if (type === "link_1") {
            oldLink["link1"] = event;
        }
        if (type === "link_2") {
            oldLink["link2"] = event;
        }
        if (type === "link_3") {
            oldLink["link3"] = event;
        }
        if (type === "link_4") {
            oldLink["link4"] = event;
        }
        if (type === "link_5") {
            oldLink["link5"] = event;
        }
        await setAllLinks(oldLink);
    };

    const getNetwork = async (network) => {
        // const fetchNetwork = await CollectionsServices.NetworkList()
        let fetchNetwork = await getNetworkList()
        let filterNetwork = fetchNetwork.networks.filter((value) => value.network === network);
        setSelectedNetwork(filterNetwork)
        setNetworkList(fetchNetwork.networks);  // netWorkList
    }

    const fileUpload = async (e, type) => {
        const reader = new FileReader();
        if (type === "main") {
            if (e.target.files && e.target.files.length > 0) {

                setLogoSrc(Loader)
                var form = new FormData()
                form.append('image', e.target.files[0])
                var fileRes = await FileUpload(form)
                reader.addEventListener("load", () => setLogoSrc(reader.result));
                await setAllFile(fileRes);
                
            }
        } else if (type === "feature") {
            if (e.target.files && e.target.files.length > 0) {
                setFeaturSrc(Loader)
                var form = new FormData()
                form.append('image', e.target.files[0])
                var fileRes = await FileUpload(form)
                reader.addEventListener("load", () => setFeaturSrc(reader.result));
                await setFeatureImage(fileRes);
            }
        } else if (type === "banner") {
            if (e.target.files && e.target.files.length > 0) {
                setBannerSrc(Loader)
                var form = new FormData()
                form.append('image', e.target.files[0])
                var fileRes = await FileUpload(form)
                reader.addEventListener("load", () => setBannerSrc(reader.result));
                await setBannerImage(fileRes);
            }
        }
        reader.readAsDataURL(e.target.files[0]);
    };


    const onFinish = async (data) => {

        await setCategoryError('')
        const user = auth?.user
        if (selectedCategories.length === 0) {
            await setCategoryError('Please select collection category!')
            return
        }
        // console.log('errorindex', errorIndex, errorIndex.indexOf(false));
        if (errorIndex.length > 0 && errorIndex !== undefined) {
            if (errorIndex.indexOf(false) > -1 || percentageError) {
                return;
            }
        }

        // data.banner_image = bannerImage ? bannerImage : collectionData.banner_image;
        // data.featured_image = featureImage ? featureImage : collectionData.featured_image;
        // data.logo_image = allFile ? allFile : collectionData.logo_image;

        data.banner_image = bannerImage && bannerImage['actual_file_path'] ? bannerImage['actual_file_path'] : collectionData.banner_image;
        data.banner_resized_image = bannerImage && bannerImage['resized_path'] || collectionData['banner_resized_image'] || null;
        data.featured_image = featureImage && featureImage['actual_file_path'] ? featureImage['actual_file_path'] : collectionData.featured_image;
        data.featured_resized_image = featureImage && featureImage['resized_path'] || collectionData['featured_resized_image'] || null;
        data.logo_image = allFile && allFile['actual_file_path'] ? allFile['actual_file_path'] : collectionData.logo_image;
        data.logo_resized_image = allFile && allFile['resized_path'] || collectionData['logo_resized_image'] || null;

        data.user_id = user._id;
        data.allLinks = allLinks;
        data.explicity = isSxplicity;
        data.categories_id = selectedCategories.map(e => e['_id']);
        // data.slug = data.collection_name.toLowerCase().replace(/\s+/, "");
        data.network = selectedNetwork[0]['_id'];
        if (data.payout.length > 0) {
            data.payout.map((item, id) => {
                item.percentage = +item.percentage;
                return item;
            })
            //If any field of payout is blank then it is not submit.
            // if (!data.payout.every(item => item.wallet_address && item.percentage > 0)) {
            //     return
            // }
        }

        let updateResponse = await collectionUpdate(data, collectionData._id)
        if (updateResponse) {
            notification.open({
                message: "Collcetion",
                description: "Collection Successfully Updated...!",
                icon: <SmileOutlined style={{ color: "#108ee9" }} />,
            });
            history.push(`/profile/${user?.username}`)
            // await setCategory([])
            // await setLogoSrc(previewIcon)
            // await setFeaturSrc(previewIcon)
            // await setBannerSrc(previewIcon)
        } else {
            notification.open({
                message: "Error",
                description: "Something went wrong...!",
                icon: <CloseOutlined style={{ color: "#f4364c" }} />,
            });
        }
    };



    const connectWithMetaMask = async () => {
        // if (!isAuthenticated) {
        //     await authenticate({ signingMessage: "Log in using Moralis" })
        //         .then(function (user) {
        //             Moralis.User.currentAsync().then(async (user) => {
        //                 const response = await findOne("Collections", "address", user.get('ethAddress'))
        //                 if (response.length > 0) {
        //                     console.log("metamask", response);
        //                 }
        //                 setwalletVisibility(false)
        //             });
        //         })
        //         .catch(function (error) {
        //             console.log(error);
        //         });
        // }
    }
    const connectWithWallet = async () => {
        // if (!isAuthenticated) {
        //     await authenticate({
        //         provider: "walletconnect",
        //         mobileLinks: [
        //             "rainbow",
        //             "metamask",
        //             "argent",
        //             "trust",
        //             "imtoken",
        //             "pillar",
        //         ]
        //     })
        //         .then(async (user) => {
        //             console.log("logged in user:", user);
        //             Moralis.User.currentAsync().then(async (user) => {
        //                 const response = await findOne("Collections", "address", user.get('ethAddress'))

        //             });
        //             await setwalletVisibility(false)
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //         });
        // }
    }

    const walletShowhide = () => {
        if (walletVisibility === false) {
            setwalletVisibility(true);
        } else {
            setwalletVisibility(false);
        }
    }

    const getNetworks = (value) => {
        // console.log('select network', value);
        setSelectedNetwork(value);
    }


    const removeAddress = async (val, id) => {
        
    }

    const isInputNumber = (e) => {
        let ch = String.fromCharCode(e.which);
        if (!/[0-9]/.test(ch)) {
            e.preventDefault();
        }
    };

    const web3check = (value) => {
        // console.log('invalid', value.payout);
        let totalPercentage = 0;
        if (value.payout && value.payout.length > 0) {
            value.payout.map((item, id) => {
                if (item?.wallet_address && item.wallet_address !== undefined) {
                    if (!Web3.utils.isAddress(item?.wallet_address)) {       // web3 address validation
                        errorIndex[id] = false;
                    } else {
                        errorIndex[id] = true;
                    }
                }
                if (item?.percentage && item?.percentage !== undefined) {
                    totalPercentage = totalPercentage + +item?.percentage
                }
            })
            // console.log('total', totalPercentage, errorIndex)
            if (totalPercentage > PERCENTAGE_VALUE) {
                setPercentageError(true);
            } else {
                setPercentageError(false);
            }
            setShowPercentage(totalPercentage)

        }

    }

    return (
        <>
            <div className="games__wrap">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-1 mx-0 px-0"></div>
                        <div className="col-2 row align-items-end flex-column text-left d-none"></div>
                        <div className="col-9 p-0 res form-transp-bg">
                            <h3 className="oracleTextClass" style={{}}>Collections</h3>
                            <Formik
                                initialValues={{
                                    collection_name: collectionData ? collectionData.collection_name : '',
                                    creator_fee: collectionData ? collectionData.creator_fee : '',
                                    external_link: collectionData ? collectionData.external_link : '',
                                    description: collectionData ? collectionData.description : '',
                                    payout: walletDetails,
                                }}
                                enableReinitialize
                                // validationSchema={CollectionSchema}
                                onSubmit={(values, { setSubmitting, resetForm }) => {
                                    onFinish(values)
                                    // resetForm()
                                    // console.log('values', values)
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    /* and other goodies */
                                }) => (
                                    <form
                                        onSubmit={handleSubmit}
                                        className="edit-collection-form createNftForm"

                                    >
                                        {/* {console.log('values11=>', values)} */}
                                        <div className="row">
                                            {/* <div className="col-12">
                                            <h3 className="connect-wallet-btn" onClick={walletShowhide}>CONNECT WALLET</h3>
                                        </div> */}
                                            <div className="col-12">
                                                <p className="required-text"><span className="required-class">*</span> Required Fields</p>
                                                <div className="input-group form-group mt-4">
                                                    <div className="custom-file-old">
                                                        <label className="image-upload-lbl">Logo Collection Image <span className="required-class">*</span> </label>
                                                        <p>This image will also be used for navigation. 350 x 350 px recommended. {" "}(File types supported: jpg, jpeg, png, gif ){" "}
                                                        </p>
                                                        <label className="custom-file-label-old" htmlFor="inputGroupFile01">
                                                            <div className="image-preview-box coll-image add-image">
                                                                <img src={logoSrc} />
                                                            </div>
                                                        </label>
                                                        <input
                                                            name="image"
                                                            type="file"
                                                            onChange={(event) => fileUpload(event, "main")}
                                                            className="custom-file-input-none"
                                                            id="inputGroupFile01"
                                                        />
                                                    </div>
                                                    <button className="create-btn new-upload-btn mt-3 mt-sm-4 mb-5" type="button">
                                                        <label for="inputGroupFile01">Upload Logo</label>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="input-group form-group mt-4">
                                                    <div className="custom-file-old">
                                                        <label className="image-upload-lbl">Feature Image </label>
                                                        <p>(File types supported: jpg, jpeg, png, gif, Recommended Size: 180 x 180 px )</p>
                                                        <label className="custom-file-label-old" htmlFor="inputGroupFile02">
                                                            <div className="image-preview-box banner-coll add-feature">
                                                                <img src={featurSrc} />
                                                            </div>
                                                        </label>
                                                        <input
                                                            name="feature_image"
                                                            type="file"
                                                            onChange={(event) => fileUpload(event, "feature")}
                                                            className="custom-file-input-none"
                                                            id="inputGroupFile02"
                                                        />
                                                    </div>
                                                    <div className='col-12 p-0'>
                                                    <button className="create-btn new-upload-btn mt-3 mt-sm-4 mb-5" type="button">
                                                        <label for="inputGroupFile02">Upload Feature Image</label>
                                                    </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="input-group form-group mt-4">
                                                    <div className="custom-file-old">
                                                        <label className="image-upload-lbl">Banner Image </label>
                                                        <p>This image will appear at the top of your collection page. Avoid including too much text in this banner, as the dimensions change on different devices. 1400 x 1400 px recommended. {" "}(File types supported: jpg, jpeg, png, gif, Recommended Size: 320 x 330 px ){" "}</p>
                                                        <label className="custom-file-label-old" htmlFor="inputGroupFile03">
                                                            <div className="image-preview-box banner-coll-image add-banner">
                                                                <img src={bannerSrc} />
                                                            </div>
                                                        </label>
                                                        <input
                                                            name="banner_image"
                                                            type="file"
                                                            onChange={(event) => fileUpload(event, "banner")}
                                                            className="custom-file-input-none"
                                                            id="inputGroupFile03"
                                                        />
                                                    </div>
                                                    <button className="create-btn new-upload-btn mt-3 mt-sm-4 mb-5" type="button">
                                                        <label for="inputGroupFile03">Upload Banner Image</label>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group mt-3">
                                                    <label>Collection Name</label>
                                                    <Field
                                                        type="text"
                                                        className="nft-form-control"
                                                        name="collection_name"
                                                    />
                                                    {errors.collection_name ? <p className="ant-form-item-explain-error">{errors.collection_name}</p> : ''}
                                                </div>
                                            </div>


                                            <div className="col-12">
                                                <div className="form-group mt-3">
                                                    <label>External link </label>
                                                    <p>
                                                        EGC will included a link you can share and promote your
                                                        NFT. You can link your own webpage.
                                                    </p>
                                                    <Field
                                                        type="url"
                                                        className="nft-form-control"
                                                        name="external_link"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="form-group mt-3">
                                                    <label>Description</label>
                                                    <p>This will be included on your item's detail.</p>
                                                    <Field as="textarea" name="description" />
                                                </div>
                                            </div>


                                            <div className="nftform-action-item col-12 d-flex justify-content-between align-items-baseline">
                                                <div className="sensitive-content-section d-flex justify-content-between align-items-center">
                                                    {/* <img className="imgw-30" src={categoryIcon} /> */}
                                                    <div className="flex-column justify-content-between align-items-baseline">
                                                        <h3><i className="fa fa-th-list"></i>  Select one or more categories*</h3>
                                                        {!loading && selectedCategories.length > 0 ? selectedCategories.map((cat, key) => (
                                                            <span key={key}>{cat.name},</span>
                                                        ))
                                                            : ''} {selectedCategories.length > 0 ? '.etc' : ''}
                                                    </div>
                                                </div>
                                                <div className="rounded-circle-bg d-flex align-items-center justify-content-center">
                                                    <img className="imgw-30 cursor-pointer" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#categoryModal" src={GrouppopUpplus} onClick={reInitModal} />
                                                </div>
                                            </div>
                                            <div style={{ color: 'red' }}>{categoryError}</div>
                                            <div className="col-12">
                                                <div className="form-group mt-3">
                                                    <label>Links</label>
                                                    <div className="links-nft-form-item d-flex justify-content-between align-items-center position-relative mb-2">
                                                    <div className="links-icons">
                                                            <svg className="CollectionForm--link-logo" fill="#8A939B" viewBox="1 1 22 22"><path clip-rule="evenodd" d="M2 12C2 6.48 6.47 2 11.99 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 11.99 22C6.47 22 2 17.52 2 12ZM15.97 8H18.92C17.96 6.35 16.43 5.07 14.59 4.44C15.19 5.55 15.65 6.75 15.97 8ZM12 4.04C12.83 5.24 13.48 6.57 13.91 8H10.09C10.52 6.57 11.17 5.24 12 4.04ZM4 12C4 12.69 4.1 13.36 4.26 14H7.64C7.56 13.34 7.5 12.68 7.5 12C7.5 11.32 7.56 10.66 7.64 10H4.26C4.1 10.64 4 11.31 4 12ZM5.08 16H8.03C8.35 17.25 8.81 18.45 9.41 19.56C7.57 18.93 6.04 17.66 5.08 16ZM5.08 8H8.03C8.35 6.75 8.81 5.55 9.41 4.44C7.57 5.07 6.04 6.34 5.08 8ZM12 19.96C11.17 18.76 10.52 17.43 10.09 16H13.91C13.48 17.43 12.83 18.76 12 19.96ZM9.5 12C9.5 12.68 9.57 13.34 9.66 14H14.34C14.43 13.34 14.5 12.68 14.5 12C14.5 11.32 14.43 10.65 14.34 10H9.66C9.57 10.65 9.5 11.32 9.5 12ZM14.59 19.56C15.19 18.45 15.65 17.25 15.97 16H18.92C17.96 17.65 16.43 18.93 14.59 19.56ZM16.5 12C16.5 12.68 16.44 13.34 16.36 14H19.74C19.9 13.36 20 12.69 20 12C20 11.31 19.9 10.64 19.74 10H16.36C16.44 10.66 16.5 11.32 16.5 12Z" fill-rule="evenodd" id="icon/action/language_24px"></path></svg>
                                                            {/* <img src={search_link_icon}></img> */}
                                                        </div>
                                                        <input
                                                            onChange={(event) =>
                                                                getLiks(event.target.value, "link_1")
                                                            }
                                                            value={allLinks.link1 ? allLinks.link1 : ''}
                                                            type="text"
                                                            className="nft-form-control"
                                                            placeholder="www.website.com"
                                                        />

                                                    </div>
                                                    <div className="links-nft-form-item d-flex justify-content-between align-items-center position-relative mb-2">
                                                    <div className="links-icons"><img src={discord_icon}></img></div>
                                                        {/* <input
                                                            onBlur={(event) =>
                                                                getLiks(event.target.value, "link_2")
                                                            }
                                                            type="text"
                                                            defaultValue={allLinks.link2}
                                                            className="nft-form-control"
                                                            placeholder="www.discord.gg/test"
                                                        /> */}
                                                        <InputMask
                                                            mask="www.discord.gg\/*************************************************************************************
                                                            ***********************************************************************************************************"
                                                            formatChars={formatCharsAdd}
                                                            onChange={(event) =>
                                                                getLiks(event.target.value, "link_2")
                                                            }
                                                            maskChar=""
                                                            type="text"
                                                            value={allLinks.link2 ? allLinks.link2 : ''}
                                                            className="nft-form-control"
                                                            placeholder="www.discord.gg/YourDiscordHandle"
                                                        />
                                                    </div>
                                                    <div className="links-nft-form-item d-flex justify-content-between align-items-center position-relative mb-2">
                                                    <div className="links-icons"><img src={insta_icon}></img></div>
                                                        {/* <input
                                                            onBlur={(event) =>
                                                                getLiks(event.target.value, "link_3")
                                                            }
                                                            type="text"
                                                            defaultValue={allLinks.link3}
                                                            className="nft-form-control"
                                                            placeholder="www.instagram.com/test"
                                                        /> */}
                                                        <InputMask
                                                            mask="www.instagram.com\/*************************************************************************************
                                                            ***********************************************************************************************************"
                                                            formatChars={formatCharsAdd}
                                                            maskChar=""
                                                            onChange={(event) =>
                                                                getLiks(event.target.value, "link_3")
                                                            }
                                                            type="text"
                                                            value={allLinks.link3 ? allLinks.link3 : ''}
                                                            className="nft-form-control"
                                                            placeholder="www.instagram.com/YourInstagramHandle"
                                                        />
                                                    </div>
                                                    <div className="links-nft-form-item d-flex justify-content-between align-items-center position-relative mb-2">
                                                    <div className="links-icons">
                                                            <img src='/img/tiktok.png'></img>
                                                        </div>
                                                        {/* <input
                                                            onBlur={(event) =>
                                                                getLiks(event.target.value, "link_4")
                                                            }
                                                            type="text"
                                                            defaultValue={allLinks.link4}
                                                            className="nft-form-control"
                                                            placeholder="www.medium.com"
                                                        /> */}
                                                        <InputMask
                                                            mask="www.tiktok.com\/*************************************************************************************
                                                            ***********************************************************************************************************"
                                                            formatChars={formatCharsAdd}
                                                            maskChar=""
                                                            onChange={(event) =>
                                                                getLiks(event.target.value, "link_4")
                                                            }
                                                            type="text"
                                                            value={allLinks.link4 ? allLinks.link4 : ''}
                                                            className="nft-form-control"
                                                            placeholder="www.tiktok.com/YourTiktokHandle"
                                                        />

                                                    </div>
                                                    <div className="links-nft-form-item d-flex justify-content-between align-items-center position-relative mb-2">
                                                    <div className="links-icons">
                                                            <img src={twitter_icon}></img>
                                                        </div>
                                                        {/* <input
                                                            onBlur={(event) =>
                                                                getLiks(event.target.value, "link_5")
                                                            }
                                                            type="text"
                                                            defaultValue={allLinks.link5}
                                                            className="nft-form-control"
                                                            placeholder="www.t.me"
                                                        /> */}
                                                        <InputMask
                                                            mask="www.twitter.com\/*************************************************************************************
                                                            ***********************************************************************************************************"
                                                            formatChars={formatCharsAdd}
                                                            maskChar=""
                                                            onChange={(event) =>
                                                                getLiks(event.target.value, "link_5")
                                                            }
                                                            type="text"
                                                            value={allLinks.link5 ? allLinks.link5 : ''}
                                                            className="nft-form-control"
                                                            placeholder="www.twitter.com/YourTwitterHandle"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-12'>
                                                <h3 className="title-inner">Blockchain</h3>
                                                <p className="mb-3 mt-1 small-para">Select the blockchain where you'd like new items from this collection to be added by default.</p>
                                                <div className="d-flex justify-content-start align-items-start gap-10 cstom-select-box">
                                                </div>
                                                    {/* <select value={selectedNetwork} disabled={true} onChange={((event) => getNetworks(event.target.value))}>
                                                        <option value="">Select Network</option>
                                                        {networkList.length > 0 ? networkList.map((value, key) => (
                                                            <option key={key} value={value._id}>
                                                                {value.network}</option>
                                                        )) : ''}
                                                    </select> */}
                                                    <Select
                                                        getOptionLabel={(opt) => (
                                                            <div className="d-flex align-items-center">
                                                                <img
                                                                    src={chainImageHandler(opt?.network)}
                                                                    height="35px"
                                                                    width="35px"
                                                                />

                                                                <span className="d-block ml-2">
                                                                    {opt?.chain_label}
                                                                </span>
                                                            </div>
                                                        )}
                                                        value={selectedNetwork}
                                                        placeholder={'Select Network..'}
                                                        getOptionValue={(option) => option._id}
                                                        options={[selectedNetwork]}
                                                        // onChange={handleChange}
                                                        // onChange={(e) => { setNetworkSelected(e) }}
                                                        menuPosition="absolute"
                                                        menuPortalTarget={document.body}
                                                        isDisabled={true}
                                                    />
                                                    {/* <option value="1" class="imagebacked" style="background-image: url(/images/icons/bug.gif);" selected>Bug</option> */}
                                                
                                            </div>

                                            <div className="col-12">
                                                <div className="form-group mt-3">
                                                    <label>Creator Fee</label>
                                                    <p>Earn a percentage of the sale price every time one of your items is sold.</p>
                                                    <p>Adding multiple addresses may increase gas fees for buyers.</p>
                                                    <br />

                                                    {/* <Field
                                                        style={{ maxWidth: "100%" }}
                                                        name="creator_fee"
                                                        type="number"
                                                        className="nft-form-control"
                                                    />
                                                    {errors.creator_fee ? <p className="ant-form-item-explain-error">{errors.creator_fee}</p> : ''} */}
                                                    <FieldArray
                                                        name="payout"
                                                        render={(walletDetails) => (
                                                            <div className="col-12 ">
                                                                <div className='col-12 p-0'>
                                                                    <button
                                                                        className="create-btn mt-3 mt-sm-4"
                                                                        type="button" onClick={() => walletDetails.push({ wallet_address: "", percentage: "" })}
                                                                    >
                                                                        Add address
                                                                    </button>
                                                                </div>
                                                                <br />
                                                                {values.payout.map((payout, index) => (
                                                                    <><div key={index}>
                                                                        <div className='row reverse-row'>
                                                                            <div className='col-8'>
                                                                            <div className='form-group'>
                                                                                <Field
                                                                                    type='text'
                                                                                    name={`payout.${index}.wallet_address`}
                                                                                    placeholder='Please enter an address..'
                                                                                    // onChange={handleChange}
                                                                                    validate={web3check(values)}
                                                                                    style={{ maxWidth: "100%" }} className="nft-form-control" />
                                                                                {!errorIndex[index] && errorIndex[index] !== undefined ? (
                                                                                    <div role='alert' className="ant-form-item-explain-error">
                                                                                        Please enter valid address.
                                                                                    </div>
                                                                                ) : null}
                                                                            </div>
                                                                                    </div>
                                                                            <div className='col-3'>
                                                                            <div className='form-group'>
                                                                                <div className='percentage-field'>
                                                                                    <span>%</span>
                                                                                    <Field
                                                                                        name={`payout.${index}.percentage`}
                                                                                        type="text" onKeyPress={(e) => isInputNumber(e)}
                                                                                        className="nft-form-control" />
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-1 add-address-delete'>
                                                                                <button className="delete-btn" type='button'
                                                                                    // onClick={() => removeAddress(values.payout, index)}
                                                                                    onClick={() => walletDetails.remove(index)}
                                                                                >
                                                                                    <img src={delete_icon}></img>
                                                                                </button>
                                                                            </div>
                                                                        </div>

                                                                    </div></>
                                                                ))}
                                                            </div>
                                                        )}
                                                    />

                                                    {values.payout.length > 0 ? (
                                                        <div className='row total-show'>
                                                            <div className='col-8'>
                                                                {percentageError ? (
                                                                    <div role='alert' className="ant-form-item-explain-error percentage-error">
                                                                        Total creator earnings must not exceed {PERCENTAGE_VALUE}%.
                                                                    </div>) : ''
                                                                }
                                                            </div>
                                                            <div className='col-3 text-right'>
                                                                <h5 className='total-percent'>Total <span>{showPercentahe}%</span></h5>
                                                            </div>
                                                            <div className='col-1 text-center'>
                                                                <h6></h6>
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>


                                            <div className="nftform-action-item col-12 d-flex justify-content-between align-items-baseline">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    {/* <img className="imgw-30" src={sensitiveIcon} /> */}
                                                    <div className="sensitive-content-section flex-column justify-content-between align-items-baseline ml-3">
                                                        <h3><i class="fa fa-exclamation-triangle"></i> Explicit & Sensitive Content </h3>
                                                        <p>Set as explicit and sensitive content.</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <label className="switch switch-radio-class">
                                                        <input
                                                            onChange={(event) => explicitData(event.target.checked)}
                                                            type="checkbox"
                                                            checked={isSxplicity ? 'checked' : ''}

                                                        />
                                                        <span className="slider round" />
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <button
                                                    className="create-btn mt-3 mt-sm-4"
                                                    htmlType="submit"
                                                >
                                                    Update Collection
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div >
            {walletVisibility && <div className="connect-wallet-box-main">
                <h2 className="main-heading">CONNECT WALLET</h2>
                <div className="wallet-infobox px-4">
                    <div className="d-flex justify-content-start align-items-center gap-10 connect-wallet-heading-box">
                        <img src={Userimage} />
                        <h3 className="m-0">My Wallet</h3>
                    </div>
                    <p className="mt-3 mb-4">Connect with one of our available wallet, or create a new one.</p>
                    <div className="connect-wallet-options-items-wrap">
                        <div className="flex-column gap-10">
                            <div onClick={(() => connectWithWallet())} className="d-flex justify-content-start align-items-center gap-10  cursor-pointer">
                                <img src={EGC_Logo_wallet} />
                                <h3>EGC Wallet</h3>
                            </div>
                            <div onClick={(() => connectWithMetaMask())} className="d-flex justify-content-start align-items-center gap-10 cursor-pointer">
                                <img src={metamask__wallet} />
                                <h3>Metamask</h3>
                            </div>
                            <div onClick={(() => connectWithWallet())} className="d-flex justify-content-start align-items-center gap-10 cursor-pointer">
                                <img src={private_wallet} />
                                <h3>Trust Wallet</h3>
                            </div>
                            <div onClick={(() => connectWithWallet())} className="d-flex justify-content-start align-items-center gap-10 cursor-pointer">
                                <img src={coinBase_wallet} />
                                <h3>Coinbase Wallet</h3>
                            </div>
                            <div onClick={(() => connectWithWallet())} className="d-flex justify-content-center align-items-center gap-10 show-more-options">
                                <h3>Show more options</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            <NftFormPopups
                // selectCategory={(event, status, name) => selectCategory(event, status, name)}
                returnCategory={(categories) => selectCategory(categories)}
                selectedCategories={selectedCategories}

                returnProperty={(property) => {}}
                allProperties={[]}

                allLevels={[]}
                returnLabel={(label) => {}}

                // setProperties={{}}
                // allProperties={[]}
                // addProperty={{}}
                // removeProperty={{}}
                // categoryUpdate={comingCategoryList}
                // allLevels={[]}
                // addLevel={{}}
                // handelLevel={{}}
                // removeLevel={{}}
                // setIsSaveProperty={setIsSaveProperty}
                // setIsSaveLevel={setIsSaveLevel}
            />
        </>
    );

}
const mapStateToProps = state => ({
    islogin: state.auth.isLogin,
    auth: state.auth.auth
})

export default connect(mapStateToProps)(EditCollectionsPage)

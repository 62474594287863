import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./action";
import { CallApi } from "../../api/CallApi";
import { API_URLS } from "../../utils/app.constant";

function* getCollectionBasicDetails(action) {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_URLS.COLLECTION.BASIC_DETAILS,
      data
    );

    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.success
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (err) {
    action &&
      action.callbackError &&
      action.callbackError(err && err.data ? err.data : err);
  }
}

function* reportCollectionItem(action) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URLS.COLLECTION.REPORT_COLLECTION_ITEM, data);

    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.success
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }

  } catch (err) {
    action &&
    action.callbackError &&
    action.callbackError(err && err.data ? err.data : err);
  }
}

function* collectionSaga() {
  yield takeLatest(actionTypes.BASIC_DETAILS, getCollectionBasicDetails);
  yield takeLatest(actionTypes.REPORT_ITEM, reportCollectionItem)
}

export default collectionSaga;

import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { Form, Button, notification, Modal } from "antd";
import { SmileOutlined, CloseOutlined } from "@ant-design/icons";
import Select from "react-select";
import { chainImageHandler } from '../../utils/helper';
import Web3 from 'web3';
import { useWeb3React } from "@web3-react/core";
import abi_mint_1155 from '../../abi/ABI_Mint_1155.json';
import { useHistory } from "react-router-dom";
import useNFT from '../../hooks/useNFT';

const BurnAddress = ({islogin, auth}) => {
  const [networkSelected, setNetworkSelected] = useState(null);
  const [networkList, setNetworkList] = useState([
    {id: '0x7E94cC8Df951630856487994E6962043931E1B94', label: 'Binance', network: 'binance'},
    {id: '0x237B72a460264C4610841127A2BD8a9F8ef39ee6', label: 'Polygon', network: 'polygon'},
    {id: '0x237B72a460264C4610841127A2BD8a9F8ef39ee6', label: 'Ethereum', network: 'ethereum'}, 
    // 0x805f3e656575b777EB78C426D93d43DbD5eD17f6
  ]);
  const { account, library } = useWeb3React();
  const [loading, setLoading] = useState(false);
  const [mintAbi, setMintAbi] = useState(null)
  const history = useHistory();
  const { confirm } = Modal;
  const { ownerList, burnSelectedNft } = useNFT()


    useEffect(() => {
      setNetworkSelected(networkList[0]);
      setMintAbi(abi_mint_1155)
    }, [islogin])

    const isInputNumber = (e) => {
      let ch = String.fromCharCode(e.which);
      if (!/[0-9]/.test(ch)) {
          e.preventDefault();
      }
  };

  const burnItem = (data) => {
    confirm({
        title: 'Do you want to burn?',
        content:
          'When clicked the OK button, this item will be burned.',
        async onOk() {
          try {
            burnAddress(data)
        } catch (e) {
            return console.log('Oops errors!');
          }
        },
        onCancel() {},
      });
  };

  const burnAddress = (data) => {
    setLoading(true);
    const web3 = new Web3(library.provider)
    try {
      const params = {
        from: String(account), //current user address
        to: networkSelected.id, //contract address
    };
    const mintContract = new web3.eth.Contract(mintAbi,  networkSelected.id);
    params['data'] = mintContract.methods
    .burn(data.wallet_address, Number(data.token_id), +data.quantity)
    .encodeABI();
    // console.log('mintContract', mintContract, params);

    web3.eth
    .sendTransaction(params)
    .then(async (res) => {
      // console.log('transaction res', res);

        let burnParams = {
            quantity: +data.quantity,
            wallet_address: data.wallet_address 
        }
        const burnResponse = await burnSelectedNft(burnParams, networkSelected.id, data.token_id);
        await ownerList(networkSelected.id, data.token_id);

        if (res) {
            setLoading(false)
            history.push(`/profile/${auth?.user.username}`);
            notification.open({
                message: "Burn",
                description: "Burn Successful...!",
                icon: <SmileOutlined style={{ color: "#108ee9" }} />,
            });
            return;
        } else {
            setLoading(false)
            notification.open({
                message: "Error",
                description: "Something went wrong...!",
                icon: <CloseOutlined style={{ color: "#f4364c" }} />,
            });
            return;
        }
    }).catch((error) => {
        setLoading(false)
        notification.open({
            message: "Error",
            description: "Something went wrong... Make Sure Are Connected With Mainnet!",
            icon: <CloseOutlined style={{ color: "#f4364c" }} />,
        });
        return
    });

    } catch (error) {
      console.log('Burn error', error);
      setLoading(false);
      return;
    }
  };

  return (
    <>
    <div className="games__wrap">
        <div className="container">
            <div className="row justify-content-center">
            <div className="col-1 mx-0 px-0 d-none"></div>
            <div className="col-2 row align-items-end flex-column text-left d-none"></div>
            <div className="col-9 p-0 res form-transp-bg">
            <h3 className="oracleTextClass">Burn</h3>
            {!islogin ? (
                <div className="pt-lg-5">
                  <p className="connect-wallet-message">*Please connect your wallet to continue.</p>
                  <button
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    className="connect-wallet-btn"
                  >
                    Connect Your Wallet
                  </button>
                </div>
              ) : (
                <Form
                className="item-form createNftForm  no-hover"
                onFinish={burnItem}
                initialValues={{
                   quantity: 1, token_id: "", wallet_address: "" 
                  }}
                >
                    <div className='row'>
                      <div className='col-12'>
                    <div className="form-group mt-3">
                    <label>
                          Network<span className="required-class">*</span>{" "}
                        </label>                      
                      <Select
                        getOptionLabel={(opt) => (
                          <div className="d-flex align-items-center">
                            <img
                              src={chainImageHandler(opt?.network)}
                              height="35px"
                              width="35px"
                            />

                            <span className="d-block ml-2">
                              {opt?.label}
                            </span>
                          </div>
                        )}
                        placeholder={'Select Network..'}
                        value={networkSelected}
                        getOptionValue={(option) => option.id}
                        options={networkList}
                        onChange={(e) => { setNetworkSelected(e) }}
                        menuPosition="absolute"
                        menuPortalTarget={document.body}
                      />
                    </div>
                    </div>

                    <div className="col-12">
                      <div className="form-group mt-3">
                        <label>
                          Wallet Address<span className="required-class">*</span>{" "}
                        </label>
                        <Form.Item
                          name="wallet_address"
                          rules={[
                            {
                              required: true,
                              message: "Please input wallet address!",
                            },
                            {
                              message: 'Invalid wallet address!',
                              validator: (_, value) => {
                                  if (!Web3.utils.isAddress(value)) {
                                      return Promise.reject();
                                  } else {
                                      return Promise.resolve();
                                  }
                              }
                          },
                          ]}
                        >
                          <input
                            type="text"
                            className="nft-form-control mb-1"
                            name="wallet_address"
                            placeholder="Enter Wallet Address"
                            />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-group mt-3">
                        <label>
                          Quantity<span className="required-class">*</span>{" "}
                        </label>
                        <Form.Item
                          name="quantity"
                          rules={[
                            {
                              required: true,
                              message: "Please input item name!",
                            },
                          ]}
                        >
                          <input
                            type="text"
                            className="nft-form-control mb-1"
                            name="quantity"
                            placeholder="Enter Quantity"
                            onKeyPress={(e) => isInputNumber(e)}
                            />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group mt-3">
                        <label>
                          Token<span className="required-class">*</span>{" "}
                        </label>
                        <Form.Item
                          name="token_id"
                          rules={[
                            {
                              required: true,
                              message: "Please input token number!",
                            },
                          ]}
                        >
                          <input
                            type="text"
                            className="nft-form-control mb-1"
                            name="token_id"
                            placeholder="Enter Token"
                            onKeyPress={(e) => isInputNumber(e)}
                            />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="col-12">
                      <button
                        className="create-btn mt-3 mt-sm-4 mb-5 burn-color"
                        type="submit"
                      >
                        {!loading ? "Burn" : "Please Wait..."}
                      </button>
                    </div>
                    </div>
                </Form>
              )}
            </div>
            </div>
        </div>
    </div>
    </>
  )
}

const mapStateToProps = (state) => ({
    islogin: state.auth.isLogin,
    auth: state.auth.auth,
  });

  export default connect(mapStateToProps)(BurnAddress);

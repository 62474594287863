export const actionTypes = {
    GET_NFT: 'GET_NFT',
    LOADING:'LOADING',
    GET_NFT_SUCCESS: 'GET_NFT_SUCCESS',   
    ALL_NFTS: 'ALL_NFTS',   
    UPDATE_PROFILE:'UPDATE_PROFILE'
};

export function getnft(payload) {
   return { type: actionTypes.GET_NFT, payload };
}

export function nftGetSuccess(payload) {
   return { type: actionTypes.GET_NFT_SUCCESS, payload };
}

export function allNft(payload) {
   // console.log('all nft action', payload);
   return { type: actionTypes.ALL_NFTS, payload };
}

export function logout() {
   return { type: actionTypes.LOGOUT };
}

export function updateUserProfile(profile) {
   return { type: actionTypes.UPDATE_PROFILE ,profile};
}

export const SAGA_ACTIONTYPES = {
   IMPORT : {
      IMPORT_NFT: "SAGA_ACTION/IMPORT_NFT",
      BULK_UPLOAD: 'SAGA_ACTION/BULK_UPLOAD',
      CSV_LIST: 'SAGA_ACTION/CSV_LIST'
   },
   NFT_LISTING:"SAGA_ACTION/NFT/LISTING",
   REVEAL_MYSTERY_BOX: "SAGA_ACTION/NFT/REVEAL_MYSTERY_BOX",
   GET_OWNER_LIST: "SAGA_ACTION/NFT/GET_OWNER_LIST",
   GET_REVEALED_LIST: "SAGA_ACTION/NFT/GET_REVEALED_LIST",
   GET_MYSTERY_BOX_TOKEN: "SAGA_ACTION/NFT/GET_MYSTERY_BOX_TOKEN",
   REPORT_ITEM:"SAGA_ACTION/NFT/REPORT_ITEM",
   UNLOCKABLE_CONTENT:"SAGA_ACTION/NFT/UNLOCKABLE_CONTENT",
   FEEDBACK_ADD: "SAGA_ACTION/FEEDBACK_ADD"
}
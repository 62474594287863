import React from "react";
import { connect } from "react-redux";
import { notification, Spin } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import TextInput from "../FormElements/TextInput";
import { useNftApi } from "../../hooks/api/nftApiHook";
import { CloseOutlined, SmileOutlined } from "@ant-design/icons";
import { useCollectionApi } from "../../hooks/api/collectionApiHook";

const reportFormSchema = () => {
  return yup.object().shape({
    reason: yup.string().required("Reason is required"),
    comment: yup
      .string()
      .required("Comment is required")
      .min(10, "Minimum 10 characters"),
  });
};

const ReportForm = (props) => {
  const { reportItem } = useNftApi();
  const { reportCollectionItem } = useCollectionApi();

  const { otherFormData, appLoaderStatus, successHandler, auth, type } = props;

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(reportFormSchema()),
  });
  console.log('other-form-data', otherFormData);
  const onSubmit = async (values) => {
    let formValues = { ...values, ...otherFormData, user_id: auth?.user?._id };
    if (type === 'nft') {
    reportItem(
      formValues,
      (message, resp) => {
        successHandler && successHandler();

        notification.open({
          message: "Done",
          description: message,
          icon: <SmileOutlined style={{ color: "#108ee9" }} />,
        });
      },
      (message, error) => {
        notification.open({
          message: " ",
          description: message,
          icon: <CloseOutlined style={{ color: "#f4364c" }} />,
        });
      }
    );
    } else if (type === 'collection') {
      reportCollectionItem ( formValues, 
        (message, resp) => {
          successHandler && successHandler();
          notification.open({
            message: "Done",
            description: message,
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          });
        },
        (message, error) => {
          notification.open({
            message: " ",
            description: message,
            icon: <CloseOutlined style={{ color: "#f4364c" }} />,
          });
        }
        )
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <div className="cstom-select-box">
          <select {...register("reason")} className="">
            <option value="">Select a reason for report</option>
            <option value="Fake collection or possible scam">
              Fake collection or possible scam
            </option>
            <option value="Explicit and sensitive content">
              Explicit and sensitive content
            </option>
            <option value="Spam">Spam</option>
            <option value="Other">Other</option>
          </select>
          </div>
          <div className="text-danger">{errors?.reason?.message}</div>
        </div>

        

        <div className="form-group">
          <textarea
            {...register("comment")}
            className=""
            placeholder="Please Enter Details Here"
          ></textarea>
          <div className="text-danger">{errors?.comment?.message}</div>
        </div>
        

        <div className="text_report_btn">
          <button
            disabled={appLoaderStatus}
            className="nft-sub-btn"
            type="submit"
          >
            {appLoaderStatus ? "Please wait..." : "Send"}
          </button>
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    appLoaderStatus: state.comman.appLoaderStatus,
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(ReportForm);

import { combineReducers } from 'redux';

import Auth from './auth/reducer';
import NFTDetails from './nft/reducer'
import profile from'./profile/reducer'
import collections from './collections/reducer'
import comman from './comman/reducer'
import crators from './crators/reducer'
import networkList from './networks/reducer'
import userReducer from './user/userReducer';


const rootReducer = combineReducers({
    auth: Auth,
    NFTDetails,
    profile,
    collections,
    comman,
    networkList,
    crators,
    user:userReducer,
});

export default rootReducer;

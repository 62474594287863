import React from "react";
import Footer from "../components/Footer/Footer";
import ModalSearch from "../components/Modal/ModalSearch";
import ModalMenu from "../components/Modal/ModalMenu";
import Scrollup from "../components/Scrollup/Scrollup";
import ExploreComponent from "../components/Explore/ExploreComponent";

export default function ExplorePage(props) {
  return (
    <div className="games__wrap exploreComponent main_wrp " id="explorePage">
      <ExploreComponent props={props} isvisibale={true} />
      <Footer />
      <ModalSearch />
      <ModalMenu />
      <Scrollup />
    </div>
  );
}

// export const smartChainUrl = "https://bscscan.com/tx/"
export const smartChainUrl = "https://testnet.bscscan.com/tx/"

export const addrs="0x908793fA20a4F69CBafc03112329685271879406"
// export const userid="0x6a17a6be25b2bbbd3f6dce4444ffc016aec77fc3"
// export const nftContractAddress  = "0xfdE688AE5C3D7576B42D60DcD9a43F8ECFe95583" //main network
export const nftContractAddress = "0x7D096533C8a9051c29aa083B843744E67734597d" //test contract address

// export const nftContract = "0x285414656D70F27e95FdC7723cBfFa291c775893" //main contract address
// market place constract address
// export const marketPlaceAddress = "0xF969af1Fd01BB494737d77B023510DA52270D60B"// main marketplace address
export const marketPlaceAddress =  '0x35EA987f0Da3249cbd7ceEA5e0776Ad4228C2C1C' //test network
// 

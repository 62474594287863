import { useEffect, useState } from 'react';
import React from 'react';
import moment from 'moment';
import { MethodName } from '../../utils/app.constant'

const ItemPrice = ({ priceList, render, network }) => {
  let endSeconds = new Date(priceList?.end_date).getTime();
  let startSeconds = new Date(priceList?.start_date).getTime();
  let priceDifference = priceList?.start_price - priceList?.end_price;
  let auctionPeriod = endSeconds - startSeconds;

  const exchRate = network?.exchange_rate_usd || 0;
  const [timeLeft, setTimeLeft] = useState(-1);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    //start timer for reducing price in case of dutch auction
    if (priceList) {
      if (priceList?.start_price) {
        setPrice( priceList.highest_bid_price || priceList.start_price);
      }
      if (
        priceList?.method === MethodName[2] &&
        moment(priceList?.start_date).isSameOrBefore(new Date()) &&
        moment(new Date()).isSameOrBefore(priceList?.end_date)
      ) {
        let timeToEnd = endSeconds - Date.now();
        if (timeToEnd > -1) {
          setTimeLeft(timeToEnd / 1000);
        }
      }
    }
  }, [priceList]);

  useEffect(() => {
    // reduce price per second for dutch auction
    if (timeLeft <= 0) return;
    let perSecondDiff = priceDifference / auctionPeriod;
    let timePassed = Date.now() - startSeconds + 1;
    let currentPrice = priceList.start_price - timePassed * perSecondDiff;
    const intervalId = setInterval(() => {
      let tempPrice = currentPrice - perSecondDiff;
      setPrice(tempPrice < priceList.end_price ? priceList.end_price : tempPrice);
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return <>{render(price, exchRate * Number(price))}</>;
};

export default ItemPrice;

import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import { Modal, Input, Checkbox, notification } from "antd";
import ViewUnlockableContent from "../Modal/ViewUnlockableContent";
import { useWeb3React } from "@web3-react/core";
import { useUserApi } from "../../hooks/api/userApiHook";
import { CloseOutlined } from "@ant-design/icons";
import { useNftApi } from "../../hooks/api/nftApiHook";

const UnlockContentModal = forwardRef((props, ref) => {
  const { library, account } = useWeb3React();
  const { isOpen, hideModal, contractAddress, tokenId, isOwner } = props;
  const { getNonce } = useUserApi();
  const { nftUnlockableContent } = useNftApi();
  const [content, setContent] = useState({
    loading: true,
    isOwner: false,
    data: null,
    msg: null,
  });
  const handleCancel = () => {
    let tempContent = { ...content };
    tempContent.loading = true;
    tempContent.data = null;
    tempContent.isOwner = false;
    tempContent.msg = null;
    setContent(tempContent);
    hideModal && hideModal();
  };
  useImperativeHandle(ref, () => {
    return {
      showContentHandler: showContentHandler,
    };
  });

  const signHandler = () => {
    return new Promise(async (resolve, reject) => {
      try {
        if (!library) {
          reject({ success: false, message: "Web3 library is not set" });
        }
        getNonce(
          { wallet_address: account },
          (message, resp) => {
            if (!resp.nonce) {
              reject({ success: false, message: "Unable to get the nonce" });
            }
            message = `Please verify your address before continuing. Your Nonce Id ${resp.nonce}`;
            library.provider
              .request({
                method: "personal_sign",
                params: [message, account],
              })
              .then((signature) => {
                resolve({ success: true, signature });
              })
              .catch((err) => {
                reject({ success: false, message: "Signature rejected" });
              });
          },
          (message, error) => {
            reject({ success: false, message: "Unable to get the nonce" });
          }
        );
      } catch (error) {
        reject({ success: false, message: error?.message });
      }
    });
  };

  const showContentHandler = async (urlNetwork, contractAddress, tokenId) => {
    try {
      if(!isOwner) {
        let tempContent = { ...content };
          tempContent.loading = false;
          tempContent.data = "You need to be the owner of this NFT before you can see this content";
          // tempContent.msg = message;
          tempContent.isOwner = isOwner;
          
          setContent(tempContent);
      } else {
        const singResp = await signHandler();
        let paramsToSend = {
          urlParams: { chain_name: urlNetwork, contract_address: contractAddress, token_id: tokenId },

          signature: singResp.signature,
          wallet_address: account,
        };

        nftUnlockableContent(
          paramsToSend,
          (message, resp) => {
          
            let tempContent = { ...content };
            tempContent.loading = false;
            tempContent.data = resp.content ? resp.content : null;
            tempContent.msg = message;
            if (!resp.content) {
              tempContent.isOwner = false;
            } else {
              tempContent.isOwner = true;
            }

            setContent(tempContent);
          },
          (message, error) => {
            notification.open({
              message: "",
              description: message,
              icon: <CloseOutlined style={{ color: "#f4364c" }} />,
            });
            handleCancel();
          }
        );
      }
    } catch (err) {
      notification.open({
        message: "",
        description: `${err.message || "Please try again later"} `,
        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
      });
      handleCancel();
    }
  };

  return (
    <>
      <Modal
        title={
          <>
            <i className="fa fa-lock" aria-hidden="true"></i> Unlockable Content
          </>
        }
        visible={isOpen}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
        destroyOnClose={true}
      >
        <ViewUnlockableContent content={content} />
      </Modal>
    </>
  );
});

export default UnlockContentModal;

import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import _, { find } from 'lodash';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import FollowerList from '../components/Modal/FollowerList'
import { NavLink, Link } from 'react-router-dom';
import moment from 'moment';
import { Modal, Spin } from 'antd';
import { notification } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import { SmileOutlined, CloseOutlined, ConsoleSqlOutlined } from "@ant-design/icons";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from 'web3'
import ImageViewer from 'react-simple-image-viewer';
import { useHistory } from 'react-router';
import profileBanner from '../image/nft-detail/profileBanner.png'
import profileHeart from '../image/nft-detail/profileHeart.png'
import profileImageNFT from '../image/nft-detail/profileImageNFT.png'
import profileEmail from '../image/nft-detail/profileEmail.png'
import profileShare from '../image/nft-detail/profileShare.png'
import EditProfile from '../image/nft-detail/edit-svgrepo-com.svg'
import ethereumIcon from '../image/category/ethereum.svg'
import NftList from '../components/NftList';
import offersImage from '../image/nft-detail/offersImage.svg'
import ActivityImage from '../image/nft-detail/ActivityImage.svg'
// import collectedImage from '../image/nft-detail/collectedImage.svg';
import collectedImage from '../image/nft-detail/my-collection.png';
// import createImage from '../image/nft-detail/create-icon.png'
import createImage from '../image/nft-detail/create-collection.png'
import collectedIcon from '../image/nft-detail/collected.png';
import mysteryIcon from '../image/nft-detail/mystery-box.png';
import collectionPost1 from '../image/category/ethereum.svg'
import followImage from '../image/nft-detail/Heart_Icon.svg'
import SelectArrow from '../image/SelectArrow.png'
import TwitterIcon from '../image/nft-detail/Twitter.png'
import FacebookIcon from '../image/nft-detail/FB.png'
import InstaIcon from '../image/nft-detail/IG.png'
import bulbIcon from '../image/nft-detail/created.png'
import SidebarmenuComponent from '../themes/SidebarmenuComponent'
import List from '../components/Activity/List'
import { offerscolumns } from '../utils/tableColumns'
import OffersList from '../components/Offers/OffersList'
import ProfileLoader from '../components/Skeletons/ProfileSkeleton';
import PageNotFound from './PageNotFound';
import abi from "../abi/nft.json"
import { nftContractAddress } from '../abi/address';
// import { Pagination } from "react-custom-pagination";
import Pagination from '@mui/material/Pagination';
import noProfileImage from '../image/no-image-profile.jpg';
import noProfileCover from '../image/no-image-cover.jpg'


import Follow from '../components/Follow/Follow'
//custom hooks
import useAuth from '../hooks/useAuth';
import useCollection from '../hooks/useCollection'
import useNFT from '../hooks/useNFT';
import { useUserApi } from "../hooks/api/userApiHook";
import useComman from '../hooks/useComman';
import useFollewer from '../hooks/useFollwers'
import ShareContentModal from '../components/Modal/ShareContentModal';
import { URLS } from '../utils/app.constant';
import { concatHostUrl } from '../utils/helper';
import FollowUnfollowBtn from '../components/Follow/FollowUnfollowBtn';
import useMoralisService from '../hooks/useMoralisService';
import ImportNft from '../components/ImportNftCollection/ImportNft';
import ImportCollection from '../components/ImportNftCollection/ImportCollection';
import MasterNftList from '../components/NftList/MasterNftList';
import { addHttps } from '../utils/helper'
import ReadMoreContent from '../components/Common/ReadMoreContent';

const web3 = new Web3(Web3.givenProvider)
const metaurl = "https://ss97p5m6hknh.usemoralis.com:2053/server/functions/getNftItemById?{ApplicationId}&{id}"

const provider = new WalletConnectProvider({
    rpc: {
        56: "https://bsc-dataseed1.binance.org",
        // 97:"https://data-seed-prebsc-1-s1.binance.org:8545/",
    },
    chainId: 56,
    network: "binance",
    qrcode: false,
    qrcodeModalOptions: {
        mobileLinks: [
            "metamask",
            "trust",
        ]
    }
});

const antIcon = <LoadingOutlined style={{ fontSize: 26 }} spin />;

function NftDetailPage2({ props, islogin, auth, isloading, profileUser, collection, total, page, nft, collectionsTotal, collectionsPage }) {

    const [userinfo, setUserinfo] = useState(false);
    const [tempMycollections, settempMycollections] = useState([]);
    const [activatedTab, setactivatedTab] = useState('collections');
    const [collectio1n, setCollection] = useState(false);
    const [collectionList, setcollectionList] = useState([])
    const [transction, setTransction] = useState(false)
    const [mystery, setMystery] = useState(false)
    const [offer, setOffer] = useState(false)
    const [isOwner, setOwner] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [allNftIds, setAllids] = useState([])
    const [socialMedia, setSocialMedia] = useState(false)
    const [loading, setLoading] = useState(true)
    const [submitting, setSubmitting] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isFollow, setIsFollow] = useState(false)

    const [senderAddress, setSenderAddress] = useState(false)
    const [nftAddress, setNftAddress] = useState(false)
    const [count, setCount] = useState(1)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [web3, setWeb3] = useState(new Web3(provider))
    const [metaweb3, setMetaWeb3] = useState(new Web3(Web3.givenProvider))
    const history = useHistory()
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [pagination, setPagination] = useState(1)
    const [skip, setSkip] = useState(9)
    const [Type, setType] = useState('')
    const [showShareModal, setShowShareModal] = useState(false)

    const [activePage, setActivePage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [nftList, setNftList] = useState([])
    const [followerListPopup, setFollowerListPopup] = useState(false);
    const [followerList, setFollowList] = useState([]);

    const ref = useRef(false)

    //pagination 
    let collectionTotalPages = collectionsTotal / 9
    collectionTotalPages = parseInt(collectionTotalPages) === 0 ? 1 : collectionTotalPages

    let nftTotalPage = parseInt((total + 9 - 1) / 9)
    // console.log('total',total)
    //custom hooks functions
    const { getSingleUser } = useAuth()
    const { collectionFilter } = useCollection()
    const { nftWithPagination } = useNFT()
    const { activityOfCollection } = useComman()
    const { create, getFollowerFilter } = useFollewer()
    const { fetchFollowData } = useUserApi();
    // const { findAllById, findOne } = useMoralisService()




    useEffect(() => {

        if (islogin && profileUser?._id && auth?.user?._id && profileUser?._id === auth?.user?._id) {
            setOwner(true)
            getFollowers();
        } else {
            setOwner(false)
        }
    }, [islogin, profileUser])



    useEffect(() => {
        if (props.match?.params?.id) {
            userDetails(props.match.params.id);
        }

    }, [props.match?.params?.id]);

    const refreshDataHandler = () => {
        userDetails(props.match.params.id);
    }

    useEffect(() => {
        setcollectionList(collection)
    }, [collection])

    /* history.listen((location, action) => {
         if (location.pathname.search('profile') > 0) {
             userDetails(location.pathname.replace('/profile/', ''))
         }
     })*/

    const openImageViewer = (img) => {
        var arr = []
        arr.push(img)
        setCurrentImage(arr);
        setIsViewerOpen(true);
    };

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const rendomNumber = () => {
        var min = 1;
        var max = 100;
        var rand = min + (Math.random() * (max - min));
        return rand
    }

    const userDetails = async (id) => {
        if (props.match.params) {
            const filter = {
                username: id
            }
            var user = await getSingleUser(filter)
            user = user.user
            if (user) {
                setSocialMedia(user.social_links)
                GetCollection(user);
                //it will use for mystery box

                // var allMystery = await findAllById('NFT_Items','temp_nft_id',user[0].get('user_id').id)
                // allMystery.map(async(value,key)=>{
                //     var updateMystery = {
                //         owner_id:user[0].get('user_id'),
                //         temp_nft_id:undefined
                //     }
                //     await updateData('NFT_Items',updateMystery,'objectId',value.id)
                // })
            }
        }
    }

    const fetchCollection = async (user, page = 1) => {
        if (user) {
            const filter = {
                user_id: user._id,
                page
            }

            let response = await collectionFilter(filter)
            // await setCollection(response.latestCollections)            
            // setPagination(response.total)
            // const nftresponse = await findAllById('NFT_Items', 'user_id', user.get('user_id'))
            // setPagination(count)
            // if(crntuser){
            //     await setOwner(crntuser.id === user.get('user_id').id ? true : false)
            // }
            // await setNft(nftresponse)
            // getFollow(crntuser,user)
        }
    }

    const fetchNft = () => {

    }

    const GetCollection = async (user) => {
        setLoading(true)
        fetchCollection(user)
        setLoading(false)
    }

    const getFollowers = async () => {
        var data = {
            to_user_id: profileUser?._id,
            type: 'user'
        }
        let response = await getFollowerFilter(data)
        setFollowList(response.offer)
    }

    const NFTFilter = async (type) => {
        setNftList([]);
        setActivePage(1);
        // setLastPage(1)

        var user = auth?.user
        // if (user) {
        if (type === 'created') {
            setType(type)
            handlePage('nft', 'created', 1)
            /*const filter = {
                user_wallet: profileUser.wallet_address,
                // owner_wallet: profileUser.wallet_address,
                page: 1
            }*/
            // const nfts = await nftWithPagination(filter)
            // setPagination(nfts.total)
        }
        if (type === 'collected') {
            setType(type)
            handlePage('nft', 'collected', 1)
            /*const filter = {
                owner_wallet: profileUser.wallet_address,
                collected_nft: true
            }
            _getNftProducts(filter)*/
            // const nfts = await nftWithPagination(filter)
            /*let nftResponse = nfts.latestNFT_items.filter((value, key) => value?.owner_id !== value?.user_id)
            setPagination(nftResponse.length)*/
            // setPagination(nfts.total)
        }
        if (type === 'collections') {
            fetchCollection()
        }
        // }
        setactivatedTab(type)
    }

    const GetOffers = async (type) => {
        // var created = await filterAndReturnID('NFT_Items','user_id',userinfo.get('user_id'))

        // var offers = await filterInArray('nft_offers','nft_id',created)
        // await setOffer(offers)
        // setactivatedTab(type)
    }

    const GetNftByorder = async (type) => {
        // var nftresponse = await OrderBy(type,'user_id',userinfo.get('user_id'))
        // await setNft(nftresponse)
    }

    const GetNewNfts = async () => {
        var date = new Date()
        var startdate = moment();
        startdate.subtract(3, 'd');
        startdate.format('DD-MM-YYYY')
        var from = startdate.valueOf()
        var to = date.getTime()
        // var response = await RecentlyListed(from,to,'user_id',userinfo.get('user_id'))
        // await setNft(response)
    }

    const Filter = (typeOfFilter) => {
        setLoading(true)
        if (typeOfFilter === 'asc' || typeOfFilter === 'desc') {
            GetNftByorder(typeOfFilter)
        }
        if (typeOfFilter === 'recent') {
            GetNewNfts()
        }
        setLoading(false)

    }
    const activity = async (type) => {
        var user = userinfo

        if (user) {
            var tran = await activityOfCollection({ user_id: user._id })
            setTransction(tran)
        }
        setactivatedTab(type)
    }

    const getMonthName = (date) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const d = new Date(date);
        return monthNames[d.getMonth()] + ' ' + d.getFullYear()
    }

    const MystresBoxData = async () => {
        var filter = {
            user_id: userinfo.get('user_id').id
        }
        // var created = await findAllWithMany('mystery_box_nft',filter)
        // if(created.length > 0){
        //     setMystery(created)
        // } 
        // else{
        //     setMystery(false)
        // }
    }

    const GetMystresBox = async (type) => {
        //MystresBoxData()
        setactivatedTab(type)
    }

    const sendMystery = async () => {

        // var user = await getUserByAddress(senderAddress.toLowerCase())
        // if (user.length > 0) {   
        //     var data={
        //         user_id:user[0].objectId,
        //     }

        //     await updateData('mystery_box_nft',data,'objectId',nftAddress) 
        //     await MystresBoxData()
        //     setNftAddress(null)
        //     notification.open({
        //         message: "Success",
        //         description: "Mystery Box Successfully send to the given address!",
        //         icon: <SmileOutlined style={{ color: "#f4364c" }} />,
        //     });        
        // } else {
        //     notification.open({
        //         message: "Error",
        //         description: "There is no user with given wallet address please check the wallet addres...!",
        //         icon: <CloseOutlined style={{ color: "#f4364c" }} />,
        //     });
        // }
    }
    const Open = async (nftId) => {
        // var getMysteryData = await findOne('mystery_box_nft','objectId',nftId)

        // var user = await currentUser()
        // await setSubmitting(true)
        // var responsedata
        // if(!await window?.ethereum?._metamask.isUnlocked()){
        //     try{
        //         provider.networkId = 56;

        //         await provider.enable()
        //         var web3 = new Web3(provider)
        //         const contract = new web3.eth.Contract(abi, nftContractAddress)
        //         responsedata = await contract
        //                                     .methods
        //                                     .mint(
        //                                         user.get('ethAddress'), 
        //                                         metaurl
        //                                     ).send({from:user.get('ethAddress')});

        //     }catch(error){
        //         setSubmitting(false)
        //         return 
        //     }
        // }else{
        //     try{
        //         const contract = new metaweb3.eth.Contract(abi, nftContractAddress)
        //         responsedata = await contract
        //                                     .methods
        //                                     .mint(
        //                                         user.get('ethAddress'), 
        //                                         metaurl
        //                                     ).send({from:user.get('ethAddress')});

        //     }catch(error){
        //         if(error.code === -32602){
        //             alert('Please Click On Lock Button in You Metamask For Fully Logout!')
        //         }
        //         setSubmitting(false)
        //         return 
        //     }
        // }

        // const tokenId = responsedata.events.Transfer.returnValues.tokenId;
        // var token_id = tokenId;
        // var mintData = {
        //     token_id:token_id,
        //     isMystery:false,
        //     temp_nft_id:undefined,
        //     categories_id:getMysteryData[0].get('categories_id'),
        //     description:getMysteryData[0].get('description'),
        //     item_image:getMysteryData[0].get('item_image'),
        //     item_name:getMysteryData[0].get('item_name'),
        //     owner_id:user,
        // }

        // var updateNft = await saveData('NFT_Items',mintData)
        // if(updateNft){
        //     notification.open({
        //         message: "Success",
        //         description: "NFT Successfully Mint!",
        //         icon: <SmileOutlined style={{ color: "#f4364c" }} />,
        //     }); 
        //     setCount(rendomNumber())
        //     var delet = await deleteData('mystery_box_nft','objectId',nftId)
        //     setTimeout(()=>{
        //         MystresBoxData()
        //     },1000)
        // }
        // else{
        //     notification.open({
        //         message: "Error",
        //         description: "Something went wrong!",
        //         icon: <CloseOutlined style={{ color: "#f4364c" }} />,
        //     });
        // }
    }

    const sendMysteryModal = async (id) => {
        setNftAddress(id)
        setIsModalVisible(true)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const getFollow = async (curretUser, userinfo) => {
        // var currentUserProfile = await findOne('Profiles','user_id',curretUser)
        // var profileUser = userinfo
        // if(curretUser){
        //     var filter = {
        //         'from_user_id':currentUserProfile.length > 0 ? currentUserProfile[0]:curretUser.get('usernam'),
        //         'to_user_id':profileUser
        //     }
        //     var res = await findAllWithMany('follow',filter)
        //     setIsFollow(res.length > 0 ? true:false)
        //     return res
        // }
    }

    const getFollowing = async (type) => {
        var data = {
            from_user_id: userinfo._id,
        }
        // let response = await getFollowerFilter(data)
        // response = response.offer
        // setFollowList(response)
        setactivatedTab(type)
    }

    const nextCollection = async (page) => {
        // var  user = await findOne('Profiles', 'username', props.match.params.id)
        // if (user.length > 0) {            
        //     var [user] = user
        //     var sk = page === 1 ? 0 : (9 * (page - 1))  
        //     const response = await findAllById('Collections', 'user_id',user.get('user_id'),100,'',sk )    
        //     // const count = await findAllById('Collections', 'user_id',user.get('user_id'),9,'yes' ) 
        //     await setCollection(response)
        // }
    }

    const nextNft = async (e, p) => {
        let user = auth?.user
        const filter = {
            user_wallet: user.wallet_address,
            owner_wallet: user.wallet_address,
            page: p
        }
        const nfts = await nftWithPagination(filter)
    }

    const shareProfileHandler = (e) => {
        e.preventDefault();
        setShowShareModal(true)
    }

    const handlePage = async (entityType, selectedTab, page) => {
        if (entityType === 'nft') {
            const filter = {
                child_collection: true,
                page
            }
            if (selectedTab === 'created') {
                filter['user_wallet'] = profileUser.wallet_address
            } else if (selectedTab === 'collected') {
                filter['owner_wallet'] = profileUser.wallet_address
                filter['collected_nft'] = true
            }
            _getNftProducts(filter)
        } else if (entityType === 'collection') {
            _getCollectionProducts(page)
        }
    }

    const _getCollectionProducts = async (pageNumber = 1) => {
        let user = auth?.user;
        let collectionArr = [];
        const filter = {
            user_id: user._id,
            pageNumber
        }
        var { total, latestCollections, page, last_page } = await collectionFilter(filter);
        collectionArr = [...collectionList, ...latestCollections]
        // console.log('response', response, filter);
        setActivePage(page);
        setcollectionList(collectionArr)
        setLastPage(last_page)
    }

    const _getNftProducts = async (filter) => {
        // let user = auth?.user;
        // console.log('user: ', user)
        let arr = [];
        // const filter = {
        //     user_wallet: user.wallet_address,
        //     // owner_id: user.wallet_address,
        //     page: pageNumber
        // }
        var { total, latestNFT_items, page, last_page } = await nftWithPagination(filter);
        if (page == 1) {
            arr = latestNFT_items
        } else {
            arr = [...nftList, ...latestNFT_items]
        }
        /*if (nftList.length === 0) {
            arr = [...nft, ...latestNFT_items]
        } else {
            arr = [...nftList, ...latestNFT_items];
        }*/

        setNftList(arr);
        setActivePage(page);
        setLastPage(last_page)
    }

    if (isloading) {
        return <ProfileLoader />
    }
    if (!isloading && !profileUser) {
        return <PageNotFound text={'User Not Found'} />
    }
    return (
        <div id="nftdetailspage2" className="main_wrp games__wrap CratoePageComponent NftDetailpage NftDetailPageComponent">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-2">
                        <SidebarmenuComponent />
                    </div>
                    <div className="col-10">
                        <div className="NftprofileWrap position-relative">
                            <div className="wide-collection-bg">
                                <img className="profileBannerImage" onClick={() => profileUser ? openImageViewer(profileUser.profile_banner) : ''} src={profileUser.profile_banner ? profileUser.profile_banner : noProfileCover} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <div className="collection-img-wrap">
                                    <div className="collection-profile">
                                        <img className="profilePictureImage" onClick={() => profileUser ? openImageViewer(profileUser.profile_image) : ''} src={profileUser.profile_image ? profileUser.profile_image : noProfileImage} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile-tabs profile-description">
                            <div className="row w-100">
                                <div className="col-9">
                                    <h3 className="nft-auther-heading">@{profileUser ? profileUser.username : ''}</h3>
                                    {/* <p className="nft-auther-para">{profileUser ? profileUser.bio : ''}</p> */}
                                    <ReadMoreContent content={profileUser ? profileUser.bio : ''} maxCharac={100} className={"nft-auther-para"} />
                                    <p className="nft-auther-para">Joined {profileUser ? getMonthName(profileUser.createdAt) : ''}</p>
                                    <a href='/create-collections' className="create-collection-btn-wrap"><img src={createImage} />Create Collection</a>
                                </div>
                                <div className="col-3">
                                    <div className="follower-count-wrap">
                                        <p className="nft-auther-para">
                                            {profileUser?.total_followers}
                                            <b>
                                            {
                                                islogin && profileUser?._id && auth?.user?._id && profileUser?._id === auth?.user?._id ? 
                                                    <a href="#" onClick={event => { event.preventDefault(); setFollowerListPopup(true) }}>
                                                        Followers
                                                    </a> : `Followers`
                                            }
                                            </b>
                                        </p>
                                        <p className="nft-auther-para">
                                            {profileUser?.total_following}
                                            <b>Following</b>
                                        </p>
                                        {/* <p className="nft-auther-para">
                                            {profileUser?.collection_follow_count}
                                            <b>Collection Following</b>
                                        </p> */}
                                    </div>

                                    <div className="profile-tabs">
                                        <ul className="nav profile_de justify-content-center gap-18">
                                            {/* <li><a href="#" onClick={(e) => shareProfileHandler(e)} ><img src={profileShare} /></a></li> */}
                                            <li><a href="#" className="share-text" onClick={(e) => shareProfileHandler(e)} ><i className="fa fa-share-alt"></i></a></li>
                                            {isOwner ?
                                                <li>
                                                    <NavLink className="nav-link edit_btn_nft" to="/setting">
                                                        <img src={EditProfile}></img>
                                                    </NavLink>
                                                </li>
                                                : ''}
                                            {/*islogin && !isOwner ? 
                                                        <li  onClick={()=>!submitLoading ? follow():''} className="favorite-heart">
                                                            <a className="follow-bt">
                                                                <img src={profileHeart} />{!submitLoading ? isFollow ? 'Following' : 'Follow' : <Spin indicator={antIcon} />}
                                                            </a>
                                                    </li> : ''*/}


                                            {(!isOwner) ?
                                                <li className="nft-filter-lables">
                                                    <FollowUnfollowBtn type={'user'} followToId={profileUser?._id} successHandler={refreshDataHandler} />
                                                </li>
                                                : null
                                            }
                                        </ul>
                                    </div>
                                    <div className="profile-tabs social-media-icons">
                                        <ul className="nav justify-content-start gap-18">
                                            {/* {console.log('social-link', socialMedia)} */}
                                            {socialMedia?.link1 && socialMedia?.link1 !== '' ? <li><a href={addHttps(socialMedia?.link1)} target="_blank"><img src={InstaIcon} /></a></li> : ''}
                                            {socialMedia?.link2 && socialMedia?.link2 !== '' ? <li><a href={addHttps(socialMedia?.link2)} target="_blank"><img src={TwitterIcon} /></a></li> : ''}
                                            {socialMedia?.link3 && socialMedia?.link3 !== '' ? <li><a href={addHttps(socialMedia?.link3)} target="_blank"><img src={FacebookIcon} /></a></li> : ''}
                                            {socialMedia?.link4 && socialMedia?.link4 !== '' ? <li><a href={addHttps(socialMedia?.link4)} target="_blank"><img src='/img/browser-icon.png' /></a></li> : ''}
                                            {socialMedia?.link5 && socialMedia?.link5 !== '' ? <li><a href={addHttps(socialMedia?.link5)} target="_blank"><img src='/img/discord-icon.png' /></a></li> : ''}
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <>
                            <div className="filter-nft-details mt-5">
                                <ul className="nav justify-content-end">
                                    <li className="nft-filter-lables">
                                        <a onClick={() => NFTFilter('collections')} className="select_animation d-flex justify-content-around align-items-center"><img src={collectedImage} />My Collections</a>
                                    </li>
                                    {/* <li className="nft-filter-lables">
                                        <a href='/create-collections' className="select_animation d-flex justify-content-around align-items-center"><img src={createImage} />Create Collection</a>
                                    </li> */}
                                    <li className="nft-filter-lables">
                                        <a onClick={() => NFTFilter('created')} className="select_animation d-flex justify-content-around align-items-center"><img src={bulbIcon} />Created</a>
                                    </li>
                                    <li className="nft-filter-lables">
                                        <a onClick={() => NFTFilter('collected')} className="select_animation d-flex justify-content-around align-items-center"><img src={collectedIcon} />Collected</a>
                                    </li>
                                    {islogin && isOwner ?
                                        <li className="nft-filter-lables">
                                            {/* onClick={() => GetMystresBox('mystrey')} */}
                                            <a href="#" onClick={(e) => { e.preventDefault(); GetMystresBox('mystrey') }} className="select_animation d-flex justify-content-around align-items-center"><img src={mysteryIcon} />Mystery Box</a>
                                        </li>
                                        : ''}
                                    {isOwner ?
                                        <li className="nft-filter-lables">
                                            <a onClick={() => getFollowing('follow')} className="d-flex justify-content-around align-items-center"><img src={followImage} />Following</a>
                                        </li>
                                        : ''}
                                    {/* <li className="nft-filter-lables">
                                        <a onClick={() => activity('activity')} className="select_animation d-flex justify-content-around align-items-center"><img src={ActivityImage} />Activity</a>
                                    </li> */}
                                    {/* <li className="nft-filter-lables">
                                        <a onClick={() => GetOffers('offers')} className="select_animation d-flex justify-content-around align-items-center"><img src={offersImage} />Offers</a>
                                    </li> */}
                                    {/* <li className="nft-filter-lables" data-toggle='modal' data-target="#nftImportModal">
                                        <a className="select_animation d-flex justify-content-around align-items-center">
                                            <img src={offersImage} />Import NFT</a>
                                    </li>
                                    <li className="nft-filter-lables" data-toggle='modal' data-target="#collectionImportModal">
                                        <a  className="select_animation d-flex justify-content-around align-items-center"><img src={offersImage} />Import Collection</a>
                                    </li> */}
                                    <li className='ms-2'>
                                        <a type="button" className="dropdown-toggle btn-outline-custom" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            &#8942;
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right dropdown-menu-custom">
                                            <ul>
                                                <li className="nft-filter-lables dropdown-item" data-toggle='modal' data-target="#nftImportModal">
                                                    <a className="select_animation d-flex justify-content-around align-items-center">
                                                        <img src={bulbIcon} />Bulk NFT Upload</a>
                                                </li>
                                                {/* <li className="nft-filter-lables dropdown-item" data-toggle='modal' data-target="#collectionImportModal">
                                                    <a className="select_animation d-flex justify-content-around align-items-center"><img src={offersImage} />Import Collection</a>
                                                </li> */}

                                                {/* <li className="nft-filter-lables dropdown-item">
                                                    <a className="select_animation d-flex justify-content-around align-items-center" href='/create-mystery-box'><img src={mysteryIcon} />Create Mystery Box</a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </>
                        <div className="nft-details-filter-inputs">
                            <div className="input-filter-item d-flex align-items-end justify-content-end gap-18">
                                <div className="details-info nft-properties d-flex justify-content-between align-items-center my-3">
                                    <div className="w-100 font_size_input align-items-center justify-content-between gap-18  position-relative">
                                        <input className="w-100 border-0 p-0 pl-4" type="text" />
                                        <div className="position-absolute top-14">
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </div>
                                    </div>

                                </div>
                                <div className="details-info nft-properties d-flex justify-content-between align-items-center mb-3 pl-0">
                                    <div className="d-flex align-items-center justify-content-between gap-18 w-100">
                                        <select onChange={((event) => Filter(event.target.value))} className='profile_select_op'>
                                            <option value="sort">Sort By</option>
                                            <option value="recent">Recently Listed</option>
                                            <option value="newcreated">Recently Created</option>
                                            <option value="newsold">Recently Sold</option>
                                            <option value="newrecived">Recently Received</option>
                                            <option value="ending">Ending Soon</option>
                                            <option value="asc">Price:Low To High</option>
                                            <option value="desc">Price:High To Low</option>
                                            <option value="highlastsale">Highest Last Sale</option>
                                            <option value="mostview">Most Viewed</option>
                                            <option value="favorite">Most Favorite</option>
                                        </select>
                                    </div>
                                    {/* <img className="mr-3" src={SelectArrow} /> */}
                                </div>
                            </div>
                        </div>
                        {/* <div className="nft-detail-hr my-3"></div> */}

                        {
                            activatedTab === 'collections' &&
                            <>
                                <div className="row mt-3 collections-list-items">
                                    {collectionList && collectionList.length ? collectionList.map((collectionItem, index) => {
                                        // const banner = collectionItem.banner_image || 'https://via.placeholder.com/326x333.png?text=My+collection';
                                        const banner = collectionItem.banner_resized_image? collectionItem.banner_resized_image : collectionItem.banner_image;
                                        const profile = collectionItem.featured_resized_image? collectionItem.featured_resized_image :collectionItem.featured_image;    // || 'https://via.placeholder.com/326x333.png?text=My+collection';

                                        return (
                                            <div className="col-4 p-0 collection-cards" key={index}>
                                                <NavLink className="nft-game-go-gtn" to={"/collection/" + collectionItem.slug}>
                                                    <div className="game-box mr-3 pb-3">
                                                        <div className="inner-game-box">
                                                            <img src={banner} />
                                                            <div className="profile-circle">
                                                                <img src={profile} />
                                                            </div>
                                                        </div>
                                                        <div className="game-box-dark row flex-column align-items-center">
                                                            <h2 className="nft-heading-1 m-0">{collectionItem.collection_name}</h2>
                                                            {/* <h3 className="nft-subheading m-0">{collectionItem.username ? 'by @' : ''}{collectionItem.username ? <NavLink to={`/profile/${collectionItem.username}`}>{collectionItem.username}</NavLink> : ''}</h3> */}
                                                            <p className="nft-box-list-desc mt-3 d-none">{collectionItem.description.slice(0, 60) + (collectionItem.description.length > 60 ? "..." : "")}</p>
                                                            {/* <h3 className="nft-subheading m-0">{collectionItem.username ? 'by @' : ''}{collectionItem.username ? <NavLink to={`/profile/${collectionItem.username}`}>{collectionItem.username}</NavLink> : ''}</h3> */}
                                                            {/* <p className="nft-box-list-desc mt-3">{collectionItem.description.substr(0, 120)}...</p> */}
                                                        </div>
                                                        {isOwner ?
                                                            <div className="edit-btn d-none">
                                                                <NavLink className="nft-game-go-gtn" to={"/edit-collections/" + collectionItem._id}>View</NavLink>
                                                            </div>
                                                            : ''}

                                                    </div>
                                                </NavLink>

                                                {isOwner &&
                                                    <em className="edit-pro" onClick={() => history.push(`/edit-collections/${collectionItem._id}`)}>
                                                        <i className='bx bx-pencil mr-1' ></i>
                                                        <span className="links_name">Edit</span>
                                                    </em>
                                                }
                                            </div>
                                        )
                                    })
                                        : <h3 className='no-data-found'>No record found for collection</h3>}
                                </div>
                                {(activePage !== lastPage) ? (
                                    <div className="d-flex justify-content-center">
                                        <button className="create-btn mt-3 mt-sm-4 mb-5" disabled={loading} onClick={(e) => { e.preventDefault(); handlePage('collection', '', activePage); }}>
                                            {!loading ? 'Load More' : 'Please Wait...'}</button>
                                    </div>
                                ) : null}

                                {/* <Pagination
                                    totalPosts={pagination}
                                    postsPerPage={9}
                                    paginate={((event)=>nextCollection(event))}          
                                /> */}
                                {/* {collectionsTotal ? <Pagination page={collectionsPage} onChange={((event) => nextCollection(event))} count={collectionTotalPages} variant="outlined" shape="rounded" /> : ''} */}

                            </>

                        }
                        {
                            (activatedTab === 'created' || activatedTab === 'collected') &&
                            <>
                                {activatedTab ? <NftList activatedTab={activatedTab} allnft={nftList.length === 0 ? nft : nftList} /> : ''}
                                {(activePage < lastPage) ? (
                                    <div className="d-flex justify-content-center">
                                        <button className="create-btn mt-3 mt-sm-4 mb-5" disabled={loading} onClick={(e) => { e.preventDefault(); handlePage('nft', Type, activePage+1); }}>
                                            {!loading ? 'Load More' : 'Please Wait...'}</button>
                                    </div>
                                ) : null}


                                {/* <Pagination
                                        totalPosts={pagination}
                                        postsPerPage={9}
                                        paginate={((event)=>nextNft(event))}          
                                    /> */}
                                {/* {total ? <Pagination color="success" page={page} className="pagination_nft" count={nftTotalPage} onChange={nextNft} variant="outlined" shape="rounded" /> : ''} */}

                            </>
                        }

                        {
                            (activatedTab === 'mystrey' && islogin && auth?.user && isOwner) ?
                                <>

                                    <MasterNftList defaultFilter={{ isMystery: true, collected_nft: true, owner_wallet: auth?.user?.wallet_address }}

                                        disabledNav={true}
                                    />
                                    {/* <div className="row mt-5 follow-list-items">
                                    {count > 0 && mystery ? mystery.map((value, key) => (
                                        <div className='col-4 p-0 collection-cards'>
                                            <div className='innermystery_box  mr-3 pb-3'>
                                                <div className='inner-game-box mystery_box_img'><img src={'https://public.nftstatic.com/static/nft/zipped/e54e5bc7c7cd4cf4a26da12252a63da4_zipped.png'} /></div>
                                                <div style={{ display: 'flex' }}>
                                                    <button onClick={() => !submitting ? sendMysteryModal(value.id) : ''}>{!submitting ? 'Send' : 'Please wait'}</button>
                                                    <button onClick={() => !submitting ? Open(value.id) : ''}>{!submitting ? 'Open' : 'Please wait'}</button>
                                                </div>
                                            </div>
                                        </div>
                                    )) : ''}
                                </div> */}

                                </> : null
                        }
                        {
                            (activatedTab === 'follow' && isOwner) && <div className="row mt-5 follow-list-items">

                                <Follow />
                            </div>
                        }
                        {
                            activatedTab === 'activity' &&
                            <div className="row mt-5 activity-list-items">
                                {transction ? <List activity={transction} /> : 'Loading'}
                            </div>
                        }
                        {
                            activatedTab === 'offers' && <div className="row mt-5 table-responsive offers-list-items">
                                {offer ? <OffersList offers={offer} /> : 'No Record Found'}
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Modal footer={false} title="Send MysteryBox" visible={isModalVisible} onCancel={handleCancel}>
                <div>
                    <input onChange={(event) => setSenderAddress(event.target.value)} placeholder='Please Enter Wallet Address!' />
                </div>
                <div>
                    <button onClick={() => sendMystery()}>Send</button>
                </div>
            </Modal>
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <Scrollup />
            {isViewerOpen && (
                <ImageViewer
                    src={currentImage}
                    currentIndex={0}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
            )}
            {props.match.params?.id ?
                <ShareContentModal
                    visible={showShareModal}
                    handleCancel={() => { setShowShareModal(false) }}
                    urlToShare={concatHostUrl(`${URLS.PROFILE}/${props.match.params.id}`)}
                />
                : null
            }
            <Modal
                title="Follower List"
                visible={followerListPopup}
                onCancel={() => setFollowerListPopup(false)}
                footer={null}
            >
                <FollowerList
                    list={followerList}
                />
            </Modal>

            <ImportNft>

            </ImportNft>

            <ImportCollection>

            </ImportCollection>

        </div>
    );
}

const mapStateToProps = state => ({
    islogin: state.auth.isLogin,
    auth: state.auth.auth,
    isloading: state.profile.isloading,
    profileUser: state.profile.profileUser,
    collection: state.collections?.collections?.latestCollections,
    total: state.NFTDetails?.nfts?.total,
    page: state.NFTDetails?.nfts?.page,
    collectionsTotal: state.collections?.collections?.total,
    collectionsPage: state.collections?.collections?.page,
    nft: state.NFTDetails?.nfts?.latestNFT_items
})

export default connect(mapStateToProps)(NftDetailPage2)
import axios from "./config";

class NetworkServices {
  async NetworkList() {
    const reponse = await axios
      .get(`/networks`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
      return reponse;
  }
}
export default new NetworkServices();

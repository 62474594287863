export const actionTypes = {
    GET_PROFILE_USER: 'GET_PROFILE_USER',
    GET_PROFILE_USER_SUCCESS: 'GET_PROFILE_USER_SUCCESS',   
    LOGOUT: 'LOGOUT',   
    UPDATE_PROFILE:'UPDATE_PROFILE'
};

export function getUserProfile(payload) {
   //  console.log('payload',payload)
   return { type: actionTypes.GET_PROFILE_USER, payload };
}

export function profileSuccess(payload) {
   return { type: actionTypes.GET_NFT_SUCCESS, payload };
}

export function logout() {
   return { type: actionTypes.LOGOUT };
}

export function updateUserProfile(profile) {
   return { type: actionTypes.UPDATE_PROFILE ,profile};
}
import React, { useState, useEffect, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { notification } from 'antd'
import { SmileOutlined, CloseOutlined } from "@ant-design/icons";
import ViewPrice from "../ItemPrice/viewPrice";

import useBid from '../../hooks/useBid';

function BidNft({ handleCancel, minPrice, nft, priceListing, networkLogo, network }) {

  const { makeBid } = useBid();
  
  const [loading, setLoading] = useState(false);
  const [minBidAmount, setMinBidAmount] = useState(0);
  const [givenInputETH, setGivenInputETH] = useState(0);
  const [givenInputUSD, setGivenInputUSD] = useState(0);
  const [conversionUSD, setConversionUSD] = useState(+network["exchange_rate_usd"])

  useEffect(() => {
    if ( priceListing['highest_bid_price'] ) {
      const nextMinBidUSD = priceListing['highest_bid_price'] * conversionUSD + 0.1;
      setMinBidAmount((nextMinBidUSD / conversionUSD).toFixed(8))
      setGivenInputETH((nextMinBidUSD / conversionUSD).toFixed(8))
      setGivenInputUSD((nextMinBidUSD).toFixed(2))
    }  else {
      setMinBidAmount(minPrice)
      setGivenInputETH(minPrice)
      setGivenInputUSD((minPrice * conversionUSD).toFixed(2))
    }
  }, [])

  const validateAmount = (amount) => {
    let error;
    if (!amount) {
      error = "Amount required!";
    } else if ( amount < minBidAmount) {
      error = `Place a bid of at least ${minBidAmount} WETH to become the highest bidder!`;
    }
    return error;
  };

  const onAmountChange = (e) => {
    setGivenInputETH(e.target.value)
    setGivenInputUSD((e.target.value * conversionUSD).toFixed(2))
  }

  const placeBid = async (values) => {
    setLoading(true);
    var data = {
      price: +values.bid_amount,
      nft_id: nft._id,
      price_id: priceListing._id,
    };

    var res = await makeBid(data);
    if (res) {
      setLoading(false);
      notification.open({
        message: "NFT",
        description: "Your Bid Successfully Placed...!",
        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
      });
      handleCancel();
    } else {
      setLoading(false);
      notification.open({
        message: "Error",
        description: "Something went wrong...!",
        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
      });
      handleCancel();
    }
  };

  return (
    <>
      <div className="connect-wallet-box-main make-offer-main">
        <h2 className="main-heading">Place your bid</h2>
        <div className="wallet-infobox px-4">
          <Formik
            enableReinitialize
            initialValues={{ bid_amount: minBidAmount }}
            onSubmit={async (values) => {
              placeBid(values)
            }}
          >
              <Form>
                  <label>Offer Amount</label>
                  <div className="d-flex">
                    <div className="d-flex align-items-center">
                      <ViewPrice network={network?.network} priceUnit={'WETH'} />
                      {/* <img src={networkLogo} className="network-logo" />  */}
                    </div>
                    <div className="bid-input">
                      <Field
                        type="number"
                        name="bid_amount"
                        placeholder="Please Enter Bid Price"
                        validate={validateAmount}
                        onKeyUp={onAmountChange}
                      />
                      <ErrorMessage name="bid_amount">
                        { msg => <div className="text-danger">{msg}</div> }
                      </ErrorMessage>
                    </div>
                  </div>
                  <div>Total offer amount: {givenInputETH} WETH ${givenInputUSD}</div>
                  <div className="bid-btn">
                    <button className="w-100" type={!loading ? "submit" : 'button'}>{ !loading ? 'Place' : 'Please wait'}</button>
                  </div>
              </Form>
          </Formik>
        </div>
      </div>
    </>
  );
}

export default BidNft;

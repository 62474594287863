import React ,{useState,useEffect} from 'react'
import { connect } from 'react-redux';
import WalletConnectProvider from "@walletconnect/web3-provider"
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import { useAlert } from "react-alert";
import Userimage from '../../image/userImage_wallet.png'
import private_wallet from '../../image/private_wallet.png'
import metamask__wallet from '../../image/metamask__wallet.png'
import EGC_Logo_wallet from '../../image/EGC_Logo_wallet.png'
import Wallet_Logo_wallet from '../../image/wallet-svg.svg'
import coinBase_wallet from '../../image/coinBase_wallet.png'
import useContractIntraction from '../../web3Setup/useContractIntraction'
import $ from 'jquery'
import { useWeb3React } from "@web3-react/core";  
import { connectors } from "../../web3Setup/connectors";
import useAuth from '../../hooks/useAuth';
import { isMobile } from "../../utils/helper"
// import useMoralisService from '../../hooks/useMoralisService'
import Web3 from "web3";

// var provider =  new WalletConnectProvider({
//             rpc: {
//                 56:"https://bsc-dataseed1.binance.org",
//             },
//             chainId: 56,
//             network: "binance",
//             bridge:"wss://bridge.walletconnect.org",
//             qrcode: false,
//             qrcodeModalOptions: {
//                 mobileLinks: [
//                 "metamask",
//                 "trust",
//                 "rainbow",
//                 "argent",
//                 "imtoken",
//                 "pillar",
//                 ]
//             }
//         });
// const provider = new WalletConnectProvider({
//     infuraId: "a89e5005f16938ed24476b6d971891a2",
//   });
  
//   //  Enable session (triggers QR Code modal)
//   const web3 = new Web3(provider);

const LoginPopup = ({islogin,isSignature, settHeaderAttempLog, headerNonce}) =>{
    // const { Moralis, authenticate, isAuthenticated, isAuthenticating, user, logout } = useMoralis();
    // const { findOne, saveData } = useMoralisService()
    const [attemptLogin,setAttemp] = useState(false)
    const { activate,active,deactivate,account } = useWeb3React();
    const { signMessage,attemptSignature }  =  useContractIntraction()
    const { authonticateUser } = useAuth()
    const [signature,setSignature] = useState('')
    const alert = useAlert();
    const [isSign,setIsSign] = useState(false)

    const setProvider = (type) => {
        window.localStorage.setItem("provider", type);
    };
  
    useEffect(()=>{
        if(active) {
            setTimeout(()=>{
                login();
            }, 300); 
        }
    },[active,attemptLogin])
    
    useEffect(()=>{
        if(isMobile() && !islogin && isSignature) {
            console.log("got mobile and not logged in");
            setIsSign(true);
        } else {
            // sing()
        }
    },[isSignature])

    const login=async()=>{     
        try{
            if(!isSignature && attemptLogin ){
                let res = await signMessage()
                setSignature(res)
            }

        }catch(error){
            
        }
    }

    const sing=async()=>{     
        // console.log('isSignature',isSignature)
        try{
            console.log(islogin, isSignature, "islogin && isSignature")
            if(!islogin && isSignature){
              
                let nonce = headerNonce ? headerNonce : signature
                //  console.log('nonseeeeeeeeee', nonce)
                let sing = await attemptSignature(`Welcome To LunaSky Your Nonce Id ${nonce}`)
                if(!sing){
                    settHeaderAttempLog && settHeaderAttempLog(false)
                    setAttemp(false);
                    return
                }
                let userResponse = await authonticateUser({sing,publicAddress:account})
                $('.close').click()
                // localStorage.setItem("signature",JSON.stringify(sing)) 
                localStorage.setItem("signature",(sing)) 
            }

        }catch(error){
            console.log('cancel popup')
        }
    }
    // console.log('isSignature',isSignature)
    const connectMetamask = () => {
        if (window && typeof window.ethereum === 'undefined') {
            window.open('https://metamask.app.link/dapp/'+window.location.href, "_blank");
            return    
        }    
        setAttemp(true)    
        activate(connectors.injected);    
        setProvider("injected");
    }


    return (
        <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content wallect-connect-content">
                    <div className="connect-wallet-box-main">
                        <button type="button" className="close closeModalicon" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h2 className="main-heading">Wallet Connect</h2>
                        <div className="wallet-infobox">
                            <div className="d-flex justify-content-start align-items-center gap-10 connect-wallet-heading-box">
                                <img src={Userimage} />
                                <h3 className="m-0">My Wallet</h3>
                            </div>
                            <div className="wallet-modal-body">
                            <p className="mt-3 mb-4">{!isSign ? 'Connect your wallet or create a new one.': 'Verify your Login'}</p>
                            {!isSign ? 
                                <div className="connect-wallet-options-items-wrap">
                                    <div className="flex-column gap-10">{isSign}
                                        <div onClick={() => {
                                                connectMetamask(); 
                                            
                                            }} 
                                            className="d-flex justify-content-start align-items-center gap-10 p-3">
                                            <img src={metamask__wallet} />
                                            <h3>Metamask</h3>
                                        </div>
                                        <div onClick={() => {
                                                setAttemp(true)
                                                activate(connectors.walletConnect);
                                                setProvider("walletConnect");
                                                }} className="d-flex justify-content-start align-items-center gap-10 p-3 no-bottom-border">
                                            <img src={Wallet_Logo_wallet} />
                                            <h3>Wallet connect</h3>
                                        </div>
                                    </div>
                                </div>
                            :
                            <div className="connect-wallet-options-items-wrap">
                                <div className="flex-column gap-10">
                                    <div onClick={() => {
                                            sing(); 
                                        }} 
                                        className="d-flex justify-content-start align-items-center gap-10 p-3">
                                        
                                        <h3>Sign to Verify your login</h3>
                                    </div>
                                </div>
                            </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    islogin:state.auth.isLogin,
    isSignature:state.auth.isSignature,
})

export default connect(mapStateToProps)(LoginPopup)


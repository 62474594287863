import React, { Component } from 'react';

const initData = {
    pre_heading: "Contact",
    heading: "Get In Touch",
    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit."
}

class Contact extends Component {
    state = {
        initData: {}
    }
    componentDidMount(){
        this.setState({
            initData: initData
        })
    }
    render() {
        return (
            <section className="author-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                        <h3 className="comming_soon_text mt-3 mb-0 text-center">Coming Soon </h3>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Contact;
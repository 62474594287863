import React from 'react';
import Footer from '../components/Footer/Footer';
import CreateLandingComponent from '../components/Create/CreateLandingComponent'

function CreateCollectionsPage() {
    return (
        <div id="createlanding" className='main_wrp'>
            <CreateLandingComponent />
            <Footer />
        </div>
   );
}

export default CreateCollectionsPage;
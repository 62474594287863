import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import arrowDown from '../image/category/arrow-down.png'
import useComman from '../hooks/useComman';

function SidebarmenuComponent({ changeArt, isvisibale, selectedCategory, islogin, category }) {
    //assigning location variable
    const location = useLocation();
    const { categories } = useComman()
    const [toggle, setToogle] = useState(true)
    const [selected, setSelected] = useState(selectedCategory)
    const [isVisibleCats, setisVisibleCats] = useState(false)
    const [changeNumber, setChangeNumber] = useState(1)
    const [resetTime, setTime] = useState(500)

    //destructuring pathname from location
    const { pathname } = location;
    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    useEffect(() => {
        fetchCategory()
        setSelected(selectedCategory)
    }, [selectedCategory])

    const fetchCategory = async () => {
        const res = await categories()
    }

    const selectedCate = (cate) => {
        return selected.includes(cate)
    }

    const update = (filter, value) => {
        var array = selected
        if (selected.includes(value)) {
            array = array.filter((va) => { return va !== value })
        }
        else {
            array.push(value)
        }
        array = array.filter(e => e)

        setSelected(array)
        randomNumber()
        setTime(1000)
        setTimeout(() => {
            // changeArt(filter, array)
           changeArt(array)
        }, resetTime)
    }

    const randomNumber = () => {
        const min = 1;
        const max = 100;
        const rand = min + Math.random() * (max - min);
        setChangeNumber(rand)
    }
    const categoriesToggleHandler = () => {
        if (isVisibleCats === false) {
            setisVisibleCats(true)
        } else {
            setisVisibleCats(false)
        }
    }
    return (
        <>
            <div id="sidebarmenucomponent" className="category-mainmenu">
                <ul className="list-unstyled games-sidebar-main sidebarStyle1">
                    <li className="nft-game-go-gtn mt-0 mb-2">
                        <NavLink to="/creators"><i className='fa fa-user'></i> <span>Creators</span></NavLink>
                    </li>
                    <li className="nft-game-go-gtn mt-0 mb-2">
                        <NavLink to="/collections"><i className='fa fa-th-large'></i> <span>Collections</span></NavLink>
                    </li>
                    {/* <li className="nft-game-go-gtn mt-0 mb-2">
                        <NavLink to="/newest">Newest</NavLink>
                    </li> */}
                    <li className="nft-game-go-gtn mt-0 mb-2">
                        <NavLink to="/assets"><i className='fa fa-star'></i> <span>Top NFTs</span></NavLink>
                    </li>
                    {islogin ?
                        <>
                        {/* <li className="nft-game-go-gtn mt-0 mb-2">
                            <NavLink to="/activities"><i className='fa fa-heartbeat'></i> <span>Activity</span></NavLink>
                        </li> */}
                        <li className="nft-game-go-gtn mt-0 mb-2">
                                <NavLink to="/mystery?isMystery=true"><i className='fa fa-user-secret'></i> <span>Mystery</span></NavLink>
                            </li>
                            </>
                        : ''}
                </ul>
                {isvisibale &&
                    <div className="category-sidebar" style={splitLocation[1] === 'activity-page' ? { width: 153 } : {}}>
                        <ul className="list-unstyled games-sidebar-main sidebarStyle2">
                            <li className="nft-game-go-gtn mt-0 mb-2">
                                <NavLink to="/assets?trending=true" className="active">TRENDING</NavLink>
                            </li>
                            <li className="nft-game-go-gtn mt-0 mb-2">
                                <NavLink to="/assets?upcoming_auctions=true">AUCTIONS</NavLink>
                            </li>
                            <li className="nft-game-go-gtn mt-0 mb-2">
                                <NavLink to="/assets?upcoming_drops=true">DROPS</NavLink>
                            </li>
                            {/* <li className="nft-game-go-gtn mt-0 mb-2">
                                <NavLink to="/activity-page">ACTIVITY</NavLink>
                            </li> */}
                        </ul>
                    </div>
                }
            </div>
            {isvisibale ?
                <div className={`subcategories-filters mt-4 CatsMobileVisibility-${isVisibleCats}`}>
                    <span className="closeCats mobile" onClick={categoriesToggleHandler}></span>
                    {splitLocation[1] !== 'activity-page' &&
                        <ul className="list-unstyled categoryFilter">
                            <li onClick={(() => setToogle(!toggle))} className="nft-game-go-gtn mt-0 mb-2 categoryLabelFilter">
                                <NavLink to="#"><img className={!toggle ? 'rotetIcon' : ''} src={arrowDown} />Categories</NavLink>
                            </li>
                            {toggle ?
                                <>
                                    {changeNumber > 0 && category ? category.map((value, key) => {

                                        return (
                                            <li className="nft-game-go-gtn mt-0 mb-2" key={key}>                                                
                                                <a className={selectedCate(value.abbreviation) ? 'active' : ''} onClick={(() => update('filter' + (key + 1), value.abbreviation))}><img src={value.icon} /> {value.name}</a>
                                            </li>
                                        )
                                    }) : ''}
                                </>
                                : ''}
                        </ul>
                    }
                </div>
                : ''}
            {isvisibale && !isVisibleCats && <div className="mobileCategorybtn" onClick={categoriesToggleHandler}>
                <h3><span>&#94;</span> Categories</h3>
            </div>}
        </>
    )
}

const mapStateToProps = state => ({
    islogin: state.auth.isLogin,
    auth: state.auth.auth,
    category: state.comman.category,
    isloading: state.comman.isloading
})
export default connect(mapStateToProps)(SidebarmenuComponent);

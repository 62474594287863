import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Spin } from "antd";
import moment from "moment";
import collectionPost1 from "../../image/nft-detail/pasted-Image.png";
import Music_img from "../../image/category/LunaSky_Music_Icon.png";
import { MAX_TEXT_LENGTH } from "../../utils/app.constant";
import { useNftApi } from "../../hooks/api/nftApiHook";
import MysteryboxRevealModal from "../MysteryBox/MysteryboxRevealModal";

const MasterNftList = (props) => {
  const { getNftListing } = useNftApi();
  const { appLoaderStatus, defaultFilter, disabledNav } = props;

  const [nftData, setNftData] = useState([]);
  const [perPage, setPerPage] = useState(props.perPage ? props.perPage : 9);
  const [activePage, setActivePage] = useState(1);
  const [paginationData, setPagonationData] = useState({
    lastPage: 0,
    total: 0,
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    loadInitailData();
  }, []);

  const loadInitailData = (page = 1) => {
    let defaultParams = typeof defaultFilter === "object" ? defaultFilter : {};

    let params = { ...defaultParams, perPage, page };

    getNftListing(
      params,
      (message, resp) => {
        let temPaginationData = { ...paginationData };
        temPaginationData.lastPage = resp?.last_page || 0;
        temPaginationData.total = resp?.total || 0;
        setPagonationData(temPaginationData);
        const gotNftData =
          resp.latestNFT_items && resp.latestNFT_items.length
            ? resp.latestNFT_items
            : [];
        let tempNftData = [...nftData, ...gotNftData];
        setNftData(tempNftData);
        
      },
      (message, error) => {}
    );
  };
  const handleNavlinkClick = (e, data) => {
    if (disabledNav) {
      setShowModal(true);
      setSelectedData({
        contractAddress: data?.contract_address,
        tokenId: data?.token_id,
        selectedNetwork: data?.chain_name,
      });
      e.preventDefault();
    }
  };
  const handlePage = () => {
    let goToPage = activePage + 1;
    if (activePage >= paginationData.lastPage) {
      return;
    }
    setActivePage(goToPage);
    loadInitailData(goToPage);
  };


  return (
    <>
      {selectedData?.contractAddress && selectedData?.tokenId ? (
        <MysteryboxRevealModal
          isOpen={showModal}
          hideModal={() => {setShowModal(false); setSelectedData({})}}
          contractAddress={selectedData.contractAddress}
          tokenId={selectedData.tokenId}
          selectedNetwork={selectedData.selectedNetwork}
          nft={nftData}
        />
      ) : null}

      <div className="row collections-list-items">
        {nftData && nftData.length ? (
          nftData.map((value, key) => (
            <div className="col-4 p-0 mb-3 nfts-list" key={key}>
              {/* <NavLink
                to={`/assets/${value.contract_address}/${value.token_id}`}
                onClick={(e) => handleNavlinkClick(e, value)}
              > */}
                <div className="hover_show_price game-box mr-3">
                <NavLink to={`/assets/${value.chain_name}/${value.contract_address}/${value.token_id}`} onClick={(e) => handleNavlinkClick(e, value)} className="reveal-btn">REVEAL</NavLink>
                  {value.file_extension === "video" ? (
                    <div className="explore_upper_div">
                      <video
                        playsInline
                        muted
                        autoPlay={true}
                        controlsList="nodownload"
                        loop=""
                        preload="auto"
                        src={value && value.item_resized_image ? value.item_resized_image : value.item_image}
                        style={{ width: "100%", height: "calc(100% - 50px" }}
                      ></video>
                    </div>
                  ) : (
                    ""
                  )}
                  {value.file_extension === "image" ? (
                    <div className="explore_upper_div">
                      <img
                        src={
                          value && value.item_resized_image ? value.item_resized_image : value.item_image
                        }
                        // : "https://via.placeholder.com/80x121.png?text=Use"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {!value.file_extension ? (
                    <div className="explore_upper_div">
                      <img
                        src={
                          value && value.item_resized_image ? value.item_resized_image : value.item_image
                        }
                        // : "https://via.placeholder.com/80x121.png?text=Use"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {value.file_extension === "audio" ? (
                    <div className="explore_upper_div audio_page">
                      <img src={Music_img}></img>
                      <audio controls>
                        <source src={ value && value.item_resized_image ? value.item_resized_image : value.item_image } />
                      </audio>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="game-box-dark row position-relative flex-column">                    
                    <h2 className="nft-heading-1 m-0 mt-3">
                      {value.item_name.slice(0, MAX_TEXT_LENGTH) +
                        (value.item_name.length > MAX_TEXT_LENGTH ? "..." : "")}
                    </h2>
                    <h3 className="nft-subheading mt-1 mb-0">
                      {value.collection_name}
                    </h3>
                  </div>
                  {value?.price_details ? (
                    <div
                      className={`w-100 hide_class_hover nft-price-wrap d-flex align-items-center justify-content-between my-2 no-border `}
                    >
                      <div className="d-flex gap-5">
                        <div className="nft-price-wrap-left d-flex">
                          {value?.item_image && <img src={collectionPost1} />}
                        </div>
                        <div className={`nft-price-wrap-right`}>
                          <h5>
                            <span className="nft-paragraph-1">Price</span>{" "}
                            <em className={`${value.network}-icon`}>
                              {parseFloat(value?.price_details?.start_price)}
                            </em>
                          </h5>
                        </div>
                      </div>
                      <span className="nft-paragraph-1">
                        Ends{" "}
                        {moment
                          .utc(value?.price_details?.end_date)
                          .local()
                          .startOf("seconds")
                          .fromNow(value?.price_details?.start_date)}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              {/* </NavLink> */}
            </div>
          ))
        ) : (
          <>{appLoaderStatus ? null : <h3 className="no-data-found">No data found</h3>} </>
        )}
      </div>
      <div className="text-center">
        {appLoaderStatus ? <Spin tip="Loading..."></Spin> : null}
      </div>
      <div className="d-flex justify-content-center">
        {activePage < paginationData.lastPage ? (
          <button
            className="create-btn mt-3 mt-sm-4 mb-5"
            disabled={appLoaderStatus}
            onClick={() => handlePage()}
          >
            {appLoaderStatus ? "Load More" : "Please Wait..."}
          </button>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  appLoaderStatus: state.comman.appLoaderStatus,
});

export default connect(mapStateToProps)(MasterNftList);

import React, { useState, useEffect, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from 'yup';
import { SmileOutlined, CloseOutlined } from "@ant-design/icons";

const MysteryBoxContentSchema = Yup.object().shape({
  collection_id: Yup.string().required('Collection required!.'),
  issue_quantity: Yup.string().required('Please enter quantity.')
});

function MysteryBoxContent({ onCancel, collectionList, selectedMysteryBoxContent, addMysteryContent }) {

  const onFinish = (data, reset) => {
    addMysteryContent(data)
    reset({})
    onCancel()
  }

  const isInputNumber = (e) => {
      let ch = String.fromCharCode(e.which);
      if (!/[0-9]/.test(ch)) {
          e.preventDefault();
      }
  };

  return (
    <>
      <div className="connect-wallet-box-main make-offer-main">
        <h2 className="main-heading">Place your bid</h2>
          <div className='row'>
            <Formik
                className="item-form createNftForm no-hover"
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  onFinish(values, resetForm)
                }}
                validationSchema={MysteryBoxContentSchema}
                initialValues={{
                  collection_id: selectedMysteryBoxContent ? selectedMysteryBoxContent?.collection?._id : collectionList.length ? collectionList[0]['_id'] : '',
                  issue_quantity: selectedMysteryBoxContent ? selectedMysteryBoxContent?.issuedQuantity : '',
                }}
              > 
              {({ values, errors, resetForm, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, isValidating, isSubmitting }) => ( 
                  <form onSubmit={handleSubmit} className="mystery-form createNftForm">
                    <div className='col-12'>
                      <div className="flex-column w-100 px-3 mb-4 input-group form-group mt-4">
                          <div className="row">
                                <label className="image-upload-lbl">COLLECTION *</label>
                                <Field as="select" name="collection_id">
                                      {collectionList.length > 0
                                        ? collectionList.map((value, key) => (
                                            <option key={key} value={value._id}>
                                                {value.collection_name}
                                            </option>
                                        ))
                                        : ""}
                                </Field>
                                {errors.collection_id && touched.collection_id ? (
                                    <div className="text-danger">{errors.collection_id}</div>
                                ) : null}
                          </div>
                      </div>
                      <div className='flex-column w-100 px-3 mb-4 input-group form-group mt-3'>
                          <div className="row">
                              <label className="image-upload-lbl">Issue Quantity</label>
                              <Field
                                  type="text"
                                  className="nft-form-control"
                                  name="issue_quantity"
                                  onKeyPress={(e) => isInputNumber(e)}
                              />
                              {errors.issue_quantity && touched.issue_quantity ? (
                                  <div className="text-danger">{errors.issue_quantity}</div>
                              ) : null}
                          </div>
                      </div>
                    </div>
                    
                    <div className="col-12 text-center">
                      <button type="button" className="btn btn-secondary" onClick={onCancel}>Close</button>
                      <button type="submit" className="btn btn-primary">SAVE</button>
                    </div>
                  </form>
              )}
            </Formik>
          </div>
      </div>
      {/* ************** Modal start **************** */}
      {/* <div className="import-modal-wrap NftmodelsPopupWrap">
          <div className="modal fade" id="addContentModal" tabIndex={-1} role="dialog" aria-labelledby="nftModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg nft-modal" role="document">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h5 className="modal-title" id="nftModalLabel">Add Content</h5>

                      </div>

                      <div className="modal-body">
                          <div className='row'>
                              <div className='col-12'>
                                  <div className="flex-column w-100 px-3 mb-4 input-group form-group mt-4">
                                      <div className="row">
                                          <div className="col-10 pl-0">
                                              <label className="image-upload-lbl">COLLECTION *</label>
                                          </div>

                                      </div>

                                      <div className="d-flex justify-content-start align-items-start gap-10">
                                          <select value={contentCollection}
                                              onChange={(event) =>
                                                  setContentCollection(event.target.value)
                                              }
                                          >
                                              {collection.length > 0
                                                  ? collection.map((value, key) => (
                                                      <option key={key}
                                                          selected={
                                                              collectionSelected === value.id
                                                                  ? true
                                                                  : false
                                                          }
                                                          value={value._id}
                                                      >
                                                          {value.collection_name}
                                                      </option>
                                                  ))
                                                  : ""}
                                          </select>
                                      </div>

                                  </div>
                              </div>

                              <div className='col-12'>
                                  <div className="flex-column w-100 px-3 mb-4 input-group form-group mt-4">
                                      <div className="custom-file-old csv-file-add">
                                          <label className="image-upload-lbl collection-dropdown">Select Options :</label>
                                          <Radio.Group onChange={changeAddressForm} value={radioValue}>
                                              <Radio value={1} >Autometic</Radio>
                                              <Radio value={2}>Specific</Radio>
                                          </Radio.Group>
                                      </div>
                                  </div>
                              </div>


                              <div className='col-12'>
                                  <div className='flex-column w-100 px-3 mb-4 input-group form-group mt-3'>
                                      <label className="image-upload-lbl collection-dropdown">Issue Quantity</label>
                                      <Field
                                          type="text"
                                          className="nft-form-control"
                                          name="issue_quantity"
                                          onKeyPress={(e) => isInputNumber(e)}
                                      />
                                  </div>
                                  {errors.issue_quantity && touched.issue_quantity ? (
                                      <div className="text-danger">{errors.issue_quantity}</div>
                                  ) : null}
                              </div>

                              {radioValue === 2 && (
                                  <>
                                      <FieldArray
                                          name='nftAdd'
                                          render={(nftAddList) => (
                                              <div className='col-12'>
                                                  <div className='flex-column w-100 px-3 mb-4 input-group form-group mt-3'>
                                                      <div className='row'>
                                                          <div className='col-8'>
                                                              <label className="image-upload-lbl collection-dropdown">NFT Select </label>
                                                          </div>
                                                          <div className='col-4'>
                                                              <button className="create-btn mt-3 mt-sm-4" type='button'
                                                                  onClick={() => nftAddList.push({ nft_name: "", quantity: '' })}>+ Add more NFT</button>
                                                          </div>
                                                      </div>
                                                      {values.nftAdd.map((nftAdd, index) => (
                                                          <>
                                                              <div key={index}>
                                                                  <div className='row'>
                                                                      <div className='col-8'>
                                                                          <Field
                                                                              type="text"
                                                                              className="nft-form-control"
                                                                              name={`nftAdd.${index}.nft_name`}
                                                                              placeholder='Please search NFT..'
                                                                              style={{ maxWidth: "100%" }}
                                                                          />
                                                                      </div>
                                                                      <div className='col-2'>
                                                                          <Field
                                                                              name={`nftAdd.${index}.quantity`}
                                                                              type="text" onKeyPress={(e) => isInputNumber(e)}
                                                                              className="nft-form-control"
                                                                              placeholder="Quantity.."
                                                                          />
                                                                      </div>
                                                                      {values.nftAdd.length > 1 ? (
                                                                          <div className='col-2'>
                                                                              <button className="delete-btn" type='button'
                                                                                  onClick={() => nftAddList.remove(index)}
                                                                              >
                                                                                  <img src={delete_icon}></img>
                                                                              </button>
                                                                          </div>
                                                                      ) : null}
                                                                  </div>
                                                              </div>
                                                              <br />
                                                          </>
                                                      ))}
                                                  </div>
                                              </div>
                                          )}
                                      />
                                  </>
                              )}
                          </div>
                      </div>

                      <div className="modal-footer justify-content-center">
                          <button type="button" className="btn btn-secondary" onClick={closeModal(values)} data-dismiss="modal">Close</button>
                          <button type="button" className="btn btn-primary" data-dismiss="modal">SAVE</button>
                      </div>
                  </div>
              </div>
          </div>
      </div> */}
      {/* ************ Modal end ***************** */}
    </>
  );
}

export default MysteryBoxContent;

import React from 'react';
import _ from 'lodash';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import OracleComponent from '../components/Games/OracleComponent';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';

export default function GamesOraclePage() {
    return (
        <div className="games__wrap trendingComponent GamesOraclePageComponent" id="gameOracle">

            {/* dummy test content */}
            <Breadcrumb title="Games" subpage="Community" page="Games" />
            <section className="help-center-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <h3 className="comming_soon_text mt-3 mb-0 text-center">Coming Soon </h3>
                    </div>

                </div>
            </section>
            
            {/* Not needed now... */}
            {/* <OracleComponent />
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <Scrollup /> */}
        </div>
    );
}

import React, { useEffect, useState, useRef } from "react";
import { connect } from 'react-redux'
import _ from "lodash";
import { NavLink } from "react-router-dom";
import SidebarmenuComponent from "../../themes/SidebarmenuComponent";
import NFTloader from "../Loader/NftLoader";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CollectionSkeleton from "../Skeletons/CollectionSkeleton";
import Pagination from '@mui/material/Pagination';

//Custom hooks
import useCollections from '../../hooks/useCollection'
import useComman from '../../hooks/useComman'
import { MAX_TEXT_LENGTH } from "../../utils/app.constant";

function DropPage({ props, isloading, total, page, allCollection }) {
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [search, setSearch] = useState(false)
  const [pagination, setPagination] = useState(1)
  const [skip, setSkip] = useState(9)
  const [activePage, setActivePage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [collectionList, setCollectionList] = useState([])
  const [showList, setShowList] = useState([])

  //page number
  let totalPage = parseInt((total + 9 - 1) / 9);
  //custom hooks functions
  const { getWithPagination } = useCollections()
  const { categorieIds } = useComman()

  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const ref = useRef(false);

  useEffect(() => {
    changeArt();
  }, []);

  // console.log('collection', page, total, allCollection);

  useEffect(() => {
    setShowList(allCollection);
  }, [allCollection])

  const changeArt = async (filter = "filter", value = "all") => {
    await setLoading(true);

    var search = props.location.search;
    var searchParams = new URLSearchParams(search);
    var params = searchParams.get("filter");

    if (params) {
      params = params.split(",");
      value = value === 'all' ? params : value
    } else {
      params = [""];
    }
    if (value !== 'all') {
      params = value
    }

    // Display the key/value pairs
    var concat = "";
    var count = 1;
    var isDelete = false;
    var allCategoryIDs = [];
    if (params.length > 0) {
      if (params[0] === '') {
        var { total, latestCollections, page, last_page } = await getWithPagination({ page: 1 });
        var count = total;
        setPagination(count)
        setActivePage(page);
        setLastPage(last_page)
        // await setAllCollection(latestCollections);
        await setLoading(false);
        await setSelectedCategory([]);
        props.history.push("/collections");
        return
      }
    }
    if (params.length === 0 && value.length === 0) {

      var { total, latestCollections, page, last_page } = await getWithPagination({ page: 1 });
      var count = total;
      setPagination(count)
      setActivePage(page);
      setLastPage(last_page)
      // await setAllCollection(latestCollections);
      await setLoading(false);
      await setSelectedCategory([]);
      props.history.push("/collections");
      return
    }
    for (var pair of params) {
      // if ('' !== pair) {
      if (count === 1) {
        concat += `${pair}`;
      } else {
        concat += `,${pair}`;
      }
      // } else {
      //   isDelete = true;
      // }
      count++;
    }

    if (!isDelete && value !== "all") {
      concat += searchParams.get("filter") ? `` : ``;
    }
    const paramsString = concat
    params = concat.split(",");
    if (concat !== "?" && value !== "all") {
      var categories = await categorieIds({ categories_names: paramsString });
      let filter = {
        categories,
        page: 1
      }
      var { total, latestCollections, page, last_page } = await getWithPagination(filter);
      setActivePage(page);
      setLastPage(last_page)
      // await setAllCollection(latestCollections);
      setPagination(total === 0 ? 1 : total)
    } else {
      var { total, latestCollections, page, last_page } = await getWithPagination({ page: 1 });
      var count = total;
      setPagination(count)
      setActivePage(page);
      setLastPage(last_page)
      // await setAllCollection(latestCollections);
    }

    await setSelectedCategory(params);
    await setLoading(false);

    props.history.push("/collections?filter=" + concat);
  };

  const next = async (e, page) => {
    var search = props.location.search;
    var searchParams = new URLSearchParams(search);
    var params = searchParams.get("filter");
    var allCategoryIDs = []

    if (params) {
      var sk = page === 1 ? 0 : (9 * (page - 1))

      var categories = await categorieIds({ categories_names: params });
      let filter = {
        categories,
        page: 1
      }
      var { total, latestCollections } = await getWithPagination(filter);
      // await setAllCollection(latestCollections);
      setPagination(total)
      setSkip(sk)
    }
    else {
      var sk = page === 1 ? 0 : (9 * (page - 1))
      var { total, latestCollections } = await getWithPagination({ page });
      setPagination(total)
      // await setAllCollection(latestCollections);
    }
  }

  const _getProducts = async (pageNumber = 1) => {
    var search = props.location.search;
    var searchParams = new URLSearchParams(search);
    var params = searchParams.get("filter");
    let arr = [];
    if (params) {
      var sk = page === 1 ? 0 : (9 * (page - 1))
      var categories = await categorieIds({ categories_names: params });
      let filter = {
        categories,
        page: pageNumber
      }
      var { total, latestCollections, page, last_page } = await getWithPagination(filter);
      // await setAllCollection(latestCollections);
      setPagination(total)
      setSkip(sk)
      setActivePage(pageNumber);
      setLastPage(last_page)
    }
    else {
      var sk = page === 1 ? 0 : (9 * (page - 1))
      var { total, latestCollections, page, last_page } = await getWithPagination({ page: pageNumber });
      if (collectionList.length === 0) {
        arr = [...allCollection, ...latestCollections];
      } else {
        arr = [...collectionList, ...latestCollections];
      }
      setPagination(total)
      setActivePage(pageNumber);
      setLastPage(last_page)
      setCollectionList(arr)
      setShowList(arr)
      // await setAllCollection(latestCollections);
    }
  }

  const handlePage = async () => {
    _getProducts(activePage + 1)
  }

  if (isloading) {
    return <CollectionSkeleton />
  }


  return (
    <div className="games__wrap  CollectionsPageComponent">
      <div className="container-fluid">
        <div className="row">
          <div className="col-3">
          <div className="left-bar-wrap">
            <SidebarmenuComponent
              selectedCategory={selectedCategory}
              isvisibale={true}
              changeArt={(filter, value) => changeArt(filter, value)}
            />
            </div>
          </div>

          <div className="col-9">
            <h3 className="oracleTextClass">COLLECTIONS</h3>
            <div className="row">
              {!loading ? (
                // _.map(allCollection, (post, index) => {
                _.map(showList, (post, index) => {
                  // const banner = post.banner_image || "https://via.placeholder.com/326x333.png?text=My+collection";
                  const banner = post.banner_resized_image? post.banner_resized_image : post.banner_image;
                  const profile = post.featured_resized_image? post.featured_resized_image : post.featured_image;   // || "https://via.placeholder.com/326x333.png?text=My+collection";

                  return (
                    <div className="col-4 p-0 collection-cards" key={index}>
                      <NavLink
                        to={"/collection/" + post.slug}
                      >
                        <div className="game-box mr-3 pb-3">
                          <div className="inner-game-box">
                            <img className="bannerImg" src={banner} />
                            <div className="profile-circle">
                              <img className="profileImg" src={profile} />
                            </div>
                          </div>
                          <div className="game-box-dark flex-column align-items-center">
                            <h2 className="nft-heading-1 m-0">
                              {post.collection_name.slice(0,MAX_TEXT_LENGTH)+(post.collection_name.length > MAX_TEXT_LENGTH ? "..." : "")}
                            </h2>
                            {/*post?.username ?
                              <h3 className="nft-subheading m-0">
                                <NavLink
                                  to={`/profile/${post?.username}`}
                                >
                                  by @{post?.username}
                                </NavLink>
                              </h3>
                            : ''*/}
                            {/* <p className="nft-box-list-desc">
                              {post.description ? post.description?.substr(0, 120) + '...' : ''}
                            </p> */}
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  );
                })
              ) : (
                ''
              )}
            </div>

            {(activePage !== lastPage) ? (
              <div className="d-flex justify-content-center">
                <button className="create-btn mt-3 mt-sm-4 mb-5" disabled={loading} onClick={(e) => { e.preventDefault(); handlePage(); }}>
                  {!loading ? 'Load More' : 'Please Wait...'}</button>
              </div>
            ) : null}

          </div>
          
          {/* <div className="pagination_nft col-12">
            <Pagination color="success" page={page} className="pagination_nft" count={totalPage} onChange={next} variant="outlined" shape="rounded" />
          </div> */}
        </div>
      </div>
    </div >
  );
}

const mapStateToProps = state => ({
  islogin: state.auth.isLogin,
  auth: state.auth.auth,
  allCollection: state.collections.collections.latestCollections,
  page: state.collections.collections.page,
  total: state.collections.collections.total,
  isloading: state.collections.isloading
})
export default connect(mapStateToProps)(DropPage);
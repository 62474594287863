import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Spin, notification } from "antd";
import { CloseOutlined, SmileOutlined } from "@ant-design/icons";
import { IMAGE_PATHS } from "../../utils/app.constant";
import { useUserApi } from "../../hooks/api/userApiHook";

function FollowUnfollowBtn(props) {
  const { followUnfollowUser, fetchFollowData } = useUserApi();
  const { followToId, islogin, auth, type, successHandler } = props;

  const [showLoader, setShowLoader] = useState(false);
  const [isFollowedByUser, setIsFollowedByUser] = useState(false);

  useEffect(() => {
    if (islogin && followToId) {
      fetchFollowedBy();
    }
  }, [followToId, islogin]);

  const fetchFollowedBy = async () => {
    setShowLoader(true);
    fetchFollowData(
      { followToId, singleData: true, type },
      (message, resp) => {
        if (resp?._id) {
          setIsFollowedByUser(true);
        } else {
          setIsFollowedByUser(false);
        }

        setShowLoader(false);
      },
      (message, error) => {
        setShowLoader(false);
      }
    );
  };

  const followUnfollowHandler = async () => {
    try {
      if (showLoader) {
        return;
      }
      setShowLoader(true);
      followUnfollowUser(
        { followToId, type },
        (message, resp) => {
          setShowLoader(false);

          if (resp?._id) {
            setIsFollowedByUser(true);
          } else {
            setIsFollowedByUser(false);
          }
          successHandler && successHandler()
          /*notification.open({
            message: "Success",
            description: message,
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          });*/
        },
        (message, error) => {
          setShowLoader(false);
          notification.open({
            message: "Error",
            description: message,
            icon: <CloseOutlined style={{ color: "#f4364c" }} />,
          });
        }
      );
    } catch (error) {
      setShowLoader(false);
      notification.open({
        message: "Error",
        description: "Unable to process...!",
        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
      });
    }
  };

  if (!islogin || !followToId) {
    return null;
  }

  return (
    <>
      <a
        className={`follow-bt ${isFollowedByUser ? "following-btn" : ""}`}
        onClick={(e) => {
          e.preventDefault();
          followUnfollowHandler();
        }}
      >
        <img src={IMAGE_PATHS.FOLLOW_IMAGE} />
        {showLoader ? (
          <Spin />
        ) : (
          <>{isFollowedByUser ? "Following" : "Follow"}</>
        )}
      </a>
    </>
  );
}
const mapStateToProps = (state) => ({
  islogin: state.auth.isLogin,
  auth: state.auth.auth,
});

export default connect(mapStateToProps)(FollowUnfollowBtn);

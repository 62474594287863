import React from "react";
import { ModelViewer } from "./ModelViewer";

/*const imgTypes = [
  "image/svg+xml",
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/gif",
  "image/webp",
];
const videoTypes = ["video/webm", "video/mp4", "video/ogg", "video/webm", "video/x-m4v", "video/quicktime"];
const audioTypes = ["audio/webm", "audio/mpeg", "audio/wav", "audio/ogg"];
const THREE_D = ["model/gltf-binary", "model/gltf+json"];*/

const imgTypes = ["image"];
const videoTypes = ["video"];
const audioTypes = ["audio"];
const THREE_D = ["model/gltf-binary", "model/gltf+json"];

const DisplayFile = (props) => {
  const { mimeType, link, className } = props;
  
  return (
    <>
      {imgTypes.includes(mimeType) ? (
        <img src={link} className={className} />
      ) : videoTypes.includes(mimeType) ? (
        <video width={"100%"} height="240" controls>
          <source src={link} type={mimeType} />
          Your browser does not support the video tag.
        </video>
      ) : audioTypes.includes(mimeType) ? (
        <audio controls>
          <source src={link} type={mimeType} />
          Your browser does not support the audio element.
        </audio>
      ) : THREE_D.includes(mimeType) && typeof window !== "undefined" ? (
        <>
          <ModelViewer link={link} poster={"/no-image.jpeg"} />
        </>
      ) : null}
    </>
  );
};

export default DisplayFile;

import FollwersServices from '../api/FollwerService';
import {  notification } from 'antd'
import { SmileOutlined, CloseOutlined, ConsoleSqlOutlined } from "@ant-design/icons";

export default function useFollow() {

    return {
        create: async (data) => {          
            let responseData = await FollwersServices.Create(data);
            if (responseData.data.status === 200) {
                // notification.open({
                //     message: "Success",
                //     description: "Follwing",
                //     icon:<SmileOutlined style={{ color: "#108ee9" }} />,
                // }); 
                return responseData?.data;
            }
            if (responseData.data.status === 202) {
                // notification.open({
                //     message: "Success",
                //     description: "Unfollow",
                //     icon:<SmileOutlined style={{ color: "#108ee9" }} />,
                // }); 
                return true
            }
            return false;
        },
        offerUpdate: async (data,id) => {               
            var responseData = await FollwersServices.Update(data,id);
            if (responseData.status === 200) {
                return responseData.data.data;
            }
            return false;
        },
        getFollowerFilter: async (data) => {          
            var responseData = await FollwersServices.GetFollowerFilter(data);
            if (responseData.status === 200) {
                return responseData.data.data;
            }
            return false;
        }, 
        getFollowList: async (data) => {    
            let responseData = await FollwersServices.GetFollowList(data);
            if (responseData.status === 200) {
                return responseData.data;
            }
            return false;
        },       
    }
}

import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
// import useMoralisService from '../hooks/useMoralisService';

import Hero from "../components/Hero/Hero";
import Auctions from "../components/Auctions/AuctionsOne";
import Collections from "../components/Collections/Collections";
import Explore from "../components/Auctions/ExploreThree1";
import Footer from "../components/Footer/Footer";
import ModalSearch from "../components/Modal/ModalSearch";
import ModalMenu from "../components/Modal/ModalMenu";
import Scrollup from "../components/Scrollup/Scrollup";
import Allast from "../components/Explore/Allasset";
import HomeSlider from "../themes/HomeSlider";
import HomeLoading from "../components/Skeletons/HomePageSkeleton";

//custom hooks
import useComman from "../hooks/useComman";
import HomePageSlider from "../components/Slider/HomePageSlider";
import { useUserApi } from "../hooks/api/userApiHook";
import { Spin, notification } from "antd";
import { SmileOutlined } from "@ant-design/icons";

function ThemeOne({ acc, web3main }) {
  // const {findAll,findFirst,findAllWithMany,getSlider,findAllById} = useMoralisService()
  const history = useHistory();
  const search = useLocation().search;

  const [sliderNft, setSlider] = useState(false);
  const [controllerNames, setControllerNames] = useState(false);
  const [loading, setLoading] = useState(true);

  const { getSlider } = useComman();
  useEffect(() => {
    getslider();
  }, []);


  const getslider = async () => {
    let slider = await getSlider();
    let name = [];
    let control = [];
    // console.log('slider', slider)
    // slider.map((value,key)=>{
    //   let controlObj = {
    //     id:value.id,
    //     img:value.img,
    //     name:value.name,
    //     display: value.name
    //   }
    //   control.push(controlObj)
    // })
    if (slider && slider.length) {
      setSlider([...slider]);
    }
    //setSlider(slider)
    //setControllerNames(control)
    setLoading(false);
  };
  if (loading) {
    return <HomeLoading />;
  }
  return (
    <div id="themeone" className="main">
      {/*controllerNames?
        <HomeSlider
          imgList={sliderNft}
          nameList={controllerNames}
          naviNames={controllerNames} />
      :''*/}

      {sliderNft && sliderNft.length ? (
        <HomePageSlider sliderData={sliderNft} />
      ) : null}

      {/* <Hero /> */}

      {/* <Auctions cut="cut" acc={acc} web3main={web3main} />

            <Collections cut="cut" acc={acc} />
            <Explore cut="cut" acc={acc} /> */}
      {/* <Allast web3main={web3main} acc={acc} /> */}

      <Footer />
      <ModalSearch />
      <ModalMenu />
      <Scrollup />
    </div>
  );
}

export default ThemeOne;

import React from 'react';
import ReactDOM from 'react-dom';

// redux
import { Provider } from 'react-redux';

import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './store/rootReducer'
import rootSaga from './store/saga'
import { MoralisProvider } from "react-moralis";
import { Web3ReactProvider } from "@web3-react/core";
import { ethers } from "ethers";

import { DAppProvider } from "@usedapp/core";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import 'antd/dist/antd.css';

// import from local
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 100
  }
}

const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 8000; // frequency provider is polling
  return library;
};


const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = sagaMiddleware;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(middlewares))
);

sagaMiddleware.run(rootSaga);

ReactDOM.render(
      <Web3ReactProvider getLibrary={getLibrary}>
        <Provider store={store}>
          <AlertProvider template={AlertTemplate} {...options}>
            <App />
          </AlertProvider>
        </Provider>  
        </Web3ReactProvider>    
      ,
  document.getElementById('root')
);



import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";

function WalletAddress(props) {
    const { isLogin, auth, wallet } = props;
    const [walletAddress, setWalletAddress] = useState('')

    useEffect(() => {
        // setWalletAddress(wallet || auth?.user?.wallet_address.slice(0, 6));
        setWalletAddress(wallet || auth?.user?.wallet_address || 'NA');
    }, [isLogin])
    // console.log('auth wallet', auth, isLogin);


    return (
        <>
            <span className='text-uppercase d-inline-block text-truncate wallet-address' style={{'maxWidth': '160px'}} title={walletAddress}>{walletAddress}</span>
            {/* <div className="row">
                <div className="col-4">
                    <label className="image-upload-lbl">Wallet Address:</label>
                </div>
                <div className="col-8">
                    <span style={{ "font-size": "20px" }}>{walletAddress}</span>
                </div>
            </div> */}
        </>
    )
}

const mapStateToProps = (state) => ({
    islogin: state.auth.isLogin,
    auth: state.auth.auth,
});

export default connect(mapStateToProps)(WalletAddress);


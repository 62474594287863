import React, { useEffect, useState, useRef } from 'react';
import { Form, notification } from "antd";
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { SmileOutlined, CloseOutlined } from "@ant-design/icons";
import userImage from '../../components/Header/Profile.svg';
import Message_Bell from '../../components/Header/Message_Bell.svg';
import offerImage from '../../components/Header/Offres_Profile.svg';
import supportImage from '../../components/Header/Support.svg';
import { NavLink } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { ProfileSchema } from '../../utils/Validation'
import previewIcon from '../../image/previewIcon.png'
import previewbanner from '../../image/BannerImage.jpeg'
import Website from '../../image/website.png'
import loader from '../../image/loader.gif'
import Loader from '../Skeletons/HomePageSkeleton'
import Insta from '../../image/IG.svg'
import Twitter from '../../image/Twitter.svg'
import Facebook from '../../image/FB.svg'
import Discord from '../../image/Group (4).png'
import { useWeb3React } from "@web3-react/core";
import InputMask from "react-input-mask";
import noProfileImage from '../../image/no-image-profile.jpg';
import noCoverImage from '../../image/no-image-cover.jpg'
import cookies from "js-cookie";

//custom hooks
import useAuth from '../../hooks/useAuth'
import useMedia from '../../hooks/useMedia'

function MyProfile({ islogin, auth }) {
    const [feature, setFeatureImage] = useState(noProfileImage)
    const [banner, setBannerImage] = useState(noCoverImage)
    const [featureSrc, setFeatureImageSrc] = useState('')
    const [bannerSrc, setBannerImageSrc] = useState('')
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [bio, setBio] = useState('')
    const [wallet, setWallet] = useState('')
    const [profile, setProfile] = useState(false)
    const [loading, setLoading] = useState(true)
    const [isSame, setSame] = useState(false)
    const [issubmitting, setSubmitting] = useState(false)
    const [fileType, setFileTypeError] = useState('')
    const [bannerFileType, setBannerFileTypeError] = useState('')
    const [user, setUser] = useState({})
    const userID = cookies.get("token");
  

    const [links, setLinks] = useState({
        link1: '',
        link2: '',
        link3: '',
        link4: '',
        link5: '',
    })
    const ref = useRef(false)
    const history = useHistory()
    const [addImage, setAddImage] = useState(false)
    const [addBanner, setAddBanner] = useState(false)

    //custom hooks functions
    const { getUser, userUpdate, getSingleUser, auth: authApi } = useAuth()
    const { FileUpload } = useMedia()
    const { account } = useWeb3React();
    const formatCharsAdd = {
        "*": "^[A-Za-z0-9_@./#&+-]*$"
    }

    useEffect(() => {
        userProfile()
        ref.current = true
        return () => {
            ref.current = false
        }
    }, [auth, islogin])

    const userProfile = async () => {
        setLoading(true)
        let user = auth?.user
       // console.log('user', user)
        if (user) {
            var profile = user
            await setProfile(profile)
            await setFeatureImage(profile.profile_image ? profile.profile_image : noProfileImage)
            await setBannerImage(profile.profile_banner ? profile.profile_banner : noCoverImage)
            await setEmail(profile.email)
            await setUsername(profile.username)
            await setBio(profile.bio)
            await setWallet(profile.wallet_address)
            await setLinks({ ...profile.social_links })
            setUser(user)
            if (profile.profile_image) {
                setAddImage(true);
            }
            if (profile.profile_banner) {
                setAddBanner(true);
            }
        }
        setLoading(false)
        // if (!islogin) {
        //     history.push('/')
        // }
    }
    useEffect(() => {
        if(!userID) {
            history.push('/')
        }
    }, [userID]);

    const saveLinks = (data) => {
        var newData = { ...links, ...data }
        setLinks(newData)
    }

    const saveFiles = async (e, type) => {
        const reader = new FileReader();
        var user = auth?.user

        var usercrnt = user
        if (type === "feature") {
            if (e.target.files && e.target.files.length > 0) {
                if (5100000 < e.target.files[0].size) {
                    setFileTypeError('You can upload 5MB size file!')
                    return
                }
                if (!e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
                    setFileTypeError('Not valid file!, Please upload (jpef,jpg,png,gif) file type')
                    return
                }
                setFeatureImage(loader)
                var form = new FormData()
                form.append('image', e.target.files[0])
                var fileRes = await FileUpload(form)

                if (usercrnt) {
                    await userUpdate({ profile_image: fileRes['actual_file_path'] }, user._id)
                    getUserDetails()
                }

                reader.addEventListener("load", () => setFeatureImage(reader.result));
                setAddImage(true);
                await setFeatureImageSrc(fileRes);
            }
        } else {
            if (e.target.files && e.target.files.length > 0) {
                if (5100000 < e.target.files[0].size) {
                    setBannerFileTypeError('You can upload 5MB size file!')
                    return
                }
                if (!e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
                    setBannerFileTypeError('Not valid file!, Please upload (jpef,jpg,png,gif) file type')
                    return
                }
                setBannerImage(loader)
                var form = new FormData()
                form.append('image', e.target.files[0])
                var fileRes = await FileUpload(form)

                if (usercrnt) {
                    await userUpdate({ profile_banner: fileRes['actual_file_path'] }, user._id)
                    getUserDetails()
                }

                reader.addEventListener("load", () => setBannerImage(reader.result));
                setAddBanner(true);
                await setBannerImageSrc(fileRes);
            }
        }
        reader.readAsDataURL(e.target.files[0]);
    };

    const submit = async (data) => {
        if (isSame) {
            return
        }
        setSubmitting(true)

        var user = auth?.user
        data.profile_banner = auth?.user.profile_banner ? auth?.user.profile_banner : bannerSrc['actual_file_path']
        data.profile_image = auth?.user.profile_image ? auth?.user.profile_image: featureSrc['actual_file_path']
        data.user_id = user
        data.social_links = links

        var res = ''
        var usercrnt = user;
        if (usercrnt) {
            res = await userUpdate(data, user._id)

        }

        if (res) {

            setSubmitting(false)
            getUserDetails()
            notification.open({
                message: "Profile",
                description: "Profile Successfully Updated...!",
                icon: <SmileOutlined style={{ color: "#655E54" }} />,
            });

        } else {
            notification.open({
                message: "Error",
                description: "Something went wrong...!",
                icon: <CloseOutlined style={{ color: "#f4364c" }} />,
            });
        }
    }

    const getUserDetails = async () => {
        // if (!islogin) {
        await authApi(account)
        // }
    }


    const CheckUsername = async (username) => {
        var usercrnt = await getSingleUser({ username });
        usercrnt = usercrnt.user
        setSame(false)
        if (usercrnt) {
            if (profile) {
                if (profile.username !== usercrnt.username) {
                    setSame(true)
                }
            } else {
                if (usercrnt) {
                    setSame(true)
                }
            }

        }

    }

    if (loading) {
        return <Loader />
    }

    return (
        <div className="container-fluid">
            <div className='form-transp-bg'>
                <div className="row">
                    <div className="col-3 mx-0 px-0 pl-5">
                        <ul className="list-unstyled myprofilesidebarMenu mt-10 pl-5">
                            <li>
                                <NavLink className="nav-link" to={profile ? `/profile/${profile?.username}` : ''}>
                                    <span><img src={userImage} /></span>
                                    <span>Profile</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to={`/burn`}>
                                    <span><img src='/img/nft-burn-icon.png' /></span>
                                    <span>Burn</span>
                                </NavLink>
                            </li>
                            {/* <li>
                                <NavLink className="nav-link" to="/">
                                    <span><img src={Message_Bell} /></span>
                                    <span>Notification</span>
                                </NavLink>
                            </li> */}
                            {/* <li>
                                <NavLink className="nav-link" to="/offers">
                                    <span><img src={offerImage} /></span>
                                    <span>Offer</span>
                                </NavLink>
                            </li> */}
                            {/* <li>
                                <NavLink className="nav-link" to="/">
                                    <span><img src={supportImage} /></span>
                                    <span>Support</span>
                                </NavLink>
                            </li> */}
                        </ul>
                    </div>
                    <div className="col-5 p-0 profile-mid-panel">
                        <h3 className="oracleTextClass" style={{}}>Profile</h3>
                        <Formik
                            initialValues={{
                                username: username,
                                email: email || '',
                                bio: bio,
                            }}
                            enableReinitialize
                            validationSchema={ProfileSchema}
                            onSubmit={(values, actions) => {
                                if (!loading) {
                                    submit(values)
                                }
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                /* and other goodies */
                            }) => (
                                <form
                                    onSubmit={handleSubmit}
                                    className="item-form createNftForm  no-hover"
                                    id="profile"
                                >
                                    <div className="row flex-column">
                                        <h5 className="profile-heading-text pl-3 mt-5">SETTING</h5>
                                        <p className="pl-3"><sup>*</sup> Required Fields</p>
                                        <div className="col-12">
                                            <div className="form-group mt-3">
                                                <label>Username<sup>*</sup> </label>
                                                <Field name="username" onBlur={((event) => CheckUsername(event.target.value))} defaultValue={profile ? profile.username : ''} type="text" className="nft-form-control" />
                                            </div>
                                            <div className='text-danger'>{errors.username}</div>
                                            <div className='text-danger'>{isSame ? 'Username already been taken!' : ''}</div>
                                        </div>


                                        <div className="col-12">
                                            <div className="form-group mt-3">
                                                <label>Bio<sup>*</sup></label>
                                                <Field as="textarea" name='bio' />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group mt-3">
                                                <label>Email<sup>*</sup> </label>
                                                <Field type="email" className="nft-form-control" name="email" />
                                                <div className='text-danger'>{errors.email}</div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group mt-3">
                                                <label>Links</label>
                                                <div className="links-nft-form-item d-flex justify-content-between align-items-center position-relative">
                                                    <input onChange={(event) => saveLinks({ link4: event.target.value })} defaultValue={links.link4} type="text"
                                                        className="nft-form-control pl-6" name="item_name" placeholder="www.website.com" />
                                                    <div className='link_icon_nft position-absolute links-icons top-4'>
                                                        <svg className="CollectionForm--link-logo" fill="#8A939B" viewBox="1 1 22 22"><path clip-rule="evenodd" d="M2 12C2 6.48 6.47 2 11.99 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 11.99 22C6.47 22 2 17.52 2 12ZM15.97 8H18.92C17.96 6.35 16.43 5.07 14.59 4.44C15.19 5.55 15.65 6.75 15.97 8ZM12 4.04C12.83 5.24 13.48 6.57 13.91 8H10.09C10.52 6.57 11.17 5.24 12 4.04ZM4 12C4 12.69 4.1 13.36 4.26 14H7.64C7.56 13.34 7.5 12.68 7.5 12C7.5 11.32 7.56 10.66 7.64 10H4.26C4.1 10.64 4 11.31 4 12ZM5.08 16H8.03C8.35 17.25 8.81 18.45 9.41 19.56C7.57 18.93 6.04 17.66 5.08 16ZM5.08 8H8.03C8.35 6.75 8.81 5.55 9.41 4.44C7.57 5.07 6.04 6.34 5.08 8ZM12 19.96C11.17 18.76 10.52 17.43 10.09 16H13.91C13.48 17.43 12.83 18.76 12 19.96ZM9.5 12C9.5 12.68 9.57 13.34 9.66 14H14.34C14.43 13.34 14.5 12.68 14.5 12C14.5 11.32 14.43 10.65 14.34 10H9.66C9.57 10.65 9.5 11.32 9.5 12ZM14.59 19.56C15.19 18.45 15.65 17.25 15.97 16H18.92C17.96 17.65 16.43 18.93 14.59 19.56ZM16.5 12C16.5 12.68 16.44 13.34 16.36 14H19.74C19.9 13.36 20 12.69 20 12C20 11.31 19.9 10.64 19.74 10H16.36C16.44 10.66 16.5 11.32 16.5 12Z" fileRule="evenodd" id="icon/action/language_24px"></path></svg>
                                                        {/* <img src={Website}></img> */}
                                                    </div>
                                                </div>
                                                <div className="links-nft-form-item d-flex justify-content-between align-items-center position-relative">
                                                    {/* <input onChange={(event) => saveLinks({ link2: event.target.value })} defaultValue={links.link2} type="text"
                                                        className="nft-form-control pl-6" name="item_name" /> */}
                                                    <InputMask
                                                        mask="www.twitter.com\/*************************************************************************************
                                                        ***********************************************************************************************************"
                                                        formatChars={formatCharsAdd} placeholder="www.twitter.com/YourTwitterHandle"
                                                        maskChar=""
                                                        onChange={(event) => saveLinks({ link2: event.target.value })} defaultValue={links.link2} type="text"
                                                        className="nft-form-control pl-6" name="item_name"
                                                    />
                                                    <div className='link_icon_nft position-absolute top-4'>
                                                        <img src={Twitter}></img>
                                                    </div>
                                                </div>
                                                <div className="links-nft-form-item d-flex justify-content-between align-items-center position-relative">
                                                    {/* <input onChange={(event) => saveLinks({ link3: event.target.value })} defaultValue={links.link3} type="text" 
                                                    className="nft-form-control pl-6" name="item_name" /> */}
                                                    <InputMask
                                                        mask="www.facebook.com\/*************************************************************************************
                                                        ***********************************************************************************************************"
                                                        formatChars={formatCharsAdd} placeholder="www.facebook.com/YourFacebook"
                                                        maskChar="" type='text'
                                                        onChange={(event) => saveLinks({ link3: event.target.value })} defaultValue={links.link3}
                                                        className="nft-form-control pl-6" name="item_name"
                                                    />
                                                    <div className='link_icon_nft position-absolute top-4'>
                                                        <img src={Facebook}></img>
                                                    </div>
                                                </div>
                                                <div className="links-nft-form-item d-flex justify-content-between align-items-center position-relative">
                                                    {/* <input onChange={(event) => saveLinks({ link5: event.target.value })} defaultValue={links.link5} type="text" 
                                                    className="nft-form-control pl-6" name="item_name" /> */}
                                                    <InputMask
                                                        mask="www.discord.gg\/*************************************************************************************
                                                        ***********************************************************************************************************"
                                                        formatChars={formatCharsAdd} placeholder="www.discord.gg/YourDiscordHandle"
                                                        maskChar="" type='text'
                                                        onChange={(event) => saveLinks({ link5: event.target.value })} defaultValue={links.link5}
                                                        className="nft-form-control pl-6" name="item_name"
                                                    />
                                                    <div className='link_icon_nft position-absolute top-4'>
                                                        <img src={Discord}></img>
                                                    </div>
                                                </div>
                                                <div className="links-nft-form-item d-flex justify-content-between align-items-center position-relative">
                                                    {/* <input onChange={(event) => saveLinks({ link1: event.target.value })} defaultValue={links.link1} type="text"
                                                     className="nft-form-control pl-6" name="item_name" /> */}
                                                    <InputMask
                                                        mask="www.instagram.com\/*************************************************************************************
                                                        ***********************************************************************************************************"
                                                        formatChars={formatCharsAdd} placeholder="www.instagram.com/YourInstagramHandle"
                                                        maskChar="" type='text'
                                                        onChange={(event) => saveLinks({ link1: event.target.value })} defaultValue={links.link1}
                                                        className="nft-form-control pl-6" name="item_name"
                                                    />
                                                    <div className='link_icon_nft position-absolute top-4'>
                                                        <img src={Insta}></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group mt-3">
                                                <label>Wallet address</label>
                                                <Field type="text" className="nft-form-control" readOnly={true} placeholder={user?.wallet_address} value={user?.ethAddress} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button className="create-btn mt-3 mt-sm-4" type="submit">{!issubmitting ? username ? 'Save' : 'Create' : 'Please Wait'}</button>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                    <div className="col-4 mt-10-cent">
                        <div className="col-12 ml-4">
                            <div className="input-group-d form-group mt-4">
                                <div className="custom-file-old">
                                    <label className="image-upload-lbl mt-4 pt-3">Profile Image (File types supported: jpg, jpeg, png, gif, Recommended Size: 210 x 210 px )</label>
                                    <p></p>
                                    <label className="custom-file-label-old" htmlFor="inputGroupFile02">
                                        <div className={`${addImage ? "image-preview-box add-image" : "image-preview-box"}`}>
                                            <img src={feature} style={{ objectFit: 'contain' }} />
                                        </div>
                                    </label>
                                    <button className="create-btn mt-3 mt-sm-4 mb-5" type="button">
                                        <label for="inputGroupFile02">Upload Profile Image</label>
                                    </button>
                                    <Form.Item
                                        name="feature_image"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input file!",
                                            },
                                        ]}
                                    >
                                        <input onChange={((event) => saveFiles(event, 'feature'))} type="file" name="feature_image" className="custom-file-input-none" id="inputGroupFile02" />
                                        {fileType !== '' ? <div role="alert" className="ant-form-item-explain-error">{fileType}</div> : ''}

                                    </Form.Item>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="input-group-d form-group mt-4">
                                <div className="custom-file-old">
                                    <label className="image-upload-lbl">Profile Banner (File types supported: jpg, jpeg, png, gif, Recommended Size: 1000 x 430 px)</label>
                                    <label className="custom-file-label-old" htmlFor="inputGroupFile03">
                                        <div className={`${addBanner ? "image-preview-box-banner-image add-banner" : "image-preview-box-banner-image"}`}>
                                            <img src={banner} style={{ objectFit: 'contain' }} />
                                        </div>
                                    </label>
                                    <button className="create-btn mt-3 mt-sm-4 mb-5" type="button">
                                        <label for="inputGroupFile03">Upload Profile Banner</label>
                                    </button>
                                    <Form.Item
                                        name="banner_image"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input file!",
                                            },
                                        ]}
                                    >
                                        <input onChange={((event) => saveFiles(event, 'banner'))} type="file" name="banner_image" className="custom-file-input-none" id="inputGroupFile03" />
                                        {bannerFileType !== '' ? <div role="alert" className="ant-form-item-explain-error">{bannerFileType}</div> : ''}
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                        {/* history.push(`/profile/${profile?.username}`) */}
                            <div onClick={() => profile ? window.open(`/profile/${profile?.username}`, "_blank") : ''} className="previewWap d-flex align-center mt-5">
                                <h5 className="previewHeading m-0">Preview Profile</h5>
                                <i className="fa fa-eye" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
const mapStateToProps = state => ({
    islogin: state.auth.isLogin,
    auth: state.auth.auth
})
export default connect(mapStateToProps)(MyProfile);

import { actionTypes } from './action';

const saveToken = (data) =>{
    var {token} = data
    if(typeof window !== 'undefined' && localStorage && token) {
        localStorage.removeItem('token')
        const now = new Date()
        const item = {
            token: token,
            expiry: now.getTime()+3600000,
        }
        localStorage.setItem('token', JSON.stringify(item))        
    }
}

export const initState = {
    isLogin: false,
    auth:{},
    isSignature:false
};

function reducer(state = initState, action) {
    switch (action.type) {
        case actionTypes.LOGIN_SUCCESS:
            // saveToken(action.payload)
            return {
                ...state,
                ...{ auth: action.payload,isLogin:true,isSignature:false },
            };
        case actionTypes.LOGOUT:
            localStorage.removeItem('token')
            return {
                ...state,
                ...{ auth: {},isLogin:false,isSignature:false },
            };
        case actionTypes.UPDATE_PROFILE:
            return {
                ...state,
                ...{ auth: action.profile },
            };
        case actionTypes.SIGNATURE:
            return {
                ...state,
                ...{ isSignature:true,auth: action.payload },
            }; 
        case actionTypes.SIGNATURE_STATUS_CHANGE:
            return {
                ...state,
                isSignature:action.payload
              };
            
        default:
            return state;
    }
}

export default reducer;

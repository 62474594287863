import React, { useEffect, useState,useRef } from 'react';
import { connect } from 'react-redux'
import {useHistory} from 'react-router-dom'
import WalletConnectProvider from "@walletconnect/web3-provider";
import userImage from '../../components/Header/Profile.svg';
import Message_Bell from '../../components/Header/Message_Bell.svg';
import offerImage from '../../components/Header/Offres_Profile.svg';
import supportImage from '../../components/Header/Support.svg';
import { NavLink } from 'react-router-dom';
import loader from '../../image/loader.gif'
import Loader from '../Skeletons/HomePageSkeleton'
import { Space, Table, Tag } from 'antd';
import moment from 'moment'
import { useWeb3React } from "@web3-react/core";  
import {  notification } from 'antd'
import { SmileOutlined, CloseOutlined, ConsoleSqlOutlined } from "@ant-design/icons";
import Web3 from 'web3';
import { nftContractAddress, marketPlaceAddress,smartChainUrl } from '../../abi/address';
import marketPlaceabi from "../../abi/marketPlace.json"
import ERC721 from "../../abi/erc721global.json"

//custom hooks
import useOffers from '../../hooks/useOffers';
import useNFT from '../../hooks/useNFT';
import useComman from '../../hooks/useComman';

const provider = new WalletConnectProvider({
    rpc: {
        56: "https://bsc-dataseed1.binance.org",
        // 97:"https://data-seed-prebsc-1-s1.binance.org:8545/",
    },
    chainId: 56,
    network: "binance",
    qrcode: false
  });
function MyOffer({props,islogin,auth}) {
    const [profile, setProfile] = useState(false)
    const [loading, setLoading] = useState(true)
    const [isSame, setSame] = useState(false)
    const [offerData,setOfferData] = useState([])
  
    const ref = useRef(false)
    const history = useHistory()
    const { library } = useWeb3React();

    //custom hooks
    const { getOfferFilter } = useOffers()
    const { getNft,update } = useNFT()
    const { transaction } = useComman()

    history.listen((location, action) => {
        userProfile()
    })
    useEffect(() => {
        userProfile(props?.match.params.id)        
        ref.current = true
        return () => {
            ref.current = false
        }
    }, [islogin])

    const timer=(date)=>{
        var start = new Date()
        var end = new Date(date)
        var state = { startDate: start.getTime(), timeEnd: end.getTime() } // example
        const startDate = moment(state.startDate);
        const timeEnd = moment(state.timeEnd);
        const diff = timeEnd.diff(startDate);
        const diffDuration = moment.duration(diff);
        var timer = {
            day: diffDuration.days(),
            hours: diffDuration.hours(),
            minutes: diffDuration.minutes(),
            seconds: diffDuration.seconds()
        }       
        if( timer.day < 0){
            return false
        }
        if( timer.hours < 0){
            return false
        }
        if( timer.minutes < 0){
            return false
        }
        return timer
    }

    const countDown = (date) => {
        var timerData =  timer(date)             
        if( !timerData){
            return <span>Closed</span>
        }
        
        return  <div>
                    {timerData.day !== 0 ? <span>{timerData.day} Day</span>:''}
                    {timerData.hours !== 0 ? <span>{timerData.hours} Hours</span>:''}
                    <span> {timerData.minutes} Minute </span>
                </div>
    }
    
    const userProfile = async (id='') => {
        
        setLoading(true)
        let user = auth?.user
        if(user){
            if(id !==''){
                var offerData = await getOfferFilter({nft_id:id})
                offerData = offerData?.offer?.latestOffres                
                setOfferData(offerData)

            }else{
                var offerData = await getOfferFilter({offer_for_user_id:user._id})
                offerData = offerData?.offer?.latestOffres  
                setOfferData(offerData)
            }
            
        }
        setLoading(false)
    }

    const checkOffer=(id)=>{

    }
    
    if(loading){
        return <Loader/>
    }
    
    const acceptOffer = async(nft,endDate,userid,price) => {
        
        var user = auth?.user      
        var time = timer(endDate)        
        var checkNft = await getNft({network: nft.chain_name, contract_address: nft.contract_address, token_id: nft.token_id})      
        const tokenId = checkNft.token_id
        const userAdd = user.wallet_address
        const buyNowPrice = price;
        // const checkNft = 
        const web3 = await new Web3(library.provider)
        const connnectApprove = new web3.eth.Contract(ERC721,nftContractAddress) 
        const isApprove = await connnectApprove.methods.isApprovedForAll(userAdd,marketPlaceAddress).call({ from: userAdd })
        // const collectionDetails = nft.collection_id;
        // const validPayout = collectionDetails['payout'].filter(e => e['wallet_address']);
        // const payoutAddresses = validPayout.map(e => e['wallet_address'])
        // const percentageFees = validPayout.map(e =>  e['percentage'] ? +e['percentage'] * 100 : 0)
        
        if(!isApprove){
            await connnectApprove.methods.setApprovalForAll(marketPlaceAddress,true).send({ from: userAdd })
        }

        if(checkNft?.isOfferAccepted){
            notification.open({
                message: "Sorry",
                description: "You have already accepted the offer for this nft!",
                icon: <CloseOutlined style={{ color: "#ff0000" }} />,
            }); 
            return 
        }
        if(!time){
            notification.open({
                message: "Sorry",
                description: "Offer time is over!",
                icon: <CloseOutlined style={{ color: "#ff0000" }} />,
            }); 
            return 
        }      

        try{
                 
            const connnectApprove = new web3.eth.Contract(ERC721,nftContractAddress) 
            const isApprove = await connnectApprove.methods.isApprovedForAll(userAdd,marketPlaceAddress).call({ from: userAdd })
            
            if(!isApprove){
                await connnectApprove.methods.setApprovalForAll(marketPlaceAddress,true).send({ from: userAdd })
            }
            const marketPlaceContract = new web3.eth.Contract(marketPlaceabi, marketPlaceAddress);

            // sellNFTOnMarket(address _nftContractAddress,uint256 _tokenId,uint256 _price,uint256 _serviceFees,address payable[] calldata _creators,uint16[] calldata _creatorFees, uint256 _qty)

            /*
            .sellNFTOnMarket(
                                        nftContractAddress,
                                        tokenId,
                                        Web3.utils.toWei(`${buyNowPrice}`, 'ether'), 
                                        Number(nft?.collection_id?.service_fee),
                                        payoutAddresses,
                                        percentageFees,
                                        1
                                        // userAdd,
                                        // '20'//for 2%
                                    )
            */

            var CreatNewSale = await  marketPlaceContract
                                    .methods
                                    .sellNFTOnMarket(
                                        nftContractAddress,
                                        tokenId,
                                        Web3.utils.toWei(`${buyNowPrice}`, 'ether'), 
                                        userAdd,
                                        '20'//for 2%
                                    ).send({ from: userAdd });

        }catch(error){
            console.log('error ',error)
            setLoading(false)
            notification.open({
                message: "NFT",
                description: "Cancel the transaction!",
                icon: <SmileOutlined style={{ color: "#108ee9" }} />,
            });
            return
        }

            const nftAuction  = CreatNewSale?.events?.NftDataCreated  
    
            var data={
                type:'FIXED',              
                ready_for_sell : true,
                isOfferAccepted:true,
                accepted_by:userid,
                isEditable:false,
                start_date: new Date(),
                end_date: new Date(),
                start_price:buyNowPrice,
                end_price:buyNowPrice,
               
            }    
            var nftResponse = await update(data,nft._id)

            var dataTransaction = {
                saller_id: nftResponse.owner_id,
                nft_id: nftResponse,
                buyer_id: undefined,
                event: 'Sale',
                price: price,
                date:new Date(),
                url:smartChainUrl,
                tnxHasId:nftAuction.transactionHash,
            }

            if(nftResponse){
                notification.open({
                    message: "Accepted offer",
                    description: "Offer successfully accepted!",
                    icon: <SmileOutlined style={{ color: "#f4364c" }} />,
                }); 
                var transtion = await transaction(dataTransaction)

            }
            else{
                notification.open({
                    message: "Sorry",
                    description: "Something webt wrong!",
                    icon: <CloseOutlined style={{ color: "#ff0000" }} />,
                }); 
            }
    }

    const columns = [
        {
          title: 'Image',      
          render: (value) =><NavLink to={`/assets/${value?.nft_id?.chain_name}/${value?.nft_id?.contract_address}/${value?.nft_id?.token_id}`}> <img style={{width:'50px',height:"50px"}} src={value?.nft_id.item_image}/></NavLink>
        },
        {
          title: 'NFT Name',
          render: (value) =><NavLink to={`/assets/${value?.nft_id?.chain_name}/${value?.nft_id?.contract_address}/${value?.nft_id?.token_id}`}> <span>{value?.nft_id.item_name}</span></NavLink>
        },
        {
            title: 'Offer Price',
            render: (value) => <span>{value?.offertprice}</span>
        },
        {
            title: 'Offer maker',
            render: (value) => <NavLink to={`/profile/${value?.user_id?.username}`}>{value?.user_id?.username}</NavLink>
        },
        {
          title: 'Expire Time',
          render: (value)=><>{countDown(value?.offer_end_expiration)}</>
         
        },
        {
          title: 'Action',
          render:(value) => <button className="offer_nft-sub-btn" onClick={()=>!value.nft_id.isOfferAccepted  ? acceptOffer(value?.nft_id,value?.offer_end_expiration,value?.user_id?._id,value?.offertprice):''}>{!value.nft_id.isOfferAccepted ? 'Accept':'Accepted'}</button>
        },
      ];

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-3 mx-0 px-0 pl-5">
                    <ul className="list-unstyled myprofilesidebarMenu mt-10 pl-5">
                        <li>
                            <NavLink className="nav-link" to={profile ? `/profile/${profile?.get('username')}`:''}>
                                <span><img src={userImage} /></span>
                                <span>Profile</span>
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink className="nav-link" to="/">
                                <span><img src={Message_Bell} /></span>
                                <span>Notification</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="nav-link" to="/offers">
                                <span><img src={offerImage} /></span>
                                <span>Offer</span>
                            </NavLink>
                        </li> */}
                        {/* <li>
                            <NavLink className="nav-link" to="/">
                                <span><img src={supportImage} /></span>
                                <span>Support</span>
                            </NavLink>
                        </li> */}
                    </ul>
                </div>
                <div className="col-9 p-0">
                    <h3 className="oracleTextClass" style={{ letterSpacing: '1px' }}>Offer</h3>
                    <Table columns={columns} dataSource={offerData} />                    
                </div>                
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    islogin:state.auth.isLogin,
    auth:state.auth.auth
})

export default connect(mapStateToProps)(MyOffer)
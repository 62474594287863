import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
//import ExploreOne from "../themes/explore-one";
//import ExploreThree from "../themes/explore-three";
//import ExploreFour from "../themes/explore-four";
//import Mcol from "../themes/Mcol";
//import Activity from "../themes/activity";
//import Coldetails from "../themes/Col-detail";
//import ItemDetails from "../themes/item-details";
//import Assetcreate from "../themes/assetcreate";
//import ExploreTwo from "../themes/explore-two";
import Create from "../themes/create";
import Profile from "../themes/theme-one";
//import Dashbord from "../themes/Dashboard";
//import PageNotFound from "../themes/PageNotFound";
/*import Blog from "../themes/blog";*/
import About from "../themes/about";
import TermsOfService from "../themes/terms-of-service";
import PrivacyPolicy from "../themes/privacy-policy";
///*import BlogSingle from "../themes/blog-single";*/
////import HelpCenter from "../themes/help-center";
////import Authors from "../themes/authors";
////import Author from "../themes/author";
////import WalletConnect from "../themes/wallet-connect";
//import Sale from "../themes/Sale";
import Header from "../components/Header/Header1";
//import Allcol from "../themes/allcol";
//import Login from "../themes/login";
//import Signup from "../themes/signup";
import Contact from "../themes/contact";
//import Alllast from "../themes/Allast";
import ScrollToTop from "../ScrollToTop";
import { Modal } from "react-bootstrap";
//import Crator from "../themes/crator";
//import MarketPage from "../themes/MarketPage";
import MyProfile from "../themes/MyProfile";
import ExplorePage from "../themes/ExplorePage";
//import AuctionPage from "../themes/AuctionPage";
//import TrendingPage from "../themes/TrendingPage";
//import DropPage from "../themes/DropsPage";
import CratorPage from "../themes/CratorPage";
//import NewestPage from "../themes/NewestPage";
//import ActivityPage from "../themes/ActivityPage";
import LendingPage from "../themes/LendingPage";
//import BorrowPage from "../themes/BorrowPage";
import NftDetailPage from "../themes/NftDetailPage";
import NftDetailPage2 from "../themes/NftDetailPage2";
import NftDetailPage3 from "../themes/NftDetailPage3";
import CollectionsPage from "../themes/CollectionPage";
import GamesOraclePage from "../themes/GamesOraclePage";
//import GamesMultiplayerPage from "../themes/GamesMultiplayerPage";
import CreateCollectionsPage from "../themes/CreateCollectionsPage";
import CreateLandingPage from "../themes/CreateLandingPage";
//import CreateStep2 from "../themes/CreateStep2";
import ListForSalePage from "../themes/ListForSalePage";
import EditNFT from "../components/Create/Edit";
//// import MysteryPage from '../themes/MysteryPage'
////import Sell from "../components/Sell/index";
import EditCollectionsPage from "../themes/EditCollection"
////import CreatMystery from '../components/Admin/Mystery/Create'
////import EditMystery from '../components/Admin/Mystery/Edit'
////import ListMystery from '../components/Admin/Mystery/index'
////import MysteryBox from '../themes/MystoryBox'
////import CreateMysteryCollection from '../components/Admin/Mystery/CreateCollection'
import OfferList from '../themes/OfferList'
//import ListComponent from '../components/Admin/Slider/ListComponent'
///import NotFound from '../themes/PageNotFound'
// import { useMoralis } from "react-moralis";
import { useCookies } from "react-cookie";
import cookies from "js-cookie";
import NewMysteryBox from "../components/MysteryBox/MysteryBox";
import EmailVerifyPage from "../themes/EmailVerifyPage";
import { boolean } from "yup";
// import MysteryList from "../components/MysteryBox/MysteryList";
/////import MysteryPage from "../components/MysteryBox/MysteryPage";
import PaymentResponse from "../themes/PaymentResponse";
import BurnAddress from "../components/BurnAddress/BurnAddress";

function MyRouts() {
  const [theme, settheme] = useState(true);
  const [accountid, setaccountid] = useState();
  const [accountid1, setaccountid1] = useState();
  const [chainid, setchainid] = useState();
  const [show, setShow] = useState(false);
  // const [isDarkMode, setDarkMode] = useState(1);
  const [isDarkMode, setDarkMode] = useState(true);

  const [web3main, setweb3main] = useState();
  const handleClose = () => setShow(false);
  const [prov, setprov] = useState();
  // const { isAuthenticated } = useMoralis();
  const [setCookie, removeCookie] = useCookies();

  const [acc, setacc] = useState(false);
  const change = (v) => {
    setacc(v);
  };
  const web3m = (v) => {
    setweb3main(v);
  };
  const provider1 = (v) => {
    setprov(v);
  };

  useEffect(async () => {
    if (acc) {
      const accounts1 = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      setaccountid1(accounts1[0]);
      const chainId = await window.ethereum.request({ method: "eth_chainId" });

      setchainid(chainId);
      const accountsa = await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x61" }], // chainId must be in hexadecimal numbers
      });

      await window.ethereum.on("chainChanged", (chainId) => {
        window.location.reload();
      });
    }
  }, [acc]);

  useEffect(() => {
    if (accountid !== accountid1) {
      window.location.reload();
      // history.push('/')
    }
  }, [accountid, isDarkMode]);

  useEffect(() => {
    if (accountid1 && chainid) {
      chainid == "0x61" ? setShow(false) : setShow(true);
    }
  }, [accountid1, chainid, isDarkMode]);

  useEffect(async () => {
    darkMode(cookies.get('darkMode'))
    if (acc) {
      const accounts1 = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      if (acc && window.ethereum) {
        async function getAccount() {
          const accounts = await window.ethereum.enable();
          const account = accounts[0];
        }

        window.ethereum.on("accountsChanged", function (accounts) {
          getAccount();
        });
      }
    }
  }, []);

  const darkMode = (data) => {

    // var data = typeof (data) === 'string' ? parseInt(data) : data
    if (data !== undefined) {
      data = JSON.parse(data);
    }
    if (data || data === undefined) {
      cookies.set("darkMode", true); //1
      var body = document.body;
      body.classList.add("nft_dark_mode_body");
      document.body.classList.remove("nft_light_mode_body");
      setDarkMode(true)  //1
    } else {
      var body = document.body;
      body.classList.add("nft_light_mode_body");
      document.body.classList.remove("nft_dark_mode_body");
      setDarkMode(false) //0
      cookies.set("darkMode", false); //0
    }
  }

  return (
    <>
      <div className={isDarkMode ? "nft_dark_mode_wraper" : "nft_light_mode_wraper"}>
        {/* <Router> */}

        <ScrollToTop />
        <Header
          theme={theme}
          change={change}
          web3m={web3m}
          provider1={provider1}
          darkMode={(data) => darkMode(data)}
          isDarkMode={isDarkMode}
        />
        <Switch>
          <Route exact path="/contact" component={Contact} />
          {/*<Route exact path="/blog" component={Blog} />*/}
          <Route exact path="/about" component={About} />
          <Route exact path="/terms-of-service" component={TermsOfService} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          {/*<Route exact path="/help-center" component={HelpCenter} />*/}

          {/* Start Admin Routes */}
          {/* <Route exact path="/dashboard" component={Dashbord} />
            <Route exact path="/page-404" component={PageNotFound} />
            <Route exact path="/create-mystery" component={CreatMystery} />
            <Route exact path="/edit-mystery/:id" component={(props)=><EditMystery {...props}/>} />
            <Route exact path="/list-mystery" component={ListMystery} />
            <Route exact path="/slider-settings" component={ListComponent} />
            <Route exact path="/create-mystery-collection" component={CreateMysteryCollection} /> */}

          {/* End Admin Routes */}

          {/* <Route exact path="/explore-1" component={ExploreOne} />
          <Route exact path="/explore-2" component={ExploreTwo} />
          <Route exact path="/explore-3" component={ExploreThree} />
          <Route exact path="/explore-4" component={ExploreFour} />
          <Route exact path="/activity" component={Activity} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/blog-single" component={BlogSingle} />
          <Route exact path="/help-center" component={HelpCenter} />
          <Route exact path="/authors" component={Authors} />
          <Route exact path="/author" component={Author} />
          <Route exact path="/wallet-connect" component={WalletConnect} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/crator" component={Crator} />
          <Route exact path="/market" component={MarketPage} />
          <Route exact path="/mystery" component={MysteryPage} />
          <Route exact path="/mysterybox/:id" component={(props)=><MysteryBox {...props}/>} />
          <Route
            exact
            path="/setting"
            children={() =><MyProfile />}
          />
          
          
          
          <Route exact path="/auction" component={(()=> <AuctionPage/>)} />
          <Route exact path="/trending" component={(() => <TrendingPage isvisibale={true}/>)} />
          <Route exact path="/drops" component={(()=> <DropPage />)} />
          <Route
            exact
            path="/crators"
            component={(props) => <CratorPage {...props} />}
          />
          
          <Route exact path="/newest" component={NewestPage} />
          <Route exact path="/activities" component={ActivityPage} />
          <Route exact path="/lending-page" component={LendingPage} />
          <Route exact path="/borrow" component={BorrowPage} />
          <Route
            exact
            path="/assets/:id"
            component={(props) => <NftDetailPage {...props} />}
          />
          <Route
            exact
            path="/profile/:id"
            component={(props) => <NftDetailPage2 {...props} />}
          />
          
          <Route
            exact
            path="/list-for-sale/:id"
            component={(props) => <Sell {...props} />}
          /> */}
          {/* 
          
          <Route exact path="/games-oracle" component={GamesOraclePage} />
          <Route
            exact
            path="/games-multiplayer"
            component={GamesMultiplayerPage}
          />
          <Route exact path="/CreateStep2" component={CreateStep2} />
          <Route exact path="/sell/:id" component={(props) => <ListForSalePage {...props}/>} />
          <Route exact path="/list-for-sale" component={ListForSalePage} /> */}

          <Route exact path="/games-oracle" component={GamesOraclePage} />
          <Route exact path="/lending-page" component={LendingPage} />


          <Route exact path="/create/" component={CreateLandingPage} />
          <Route
            exact
            path="/explore/"
            component={(props) => <CollectionsPage {...props} />}
          />
          <Route
            exact
            path="/setting"
            children={() => <MyProfile />}
          />
          <Route exact path="/burn" children={() => <BurnAddress />} />
          <Route
            exact
            path="/create-nft:collection_name?"
            children={(props) => (
              <Create
                props={props}
                theme={theme}
                acc={acc}
                web3main={web3main}
                prov={prov}
              />
            )}
          />
          <Route
            exact
            path="/assets"
            component={(props) => (
              <ExplorePage {...props} routePath={"/assets"} />
            )}
          />
          <Route
            exact
            path="/assets/:network/:contract_address/:token_id"
            component={(props) => <NftDetailPage props={props} />}
          />
          {/* change={onc} */}
          <Route exact path="/">
            <Profile theme={theme} acc={acc} web3main={web3main} prov={prov} />
          </Route>
          <Route
            exact
            path="/create-collections"
            component={CreateCollectionsPage}
          />
          <Route
            exact
            path="/create-mystery-box"
            component={NewMysteryBox}
          />
          <Route
            exact
            path="/edit-mystery-box/:network/:contract_address/:token_id"
            // component={NewMysteryBox}
            children={(props) => (
              <NewMysteryBox
                props={props}
                theme={theme}
                acc={acc}
                web3main={web3main}
                prov={prov}
              />
            )}
          />
          {/* <ExplorePage {...props} routePath={"/assets"} /> */}
          <Route exact path="/mystery" component={(props) => <ExplorePage {...props} routePath={"/mystery?isMystery=true"} />} />
          {/* <Route exact path="/mystery" component={(props) => <MysteryPage {...props} />} /> */}

          <Route
            exact
            path="/collections"
            component={(props) => <CollectionsPage {...props} />}
          />
          <Route exact path="/sell/:network/:contract_address/:token_id" component={(props) => <ListForSalePage {...props} />} />
          <Route
            exact
            path="/edit_nft/:network/:contract_address/:token_id"
            children={(props) => (
              <EditNFT
                props={props}
                theme={theme}
                acc={acc}
                web3main={web3main}
                prov={prov}
              />
            )}
          />
          <Route
            exact
            path="/collection/:name"
            component={(props) => <NftDetailPage3 props={props} />}
          />
          <Route
            exact
            path="/creators"
            component={(props) => <CratorPage {...props} />}
          />
          <Route
            exact
            path="/profile/:id"
            component={(props) => <NftDetailPage2 props={props} />}
          />
          <Route
            exact
            path="/edit-collections/:id"
            component={(props) => <EditCollectionsPage {...props} />}
          />
          <Route
            exact
            path="/offers/:id?"
            children={(props) => <OfferList props={props} />}
          />
          <Route exact path="/email-verify" component={EmailVerifyPage} />
          <Route exact path="/payment-response" component={PaymentResponse} />
          {/* <Route exact path="/auctions">
            <AuctionPage theme={theme} acc={acc} web3main={web3main} />
          </Route>
          <Route exact path="/item-details/:nftid">
            <ItemDetails
              theme={theme}
              acc={acc}
              web3main={web3main}
              prov={prov}
            />
          </Route>
          <Route exact path="/sale">
            <Sale theme={theme} acc={acc} web3main={web3main} prov={prov} />
          </Route>
          <Route
            exact
            path="/create-nft:collection_name?"
            children={(props) => (
              <Create
                props={props}
                theme={theme}
                acc={acc}
                web3main={web3main}
                prov={prov}
              />
            )}
          />

          <Route
            exact
            path="/edit_nft/:id"
            children={(props) => (
              <EditNFT
                props={props}
                theme={theme}
                acc={acc}
                web3main={web3main}
                prov={prov}
              />
            )}
          /> */}

          {/* <Route exact path="/mycollection">
            <Mcol theme={theme} acc={acc} web3main={web3main} prov={prov} />
          </Route>
          <Route exact path="/assetcreate">
            <Assetcreate
              theme={theme}
              acc={acc}
              web3main={web3main}
              prov={prov}
            />
          </Route>
          <Route exact path="/col-details/:colid">
            <Coldetails
              theme={theme}
              acc={acc}
              web3main={web3main}
              prov={prov}
            />
          </Route>
          <Route exact path="/allcol">
            <Allcol acc={acc} web3main={web3main} prov={prov} />
          </Route>
          <Route exact path="/allnft">
            <Alllast acc={acc} web3main={web3main} prov={prov} />
          </Route>
          <Route path='/*' component={<NotFound text={'Page Not Found'}/>} /> */}
        </Switch>
        {/* </Router> */}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h5>Switch Wallet to Smart Chain-Testnet</h5>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MyRouts;

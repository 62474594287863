import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Space, Radio } from "antd";
import userEvent from "@testing-library/user-event";
import WalletAddress from "../WalletAddress/WalletAddress";

function ImportCollection(props) {
    const [collectionSelected, setCollectionSelected] = useState(false);
    const [radioValue, setRadioValue] = useState(1);
    const { isLogin, auth } = props;
// console.log('auth', auth);

    return (
        <>
            <div className="import-modal-wrap NftmodelsPopupWrap">
                <div className="modal fade" id="collectionImportModal" tabIndex={-1} role="dialog" aria-labelledby="collectionModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg nft-modal" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="collectionModalLabel">Import Collection</h5>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="input-group form-group mt-4">
                                        <div className="custom-file-old csv-file-add">
                                            <label className="image-upload-lbl collection-dropdown">Source:</label>
                                            <Radio.Group value={radioValue}>
                                                <Radio value={1} >OpenSea</Radio>
                                                {/* <Radio value={2}>B</Radio> */}
                                            </Radio.Group>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-column w-100 px-3 mb-4">
                                    <div className="row">
                                        <div className="col-4">
                                            <label className="image-upload-lbl">Wallet Address:</label>
                                        </div>
                                        <div className="col-8">
                                            <span className="wallet-addres-box">{auth?.user?.wallet_address}</span>
                                        </div>
                                    </div>
                                    {/* <WalletAddress /> */}
                                </div>


                                <div className="flex-column w-100 px-3 mb-4">
                                    <div className="row">
                                        <div className="col-10 pl-0">
                                            <label className="image-upload-lbl collection-dropdown">COLLECTION *</label>
                                        </div>

                                        <div className="col-2 d-flex justify-content-end collection-dropdown">
                                            {/* onClick={() => fetchCollection()} */}
                                            <div className="rounded-circle-bg d-flex align-items-center justify-content-center">
                                                {/* <img
                                                    className="imgw-30 cursor-pointer"
                                                    src='/img/Grouprefresh.png'
                                                /> */}
                                                <i className="fa fa-undo cursor-pointer"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex collection-dropdown">
                                        <select
                                            onChange={(event) =>
                                                setCollectionSelected(event.target.value)
                                            }
                                        >
                                            <option value="">Select Collection</option>
                                            <option value="Trest1">Test 1</option>
                                            <option value="Trest2">Test 2</option>
                                            <option value="Trest3">Test 3</option>

                                        </select>
                                    </div>
                                    {/* <div style={{ color: "red" }}>{selectCollectionErr}</div> */}

                                </div>
                            </div>

                            <div className="modal-footer justify-content-center">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">Start Import</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    islogin: state.auth.isLogin,
    auth: state.auth.auth,
});

export default connect(mapStateToProps)(ImportCollection)
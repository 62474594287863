import { useDispatch } from 'react-redux';
import { getNetworks } from '../store/networks/action';
import NetworkServices from '../api/NetworkService';
import { useCookies } from "react-cookie";

export default function useAdmin() {
    const dispatch = useDispatch();
    return {
        getNetworkList: async () => {
            var responseData = await NetworkServices.NetworkList();
            if (responseData.status === 200) {
                dispatch(getNetworks(responseData.data.data.networks))
                return responseData.data.data;
            }
        }
    }
}

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, Input, Checkbox, notification } from "antd";
import { useNftApi } from "../../hooks/api/nftApiHook";
import ReportForm from "./ReportForm";

const ReportModal = (props) => {
  const {} = useNftApi();
  const { isOpen, hideModal, appLoaderStatus, getNftDetails, nftDetails } =
    props;

  const handleCancel = () => {
    hideModal && hideModal();
  };
  const successHandler = () => {
    handleCancel();
    getNftDetails();
  };

  useEffect(() => {
    // console.log('nftDetails=>', nftDetails);
  }, [])

  return (
    <>
      <Modal
        className="nft_quick_report_wrp "
        title="Report"
        open={isOpen}
        visible={isOpen}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
        destroyOnClose={true}
      >
        <div className="nft_quick_report_inner p-4">
          <ReportForm
            otherFormData={nftDetails ? { ...nftDetails } : {}}
            successHandler={successHandler}
            type='nft'
          />
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    islogin: state.auth.isLogin,
    appLoaderStatus: state.comman.appLoaderStatus,
  };
};

export default connect(mapStateToProps)(ReportModal);

import axios from "./config";

class BidServices {

  async MakeBid(data) {
    const reponse = await axios
      .post(`/bids`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }

  async GetBidWithPagination(data) {
    var url = '/bids';
    const queryString = Object.keys(data).map(key => `${key}=${data[key]}`).join('&')
    if ( queryString ) {
      url += `?${queryString}`
    }
    const reponse = await axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }

  async CancelBid(bid_id) {
    const reponse = await axios
      .post(`/bids/${bid_id}/cancel`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }

  async AcceptBid(bid_id) {
    const reponse = await axios
      .post(`/bids/${bid_id}/accept`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }

  /*async GetWinnerOfBid(data) {
    const reponse = await axios
      .post(`/bids/get_latest_bid/`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }*/
}

export default new BidServices();

import { actionTypes } from "../../store/collections/action";

import { useApiCall } from "./appApiCallHook";

export function useCollectionApi() {
  const callApi = useApiCall();

  const getCollectionBasicDetails = (data, onSuccess, onError) => {
    callApi(actionTypes.BASIC_DETAILS, data, onSuccess, onError);
  };

  const reportCollectionItem = (data, onSuccess, onError) => {
    callApi(actionTypes.REPORT_ITEM, data, onSuccess, onError)
  }

  return {
    getCollectionBasicDetails,
    reportCollectionItem
  };
}

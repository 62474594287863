export const actionTypes = {
    GET_CRATORS: 'GET_CRATORS',
    GET_PROFILE_USER_SUCCESS: 'GET_PROFILE_USER_SUCCESS',   
    LOGOUT: 'LOGOUT',   
    UPDATE_PROFILE:'UPDATE_PROFILE'
};

export function getCrators(payload) {
   return { type: actionTypes.GET_CRATORS, payload };
}

export function profileSuccess(payload) {
   return { type: actionTypes.GET_NFT_SUCCESS, payload };
}

export function logout() {
   return { type: actionTypes.LOGOUT };
}

export function updateUserProfile(profile) {
   return { type: actionTypes.UPDATE_PROFILE ,profile};
}
import React,{useEffect,useState} from 'react';
import {connect} from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom';
import LoginPopup from '../Header/LoginPopup';
import useContractIntractionfrom from '../../web3Setup/useContractIntraction'
import { cond } from 'lodash';

function CreateCollectionsPage({islogin}) {
    const [active,setActive]  = useState(false);
    // const {isConnected} = useContractIntractionfrom()
    const [category,setCategory] = useState([])
    const [collectionNft , setCollectionNft] = useState('')
    const [selectCollectionErr,setCollectionError] = useState('')
    const history = useHistory()

    const isActive=async()=>{
        // setActive(await isConnected())
    }
    
    useEffect(()=>{
        isActive()
    },[])
    
    return (
        <>
            <div className="games__wrap">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-1 mx-0 px-0">
                        </div>
                        <div className="col-2 row align-items-end flex-column text-left">

                        </div>
                            <div className="col-9 p-0 mb-5">
                            {!islogin ? 
                            <div className="pt-lg-5">
                                <p className="connect-wallet-message">*Please connect your wallet to continue.</p>
                                <button data-toggle="modal" data-target="#exampleModalCenter" className="connect-wallet-btn">Connect Your Wallet</button>
                            </div>:
                                <>
                                    <h3 className="oracleTextClass">CREATE</h3>
                                    <div className="flex-column w-100 create-landing-page">
                                        <h3 className="p-0 m-0">SELECT ONE:</h3>
                                        <div className="pl-5">
                                            <div className="flex-column create-item">
                                                <h3>CREATE ITEM</h3>
                                                <NavLink to={"/create-nft/"} className="">NEW Item</NavLink>
                                            </div>
                                            <div className="flex-column create-collection">
                                                <h3>COLLECTION</h3>
                                                <p>This is the collection where your item will appear.</p>
                                                <div className="d-flex justify-content-start align-items-start gap-10">
                                                    <a onClick={(()=> history.push('/create-collections') )} className="">NEW Collection</a>
                                                </div>
                                                <div style={{color:'red'}}>{selectCollectionErr}</div>
                                            </div>
                                        </div>
                                    </div>
                                </>    
                            }
                            </div>
                        
                    </div>
                </div>
                {/* {!islogin ? <LoginPopup />:''} */}
            </div >
        </>
    );

}
const mapStateToProps = state => ({
    islogin:state.auth.isLogin 
})

export default connect(mapStateToProps)(CreateCollectionsPage);

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DateTimePicker from "react-datetime-picker";
import { CloseOutlined, SmileOutlined } from "@ant-design/icons";
import { notification } from "antd";
import useNFT from "../../hooks/useNFT";
import { MAX_BUY_QUANTITY, TermsURL } from "../../utils/app.constant";
import { calculateDutchAuctionPrice } from '../../utils/helper'
import binancePriceImage from "../../image/networkPrice/binance.jpg";
import maticPriceImage from "../../image/networkPrice/matic.jpg";
import ethereumPriceImage from "../../image/networkPrice/ethereum.jpg";
import { getPercentageAmount } from "../../utils/helper";
import { useUserApi } from "../../hooks/api/userApiHook";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import ItemPrice from '../ItemPrice/purchaseItemPrice'
// import { CONTRACT_ADDRESS_SPECIAL } from '../../../src/utils/app.constant'

function BuyNowNft({
  loading,
  priceListingBuyNFT,
  network,
  expiration,
  datetime,
  setDateTime,
  saveOffer,
  setOfferPrice,
  setExpiration,
  nft,
  NftBuy,
  priceLogo,
  getEstimatedAmount,
  appLoaderStatus,
  handleCancel,
}) {
  const { account, library } = useWeb3React();
  const { buyCryptoOnboarding } = useUserApi();
  const [qty, setQty] = useState(1);
  const [empty, setEmpty] = useState("");
  const [maxQuantityToBuy, setMaxQuantityToBuy] = useState(() => {
    return priceListingBuyNFT &&
      priceListingBuyNFT.supply_to_sale > MAX_BUY_QUANTITY
      ? MAX_BUY_QUANTITY
      : priceListingBuyNFT.supply_to_sale;
  });
  const { buyListing } = useNFT();
  const [validationObject, setValidationObject] = useState({
    maxQuantity: priceListingBuyNFT?.supply_to_sale || 0,
    ischecked: false,
  });
  // const [priceUnit, setPriceUnit] = useState('BNB')
  // const [isSpecialContract, setIsSpecialContract] = useState(() => nft.contract_address.toLowerCase() === CONTRACT_ADDRESS_SPECIAL.toLowerCase() )

  const [summary, setSummary] = useState({
    totalAmountInUsd: 0,
    totalAmount: 0,
    totalGasFee: 0,
    totalGasFeeInUsd: 0,
  });

  const checkboxCheckHanlder = (event) => {
    let temp = { ...validationObject };
    temp["ischecked"] = event;
    setValidationObject(temp);
  };

  const validationCheck = async () => {
    if (qty == 0) {
      notification.open({
        message: "Error",
        description: `${"Quantity cannot be zero!"} `,
        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
      });
      return;
    } else if (qty > maxQuantityToBuy) {
      notification.open({
        message: "Error",
        description: `Quantity should not be greater than ${maxQuantityToBuy}`,
        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
      });
      return;
    } else if (!validationObject.ischecked) {
      notification.open({
        message: "Error",
        description: `Please accept the Terms of Service!`,
        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
      });
      return;
    } else {
      var nftPrice = priceListingBuyNFT.start_price;
      if ( priceListingBuyNFT.method === 'sell_with_declining_price' ) {
        nftPrice = calculateDutchAuctionPrice(priceListingBuyNFT.start_date, priceListingBuyNFT.end_date, priceListingBuyNFT.start_price, priceListingBuyNFT.end_price)
      }

      const price = qty * nftPrice;
      const totalPrice = price; // + price * (priceListingBuyNFT?.service_fee / 100);
      let params = {
        nftId: nft._id,
        quantity_param: +qty,
        amount_param: totalPrice.toFixed(8),
        priceId_param: priceListingBuyNFT,
      };
      NftBuy(params);
    }
  };

  const priceUSD =
    (priceListingBuyNFT?.start_price * +network?.exchange_rate_usd).toFixed(
      2
    ) || 0;

  useEffect(() => {
    if (nft) {
      buySummaryHandler(qty ? qty : 1);
    }
  }, [qty]);

  const buySummaryHandler = async (selectedQuantity) => {
    let initialAmount = selectedQuantity * priceListingBuyNFT?.start_price;

    //disable extra fees
    // let totalAmount = getPercentageAmount(
    //   initialAmount,
    //   priceListingBuyNFT?.service_fee,
    //   true
    // );
    let totalAmount = initialAmount;
    let totalAmountInUsd = totalAmount * network?.exchange_rate_usd;
    let totalGasFee = summary.totalGasFee;
    let totalGasFeeInUsd = summary.totalGasFeeInUsd;

    try {
      let response = await getEstimatedAmount({
        amount: totalAmount.toFixed(4),
        qty: selectedQuantity,
        priceId_param: priceListingBuyNFT,
        owner_address: priceListingBuyNFT.wallet_address
      });
      if (response.success) {
        // console.log("network.propose_gas_price", network.propose_gas_price, finalGas);
        const finalGas =
          (response.estimatedGasFee * +network.propose_gas_price * 10) ^ 9; // output is in gwei converted to wei
        totalGasFee = Number(
          Web3.utils.fromWei(String(Math.round(finalGas)), "ether")
        );
        totalGasFeeInUsd = totalGasFee * +network?.exchange_rate_usd;
        
      }
      setSummary({
        totalAmountInUsd,
        totalAmount,
        totalGasFee,
        totalGasFeeInUsd,
      });
    } catch (error) {}
  };

  const buyCryptoHandler = async () => {
    try {
      if (summary.totalAmountInUsd <= 0) {
        notification.open({
          message: "Error",
          description: "Amount must be greater than zero",
          icon: <SmileOutlined style={{ color: "#108ee9" }} />,
        });
        return;
      }

      if (!validationObject.ischecked) {
        notification.open({
          message: "Error",
          description: `Please accept the Terms of Service!`,
          icon: <SmileOutlined style={{ color: "#108ee9" }} />,
        });
        return;
      }

      const web3 = new Web3(library.provider);
      let currentChainId = await web3.eth.getChainId();
      let totalAmountToBuy =
        summary.totalAmountInUsd + summary.totalGasFeeInUsd;
      let values = {
        amount: totalAmountToBuy.toFixed(2),
        chainId: currentChainId,
      };
      buyCryptoOnboarding(
        values,
        (message, resp) => {
          if (resp.url) {
            window.open(
              resp.url,
              "targetWindow",
              "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=450,height=350"
            );
          }
          handleCancel && handleCancel();
        },
        (message, error) => {
          notification.open({
            message: "Error",
            description: message,
            icon: <CloseOutlined style={{ color: "#f4364c" }} />,
          });
        }
      );
    } catch (error) {
      notification.open({
        message: "Error",
        description: error?.message || "Unable to proceed",
        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
      });
    }
  };

  return (
    <>
      <div className="connect-wallet-box-main make-offer-main">
        <h2 className="main-heading">Complete checkout</h2>
        <div className="wallet-infobox px-4">
          <div className="buynft-poprow">
            <h3 className="m-0">Item</h3>
            <h5 className="text-left">
              {nft ? nft.collection_id?.collection_name : ""}
            </h5>
          </div>
          <div className="buynft-img">
            <img
              className="nft-detail-img w-30"
              src={
                nft && nft.item_resized_image
                  ? nft.item_resized_image : nft.item_image
              }
            />
            {/* : "https://via.placeholder.com/80x121.png?text=Use" */}
          </div>
          <div className="buynft-description">
            <p className="mt-4">{nft ? nft.description : ""}</p>
          </div>
          {
                // isSpecialContract && nft.network === 'binance' ?  
                // <div className="buynft-poprow align-items-center">
                //   <select name="price_unit" className="form-control" onChange={((event) => setPriceUnit(event.target.value))}>
                //     <option value="BNB">BNB</option>
                //     <option value="BUSD">BUSD</option>
                //   </select>
                // </div> : ''
          }
          {
            maxQuantityToBuy != 1 ?
            <>
              <div className="buynft-poprow align-items-center">
                <h3 className="m-0">Quantity</h3>
                <b>
                  <input
                    type="number"
                    max={maxQuantityToBuy}
                    min={1}
                    onChange={(event) => setQty(+event.target.value)}
                    className="form-control"
                    value={qty}
                    onWheel={(e) => e.target.blur()}
                  ></input>
                </b>
              </div>
              <div className="buynft-poprow">
                <h3 className="m-0">Sub Total</h3>
                <b>
                  {
                    priceListingBuyNFT.price_unit === 'busd' ? 
                      `${parseFloat(parseFloat(priceListingBuyNFT?.start_price * qty).toFixed(4))} BUSD` :
                      <>
                        <img src={priceLogo} />{" "}
                        {
                          nft ? 
                          parseFloat(parseFloat(priceListingBuyNFT?.start_price * qty).toFixed(8)) + ` (${"$" + (priceUSD * qty).toFixed(2)} USD)`
                          : ""
                        }{" "}
                      </>
                  }
                </b>
              </div>
            </> : ''
          }
          <div className="buynft-poprow">
            <h3 className="m-0">Service Fees</h3>
            <b>{nft ? priceListingBuyNFT?.service_fee + "%" : ""} </b>
          </div>
          <div className="buynft-poprow">
            <h3 className="m-0">Gas Fee</h3>
            <b>
              {" "}
              {
                priceListingBuyNFT.price_unit === 'busd' ? 
                  <>{summary.totalGasFee ? summary.totalGasFee.toFixed(4) : 0} BUSD </>:
                  <>
                    <img src={priceLogo} />
                    {summary.totalGasFee ? summary.totalGasFee.toFixed(8) : 0} (${" "}
                    {summary.totalGasFeeInUsd ? summary.totalGasFeeInUsd.toFixed(2) : 0}
                    USD){" "}
                  </>
              }
            </b>
          </div>
          <div className="buynft-poprow">
            <h3 className="m-0">Total</h3>
            <b>
              {
                priceListingBuyNFT.price_unit === 'busd' ? '' : <><img src={priceLogo} />{" "}</>
              }
              {
                priceListingBuyNFT &&
                <ItemPrice 
                  priceList={priceListingBuyNFT} 
                  render={(price, priceUSD) => (
                      priceListingBuyNFT.price_unit === 'busd' ? 
                      <>{parseFloat((price * qty + summary.totalGasFee).toFixed(4))} BUSD</> :
                      <>
                        {parseFloat((price * qty + summary.totalGasFee).toFixed(8))} (${(priceUSD*qty + summary.totalGasFeeInUsd).toFixed(2)} USD)
                      </>
                  )} 
                  network={network} />
              }
              {/* {nft ?
                `${(summary.totalAmount + summary.totalGasFee).toFixed(8)} ($${(summary.totalAmountInUsd + summary.totalGasFeeInUsd).toFixed(2)}USD)`
              :null} */}
              {/*nft
                ? (
                    qty * priceListingBuyNFT?.start_price +
                    (qty *
                      priceListingBuyNFT?.start_price *
                      priceListingBuyNFT?.royalty) /
                      100
                  ).toFixed(4) +
                  ` (${
                    "$" +
                    (
                      qty *
                        priceListingBuyNFT?.start_price *
                        network?.exchange_rate_usd +
                      ((qty *
                        priceListingBuyNFT?.start_price *
                        priceListingBuyNFT?.royalty) /
                        100) *
                        network?.exchange_rate_usd
                    ).toFixed(2)
                  } USD)`
                : ""*/}{" "}
            </b>
          </div>
          <div className="buynft-terms">
            <input
              id="ux_term_condition"
              type="checkbox"
              onChange={(event) =>
                checkboxCheckHanlder(event.currentTarget.checked)
              }
            />
            <p>
              <label htmlFor="ux_term_condition">By checking this box, I agree to EGC Infinite Canvas's{" "}</label>
              <a href={TermsURL} target="_blank">Terms of Service.</a>
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          
          <div className="p-2 bd-highlight">
            <div
              className="confirm-checkout-btn"
              onClick={() => (!loading ? validationCheck() : "")}
            >
              {!loading ? "Buy Now" : "Please wait..."}
            </div>
          </div>

          {/* <div className="p-2 bd-highlight">
            <div
              className="confirm-checkout-btn"
              onClick={() => buyCryptoHandler()}
            >
              {!appLoaderStatus ? "Add Fund" : "Please wait..."}
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    appLoaderStatus: state.comman.appLoaderStatus,
  };
};

export default connect(mapStateToProps)(BuyNowNft);

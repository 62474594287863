import { values } from 'lodash'
import React,{useEffect,useState} from 'react'

const OffersList = ({offers}) =>{
    return (
        <table>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Item Image</th>
                    <th>Item Name</th>
                    <th>Price</th>
                    <th>Offer Start</th>
                    <th>Offer End</th>                    
                </tr>
            </thead>
            <tbody>
                {offers.map((value,key)=>(
                    <tr>
                        <td>{key+1}</td>
                        <td>
                            <img style={{height: '43px'}} src={value?.get('nft_id')?.get('item_image')}/>
                        </td>
                        <td>{value?.get('nft_id')?.get('item_name')}</td>
                        <td>{value.get('offertprice')}</td>
                        <td>{value.get('offer_start_expiration')}</td>
                        <td>{value.get('offer_end_expiration')}</td>
                    </tr>
                ))}    
            </tbody>
        </table>
    )
}

export default OffersList
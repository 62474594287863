import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table, notification } from "antd";
import Pagination from "@mui/material/Pagination";
import { useUserApi } from "../../hooks/api/userApiHook";
import { CloseOutlined, SmileOutlined } from "@ant-design/icons";
import ConfirmAlert from "../Common/ConfirmAlert";

/*
const Follow = ({followerList,follow,isFollow}) =>{
    console.log('followerList',followerList)
    const dataSource = [
        {
          key: '1',
          name: 'Mike',
          age: 32,
          address: '10 Downing Street',
        },
        {
          key: '2',
          name: 'John',
          age: 42,
          address: '10 Downing Street',
        },
      ];
      
      const columns = [
        {
          title: 'Follow By',
          render:(value)=>{
            return <span>{value?.from_user_id?.username}</span>
          }
        },
        {
          title: 'Follow To',
          render:(value)=>{
            return <span>{value?.to_user_id?.username}</span>
          }
        },
        {
          title: 'Action',
          render:(value)=>{
            return <button className="unfollow_btn" onClick={()=>follow(value.to_user_id)}>Unfollow</button>
          }
        },
      ];
      
    return (
        <Table dataSource={followerList} columns={columns} />
    )
}

export default Follow;*/

const Follow = (props) => {
  const { fetchFollowData, followUnfollowUser } = useUserApi();
  const { islogin, auth, appLoaderStatus } = props;
  const [tableData, setTableData] = useState([]);
  const [perPage, setPerPage] = useState(props.perPage ? props.perPage : 10);

  const [activePage, setActivePage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (islogin) {
      loadInitialData();
    }
    columnHandler(islogin);
  }, [islogin]);

  const columnHandler = (showPrivateContent = false) => {
    let privateCol = [
      {
        title: "Action",
        render: (value) => {
          return (
            <button
              className="unfollow_btn"
              onClick={() => {
                followUnfollowHandler(
                  value.to_user_id || value.collection_id,
                  value.type
                );
              }}
            >
              Unfollow
            </button>
          );
        },
      },
    ];

    let colArray = [
      {
        title: "Name",
        render: (value) => {
          return (
            <span>
              {value?.to_user_id?.username ||
                value?.collection_id?.collection_name}
            </span>
          );
        },
      },
      {
        title: "Type",
        render: (value) => {
          return <span>{value?.type ? value.type.toUpperCase() : ""}</span>;
        },
      },
    ];

    if (showPrivateContent) {
      setColumns([...colArray, ...privateCol]);
    } else {
      setColumns(colArray);
    }
  };

  const loadInitialData = (page = 1) => {
    let queryParams = {
      page,
      perPage,
      withFollowedToDetails: true,
      withCollection: true,
    };

    fetchFollowData(
      { ...queryParams },
      (message, resp) => {
        setTableData(resp?.data?.length ? resp.data : []);
        setPageCount(resp?.totalPage ? resp?.totalPage : 0);
      },
      (message, error) => {}
    );
  };

  const followUnfollowHandler = (followToId, type) => {
    ConfirmAlert({
      title: "Are you sure?",
      message: "Do you wish to unfollow ?",
      onConfirm: () => {
        followUnfollowUser(
          { followToId, type },
          (message, resp) => {
            const countData = tableData?.length ? tableData.length - 1 : 0;
            if (activePage > 1 && countData <= 0) {
              let newPage = activePage - 1;
              setActivePage(newPage);
              loadInitialData(newPage);
            } else {
              loadInitialData(activePage);
            }

            notification.open({
              message: "Success",
              description: message,
              icon: <SmileOutlined style={{ color: "#108ee9" }} />,
            });
          },
          (message, error) => {
            notification.open({
              message: "Error",
              description: message,
              icon: <CloseOutlined style={{ color: "#f4364c" }} />,
            });
          }
        );
      },
    });
  };

  const changePageHandler = (event, page) => {
    if (activePage === page) {
      return;
    }
    setActivePage(page);
    loadInitialData(page);
  };

  if (!islogin) {
    return null;
  }

  return (
    <>
      <Table
      className="table table-bordered"
        dataSource={tableData}
        columns={columns}
        pagination={false}
        rowKey="_id"
      />

      <Pagination
        page={activePage}
        onChange={changePageHandler}
        count={pageCount}
        variant="outlined"
        shape="rounded"
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  islogin: state.auth.isLogin,
  auth: state.auth.auth,
  appLoaderStatus: state.comman.appLoaderStatus,
});

export default connect(mapStateToProps)(Follow);

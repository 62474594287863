import React from "react";
import { Modal, Input, Checkbox, notification } from "antd";
import ShareButtons from "./ShareButtons";

const ShareContentModal = (props) => {
  const { title, handleCancel, visible, urlToShare } = props;

  const closeHandler = () => {
    handleCancel && handleCancel();
  };

  return (
    <>
      <Modal
        className="nft_quick_share_wrp"
        title={title ? title : "Share"}
        visible={visible}
        onCancel={closeHandler}
        footer={null}
      >
        <ShareButtons urlToShare={urlToShare} />
      </Modal>
    </>
  );
};

export default ShareContentModal;

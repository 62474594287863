/**
    ACTION  is use to store the data in redux
    SAGA_ACTIONS is use to call the api
 */
export const USER_ACTION_TYPES = {
  CARD: {
    GET_CARDS: "ACTIONS/USER/CARD/GET_CARDS",
  },
  FOLLOW: {},
};

export const USER_SAGA_ACTION_TYPES = {
  GLOBAL_SEARCH: "SAGA_ACTIONS/USER/GLOBAL_SEARCH",
  CARD: {
    GET_CARDS: "SAGA_ACTIONS/USER/CARD/GET_CARDS",
    ADD_CARD: "SAGA_ACTIONS/USER/CARD/ADD_CARD",
    MARK_CARD_AS_DEFAULT: "SAGA_ACTIONS/USER/CARD/MARK_CARD_AS_DEFAULT",
    DELETE_CARD: "SAGA_ACTIONS/USER/CARD/DELETE_CARD",
  },
  FOLLOW: {
    FOLLOW_UNFOLLOW: "SAGA_ACTIONS/USER/FOLLOW/FOLLOW_UNFOLLOW",
    FETCH_FOLLOW_DATA: "SAGA_ACTIONS/USER/FOLLOW/FETCH_FOLLOW_DATA",
  },
  NFT: {
    LIKE_UNLIKE_NFT: "SAGA_ACTIONS/USER/NFT/LIKE_UNLIKE_NFT",
    FETCH_LIKED_NFT: "SAGA_ACTIONS/USER/NFT/FETCH_LIKED_NFT",
  },
  VERIFY_SUBSCRIBED_EMAIL:"SAGA_ACTIONS/USER/VERIFY_SUBSCRIBED_EMAIL",
  BUY_CRYPTO_ONBOARDING:"SAGA_ACTIONS/USER/BUY_CRYPTO_ONBOARDING",
  WYRE_ORDER_SAVE:"SAGA_ACTIONS/USER/WYRE_ORDER_SAVE",
  GET_NONCE: "SAGA_ACTIONS/USER/GET_NONCE"
};

import axios from "./config";

class CollectionsServices {  
  async Create(data) {
    const reponse = await axios.post(`/collections`,data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }

  async CollectionOfUser() {
    const reponse = await axios.get(`/collections/get_collection_by_id`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }
  async CollectionWithPagination(data) {
    const reponse = await axios.post(`/collections/get_collections`,data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }
  async CollectionFilter(data) {
    const reponse = await axios.post(`/collections/collection_filter`,data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }

  async SingleCollectionFilter(data) {
    const reponse = await axios.post(`/collections/single_collection_filter`,data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }

  async CollectionNftsData(data) {
    const reponse = await axios.post(`/collections/get_nfts_owner_and_count`,data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }
  async CollectionUpdate(data,id) {
    const reponse = await axios.put(`/collections/${id}`,data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }
  async NetworkList() {
    const response = await axios.get(`/networks`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error.response;
    });
  return response;
  }
}
export default new CollectionsServices();

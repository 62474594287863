import { useDispatch } from "react-redux";
import { useAppLoader } from "../actions/appLoaderHook";

export function useApiCall() {
  const dispatch = useDispatch();
  const { showLoader, hideLoader } = useAppLoader();

  const callApi = (
    sagaAction,
    dataOrParams = {},
    callbackSuccess,
    callbackError
  ) => {
    showLoader();
  
    dispatch({
      type: sagaAction,
      payload: dataOrParams,
      callbackSuccess: (data) => {
        hideLoader();
        let message =
          data && data.message
            ? data.message
            : "Request processed successfully";
        let resp = data && data.data ? data.data : null;

        callbackSuccess && callbackSuccess(message, resp);
      },
      callbackError: (error) => {
      
        hideLoader();
        let resp = error?.messages?.error || null;
        let message = "";
        if (Array.isArray(resp) && error?.messages?.status === 422) {
          resp.forEach((item) => {
            message += `${item?.msg}\n`;
          });
        } else {
          message =
            error?.messages?.message || error?.message ||
            "Unable to process request, please try again";
        }
        callbackError && callbackError(message, resp ? resp : error);
      },
    });
  };
  return callApi;
}

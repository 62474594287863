import { actionTypes } from './action';

export const initState = {
    crators:[],
    isloading:true
};

function reducer(state = initState, action) {
    switch (action.type) {
        case actionTypes.GET_CRATORS:
            return {
                ...state,
                ...{ crators: action.payload,isloading:false},
            };
        case actionTypes.LOGOUT:
            localStorage.removeItem('token')
            return {
                ...state,
                ...{ auth: {},isLogin:false },
            };
        case actionTypes.UPDATE_PROFILE:
            return {
                ...state,
                ...{ auth: action.profile },
            };    

        default:
            return state;
    }
}

export default reducer;

import React,{useEffect} from "react";
import { useWeb3React } from "@web3-react/core";  
import { useDispatch } from 'react-redux';
import AuthService from "../api/AuthService";
import Web3 from 'web3' 
import {connectors} from './connectors'
import useAuth from "../hooks/useAuth";
import {login, signatureStatusUpdate} from '../store/auth/action'

export default function useAdmin() {
    const { library,chainId,account, activate,deactivate, active } = useWeb3React();
    useEffect(() => {
        const provider = window.localStorage.getItem("provider");
        if (provider) activate(connectors[provider]);

    }, []);

    const dispatch = useDispatch();

    return {
        signMessage: async () => {
            if (!library) return;
            try {             
              let params = {wallet_address:account}
              let response = await AuthService.UserLogin(params)
              if(response.status === 200){
                dispatch(login())
                return response.data?.data?.nonce 
              }
              
            } catch (error) {
              window.localStorage.clear();   
              await deactivate();
              console.log('error',error)
            }
        },
        isConnected:()=>{
            return account ? true:false
        },
        attemptSignature:async(message)=>{
          // return
          try{
            if (!library) return;
            const signature = await library.provider.request({
                  method: "personal_sign",
                  params: [message, account]
                });
            return signature
          }catch(error){
            dispatch(signatureStatusUpdate(false))
            
          }
        },
        verifyMessage:async () => {
          if (!library) return;
          try {
            let signature = window.localStorage.getItem('signature') 
            const verify = await library.provider.request({
              method: "personal_ecRecover",
              params: ['Welcome To LunaSky', signature]
            });
            return (verify === account.toLowerCase());
          } catch (error) {
            console.log('error',error);
          }
        }
    }
}
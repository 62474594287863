import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Spin, notification } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { useUserApi } from "../hooks/api/userApiHook";

const PaymentResponse = (props) => {
  const { appLoaderStatus } = props;
  const history = useHistory();
  const { wyreOrderSave } = useUserApi();
  const search = useLocation().search;
  const [response, setResponse] = useState({});
  const orderId =
    new URLSearchParams(search).get("orderId") ||
    new URLSearchParams(search).get("id");
  const wyreTxnFailed = new URLSearchParams(search).get("wyreTxn");

  useEffect(() => {
    if (orderId) {
      orderSaveHandler();

      return;
    }
    if (wyreTxnFailed && wyreTxnFailed === "failed") {
      setResponse({
        success: false,
        message: "Unable to process the payment.Please try again later.",
      });

      closeWindowHandler();
      return;
    }

    if (!wyreTxnFailed && !orderId) {
      setResponse({
        success: false,
        message: "Transaction cancelled",
      });
      closeWindowHandler();
      return;
    }
  }, [orderId, wyreTxnFailed]);

  const orderSaveHandler = () => {
    wyreOrderSave(
      { orderId },
      (message, resp) => {
        setResponse({
          success: true,
          message:
            "Thank you for the payment. You can continue to buy the NFT once the fund is deposited in your wallet.",
        });
        closeWindowHandler();
      },
      (message, error) => {}
    );
  };

  const closeWindowHandler = () => {
    setTimeout(() => {
      history.push("/");
    }, 5000);
  };

  return (
    <div className="games__wrap">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-1 mx-0 px-0 d-none"></div>
          <div className="col-2 row align-items-end flex-column text-left d-none"></div>
          <div className="col-9 p-0 res ">
            <div
              style={{ height: "400px" }}
              className=" d-flex align-items-center justify-content-center"
            >
              {appLoaderStatus ? <Spin /> : null}
              {response.hasOwnProperty("success") ? (
                <>
                  {response.success ? (
                    <div className="popup">
                      <img src="/img/404-tick.png" alt="tickmark" />
                      <h2>{response?.message}</h2>
                    </div>
                  ) : (
                    <div className="popup">
                      <img src="/img/cross.jpg" alt="tickmark" />
                      <h2>{response?.message}</h2>
                    </div>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appLoaderStatus: state.comman.appLoaderStatus,
  };
};

export default connect(mapStateToProps)(PaymentResponse);

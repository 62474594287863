import React from 'react'
import Skeleton from 'react-loading-skeleton'

const NFTloader = () =>{
    return (
        <Skeleton count={2} />
        // <iframe src="https://embed.lottiefiles.com/animation/100595"></iframe>
        // <iframe src="https://embed.lottiefiles.com/animation/100175"></iframe>
        
    )
}

export default NFTloader;
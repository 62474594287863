import { actionTypes } from './action';

export const initState = {
    nftDetails:{},
    isloading:true,
    nfts:[]
};

function reducer(state = initState, action) {
    switch (action.type) {
        case actionTypes.GET_NFT_SUCCESS:
            return {
                ...state,
                ...{ nftDetails: action.payload.nftDetails,isloading:false},
            };
        case actionTypes.ALL_NFTS:
            return {
                ...state,
                ...{ nfts: action.payload,isloading:false },
            };    
        case actionTypes.LOADING:
            return {
                ...state,
                ...{ nfts: action.payload,isLogin:false },
            };
        case actionTypes.UPDATE_PROFILE:
            return {
                ...state,
                ...{ auth: action.profile },
            };    

        default:
            return state;
    }
}

export default reducer;

import axios from "./config";

class ActivityServices {
  async FetchActivities({ urlNetwork, contract_address, token_id }, query) {
    const queryString = Object.keys(query).map(e => `${e}=${query[e]}`)
    const reponse = await axios.get(`/activities/${urlNetwork}/${contract_address}/${token_id}?${queryString}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return reponse;
  }
  
}

export default new ActivityServices();

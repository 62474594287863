import React, { Component } from 'react';
import _ from 'lodash';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import CratorComponent from '../components/Crator/CratorComponent';

export default function DropPage({props}) {
    return (
        <div id="cratorPage" className="main_wrp games__wrap CratoePageComponent">
            <CratorComponent {...props} />
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <Scrollup />
        </div>
    );
}

import { actionTypes } from './action';

export const initState = {
    category:[],
    isloading:true,
    appLoaderStatus:false,
};

function reducer(state = initState, action) {
    switch (action.type) {
        case actionTypes.GET_CATEGORY:
            return {
                ...state,
                ...{ category: action.payload,isloading:false},
            };
        case actionTypes.LOGOUT:
            localStorage.removeItem('token')
            return {
                ...state,
                ...{ auth: {},isLogin:false },
            };
        case actionTypes.UPDATE_PROFILE:
            return {
                ...state,
                ...{ auth: action.profile },
            };    

            case actionTypes.APP_LOADER_STATUS:
                return {
                    ...state,
                    appLoaderStatus: action.payload,
                };   

        default:
            return state;
    }
}

export default reducer;

import React, { useState, useEffect, useRef , forwardRef, useImperativeHandle} from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import Fuse from "fuse.js";
import _ from 'lodash'
import useComman from '../../hooks/useComman';
import * as qs from "query-string";

const statusList = [
   // {label:'All', value:'all'},
    {label:'Buy Now', value:'buynow'},
    {label:'On Auction', value:'onauction'},
    {label:'New', value:'new'},
   // {label:'Has Offers', value:'offer'}
]

const SidebarFiltersAccordion  = forwardRef((props, ref) => {

    const {filterActionHandler, setCallNftApi} = props;
    const history = useHistory();
    const {search: urlQueryString, pathname} =  useLocation();
    const { categories } = useComman();
    const [categoryList, setCategoryList] = useState([])
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
   
  
    useEffect(async() => {
        const response = await categories()
        if (response.length > 0) {
            setCategoryList(response)
        }
        setCallNftApi &&  setCallNftApi(true);
    }, []);

    useEffect(() => {
        let parsed = qs.parse(urlQueryString, { arrayFormat: "comma" });
        if(categoryList && categoryList.length){
            initialDataSetFromUrl(parsed);
        }
    }, [categoryList])

    useImperativeHandle(ref, () => {
        return {
            selectedCategories,
            setCategoryList
        };
      });

    const initialDataSetFromUrl = (parsedData)=>{
      
        if( parsedData.status){
            if(Array.isArray(parsedData.status)){
                setSelectedStatus(parsedData.status);
            }else{
                setSelectedStatus(new Array(parsedData.status));
            }
        }
        if(parsedData.categories){
            if(Array.isArray(parsedData.categories)){
                let categoryArr = categoryList.filter(function(item) {
                    return parsedData.categories.includes(item.abbreviation)
                }).map((flteredCat)=>{
                    return {
                        label: flteredCat?.abbreviation, value: flteredCat?._id, name: flteredCat?.name
                    }
                });
                if(categoryArr?.length){
                    setSelectedCategories(categoryArr);
                }
            }else{
                let categoryArr  = []
                let foundCategory = categoryList.find((item)=> item.abbreviation === parsedData.categories );
                if(foundCategory?._id){
                    categoryArr.push({label: foundCategory?.abbreviation, value: foundCategory?._id, name: foundCategory?.name})
                    setSelectedCategories(categoryArr)
                }
            }
        }
    }

    const statusSelectHandler  =(value)=>{
        let tempSelectedStatus = [...selectedStatus];
        if (tempSelectedStatus.includes(value)) {
            tempSelectedStatus.splice(tempSelectedStatus.indexOf(value), 1);
        } else {
        tempSelectedStatus.push(value);
        }
        setSelectedStatus(tempSelectedStatus);
        filterActionHandler && filterActionHandler({status : tempSelectedStatus})
    }

    const selectCategoryHandler = ( {label, value, name})=>{
        let tempCat = [...selectedCategories];
        let index = tempCat.findIndex(obj => obj.value === value);
        if(index>-1){
            tempCat.splice(index, 1);
        }else{
            tempCat.push({
                label, value, name
            })
        }
        setSelectedCategories(tempCat); 
        filterActionHandler && filterActionHandler({categories : tempCat.map((item)=> item.label)});
    }
    

    return (
        <>
            {/* Filters accordion */}
            <div id="accordion" className="SidebarAccordionFilters">
                <div className="card">
                    <div className="card-header" id="headingStatus">
                        <h5 className="mb-0">
                            <button className="btn btn-link" data-toggle="collapse" data-target="#collapseStatus" aria-expanded="true" aria-controls="collapseStatus">
                                Status
                            </button>
                        </h5>
                    </div>
                    <div id="collapseStatus" className="collapse show" aria-labelledby="headingStatus" data-parent="#accordion">
                        <div className="card-body">
                            <div className="Panel--isContentPadded FeaturedFilter--items">
                           

                                {statusList.length ? 
                                    statusList.map((v, i)=>{
                                        return(
                                            <button  key={i} onClick={()=> statusSelectHandler(v.value)} 
                                            className={`UnstyledButtonreact__UnstyledButton-sc-ty1bh0-0 btgkrL FeaturedFilter--item ${selectedStatus.includes(v.value) ?  'FeaturedFilter--isSelected': ''}`} type="button" >{v.label}</button>
                                        )
                                    })
                                    :null
                                }

                                
                            </div>
                        </div>
                    </div>
                </div>
                 {/* <div className="card">
                    <div className="card-header" id="headingPrice">
                        <h5 className="mb-0">
                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapsePrice" aria-expanded="false" aria-controls="collapsePrice">
                                Price
                            </button>
                        </h5>
                    </div>
                    <div id="collapsePrice" className="collapse" aria-labelledby="headingPrice" data-parent="#accordion">
                        <div className="card-body">
                            <select name="headingPrice w-100">
                                <option value="USD">United States Dollar (USD)</option>
                                <option value="ETH">Ether (ETH)</option>
                            </select>
                            <div className="d-flex justify-content-between align-items-center w-100 my-2 gap-10">
                                <input type="number" placeholder="Min" />
                                <p>to</p>
                                <input type="number" placeholder="Max" />
                            </div>
                            <button type="button" className="filter-apply-btn">Apply</button>
                        </div>
                    </div>
                </div>
               <div className="card">
                    <div className="card-header" id="headingCollections">
                        <h5 className="mb-0">
                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseCollections" aria-expanded="false" aria-controls="collapseCollections">
                                Collections
                            </button>
                        </h5>
                    </div>
                    <div id="collapseCollections" className="collapse" aria-labelledby="headingCollections" data-parent="#accordion">
                        <div className="card-body">
                            <div className="input-group md-form form-sm form-1 pl-0">
                                <div className="input-group-prepend">
                                    <span className="input-group-text purple lighten-3" id="basic-text1"><i className="fas fa-search text-white"
                                        aria-hidden="true"></i></span>
                                </div>
                                <input className="form-control my-0 py-1" onChange={(event)=>filterCollection(event.target.value)} type="text" placeholder="Filter" aria-label="Search" />
                            </div>
                            <div className="collections-lists-items">
                                <div className="flex-column justify-content-center align-items-start my-4">
                                    {
                                        _.map(collectionLists, (collectionItem, index) => {
                                            const profile = collectionItem.featured_resized_image? collectionItem.featured_resized_image :collectionItem.featured_image;
                                            return (
                                                <div className="d-flex align-items-center mb-3" key={index}>
                                                    <img src={profile} />
                                                    <h2 className="collection-name m-0"><a href={'/collection/'+collectionItem.slug}>{collectionItem.collection_name}</a></h2>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="card">
                    <div className="card-header" id="headingCategories">
                        <h5 className="mb-0">
                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseCategories" aria-expanded="false" aria-controls="collapseCategories">
                                Categories
                            </button>
                        </h5>
                    </div>
                    <div id="collapseCategories" className="collapse" aria-labelledby="headingCategories" data-parent="#accordion">
                        <div className="card-body">
                            <div className="collections-lists-items categories-list-items">
                                <div className="flex-column justify-content-center align-items-start my-4">
                                <ul className="list-unstyled categoryFilter">

                                    {categoryList && categoryList.length ? 
                                        categoryList.map((v, i)=>{
                                            const catIcon = v?.icon || `https://via.placeholder.com/30x30.png?text=${v.name}`;
                                            let index = selectedCategories.findIndex(obj => obj.value === v._id);
                                            return (

                                                <li key={i} role="button" onClick={()=> selectCategoryHandler({label: v.abbreviation, value: v._id , name:v.name }) } className="nft-game-go-gtn mt-0 mb-2" >                                                
                                                    <a  onClick={(e)=> e.preventDefault()} className={`${index >-1 ? 'active' : ''}`} > <img src={catIcon} />{v.name}</a>
                                                </li>
                                            
                                            )
                                        })
                                        : null
                                    }

                                </ul>
                                  
                                    {/*
                                        _.map(categorie, (categoryItem, index) => {
                                            const catIcon = categoryItem.icon || `https://via.placeholder.com/30x30.png?text=${categoryItem.get('name')}`;
                                            return (
                                                <div className="d-flex align-items-center mb-3" key={index}>
                                                    <img src={catIcon} />
                                                    <h2 onClick={()=>GetNftBycateory(categoryItem._id)} className="collection-name m-0">{categoryItem.name}</h2>
                                                </div>
                                            )
                                        })
                                    */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="card">
                    <div className="card-header" id="headingOnSale">
                        <h5 className="mb-0">
                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOnSale" aria-expanded="false" aria-controls="collapseOnSale">
                                On Sale In
                            </button>
                        </h5>
                    </div>
                    <div id="collapseOnSale" className="collapse" aria-labelledby="headingOnSale" data-parent="#accordion">
                        <div className="card-body">
                            <div className="input-group md-form form-sm form-1 pl-0">
                                <div className="input-group-prepend">
                                    <span className="input-group-text purple lighten-3" id="basic-text1"><i className="fas fa-search text-white"
                                        aria-hidden="true"></i></span>
                                </div>
                                <input className="form-control my-0 py-1" type="text" placeholder="Filter" aria-label="Search" />
                            </div>
                            <div className="collections-lists-items">
                                <div className="flex-column justify-content-center align-items-start my-4">
                                    <div className="d-flex align-items-center mb-3">
                                        <h2 className="collection-name m-0">ETH</h2>
                                    </div>
                                    <div className="d-flex align-items-center mb-3">
                                        <h2 className="collection-name m-0">WETH</h2>
                                    </div>
                                    <div className="d-flex align-items-center mb-3">
                                        <h2 className="collection-name m-0">WETH</h2>
                                    </div>
                                    <div className="d-flex align-items-center mb-3">
                                        <h2 className="collection-name m-0">ADORs</h2>
                                    </div>
                                    <div className="d-flex align-items-center mb-3">
                                        <h2 className="collection-name m-0">DERT</h2>
                                    </div>
                                    <div className="d-flex align-items-center mb-3">
                                        <h2 className="collection-name m-0">NTR</h2>
                                    </div>
                                    <div className="d-flex align-items-center mb-3">
                                        <h2 className="collection-name m-0">VLRR</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>
            {/* End Filter accordion */}</>
    )
});

export default SidebarFiltersAccordion;
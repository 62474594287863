import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import previewIcon from '../../image/previewIcon.png';
import GrouppopUpplus from "../../image/nft-form/GrouppopUpplus.png";
import useComman from '../../hooks/useComman';
import { Formik, Form, Field, FieldArray } from 'formik';
import useMedia from '../../hooks/useMedia';
import { Modal, message, notification } from "antd";
import { SmileOutlined, CloseOutlined } from "@ant-design/icons";
import loader from '../../image/loader.gif';
import { Space, Radio } from "antd";
import { MysteryBoxSchema } from '../../utils/Validation';
import categoryIcon from '../../image/nft-form/Groupnft-form-icon-1.png';
import NftFormPopups from '../../themes/NftFormPopups';
import delete_icon from '../../image/delete-icon.png';
import { useMysteryBoxApi } from '../../hooks/api/mysteryboxApiHook';
import { chainImageHandler, switchNetwork } from "../../utils/helper";
import Select from "react-select";
import useNetworks from '../../hooks/useNetwork';
import useNFT from "../../hooks/useNFT";
import * as address from "../../abi/address.json";
import { useHistory, useParams } from "react-router-dom";
import { NFT_FILE_MAX_SIZE } from '../../../src/utils/app.constant'
import MysteryBoxContent from '../Modal/MysteryBoxContent'


function MysteryBox({ props, islogin, auth }) {
    const { network, contract_address: getContractAddress, token_id: getTokenId } = useParams();
    const [addImage, setAddImage] = useState(false)
    const [fileShowType, setFileShowType] = useState("image");
    const [uploadedFile, setUploadedFile] = useState();
    const [file, setFile] = useState(previewIcon);
    const [collection, setCollection] = useState([]);
    const { collectionsOfUser } = useComman();
    const [collectionSelected, setCollectionSelected] = useState(false);
    const [loading, setLoading] = useState(false)
    const [fileType, setFileTypeError] = useState("");
    const [fileExtension, setFileExtension] = useState("");
    const [radioValue, setRadioValue] = useState(1);
    const [contentCollection, setContentCollection] = useState(false)
    // const [nftList, setNftList] = useState([])
    const [nftSelected, setNftSelected] = useState(false)
    const [catError, setCateError] = useState("");
    const [mysteryBoxContentError, setMysteryBoxContentError] = useState("");
    
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedMysteryBoxContent, setSelectedMysteryBoxContent] = useState(null);
    const [nftAddList, setNftAddLisst] = useState([{ nft_name: "", quantity: '' }]);
    const [networkList, setNetworkList] = useState([])
    const [networkSelected, setNetworkSelected] = useState(null);
    const [showMysteryBoxModal, setShowMysteryBoxModal] = useState(false);
    // const [getContractAddress, setContractAddress] = useState(null);
    // const [getTokenId, setTokenId] = useState(null);
    const [nftDetails, setNftDetails] = useState(null)
    const [editMode, setEditMode] = useState(false);

    const { FileUpload } = useMedia();
    const { getNetworkList } = useNetworks();
    const { createMystery, updateMysteryBox } = useMysteryBoxApi();
    const history = useHistory();

    const { getNftDetails } = useNFT();

    /*useEffect(() => {
        // console.log('props effect', props?.match?.params);
        setContractAddress(props?.match?.params?.contract_address)
        setTokenId(props?.match?.params?.token_id)
    }, [props?.match?.params])*/

    useEffect(() => {
        getNFT();
        fetchCollection();
        fetchNetwork();
    }, [islogin]);

    useEffect(() => {
        updateLocalData();
    }, [nftDetails, collection])

    useEffect(() => {
        if (networkList.length > 0) {
            // let filterNetwork = networkList.filter((value) => value.network === nftDetails.network);
            // console.log('filter network', filterNetwork);
            setNetworkSelected(networkList[0])
        }
    }, [networkList])
    
    const updateLocalData = () => {
        // const isEmpty = Object.keys(nftDetails).length === 0;
        // if (!isEmpty && props?.match?.params !== undefined) {
        if ( nftDetails ) {
            // console.log('props 22', nftDetails);
            let filterNetwork = networkList.filter((value) => value.network === nftDetails.network);
            setNetworkSelected(() => filterNetwork.length && filterNetwork[0] || null)
            setFileShowType(nftDetails?.file_extension)
            setFile(nftDetails && nftDetails.item_resized_image? nftDetails.item_resized_image : nftDetails.item_image)
            setContentCollection(nftDetails?.mystery_contents?.content_collection_id)
            setCollectionSelected(nftDetails?.collection_id?._id)
            const categories = nftDetails && nftDetails.categories_id && nftDetails?.categories_id.map((cat) => ({ _id: cat['_id'], name: cat['name'] }));
            setSelectedCategories(categories)
            // setContractAddress(nftDetails?.contract_address)
            // setTokenId(nftDetails?.token_id)
            setUploadedFile(nftDetails.item_resized_image || nftDetails?.item_image)
            if ( nftDetails.mystery_contents.content_collection_id ) {
                const selectCollection = collection.find(e => e['_id'] === nftDetails?.mystery_contents?.content_collection_id)
                setSelectedMysteryBoxContent({
                    collection: selectCollection, 
                    issuedQuantity: +nftDetails?.mystery_contents?.content_issue_quantity
                });
            }
        }
    }

    const fetchCollection = async () => {
        var res = await collectionsOfUser();
        // const detailsEmpty = Object.keys(nftDetails).length === 0;
        // console.log('collection', res[0]._id, detailsEmpty);
        setCollection(res);
        if (res.length > 0 && nftDetails) {
            // setCollection(res[0]['_id'])
            setContentCollection(res[0]._id)
            setCollectionSelected(res[0]._id)
        }
    };

    const getNFT = async () => {
        if ( getContractAddress && getTokenId ) {
            // console.log('add', getContractAddress, network);
            var nft = await getNftDetails({ network, contract_address: getContractAddress, token_id: getTokenId });
            setNftDetails(nft.nft_items);
            setEditMode(true);
        }
    };

    const AddMysteryContent = (formDate) => {
        const selectCollection = collection.find(e => e['_id'] === formDate['collection_id'])
        setSelectedMysteryBoxContent({collection: selectCollection, issuedQuantity: +formDate.issue_quantity});
    }

    const fetchNetwork = async () => {
        const fetchNetwork = await getNetworkList()
        setNetworkList(fetchNetwork.networks);
        if (fetchNetwork.networks.length) {
            setNetworkSelected(fetchNetwork.networks[0])
        }
    };

    const selectFile = async (e) => {
        setLoading(true)
        const reader = new FileReader();
        if (e.target.files && e.target.files.length > 0) {
            if (NFT_FILE_MAX_SIZE < e.target.files[0].size) {
                setFileTypeError("You can upload 5MB size file!");
                return;
            }
            if (!e.target.files[0].type.match(
                /.(jpg|jpeg|png|gif|mp4|mov|wmv|wav|avi|avchd|mkv|webm|mpeg|mp3|glb|gltf)$/i)
            ) {
                setFileTypeError(
                    "Not valid file!, Please upload (jpg, jpeg, png, gif, mp4, mov, wmv, wav, avi, avchd, mkv, webm, mpeg, mp3, glb, gltf) file type"
                );
                return;
            }

            setFile(loader);
            setFileExtension(e.target.files[0].type);
            var form = new FormData();
            form.append("image", e.target.files[0]);
            var fileRes = await FileUpload(form);
            // console.log("file res", fileRes)

            if (fileRes) {
                setFileShowType(e.target.files[0].type.slice(0, 5));
                reader.addEventListener("load", () => setFile(reader.result));
                setAddImage(true)
                await setUploadedFile(fileRes);
                setFileTypeError("");
            } else {
                notification.open({
                    message: "Error",
                    description: "This file not supported!",
                    icon: <CloseOutlined style={{ color: "#f4364c" }} />,
                });
                setFileTypeError("This file not supported!");
                setFile(previewIcon);
                setLoading(false);
                setAddImage(false);
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setLoading(false);
    }

    const addContent = async () => {
        // console.log('Async content');
    }

    const selectCategory = async (categories) => {
        setSelectedCategories(categories)
    };

    const onFinish = async (data, resetForm) => {
        // console.log('mystery form', data, contentCollection, selectedCategories, nftDetails)
        setCateError("")
        setMysteryBoxContentError("")

        if ( !selectedCategories.length || ( !selectedMysteryBoxContent || ( selectedMysteryBoxContent && (!selectedMysteryBoxContent.collection || !selectedMysteryBoxContent.issuedQuantity) ) ) ) {
            if ( !selectedCategories.length ) {
                setCateError('Category required!')
            }
            if ( !selectedMysteryBoxContent || ( selectedMysteryBoxContent && (!selectedMysteryBoxContent.collection || !selectedMysteryBoxContent.issuedQuantity) ) ) {
                setMysteryBoxContentError('Mystery box content required!')
            }
            return;
        }
        if (!uploadedFile || ( uploadedFile && !uploadedFile['actual_file_path'] && !editMode)) {
            return;
        }
        const inputNetwork = networkList.find(e => e["_id"] == (networkSelected[0] ? networkSelected[0]['_id'] : networkSelected._id))
        const contractNetwork = inputNetwork['network'] + '_' + inputNetwork['chain_id']
        const contractAddress = address["1155"][contractNetwork]['mint_address']

        /*const attributeCategory = selectedCategories.map(cat => ({
            trait_type: "Category",
            value: cat['name'] || ""
        }))*/

        let mysteryItem = {
            item_image: uploadedFile['actual_file_path'] || '',
            item_resized_image: uploadedFile['resized_path'] || null,
            // collection_id: collectionSelected || '',
            collection_id: data.collection_id,
            item_name: data.box_name || '',
            // boxIssueQuantity: data.category || '',
            description: data.description,
            content_collection_id: selectedMysteryBoxContent?.collection?._id || '',
            content_issue_quantity: +selectedMysteryBoxContent?.issuedQuantity || '',
            network_id: networkSelected[0] ? networkSelected[0]['_id'] : networkSelected._id,
            file_extension: fileShowType,
            categories_id: selectedCategories.map(e => e['_id']),
            content_issue_type: 'automatic',
            supply: +data.supply,
            contract_address: getContractAddress? getContractAddress : contractAddress,
            token_id: getTokenId ? getTokenId : '',
            enable_lazy_minting: data.enable_lazy_minting,
            metadata: {
                name: data.box_name || '',
                image: uploadedFile['actual_file_path'] || '',
                description: data.description,
                attributes: [],
            }
        }

        if (getTokenId && getContractAddress) {
            // console.log('Edit');
            mysteryItem.chain_name = nftDetails?.chain_name;
            mysteryItem.item_image = uploadedFile['actual_file_path'] || uploadedFile;
            updateMysteryBox(mysteryItem,
                (message, response) => {
                    // console.log('update Mystery response =>', message, response);
                    const resp = response;
                    document.getElementById("inputGroupFile01").value = "";
                    setCollectionSelected(false)
                    setContentCollection(false)
                    history.push(`/assets/${resp.chain_name }/${resp.contract_address}/${resp.token_id}`)
                    // setContractAddress(null);
                    // setTokenId(null)
                    resetForm({})
                    notification.open({
                        message: "Mystery Box",
                        description: "Mystery Box Updated Successfully...!",
                        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
                    });
                },
                (message, error) => {
                    notification.open({
                        message: "Error",
                        description: "Something went wrong...!",
                        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
                    });
                })

        } else {
            // console.log('Save');
            createMystery(mysteryItem,
                (message, response) => {
                    // console.log('Mystery message & response', message, response);
                    const resp = response;
                    document.getElementById("inputGroupFile01").value = "";
                    setCollectionSelected(false)
                    setContentCollection(false)
                    history.push(`/assets/${resp.chain_name}/${resp.contract_address}/${resp.token_id}`)
                    // setContractAddress(null);
                    // setTokenId(null)
                    resetForm({})
                    notification.open({
                        message: "Mystery Box",
                        description: "Mystery Box Created Successfully...!",
                        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
                    });

                },
                (message, error) => {
                    notification.open({
                        message: "Error",
                        description: "Something went wrong...!",
                        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
                    });
                });
        }
    };

    const changeAddressForm = (e) => {
        setRadioValue(e.target.value);
    };

    const reInitModal = () => {
        setSelectedCategories(JSON.parse(JSON.stringify(selectedCategories)))
    }

    const isInputNumber = (e) => {
        let ch = String.fromCharCode(e.which);
        if (!/[0-9]/.test(ch)) {
            e.preventDefault();
        }
    };

    const handleNetworkChange = e => {
        // console.log("e", e)
        setNetworkSelected(e._id);
    }

    const closeModal = (data) => {
        // console.log('close', data);
    }


    return (
        <>
            <div className="games__wrap">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-1 mx-0 px-0 d-none"></div>
                        <div className="col-2 row align-items-end flex-column text-left d-none"></div>
                        <div className="col-9 p-0 res form-transp-bg">
                            {getTokenId && getContractAddress ? (
                                <h3 className="oracleTextClass">Update Mystery Box</h3>
                            ) : (
                                <h3 className="oracleTextClass">Create Mystery Box</h3>
                            )}
                            {!islogin ?
                                (
                                    <div className="pt-lg-5">
                                    <p className="connect-wallet-message">*Please connect your wallet to continue.</p>
                                        <button
                                            data-toggle="modal"
                                            data-target="#exampleModalCenter"
                                            className="connect-wallet-btn"
                                        >
                                            Connect Your Wallet
                                        </button>
                                    </div>
                                ) : (
                                    <>
                                        <Formik
                                            initialValues={{
                                                // nftAdd: nftAddList
                                                item_image: nftDetails ? nftDetails?.item_image : '',
                                                collection_id: nftDetails ? nftDetails?.collection_id?._id : collection.length ? collection[0]['_id'] : '',
                                                box_name: nftDetails ? nftDetails?.item_name : '',
                                                // categories: nftDetails && nftDetails.categories_id ? nftDetails.categories_id : [],
                                                description: nftDetails ? nftDetails?.description : '',
                                                issue_quantity: nftDetails ? nftDetails?.mystery_contents?.content_issue_quantity : '',
                                                // network: nftDetails ? nftDetails?.network_id : '',
                                                supply: nftDetails ? nftDetails?.supply : '',
                                                enable_lazy_minting: nftDetails ? nftDetails?.enable_lazy_minting : false
                                            }}
                                            enableReinitialize
                                            validationSchema={MysteryBoxSchema}
                                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                                onFinish(values, resetForm)
                                            }}
                                        >
                                            {({ values, errors, resetForm, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, isValidating, isSubmitting }) => (

                                                <form onSubmit={handleSubmit} className="mystery-form createNftForm">
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <p>
                                                                <span className="required-class">*</span> Required
                                                                Fields
                                                            </p>
                                                            <div className="input-group form-group mt-4 mb-0">
                                                                <div className="custom-file-old">
                                                                    <label className="image-upload-lbl mb-2">
                                                                        Image, Video, Audio, or 3D Model
                                                                        <span className="required-class caution">
                                                                            * Max size: 100 MB
                                                                        </span>{" "}
                                                                    </label>
                                                                    <p>
                                                                        {" "}
                                                                        (File types supported:jpg, jpeg, png, gif, mp4, mov,
                                                                        wmv, wav, avi, avchd, mkv, webm, mpeg, mp3, glb,
                                                                        gltf ){" "}
                                                                    </p>
                                                                    <label
                                                                        className="custom-file-label-old"
                                                                        htmlFor="inputGroupFile01"
                                                                    >
                                                                        <div className="image-preview-box cnftimg add-image">
                                                                            {fileShowType === "image" ? (
                                                                                <img src={file || nftDetails?.item_resized_image} />
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            {fileShowType === "video" ? (
                                                                                <video
                                                                                    playsInline
                                                                                    muted
                                                                                    autoPlay={true}
                                                                                    controlsList="nodownload"
                                                                                    loop=""
                                                                                    preload="auto"
                                                                                    src={['uploadedFile']}
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        height: "calc(100% - 50px",
                                                                                    }}
                                                                                ></video>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            {fileShowType === "audio" ? (
                                                                                <audio controls>
                                                                                    <source src={uploadedFile && uploadedFile['actual_file_path']} />
                                                                                </audio>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                    </label>
                                                                    <input
                                                                        name="item_image"
                                                                        onChange={(event) => {
                                                                            setFieldValue("item_image", event.currentTarget.files[0])
                                                                            selectFile(event)}
                                                                        }
                                                                        type="file"
                                                                        className="custom-file-input-none"
                                                                        id="inputGroupFile01"
                                                                    />
                                                                    <div className='text-danger'>{errors.item_image}</div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 mt-2">
                                                                <div className="row">
                                                                    <div className="col-10 pl-0">
                                                                        <h3 className="image-upload-lbl">COLLECTION <span className="required-class">*</span></h3>
                                                                        <p className="mb-3 mt-1 d-flex">
                                                                    This is the collection where your item will appear.
                                                                            <div className="tooltip-wrapper">
                                                                                {/* <img src="/img/info-icon.png"></img> */}
                                                                                <i className="fa  fa-info-circle"></i>
                                                                                <div className="custom-tooltip">You can organize your items in a collection by selecting the
                                                                                    <a href="#" onClick={(e) => { e.preventDefault(); window.open(`/profile/${auth.user.username}`, "_blank") }}> collection here.</a>
                                                                                </div>
                                                                            </div>
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-1 d-flex justify-content-end pr-0">
                                                                        <div onClick={() => fetchCollection()} className="rounded-circle-bg d-flex align-items-center justify-content-center">
                                                                            {/* <img className="imgw-30 cursor-pointer" src='/img/Grouprefresh.png' /> */}
                                                                            <i className="fa fa-undo cursor-pointer"></i>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-1 d-flex justify-content-end">
                                                                        <div onClick={() => window.open("/create-collections", "_blank")} className="rounded-circle-bg d-flex align-items-center justify-content-center">
                                                                            <img
                                                                                className="imgw-30 cursor-pointer"
                                                                                src={GrouppopUpplus}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="d-flex justify-content-start align-items-start gap-10 cstom-select-box">
                                                                    <Field as="select" name="collection_id">
                                                                        {/* <option value="">Select Collection</option> */}
                                                                        {collection.length > 0
                                                                            ? collection.map((value, key) => (
                                                                                <option key={key} value={value._id}>
                                                                                    {value.collection_name}
                                                                                </option>
                                                                            ))
                                                                            : ""}
                                                                    </Field>
                                                                    {/* <select value={collectionSelected}
                                                                        onChange={(event) =>
                                                                            setCollectionSelected(event.target.value)
                                                                        }
                                                                    >
                                                                        {collection.length > 0
                                                                            ? collection.map((value, key) => (
                                                                                <option key={key}
                                                                                    selected={
                                                                                        collectionSelected === value.id
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    value={value._id}
                                                                                >
                                                                                    {value.collection_name}
                                                                                </option>
                                                                            ))
                                                                            : ""}
                                                                    </select> */}
                                                                </div>
                                                                {/* <div style={{ color: "red" }}>{selectCollectionErr}</div> */}
                                                                {errors.collection_id && touched.collection_id ? (
                                                                    <div className="text-danger">{errors.collection_id}</div>
                                                                ) : null}
                                                            </div>

                                                            <div className="col-12">
                                                                <div className="form-group mt-3">
                                                                    <label className="image-upload-lbl">
                                                                        <h5>Box name<span className="required-class">*</span>{" "}</h5>
                                                                    </label>
                                                                    <Field
                                                                        type="text"
                                                                        className="nft-form-control"
                                                                        name="box_name"
                                                                    />
                                                                    {errors.box_name && touched.box_name ? (
                                                                    <div className="text-danger">{errors.box_name}</div>
                                                                ) : null}
                                                                </div>
                                                                {/* <div className='text-danger'>{errors.box_name}</div> */}
                                                            </div>

                                                            {/* <div className='col-12'>
                                                                <div className="form-group mt-3">
                                                                    <label className="image-upload-lbl">
                                                                        Box Royalties<span className="required-class">*</span>{" "}
                                                                    </label>
                                                                    <Field
                                                                        type="text"
                                                                        className="nft-form-control"
                                                                        name="royalties"
                                                                    />
                                                                    <div className='text-danger'>{errors.royalties}</div>
                                                                </div>
                                                            </div> */}

                                                            <div className="nftform-action-item  col-12 d-flex justify-content-between align-items-baseline">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    {/* <img className="imgw-30" src={categoryIcon} /> */}
                                                                    <div className="flex-column justify-content-between align-items-baseline ml-3">
                                                                        <h3 className="image-upload-lbl">
                                                                           <i className="fa fa-th-list"></i> Select Box Categories
                                                                            <span className="required-class">*</span>
                                                                        </h3>
                                                                        {/* <Field name='categories' type="hidden" value={selectedCategories} /> */}
                                                                        <p className='mb-3 mt-1 small-para'>
                                                                            <div className="text-danger">{!selectedCategories.length && catError || ''}</div>
                                                                            {selectedCategories && selectedCategories?.length > 0
                                                                                ? selectedCategories?.map((cat, key) => (
                                                                                    <span key={key}>{cat.name},</span>
                                                                                ))
                                                                                : ""}{" "}
                                                                            {selectedCategories.length > 0 ? ".etc" : ""}{" "}
                                                                        </p>
                                                                    </div>
                                                                    
                                                                    {/* {errors.categories ? (
                                                                        <div className="text-danger">{errors.categories}</div>
                                                                    ) : null} */}
                                                                </div>
                                                                <div className="rounded-circle-bg d-flex align-items-center justify-content-center">
                                                                    <img
                                                                        className="imgw-30 cursor-pointer"
                                                                        data-toggle="modal"
                                                                        data-target="#categoryModal"
                                                                        src={GrouppopUpplus}
                                                                        onClick={reInitModal}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="nftform-action-item  col-12 d-flex justify-content-between align-items-baseline">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <div className="flex-column justify-content-between align-items-baseline ml-3">
                                                                        <h3 className="image-upload-lbl">
                                                                           <i className="fa fa-gift"></i> Add Box Content
                                                                            <span className="required-class">*</span>
                                                                        </h3>
                                                                        <div className="text-danger">{!selectedMysteryBoxContent && mysteryBoxContentError || ''}</div>
                                                                        <p className='mb-3 mt-1 small-para'>
                                                                            {selectedMysteryBoxContent ? `Collection: ${selectedMysteryBoxContent?.collection?.collection_name}, Issued Quantity: ${selectedMysteryBoxContent.issuedQuantity}` : ''}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="rounded-circle-bg d-flex align-items-center justify-content-center">
                                                                    <img
                                                                        className="imgw-30 cursor-pointer"
                                                                        src={GrouppopUpplus}
                                                                        onClick={() => setShowMysteryBoxModal(true)}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-12">
                                                                <div className="form-group mt-3">
                                                                    <h3>Description<span className="required-class">*</span></h3>
                                                                    <p className='mb-3 mt-1 small-para'>This will be included on your item's detail.</p>
                                                                    <Field as='textarea' name="description"></Field>
                                                                    {errors.description && touched.description ? (
                                                                    <div className="text-danger">{errors.description}</div>
                                                                ) : null}
                                                                </div>
                                                                {/* <div className='text-danger'>{errors.description}</div> */}
                                                            </div>

                                                            <div className="nftform-action-item col-12 d-flex justify-content-between align-items-baseline">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <div className="flex-column justify-content-between align-items-baseline ml-3">
                                                                        <h3>
                                                                        <i className="fa fa-gavel" aria-hidden="true"></i>{" "}
                                                                            Enable Lazy Minting
                                                                        </h3>
                                                                        <p>Set as enable lazy minting.</p>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex align-items-center justify-content-center">
                                                                    <label className="switch switch-radio-class">
                                                                        <Field 
                                                                        type="checkbox" 
                                                                        name="enable_lazy_minting" 
                                                                        />
                                                                        {/* <input
                                                                            checked={enableLazyMinting ? 'checked' : ''}
                                                                            name="enable_lazy_minting"
                                                                            type="checkbox"
                                                                        /> */}
                                                                        <span className="slider round" />
                                                                    </label>
                                                                </div>
                                                            </div>

                                                            <div className='col-12'>
                                                                <div className="form-group mt-3">
                                                                    <h3>Supply<span className="required-class">*</span> </h3>
                                                                    <p className='mb-3 mt-1 small-para'>The number of items that can be minted. No gas cost to you!</p>
                                                                    <Field
                                                                        type="text" disabled={getTokenId && getContractAddress? true : false}
                                                                        className="nft-form-control"
                                                                        name="supply"
                                                                        onKeyPress={(e) => isInputNumber(e)}
                                                                    />
                                                                    {errors.supply && touched.supply ? (
                                                                    <div className="text-danger">{errors.supply}</div>
                                                                ) : null}
                                                                </div>
                                                                {/* <div className='text-danger'>{errors.supply}</div> */}
                                                            </div>

                                                            <div className='col-12'>
                                                                <div className='form-group mt-3'>
                                                                    <h3>Blockchain<span className="required-class">*</span></h3>
                                                                    <p className="mb-3 mt-1 small-para">Select the blockchain where you'd like new items from this collection to be added by default.</p>

                                                                    <Select
                                                                        getOptionLabel={(opt) => (
                                                                            <div className="d-flex align-items-center">
                                                                                <img src={chainImageHandler(opt?.network)} height="35px" width="35px" />
                                                                                <span className="d-block ml-2">
                                                                                    {opt?.chain_label}
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                        value={networkSelected}
                                                                        placeholder={'Select Network..'}
                                                                        getOptionValue={option => option._id}
                                                                        options={networkList}
                                                                        // onChange={handleNetworkChange}
                                                                        onChange={(e) => { setNetworkSelected(e) }}
                                                                        menuPosition="absolute"
                                                                        menuPortalTarget={document.body}
                                                                        isDisabled={getTokenId && getContractAddress ? true : false }
                                                                    />
                                                                </div>
                                                            </div>

                                                            {/* <div className='col-12'>
                                                                <button className="add-content-btn mt-3 mt-sm-4" type="button" onClick={addContent}
                                                                >
                                                                    + Add Content
                                                                </button>
                                                            </div>
                                                            {errors.issue_quantity && touched.issue_quantity ? (
                                                                <div className="text-danger">{errors.issue_quantity}</div>
                                                            ) : null}

                                                            <br />
                                                            <br /> */}

                                                        </div>
                                                        {/* <div className='col-6'>
                                                            <div className="input-group form-group mt-4 mb-0">
                                                                <div className="custom-file-old">
                                                                    <label className="image-upload-lbl mb-2">
                                                                        Preview
                                                                    </label>

                                                                    <label
                                                                        className="custom-file-label-old"
                                                                        htmlFor="inputGroupFile02"
                                                                    >
                                                                        <div className={`${addImage ? "image-preview-box cnftimg add-image" : "image-preview-box cnftimg"}`}>
                                                                            {fileShowType === "image" ? (
                                                                                <img src={file} />
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            {fileShowType === "video" ? (
                                                                                <video
                                                                                    playsInline
                                                                                    muted
                                                                                    autoPlay={true}
                                                                                    controlsList="nodownload"
                                                                                    loop=""
                                                                                    preload="auto"
                                                                                    src={uploadedFile}
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        height: "calc(100% - 50px",
                                                                                    }}
                                                                                ></video>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            {fileShowType === "audio" ? (
                                                                                <audio controls>
                                                                                    <source src={uploadedFile} />
                                                                                </audio>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    
                                                    <div className="col-12 text-right">
                                                        {getTokenId && getContractAddress ? (
                                                            <button
                                                                className="create-btn mt-3 mt-sm-4"
                                                                type="submit"
                                                                // onClick={() => {setFieldValue("categories", selectedCategories); }}
                                                            >
                                                                Update Item
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="create-btn mt-3 mt-sm-4"
                                                                type="submit"
                                                                // onClick={() => {setFieldValue("categories", selectedCategories); }}
                                                            >
                                                                Create Item
                                                            </button>
                                                        )}

                                                    </div>
                                                    <br />
                                                    <br />
                                                </form>
                                            )}
                                        </Formik>
                                    </>
                                )}

                        </div>
                    </div>
                </div>
            </div>

            <Modal
                className='nft_quick_report_wrp'
                title="Add Content"
                visible={showMysteryBoxModal}
                onCancel={() => setShowMysteryBoxModal(false)}
                footer={null}
                maskClosable = {false}
                destroyOnClose={true}
            >
                <MysteryBoxContent
                    onCancel={() => setShowMysteryBoxModal(false)}
                    collectionList={collection}
                    selectedMysteryBoxContent={selectedMysteryBoxContent}
                    addMysteryContent={data => AddMysteryContent(data)}
                />
            </Modal>

            <NftFormPopups
                returnCategory={(categories) => selectCategory(categories)}
                selectedCategories={selectedCategories}
                // setProperties={(event) => handelProperty(event)}
                allProperties={[]}
                // saveAllLevels={(data) => handelLavle(data)}
                allLevels={[]}
                setIsSaveProperty={{}}
                setIsSaveLevel={{}}
            />
        </>
    )
}

const mapStateToProps = state => ({
    islogin: state.auth.isLogin,
    auth: state.auth.auth,
    // nftDetails: state.NFTDetails?.nftDetails,
})

export default connect(mapStateToProps)(MysteryBox);

export const TokenStandard = ['ERC721', 'ERC1155']

export const MethodName = ["fixed", "sell_to_highest_bidder", "sell_with_declining_price"]
export const MetadataDisplayType = {
    "BOOST_NUMBER": 'boost_number',
    "BOOST_PERCENTAGE": "boost_percentage",
    "NUMBER": "number",
    "DATE": "date",
    "TEXT": "text",
    "READ_ONLY": "read_only"
}


export const URLS = {

    PROFILE:'/profile',
    NFT_DETAILS:'/assets',
    COLLECTION_DETAILS:'/collection',
}
export const TermsURL = '/terms-of-service'
export const METADATA_BASE_URL = "https://meta.lunasky.com"

export const NetworkURLs = {
    "ethereum_1": {
        "scanUrl": "https://etherscan.io"
    },
    "ethereum_3": {
        "scanUrl": 'https://ropsten.etherscan.io',
    },
    "ethereum_4": {
        "scanUrl": "https://rinkeby.etherscan.io"
    },
    "ethereum_5": {
        "scanUrl": 'https://goerli.etherscan.io',
    },
    "ethereum_42": {
        "scanUrl": 'https://kovan.etherscan.io',
    },
    "polygon_80001": {
        "scanUrl": "https://mumbai.polygonscan.com"
    },
    "polygon_137": {
        "scanUrl": "https://polygonscan.com"
    },
    "binance_97": {
        "scanUrl": "https://testnet.bscscan.com"
    },
    "binance_56": {
        "scanUrl": "https://bscscan.com"
    }
}

export const IMAGE_PATHS =  {

    FOLLOW_IMAGE: '/img/followImage.png',
    ADD_BALANCE_IMAGE: '/img/setting-svgrepo-com.png'

}

export const PERCENTAGE_VALUE = 10;
export const MAX_TEXT_LENGTH = 22;
export const NFT_FILE_MAX_SIZE = 1024 * 1024 * 100; // 100MB

export const API_URLS = {
    GLOBAL_SEARCH:'/nft/global-search',
    COLLECTION:{
        BASIC_DETAILS:'/collections/basic-details',
        REPORT_COLLECTION_ITEM:'/collections/save-report',
    },
    USER:{
        FOLLOW_UNFOLLOW: '/follwers',
        FETCH_FOLLOW_DATA: '/follwers',
        NFT:{
            LIKE_UNLIKE_NFT:'/nft/like-dislike',
            FETCH_LIKED_NFT:'/nft/favorite',
        },
        CARD:{
            GET_CARDS:'/payment-method/card',
            ADD_CARD:'/payment-method/card', 
            MARK_CARD_AS_DEFAULT:'/payment-method/mark-card-as-default', 
            DELETE_CARD:'/payment-method/card', 
        },
        IMPORT: {
            NFT:'/nft/import',
            BULK_UPLOAD: '/nft/confirm-bulk-upload',
            CSV_LIST_PAGINATION: '/nft/list-csv-record'
        },
        VERIFY_SUBSCRIBED_EMAIL: '/users/verify-subscribed-email',
        BUY_CRYPTO_ONBOARDING: '/transaction/buy-crypto-onboarding',
        WYRE_ORDER_SAVE: '/transaction/wyre-order-save',
        GET_NONCE: '/users/login',
        MYSTERY: {
            CREATE_MYSTERY: 'mysterybox',
            GET_MYSTERY: 'mysterybox/:contract_address/:token_id'
        },
        ADD_FEEDBACK: '/feedback'
    },
    NFTS:{
        LISTING:'/nft',
        REVEAL_MYSTERY_BOX:'/nft/reveal',
        GET_OWNER_LIST:'/nft/owner-list',
        GET_REVEALED_LIST:'/nft/revealed-list',
        GET_MYSTERY_BOX_TOKEN:'/nft/gifts',
        REPORT_ITEM:'/nft/save_report',
        UNLOCKABLE_CONTENT:'/nft/unlockable-content',
        
    }
}

export const SERVICE_FEE = 2
export const MAX_BUY_QUANTITY = 5
// eth etst goerli
// export const CONTRACT_ADDRESS_SPECIAL='0xc35A64101836Fa1049fc38EaDD964Fb002Dc1Bf5'

// BNB Mainnet
export const CONTRACT_ADDRESS_SPECIAL='0x62027611E88715F3D838E6930d5303cBc05D6DD6'
export const CONTRACT_ADDRESS_BUSD_ERC20='0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'

import { SAGA_ACTIONTYPES } from "./action";
import { CallApi } from "../../api/CallApi";
import { API_URLS } from "../../utils/app.constant";
import { call, put, takeLatest } from "redux-saga/effects";

function* createMysteryBox(action) {
    try {
        const data = action.payload;
        const response = yield call(CallApi.POST, API_URLS.USER.MYSTERY.CREATE_MYSTERY, data);
        //  console.log('mystery/saga', action, response);
        if (response.status >= 200 && response.status < 300 && response.data && response.data.success) {
            action && action.callbackSuccess && action.callbackSuccess(response.data);
        } else {
            action && action.callbackError && action.callbackError(response.data)
        }
    } catch (err) {
        console.log('mystery-catch', err);
        action && action.callbackError && action.callbackError(err && err.data ? err.data : err);
        yield put({ type: 'CREATE_MYSTERY_FAILED', message: err.message });
    }
}

function* getMysteryDetails(action) {
    try {
        const data = action.payload;
        const response = yield call(CallApi.PUT, `mysterybox/${data.chain_name}/${data.contract_address}/${data.token_id}`, data)
        if (response.status >= 200 && response.status < 300 && response.data && response.data.success) {
            action && action.callbackSuccess && action.callbackSuccess(response.data);
        } else {
            action && action.callbackError && action.callbackError(response.data)
        }
    } catch (err) {
        console.log('mystery get-catch', err);
        action && action.callbackError && action.callbackError(err && err.data ? err.data : err);
        yield put({ type: 'GET_MYSTERY_FAILED', message: err.message });
    }
}

function* MysterySaga() {
    yield takeLatest(SAGA_ACTIONTYPES.MYSTERY_BOX.CREATE_MYSTERY, createMysteryBox);
    yield takeLatest(SAGA_ACTIONTYPES.MYSTERY_BOX.GET_MYSTERY_DETAILS, getMysteryDetails);
}

export default MysterySaga;

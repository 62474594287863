import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, Input, Checkbox, notification } from "antd";
import ReportForm from "./ReportForm";

const ReportCollectionModal = (props) => {

    const { isOpen, hideModal, collection, getCollectionDetails } = props;

    const handleCancel = () => {
        hideModal && hideModal();
    };

    const successHandler = () => {
        handleCancel();
        getCollectionDetails();
    };

    return (
        <>
            <Modal
                className="nft_quick_report_wrp "
                title="Report Collection"
                open={isOpen}
                visible={isOpen}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
                destroyOnClose={true}
            >
                <div className="nft_quick_report_inner p-4">
                    <ReportForm
                        otherFormData={collection ? { ...collection } : {}}
                        successHandler={successHandler}
                        type='collection'
                    />
                </div>
            </Modal></>
    )
}

export default ReportCollectionModal
export const actionTypes = {
    LOGIN: 'LOGIN',
    SIGNATURE: 'SIGNATURE',   
    LOGIN_SUCCESS:"LOGIN_SUCCESS",
    LOGOUT: 'LOGOUT',   
    UPDATE_PROFILE:'UPDATE_PROFILE',
    SIGNATURE_STATUS_CHANGE:'SIGNATURE_STATUS_CHANGE'
};

export function login(payload) {
   return { type: actionTypes.LOGIN, payload };
}

export function signature(payload) {
   return { type: actionTypes.SIGNATURE, payload };
}

export function loginSuccess(payload) {
   return { type: actionTypes.LOGIN_SUCCESS, payload };
}

export function logout() {
   return { type: actionTypes.LOGOUT };
}

export function updateUserProfile(profile) {
   return { type: actionTypes.UPDATE_PROFILE ,profile};
}

export function signatureStatusUpdate(payload) {
   return { type: actionTypes.SIGNATURE_STATUS_CHANGE ,payload};
}
import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import loader from '../Header/lunasky.png'

export default function SidebarSkeleton() {
 

  return (
    <div className={"carousel_container"}>
      <div className="wrapper_loader">
        <h1 className="brand">
          <span>LunaSky</span>
          <span><img width={"50"} height={"50"} src={loader}></img></span>
        </h1>
        <div className="loading-bar"></div>
      </div>      
    </div>
  );
}

import React, { Component } from 'react';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import BlogSection from '../components/Blog/Blog';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';

class Blog extends Component {
    render() {
        return (
            <div className="main">
                <Breadcrumb title="About" id="about" subpage="Community" page="About" />
                <h3 className="comming_soon_text mt-3 mb-0 text-center">Coming Soon </h3>
                <Footer />
                <ModalSearch />
                <ModalMenu />
                <Scrollup />
            </div>
        );
    }
}

export default Blog;
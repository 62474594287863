import axios from "./config";

class NFTServices {
  async GetNft({ network, contract_address, token_id }) {
    const reponse = await axios
      .get(`/nft/${network}/${contract_address}/${token_id}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }

  /*
  * only to edit the NFT
  */
  async GetNftDetails({ network, contract_address, token_id }) {
    // ${network}/
    const reponse = await axios
      .get(`/nft/details/${network}/${contract_address}/${token_id}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }

  async Create(data) {
    const reponse = await axios
      .post(`/nft`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }

  async SellListing(data, network, contract_address, token_id) {
    // ${network}
    const response = await axios
      .post(`/nft/list-sale/${network}/${contract_address}/${token_id}`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  }


  async BuyListing(data, network, contract_address, token) {
    // ${network}
    const response = await axios
      .post(`/nft/buy/${network}/${contract_address}/${token}`, data)

      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  }

  async CancelSellListing(id) {
    const response = await axios
      .post(`/nft/list-sale/cancel/${id}`)
      .then((response) => {
        return response;
      }) .catch((error) => {
        return error.response;
      });
    return response;
  }

  async OwnerList(network, contract_address, token, wallet_address = null) {
    // ${network}
    var url = `/nft/owner-list/${network}/${contract_address}/${token}`;
    if ( wallet_address ) {
      url += '?wallet_address='+ wallet_address;
    }
    const response = await axios
      .get(url)
      .then((response) => {
        return response;
      }) .catch((error) => {
        return error.response;
      });
    return response;
  }

  async PriceList(network, contract_address, token, wallet_address = null) {
    // console.log('priceListAPI', network); ${network}
    var url = `/nft/price-list/${network}/${contract_address}/${token}`;
    if ( wallet_address ) {
      url += '?wallet_address='+ wallet_address;
    }
    const response = await axios
      .get(url)
      .then((response) => {
        return response;
      }) .catch((error) => {
        return error.response;
      });
    return response;
  }

  async Update(data, network, contract_address, token_id) {
    const reponse = await axios
      .put(`/nft/${network}/${contract_address}/${token_id}`, data)
      .then((response) => {
        console.log("update",response)
        return response;
      })
      .catch((error) => {
        console.log("update", error);
        return error.response;
      });
    return reponse;
  }

  async MintNft(network, contract_address, token_id) {
    // ${network}
    const reponse = await axios
      .put(`/nft/mint/${network}/${contract_address}/${token_id}`, {})
      .then((response) => {
        console.log("update",response)
        return response;
      })
      .catch((error) => {
        console.log("update", error);
        return error.response;
      });
    return reponse;
  }

  async DeleteNon_mintedNft(params) {
    const response = await axios
    .delete(`/nft/${params.network}/${params.contract_address}/${params.token_id}`)
    .then((response) => {
      // console.log('delete=>', response);
      return response;
    })
    .catch((error) => {
      console.log('delete error', error);
      return error.response;
    });
    return response;
  }

  async GetNFTWithPagination(data) {
    var url = '/nft';
    const queryString = Object.keys(data).map(key => `${key}=${data[key]}`).join('&')
    if ( queryString ) {
      url += `?${queryString}`
    }
    const reponse = await axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }

  /*async GetManyNFTWithPaginationByCollection(data) {
    const reponse = await axios
      .post(`/nft/get_many_nfts/`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }*/

  async SaveReport(data) {
    const reponse = await axios
      .post(`/nft/save_report`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }
  /*async FilterNftByName(name) {
    const reponse = await axios
      .post(`/nft/nft_filter`, name)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }*/

  async TransferNFT(data, network, contractAddress, tokenId) {
    // ${network}
    const reponse = await axios.post(`/nft/transfer/${network}/${contractAddress}/${tokenId}`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log('update',error);
        return error.response;
      });
    return reponse;
  }
  async LikeUnLikeNFT(data) {
   
    const reponse = await axios.post(`/nft/like-dislike`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return reponse;
  }
  async FetchFavNft(data) {
  
    const reponse = await axios.get(`/nft/favorite`, {params:data})
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return reponse;
  }
  async FetchNftUnlockableContent({ network, contract_address, token_id }) {
    const reponse = await axios.get(`/nft/unlockable-content/${network}/${contract_address}/${token_id}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return reponse;
  }
  async BurnSelectedNft(data, chianName, contractAddress, tokenId) {
    const response = await axios.put(`/nft/burn/${chianName}/${contractAddress}/${tokenId}`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  }

}


export default new NFTServices();

import { actionTypes } from './action';

export const initState = {
    collections:[],
    isloading:true,
    collectionDetails:{}
};

function reducer(state = initState, action) {
    switch (action.type) {
        case actionTypes.GET_COLLECTIONS:
            return {
                ...state,
                ...{ collections: action.payload,isloading:false},
            };
        case actionTypes.LOADING:
            return {
                ...state,
                ...{ isloading:true },
            };
        case actionTypes.GET_SINGLE_COLLECTIONS:
            return {
                ...state,
                ...{ collectionDetails: action.payload,isloading:false },
            };    

        default:
            return state;
    }
}

export default reducer;

import abiMint1155 from "../abi/ABI_Mint_1155.json";
import abiMint721 from "../abi/ABI_Mint_721.json";
import abiMint1155Special from "../abi/ABI_Mint_1155_SPECIAL.json";
import abiMarketplace1155 from "../abi/ABI_MarketPlace_1155.json";
import abiMarketPlace721 from "../abi/ABI_Marketplace_721.json";

import { TokenStandard, CONTRACT_ADDRESS_SPECIAL } from "../utils/app.constant";
import * as address from "../abi/address.json";

export function useMintContractAbiSelector() {
  let getMintAbi = (tokenStandard, contractNetwork, contractAddress = null) => {
    if (tokenStandard === TokenStandard[0]) {
      let mintAddress = address["721"][contractNetwork]["mint_address"];
      return { abi: abiMint721, mintAddress };
    } else if (tokenStandard === TokenStandard[1]) {
      let mintAddress = address["1155"][contractNetwork]["mint_address"];
      if ( contractAddress && contractAddress.toLowerCase() == CONTRACT_ADDRESS_SPECIAL.toLowerCase() ) {
        return { abi: abiMint1155Special, mintAddress: CONTRACT_ADDRESS_SPECIAL };
      } else {
        return { abi: abiMint1155, mintAddress };
      }
    } else {
      return { abi: null, mintAddress: null };
    }
  };
  return getMintAbi;
}

export function useMarketplaceContractAbiSelector() {
  let getMarketplaceAbi = (tokenStandard, contractNetwork) => {
    if (tokenStandard === TokenStandard[0]) {
      let mintAddress = address["721"][contractNetwork]["marketplace_address"];
      return { abi: abiMarketPlace721, mintAddress };
    } else if (tokenStandard === TokenStandard[1]) {
      let mintAddress = address["1155"][contractNetwork]["marketplace_address"];
      return { abi: abiMarketplace1155, mintAddress };
    } else {
      return { abi: null, mintAddress: null };
    }
  };
  return getMarketplaceAbi;
}

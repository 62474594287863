import OffersServices from '../api/OffersService';
import {  notification } from 'antd'
import { SmileOutlined, CloseOutlined, ConsoleSqlOutlined } from "@ant-design/icons";

export default function useOffers() {

    return {
        create: async (data) => {          
            var responseData = await OffersServices.Create(data);
            if (responseData.status === 201) {
                notification.open({
                    message: "Success",
                    description: "Follwing",
                    icon:<SmileOutlined style={{ color: "#108ee9" }} />,
                }); 
                return responseData.data.data;
            }
            if (responseData.status === 202) {
                notification.open({
                    message: "Success",
                    description: "Unfollow",
                    icon:<SmileOutlined style={{ color: "#108ee9" }} />,
                }); 
                return false
            }
            
            return false;
        },
        offerUpdate: async (data,id) => {               
            var responseData = await OffersServices.Update(data,id);
            if (responseData.status === 200) {
                return responseData.data.data;
            }
            return false;
        },
        getOfferFilter: async (data) => {          
            var responseData = await OffersServices.GetOfferFilter(data);
            if (responseData.status === 200) {
                return responseData.data.data;
            }
            return false;
        },        
    }
}

import React, { Component } from "react";
import Coverflow from "react-coverflow";
import crator_Image from "../../image/homeslides/crators.png";
import drops_Image from "../../image/homeslides/drops.png";
import lends_Image from "../../image/homeslides/lends.png";
import Uauctions_Image from "../../image/homeslides/upcoming_auctions.png";
import wearable_Image from "../../image/homeslides/wearable.png";
import seller_Image from "../../image/homeslides/sellers.png";

const initData = {
  pre_heading: "LunaSkyNFT",
  heading: "Discover, collect, and sell extraordinary NFTs",
  content: "Explore on the world's best & largest NFT marketplace",
  btn_1: "Explore",
  btn_2: "Create",
};

class Hero extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    data: {},
  };
  componentDidMount() {
    this.setState({
      data: initData,
    });
  }
  render() {
    return (
      <>
        <section className="homeslider">
          <Coverflow
            // width={1170}
            // height={480}
            // displayQuantityOfSide={2}
            // navigation={false}
            // enableScroll={false}
            // infiniteScroll={true}
            // enableHeading={true}
            // active={1}
            width={960}
            height={480}
            displayQuantityOfSide={2}
            navigation={false}
            enableHeading={false}
          >
            <div
              onClick={() => this.fn()}
              onKeyDown={() => this.fn()}
              role="menuitem"
              tabIndex="99"
            ></div>
            <img src={drops_Image} alt="Drops" data-action="http://passer.cc" />
            <img
              src={wearable_Image}
              alt="Wearable"
              data-action="https://doce.cc/"
            />
            <img
              src={seller_Image}
              alt="Sellers"
              data-action="http://tw.yahoo.com"
            />
            <img
              src={lends_Image}
              alt="Lends"
              data-action="http://www.bbc.co.uk"
            />
            <img
              src={crator_Image}
              alt="Crators"
              data-action="https://medium.com"
            />
            <img
              src={Uauctions_Image}
              alt="Auctions"
              data-action="http://www.google.com"
            />
          </Coverflow>
          <div className="sliderDots d-flex justify-content-center container pr-4 flex-column align-items-center">
            <h2>Drops</h2>
            <ul className="list-unstyled d-flex justify-content-center">
              <line className="active"></line>
              <line></line>
              <line></line>
              <line></line>
              <line></line>
              <line></line>
            </ul>
          </div>
          <ul className="list-unstyled sliderMenuitems d-flex justify-content-around mt-4">
            <li>Upcoming Drops</li>
            <li>Top Wearables</li>
            <li>Top Sellers</li>
            <li>Top Lends</li>
            <li>Top Creators</li>
            <li>Upcoming Auctions</li>
          </ul>
        </section>
        {/* <section className="hero-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-7">
                                <span>{this.state.data.pre_heading}</span>
                                <h1 className="mt-4">{this.state.data.heading}</h1>
                                <p>{this.state.data.content}</p>
                                <div className="button-group">
                                    <a className="btn btn-bordered-white" href="/sale">Explore</a>
                                    <a className="btn btn-bordered-white" href="/create">Create</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shape">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 465" version="1.1">
                            <defs>
                                <linearGradient x1="49.7965246%" y1="28.2355058%" x2="49.7778147%" y2="98.4657689%" id="linearGradient-1">
                                    <stop stopColor="rgba(69,40,220, 0.15)" offset="0%" />
                                    <stop stopColor="rgba(87,4,138, 0.15)" offset="100%" />
                                </linearGradient>
                            </defs>
                            <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                <polygon points fill="url(#linearGradient-1)">
                                    <animate id="graph-animation" xmlns="http://www.w3.org/2000/svg" dur="2s" repeatCount attributeName="points" values="0,464 0,464 111.6,464 282.5,464 457.4,464 613.4,464 762.3,464 912.3,464 1068.2,464 1191.2,464 1328.1,464 1440.1,464 1440.1,464 0,464; 0,464 0,367 111.6,323.3 282.5,373 457.4,423.8 613.4,464 762.3,464 912.3,464 1068.2,464 1191.2,464 1328.1,464 1440.1,464 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,336.6 457.4,363.5 613.4,414.4 762.3,464 912.3,464 1068.2,464 1191.2,464 1328.1,464 1440.1,464 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,323.3 613.4,340 762.3,425.6 912.3,464 1068.2,464 1191.2,464 1328.1,464 1440.1,464 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,263 613.4,290.4 762.3,368 912.3,446.4 1068.2,464 1191.2,464 1328.1,464 1440.1,464 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,263 613.4,216 762.3,329.6 912.3,420 1068.2,427.6 1191.2,464 1328.1,464 1440.1,464 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,263 613.4,216 762.3,272 912.3,402.4 1068.2,373 1191.2,412 1328.1,464 1440.1,464 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,263 613.4,216 762.3,272 912.3,376 1068.2,336.6 1191.2,334 1328.1,404 1440.1,464 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,263 613.4,216 762.3,272 912.3,376 1068.2,282 1191.2,282 1328.1,314 1440.1,372.8 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,263 613.4,216 762.3,272 912.3,376 1068.2,282 1191.2,204 1328.1,254 1440.1,236 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,263 613.4,216 762.3,272 912.3,376 1068.2,282 1191.2,204 1328.1,164 1440.1,144.79999999999998 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,263 613.4,216 762.3,272 912.3,376 1068.2,282 1191.2,204 1328.1,164 1440.1,8 1440.1,464 0,464;" fill="freeze" />
                                </polygon>
                            </g>
                        </svg>
                    </div>
                </section> */}
      </>
    );
  }
}

export default Hero;

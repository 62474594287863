import axios from "./config";

class CommServices {
  
  async Categories() {
    const reponse = await axios.get(`/categories`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }
  async CategorieIds(data) {
    const reponse = await axios.post(`categories/get_by_name`,data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }
  
  async CollectionOfUser() {
    const reponse = await axios.get(`/collections/get_collection_by_id`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }
  async Transaction(data) {
    const reponse = await axios.post(`/transaction`,data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }
  async ActivityOfCollection(data) {
    const reponse = await axios.post(`/transaction/acivity`,data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }
  async GetSlider(data) {
    const reponse = await axios.get(`/slider`,data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }
}
export default new CommServices();

import React, { useState, useEffect } from "react";

export default function WelcomeVisibility() {
  return (
    <>
      <div className="connect-wallet-box-main border-round">
        <h2 className="main-heading"></h2>
        <div className="p-4">
        <p>Welcome to LunaSky Public Beta V1. As a Beta version there will be certain functionalities that do not yet operate as expected. We are aware of these and they will be corrected when V2 launches soon. 
          {/* Please visit our Beta page for more information. */}
          </p>
        </div>
      </div>
    </>
  );
}

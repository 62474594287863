import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { FeedbackSchema } from '../../utils/Validation';
import { useImportApi } from '../../hooks/api/importApiHook';
import { notification } from "antd";
import { SmileOutlined, CloseOutlined } from "@ant-design/icons";
import useMedia from '../../hooks/useMedia'

const FeedbackModal = (props) => {
    const { isLogin, auth, handleCancel } = props;
    const [fileTypeError, setFileTypeError] = useState('');
    const [selectFormData, setSelectFormData] = useState('');
    const { addFeedback } = useImportApi();
    const { FileUpload, feedbackFileUpload } = useMedia();
    const [buttonDisable, setButtonDisable] = useState(false)

    const onFinishFeedback = (data, errors, resetForm) => {
        // console.log('data=>', data, errors, Object.keys(errors).length);

        if (Object.keys(errors).length > 0 || data.userEmail === '' || data.userName === '' || data.userFeedback === '') {
            return;
        }

        let Params = {
            name: data.userName,
            email: data.userEmail,
            phone: '' + data.userPhone,
            message: data.userFeedback,
            attachment: selectFormData
        }

        addFeedback(Params,
            (message, response) => {
                // console.log('message & response', message, response);
                notification.open({
                    message: "Suggestion",
                    description: "Suggestion Submitted Successfully!",
                    icon: <SmileOutlined style={{ color: "#108ee9" }} />,
                });
                resetForm();
                document.getElementById("uxFeedbackFileUpload").value = "";
                document.getElementById('closeButton').click();
                setSelectFormData('')
            },
            (message, error) => {
                notification.open({
                    message: "Error",
                    description: error.message,
                    icon: <CloseOutlined style={{ color: "#f4364c" }} />,
                });
            })

    };

    const closeModal = (resetForm) => {
        resetForm();
        document.getElementById('closeButton').click();
        handleCancel()
        document.getElementById("uxFeedbackFileUpload").value = "";
        setSelectFormData('')
    }

    const isInputNumber = (e) => {
        let ch = String.fromCharCode(e.which);
        if (!/[0-9]/.test(ch)) {
            e.preventDefault();
        }
    };

    const fileUpload = async (e, type) => {
        const reader = new FileReader();
        setButtonDisable(true);
        if (e.target.files && e.target.files.length > 0) {
            if (!e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
                setFileTypeError('Not valid file!, Please upload (jpef,jpg,png,gif) file type')
                return
            }
            var form = new FormData()
            form.append('image', e.target.files[0])
            var fileRes = await feedbackFileUpload(form)
            setFileTypeError('')
            if (fileRes?.actual_file_path) {
                setButtonDisable(false);
                setSelectFormData(fileRes.actual_file_path)
            }
        }

    };

    return (
        <div className="NftmodelsPopupWrap">
            {/* id="feedbackformpopup"  */}
            <div className="Nftmodel-popup-item">
                <div >
                {/* className="modal fade" id="feedbackModal" tabIndex={-1} role="dialog" aria-labelledby="feedbackModalLabel" aria-hidden="true" */}
                    <div >
                    {/* className="modal-dialog modal-lg category-modal" role="document" */}
                        <div>
                        {/* className="modal-content" */}
                            {/* <div className="modal-header">
                                <h5 className="modal-title" id="feedbackModalLabel">Beta Suggestion Box</h5>
                            </div> */}
                            <Formik
                                initialValues={{
                                    userName: '',
                                    userEmail: '',
                                    userPhone: '',
                                    userFeedback: '',
                                    uploadAttachment: ''
                                }}
                                enableReinitialize
                                validationSchema={FeedbackSchema}
                            // onSubmit={(values, errors, { setSubmitting, resetForm }) => {
                            //     console.log('data 1=>', values, errors);

                            //     onFinishFeedback(values, errors, resetForm)
                            // }}
                            >
                                {({ values, errors, resetForm, touched, handleSubmit, setFieldValue, setFieldTouched, isValidating, isSubmitting }) => (
                                    <Form className="mystery-form createNftForm">
                                        <div className="modal-body flex-column">

                                            <div className='row'>
                                                <div className="col-12">
                                                    <div className="form-group mt-3">
                                                        <label>
                                                            <h5>Name<span className="required-class">*</span>{" "}</h5>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            className="nft-form-control"
                                                            name="userName"
                                                        />
                                                        {errors.userName && touched.userName ? (
                                                            <div className="text-danger">{errors.userName}</div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="form-group mt-3">
                                                    {/* className="image-upload-lbl" */}
                                                        <label>
                                                            <h5>Email<span className="required-class">*</span>{" "}</h5>
                                                        </label>
                                                        <Field
                                                            type="email"
                                                            className="nft-form-control"
                                                            name="userEmail"
                                                        />
                                                        {errors.userEmail && touched.userEmail ? (
                                                            <div className="text-danger">{errors.userEmail}</div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="form-group mt-3">
                                                    {/* className="image-upload-lbl" */}
                                                        <label>
                                                            <h5>Phone</h5>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            className="nft-form-control"
                                                            name="userPhone"
                                                            onKeyPress={(e) => isInputNumber(e)}
                                                        />
                                                        {errors.userPhone ? (
                                                            <div className="text-danger">{errors.userPhone}</div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="form-group mt-3">
                                                    {/* className="image-upload-lbl" */}
                                                        <label>
                                                            <h5>Feedback/Suggestion<span className="required-class">*</span>{" "}</h5>
                                                        </label>
                                                        <Field
                                                            as="textarea"
                                                            className="nft-form-control"
                                                            name="userFeedback"
                                                        />
                                                        {errors.userFeedback && touched.userFeedback ? (
                                                            <div className="text-danger">{errors.userFeedback}</div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="form-group mt-3">
                                                    {/* className="image-upload-lbl" */}
                                                        <label>
                                                            <h5>Attachment</h5>
                                                        </label>
                                                        <input
                                                            name="uploadAttachment"
                                                            type="file"
                                                            onChange={(event) => fileUpload(event, "feature")}
                                                            className="form-control file-height"
                                                            id="uxFeedbackFileUpload"
                                                        />
                                                        {fileTypeError !== '' ? <div role="alert" className="ant-form-item-explain-error">{fileTypeError}</div> : ''}

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="modal-footer justify-content-center">
                                            <button type="button" id='closeButton' onClick={() => closeModal(resetForm)} className="btn btn-secondary hide-close" data-dismiss="modal"> Close</button>
                                            <button type="button" onClick={() => onFinishFeedback(values, errors, resetForm)}
                                                className="btn btn-primary" disabled={buttonDisable}>Submit</button>
                                        </div>

                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = (state) => ({
    islogin: state.auth.isLogin,
    auth: state.auth.auth,
    appLoaderStatus: state.comman.appLoaderStatus,

});

export default connect(mapStateToProps)(FeedbackModal)
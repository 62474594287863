import React from 'react';
import Footer from '../components/Footer/Footer';
import CreateCollectionComponent from '../components/Create/CreateCollectionsComponent'
function CreateCollectionsPage() {

    return (
        <div id="createcollection">
            <CreateCollectionComponent />
            <Footer />
        </div>
    );

}

export default CreateCollectionsPage;
import React, { useEffect, useState } from 'react'
import useComman from '../hooks/useComman'
import $ from 'jquery';
import delete_icon from '../../src/image/delete-icon.png';


export default function NftFormPopups({/*setIsSaveProperty,setIsSaveLevel,*/ returnCategory, /*setProperties,*/ allProperties, returnProperty, returnLabel, allLevels, saveAllLevels, categoryUpdate, nftDetails, selectedCategories }) {
    // console.log("modalProp",allProperties)
    const [category, setCategory] = useState([]) // list of categories from api
    const [categoriesToPopulate, setCategoriesToPopulate] = useState([]) // showing checked or not

    const [propertiesToPopulate, setPropertiesToPopulate] = useState([]) // showing properties
    const [levelsToPopulate, setLevelsToPopulate] = useState([]) // showing properties

    const [checkEmpty, setEmpty] = useState('')
    // const [Properties, setAllProperties]=useState(allProperties)
    const [randomnumber, setRandomNumber] = useState(1)
    // const [levels, setAllLevels] = useState(allLevels)

    const { categories } = useComman()

    useEffect(() => {
        fetchCategories()
    }, [])

    useEffect(() => {
        const categoryList = category.map(cat => {
            const gotCat = selectedCategories && selectedCategories.find(e => e['_id'] == cat['_id'])
            cat['isSelected'] = gotCat ? true : false;
            return cat;
        })
        setCategoriesToPopulate(categoryList)
    }, [selectedCategories, category])

    useEffect(() => {
        const alteredProperties = allProperties.length ? allProperties.map(e => ({ ...e, key: randomNumber() })) : [{ type: "", name: "", key: randomNumber() }]
        setPropertiesToPopulate(alteredProperties)
    }, [allProperties])

    useEffect(() => {
        const alteredLevels = allLevels.length ? allLevels.map(e => ({ ...e, key: randomNumber() })) : [{ name: '', value_one: 3, value_two: 5, key: randomNumber() }]
        setLevelsToPopulate(alteredLevels)
    }, [allLevels])

    // const properties = { type: '', name: '' }
    // const levelDefault = { name: '', value_one: 3, value_two: 5 }

    const checkCategory = async (categoryId, status) => {
        var categoryList = JSON.parse(JSON.stringify(categoriesToPopulate));
        const gotIndex = categoryList.findIndex(e => e['_id'] === categoryId)
        if (gotIndex != -1) {
            categoryList[gotIndex]['isSelected'] = status;
            setCategoriesToPopulate(categoryList)
        }
    }

    const saveCategoryForm = () => {
        const categories = categoriesToPopulate.filter(e => e['isSelected']).map(e => ({ _id: e['_id'], name: e['name'] }))
        returnCategory(categories)
        // $("#categoryModal").modal('hide')
        $("#saveCollectionPro").click()
    }

    const fetchCategories = async () => {
        let category = await categories();
        setCategory(category)
    }

    const handelProperty = async (data, index) => {
        /*// console.log("handleData",data,"index",index)
        let all = Properties
        // console.log("all",all)
        let newData = ({ ...all[index], ...data })
        // console.log('newData',newData)
        all[index] = newData
        await setAllProperties(all)*/
        const properties = JSON.parse(JSON.stringify(propertiesToPopulate));
        const updatedData = {
            ...properties[index],
            ...data
        }
        properties.splice(index, 1, updatedData)
        setPropertiesToPopulate(properties)
    }

    const addProperty = async () => {
        /*var prop = allProperties
        prop.push(properties)
        setAllProperties(prop)
        setRandomNumber(randomNumber())*/
        setPropertiesToPopulate((prevState) => {
            return [...prevState, { type: '', name: '', key: randomNumber() }]
        })
    }

    const removeProperty = async (index) => {
        /*let old = allProperties
        let newArray = old.filter((value, key) => {
            if (key !== index) {
                return value
            }
        })
        await setAllProperties(newArray)
        setRandomNumber(randomNumber())*/
        const properties = JSON.parse(JSON.stringify(propertiesToPopulate));
        properties.splice(index, 1)
        setPropertiesToPopulate(properties)
    }

    const randomNumber = () => {
        var min = 1;
        var max = 100;
        var rand = min + (Math.random() * (max - min));
        return rand
    }

    const saveForm = async () => {
        /*
        let properties = Properties
        // console.log("ssaveprop",properties)
        setEmpty('')
        properties.map((value,key)=>{
            if(value.name === '' || value.type === '' ){
                setEmpty('Please enter the details!')
                setIsSaveProperty(false)
                return
            }
            else{
                // $('#saveDataPro').click()
                // setProperties(Properties) 
                setIsSaveProperty(true)
            }
        })
        // await setProperties(Properties)    
        */
        const alteredProperties = propertiesToPopulate.map(e => ({ type: e['type'], name: e['name'] }))
        returnProperty(alteredProperties);
        $('#saveDataPro').click()
    }

    const saveLavleForm = () => {
        /*var level = levels
        setEmpty('')
        level.map((value,key)=>{
            if(value.name === '' ){
                setEmpty('Please enter the details!')
                setIsSaveLevel(false)
            }
            else{
                $('#levelPoup').click()
                setIsSaveLevel(true)
            }
        })
        saveAllLevels(level)*/
        const alteredLevels = levelsToPopulate.map(e => ({ name: e['name'], value_one: e['value_one'], value_two: e['value_two'] }))
        returnLabel(alteredLevels);
        $('#levelPoup').click()
    }

    const handelLevel = async (data, index) => {
        /*
        var all = levels
        var newData = ({ ...all[index], ...data })
        all[index] = newData
        await setAllLevels(all)
        */
        const levels = JSON.parse(JSON.stringify(levelsToPopulate));
        const updatedData = {
            ...levels[index],
            ...data
        }
        levels.splice(index, 1, updatedData)
        setLevelsToPopulate(levels)
    }

    const addLevel = async () => {
        /*var lev = levels
        // console.log('lev',lev)
        lev.push(levelDefault)
        setAllLevels(lev)
        setRandomNumber(randomNumber())*/
        setLevelsToPopulate((prevState) => {
            return [...prevState, { name: '', value_one: 3, value_two: 5, key: randomNumber() }]
        })
    }

    const removeLevel = async (index) => {
        /*
        var old = levels
        var newArray = old.filter((value, key) => {
            if (key !== index) {
                return value
            }
        })
        await setAllLevels(newArray)
        setRandomNumber(randomNumber())
        */
        const levels = JSON.parse(JSON.stringify(levelsToPopulate));
        levels.splice(index, 1)
        setLevelsToPopulate(levels)
    }

    return (
        <div id="nftformpopup" className="NftmodelsPopupWrap">
            <div className="Nftmodel-popup-item">
                {/* <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#categoryModal">
                    Launch demo modal
                </button> */}
                <div className="modal fade" id="categoryModal" tabIndex={-1} role="dialog" aria-labelledby="categoryModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg category-modal" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="categoryModalLabel">CATEGORY</h5>
                            </div>
                            <div className="modal-body-description px-4 mt-5 pt-2"><p>SELECT ONE OR MORE CATEGORIES</p></div>
                            <div className="modal-body flex-column">
                                <div className="categories-lists-updates w-100 d-flex justify-content-around align-content-center">
                                    {/* {category && category.map((value, key) => (
                                        <div className="col-6 mb-3">
                                            <div className="categoty-item">
                                                <div className="d-flex align-items-center justify-content-start gap-18 ml-4 py-3">
                                                    <label className="switch switch-radio-class">
                                                        <input onClick={((event, status) => selectCategory(value.id, event.target.checked))} type="checkbox" />
                                                        <span className="slider round" />
                                                    </label>
                                                    <h4>{value.get('name')}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    ))} */}
                                    {
                                        categoriesToPopulate && categoriesToPopulate.map((cat, index) => {
                                            // {console.log("category",category)}

                                            // {console.log("categorypit",cat,"categoryID",categoryID)}
                                            // checked={xyz.includes('xyzz')}
                                            return (
                                                <div className="col-6 mb-3" key={index}>
                                                    <div className="categoty-item">
                                                        <div className="d-flex align-items-center justify-content-start gap-18 ml-4 py-3">
                                                            <label className="switch switch-radio-class">
                                                                <input onChange={((event, status, name) => checkCategory(cat['_id'], event.target.checked))} type="checkbox"
                                                                    checked={cat.isSelected ? 'checked' : ''}
                                                                    className="__category-item-list" />
                                                                <span className="slider round" />
                                                            </label>
                                                            <h4>{cat.name}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {/* <div className="categories-lists-updates w-100 d-flex justify-content-around align-content-center">
                                    <div className="col-6 mb-3">
                                        <div className="categoty-item">
                                            <div className="d-flex align-items-center justify-content-start gap-18 ml-4">
                                                <label className="switch switch-radio-class">
                                                    <input  onClick={((event)=>event.target.checked ? selectCategory('art'):'')} type="checkbox" />
                                                    <span className="slider round" />
                                                </label>
                                                <h4>Cards</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 mb-3">
                                        <div className="categoty-item">
                                            <div className="d-flex align-items-center justify-content-start gap-18 ml-4">
                                                <label className="switch switch-radio-class">
                                                    <input  onClick={((event)=>event.target.checked ? selectCategory('art'):'')} type="checkbox" />
                                                    <span className="slider round" />
                                                </label>
                                                <h4>Domains</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="categories-lists-updates w-100 d-flex justify-content-around align-content-center">
                                    <div className="col-6 mb-3">
                                        <div className="categoty-item">
                                            <div className="d-flex align-items-center justify-content-start gap-18 ml-4">
                                                <label className="switch switch-radio-class">
                                                    <input  onClick={((event)=>event.target.checked ? selectCategory('art'):'')} type="checkbox" />
                                                    <span className="slider round" />
                                                </label>
                                                <h4>Utility</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 mb-3">
                                        <div className="categoty-item">
                                            <div className="d-flex align-items-center justify-content-start gap-18 ml-4">
                                                <label className="switch switch-radio-class">
                                                    <input  onClick={((event)=>event.target.checked ? selectCategory('art'):'')} type="checkbox" />
                                                    <span className="slider round" />
                                                </label>
                                                <h4>Film-Animation</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" style={{ display: 'none' }} id="saveCollectionPro" className="btn btn-primary" data-dismiss="modal">Save</button>
                                <button type="button" onClick={() => saveCategoryForm()} className="btn btn-primary" >Save</button>
                                {/* <button type="button" className="btn btn-primary" data-dismiss="modal">SAVE</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Nftmodel-popup-item">
                {/* <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#categoryModal">
                    Launch demo modal
                </button> */}
                <div className="modal fade" id="propertyModal" tabIndex={-1} role="dialog" aria-labelledby="propertyModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg properties-modal" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="propertyModalLabel">PROPERTIES</h5>
                            </div>
                            <div className="modal-body-description px-4 my-4">
                                <h4>ADD PROPERTIES</h4>
                                <p className="mb-4 mt-4">Properties show up underneath your item, are clickable, and can be filtered in your collection's sidebar.</p>
                            </div>

                            {propertiesToPopulate?.map((value, key) => (
                                <div className="modal-body d-flex position-relative" key={value.key}>
                                    <div className="modal-proprty-inputs row w-100">
                                        <div className="modal-proprty-inputs-item flex-column col-5">
                                            <label>TYPE</label>
                                            <input defaultValue={value.type} onChange={(event) => handelProperty({ type: event.target.value }, key)} type="text" placeholder="CHARACTER" />
                                        </div>
                                        <div className="modal-proprty-inputs-item flex-column col-6">
                                            <label>NAME</label>
                                            <input defaultValue={value.name} onChange={(event) => handelProperty({ name: event.target.value }, key)} type="text" placeholder="MALE" />
                                        </div>
                                        <div className="modal-proprty-inputs-item flex-column col-1">

                                            {propertiesToPopulate.length > 1 ? <button className="delete-btn" onClick={(() => removeProperty(key))}> <img src={delete_icon}></img></button> : ''}
                                        </div>
                                    </div>

                                </div>
                            ))}
                            <div className="modal-body d-flex">
                                <div className="modal-proprty-inputs d-flex w-100 justify-content-between align-items-center gap-18">
                                    <button onClick={() => addProperty()} type="button" className="btn btn-secondary addmoreformbtn">+ Add More</button>
                                </div>
                            </div>
                            <div>{checkEmpty}</div>
                            <div className="modal-footer justify-content-center">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" style={{ display: 'none' }} id="saveDataPro" className="btn btn-primary" data-dismiss="modal">Save</button>
                                <button type="button" onClick={() => saveForm()} className="btn btn-primary" >Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Nftmodel-popup-item">
                {/* <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#categoryModal">
                    Launch demo modal
                </button> */}
                <div className="modal fade" id="levelsModal" tabIndex={-1} role="dialog" aria-labelledby="levelsModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg levels-modal" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="levelsModalLabel">LEVELS</h5>
                            </div>
                            <div className="modal-body-description px-4 my-4">
                                <h4>ADD LEVELS</h4>
                                <p className="mb-4 mt-4">Levels show up underneath your item, are clickable, and can be filtered in your collection's sidebar.</p>
                            </div>
                            {levelsToPopulate?.map((value, key) => (
                                <div className="modal-body d-flex position-relative" key={value.key}>
                                    <div className="modal-proprty-inputs row w-100">
                                        <div className="modal-proprty-inputs-item flex-column col-6">
                                            <label>NAME</label>
                                            <input onChange={((event) => handelLevel({ name: event.target.value }, key))} type="text" defaultValue={value.name} placeholder="SPEED" />
                                        </div>
                                        <div className="modal-proprty-inputs-item flex-column col-5">
                                            <label>Value</label>
                                            <div className="nft-input-number-right-border-0 d-flex justify-content-between align-items-center">
                                                <input onChange={((event) => handelLevel({ value_one: event.target.value }, key))} defaultValue={value.value_one} type="number" placeholder="0" />
                                                <h3 className="of-bg-effect">OF</h3>
                                                <input onChange={((event) => handelLevel({ value_two: event.target.value }, key))} defaultValue={value.value_two} type="number" placeholder="0" />
                                            </div>
                                        </div>
                                        <div className="modal-proprty-inputs-item flex-column col-1">

                                            {levelsToPopulate.length > 1 ? <button className="delete-btn" onClick={(() => removeLevel(key))}> <img src={delete_icon}></img></button> : ''}
                                        </div>
                                    </div>
                                    {/* {key > 0 ? <button className="deleteRepeateritems" onClick={() => removeLevel(key)}>Delete</button> : ''} */}
                                </div>
                            ))}
                            <div className="modal-body d-flex">
                                <div className="modal-proprty-inputs d-flex w-100 justify-content-between align-items-center gap-18">
                                    <button onClick={() => addLevel()} type="button" className="btn btn-secondary addmoreformbtn" >+Add More</button>
                                </div>
                            </div>
                            <div>{checkEmpty}</div>
                            <div className="modal-footer justify-content-center">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" style={{ display: 'none' }} data-dismiss="modal" className="btn btn-primary" id='levelPoup' >Save</button>
                                <button type="button" className="btn btn-primary" onClick={() => saveLavleForm()}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

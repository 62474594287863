import React, { useState, useEffect } from "react";

export default function FollowerList({ list }) {
  return (
    <>
      <div className="connect-wallet-box-main make-offer-main">
        <h2 className="main-heading">Follower List</h2>
        <div className="wallet-infobox px-4">
          <div>
            <div className="container">
                  <div className="row items">
                      <div className="col-12 col-md-12 col-lg-12" style={{"overflowY": "scroll", "maxHeight": "400px"}}>
                        {
                          list.length ? 
                            <ul className="lunanft-tab owner-list nav nav-tabs" id="nav-tab">
                                {
                                  list.map((e, key) => {
                                    return (
                                      <li key={key}>
                                        <div className="owner-list-user">
                                          <div className="owner-name">{e?.from_user_id?.username}</div>
                                          <div className="owner-address">{e?.from_user_id?.wallet_address}</div>
                                        </div>
                                      </li>
                                    )
                                  })
                                }
                            </ul> : <div className="col-12 col-md-12 col-lg-12 text-center mt-3">No data found!</div>
                        } 
                      </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { USER_ACTION_TYPES } from "./userAction";

export const initState = {
  cards: [],
};

function userReducer(state = initState, action) {
  switch (action.type) {
    case USER_ACTION_TYPES.CARD.GET_CARDS:
      return {
        ...state,
        cards: action.payload,
      };

    default:
      return state;
  }
}

export default userReducer;

import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Checkbox, notification } from "antd";
import { SmileOutlined, CloseOutlined } from "@ant-design/icons";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import Select from "react-select";
import { chainImageHandler } from '../../utils/helper';

import categoryIcon from '../../image/nft-form/Groupnft-form-icon-1.png'
import sensitiveIcon from '../../image/nft-form/Groupnft-form-icon-5.png'
import GrouppopUpplus from '../../image/nft-form/GrouppopUpplus.png'
import NftFormPopups from '../../themes/NftFormPopups'
import previewIcon from '../../image/previewIcon.png'
import Userimage from '../../image/userImage_wallet.png'
import private_wallet from '../../image/private_wallet.png'
import metamask__wallet from '../../image/metamask__wallet.png'
import EGC_Logo_wallet from '../../image/EGC_Logo_wallet.png'
import coinBase_wallet from '../../image/coinBase_wallet.png'
import Loader from '../../image/loader.gif'
import delete_icon from '../../image/delete-icon.png';

import twitter_icon from '../../image/twitter-icon.png';
import fb_icon from '../../image/fb-icon.png';
import discord_icon from '../../image/discord-icon.png';
import insta_icon from '../../image/insta-icon.png';
import search_link_icon from '../../image/search-link-icon.png';
import binancePriceImage from '../../image/networkPrice/binance.jpg';
import maticPriceImage from '../../image/networkPrice/matic.jpg';
import ethereumPriceImage from '../../image/networkPrice/ethereum.jpg';

//Custom Hooks
import useMedia from '../../hooks/useMedia'
import useCollections from '../../hooks/useCollection'
import useNetworks from '../../hooks/useNetwork'
import CollectionsServices from '../../api/CollectionsServices';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { useForm } from "react-hook-form";
import { PERCENTAGE_VALUE, TokenStandard } from '../../utils/app.constant'
import Web3 from 'web3';
import { set } from 'lodash';



function CreateCollectionsPage({ auth, islogin }) {
    const [walletVisibility, setwalletVisibility] = useState(false);
    const [allFile, setAllFile] = useState(null);
    const [featureImage, setFeatureImage] = useState(null);
    const [bannerImage, setBannerImage] = useState(null);
    const [logoSrc, setLogoSrc] = useState(previewIcon)
    const [featurSrc, setFeaturSrc] = useState(previewIcon)
    const [bannerSrc, setBannerSrc] = useState(previewIcon)
    const [isSxplicity, setIsSxplicity] = useState(false);
    const [categoryError, setCategoryError] = useState('')
    // const [category, setCategory] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [isSaveProperty, setIsSaveProperty] = useState(false)
    const [isSaveLevel, setIsSaveLevel] = useState(false)
    // const [categoryName, setCategoryName] = useState([])
    const [loading, setLoading] = useState(false)
    const [fileType, setFileTypeError] = useState('')
    const [cratorFeesError, setCratorFeesError] = useState('')
    const [allLinks, setAllLinks] = useState({
        link1: "",
        link2: "",
        link3: "",
        link4: "",
        link5: "",
    });
    const [networkList, setNetworkList] = useState([])
    const [selectedNetwork, setSelectedNetwork] = useState('')
    const [addressField, setAddressField] = useState([])
    const [imageAdd, setImageAdd] = useState(false);
    const [featureAdd, setFeatureAdd] = useState(false);
    const [bannerAdd, setBannerAdd] = useState(false);

    // react hooks form
    const [indexes, setIndexes] = React.useState([]);
    const [counter, setCounter] = React.useState(0);
    const { register, handleSubmit } = useForm();
    const [errorIndex, setErrorIndex] = React.useState([]);
    const [percentageError, setPercentageError] = useState(false);
    const [showPercentahe, setShowPercentage] = useState(0);

    //Import custom hooks
    const { FileUpload } = useMedia()
    const { create } = useCollections()
    const { getNetworkList } = useNetworks()
    const history = useHistory()

    const payoutAddress = [{ wallet_address: '', percentage: 0 }];
    const addressDefault = { wallet_address: '', percentage: 0 };

    const [allPayout, setAllPayout] = useState(payoutAddress)
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const formatCharsAdd = {
        "*": "^[A-Za-z0-9_@./#&+-]*$"
    }

    /*useEffect(() => {

    }, [category, isSaveProperty, isSaveLevel, categoryName])*/

    useEffect(() => {
        
    }, [auth])

    useEffect(() => {
        getNetwork();
    }, []);

    const getNetwork = async () => {
        // const fetchNetwork = await CollectionsServices.NetworkList()
        let fetchNetwork = await getNetworkList()
        // console.log('fetch network list', fetchNetwork);
        let netWorkList = fetchNetwork.networks.map(x => {
            x.image = x.network == 'binance' ? binancePriceImage : x.network == 'polygon' ? maticPriceImage : x.network == 'ethereum' ? ethereumPriceImage : ''
            return x;
        });
        setNetworkList(netWorkList);  //fetchNetwork.networks
    }

    const explicitData = async (data) => {
        await setIsSxplicity(data);
    };
    /*const selectCategory = async (newCate, status, name) => {
        setLoading(true)
        if (!status) {
            var old = category.filter((value) => value !== newCate)
            var cate = categoryName.filter((valueName) => valueName !== name)
            await setCategory(old)
            setCategoryName(cate)
        } else {
            var old = category
            var cateName = categoryName
            cateName.push(name)
            old.push(newCate)
            await setCategory(old)
            await setCategoryName(cateName)
        }
        setLoading(false)
    }*/

    const selectCategory = async (categories) => {
        setSelectedCategories(categories)
        if (categories.length > 0) {
            setCategoryError('')
        } else {
            setCategoryError('Please select collection category!')
        }
    };

    const reInitModal = () => {
        setSelectedCategories(JSON.parse(JSON.stringify(selectedCategories)))
    }


    const getLiks = (event, type) => {

        let oldLink = { ...allLinks };
        if (type === "link_1") {
            oldLink["link1"] = event;
        }
        if (type === "link_2") {
            oldLink["link2"] = event;
        }
        if (type === "link_3") {
            oldLink["link3"] = event;
        }
        if (type === "link_4") {
            oldLink["link4"] = event;
        }
        if (type === "link_5") {
            oldLink["link5"] = event;
        }
        setAllLinks(oldLink);
    };

    const fileUpload = async (e, type) => {
        const reader = new FileReader();
        if (type === "main") {
            if (e.target.files && e.target.files.length > 0) {
                if (5100000 < e.target.files[0].size) {
                    setFileTypeError('You can upload 5MB size file!')
                    return
                }
                if (!e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
                    setFileTypeError('Not valid file!, Please upload (jpef,jpg,png,gif) file type')
                    return
                }
                setLogoSrc(Loader)
                var form = new FormData()
                form.append('image', e.target.files[0])
                var fileRes = await FileUpload(form)
                reader.addEventListener("load", () => setLogoSrc(reader.result));
                setImageAdd(true);
                await setAllFile(fileRes);
            }
        } else if (type === "feature") {
            if (e.target.files && e.target.files.length > 0) {
                setFeaturSrc(Loader)
                var form = new FormData()
                form.append('image', e.target.files[0])
                var fileRes = await FileUpload(form)
                reader.addEventListener("load", () => setFeaturSrc(reader.result));
                setFeatureAdd(true)
                await setFeatureImage(fileRes);
            }
        } else if (type === "banner") {
            if (e.target.files && e.target.files.length > 0) {
                setBannerSrc(Loader)
                var form = new FormData()
                form.append('image', e.target.files[0])
                var fileRes = await FileUpload(form)
                reader.addEventListener("load", () => setBannerSrc(reader.result));
                setBannerAdd(true);
                await setBannerImage(fileRes);
            }
        }
        reader.readAsDataURL(e.target.files[0]);
    };

    const checkCratorFess = (value) => {
        setCratorFeesError('')
        if (parseFloat(value) > 10) {
            setCratorFeesError('Creator fees should be less than 10!')
            return
        }
        if (parseFloat(value) < 1) {
            setCratorFeesError('Creator fees should be greater than 0')
            return
        }
        if (parseFloat(value) === '') {
            setCratorFeesError('Please enter creator fees!')
            return
        }
        // setCratorFeesError
    }


    const onFinish = async (data) => {
        checkCratorFess(data.creator_fee)
        setCategoryError('')
        if (selectedCategories.length === 0) {
            setCategoryError('Please select collection category!')
            return
        }
        // console.log('errIndex', errorIndex);
        if (errorIndex.length > 0 && errorIndex !== undefined) {
            if (errorIndex.indexOf(false) > -1 || percentageError) {
                return;
            }
        }
        let { user } = auth
        data.banner_image = bannerImage['actual_file_path'];
        data.banner_resized_image = bannerImage['resized_path'] || null;
        data.featured_image = featureImage['actual_file_path'];
        data.featured_resized_image = featureImage['resized_path'] || null;
        data.logo_image = allFile['actual_file_path'];
        data.logo_resized_image = allFile['resized_path'] || null;
        data.user_id = user._id;
        data.allLinks = allLinks;
        data.explicity = isSxplicity;
        data.categories_id = selectedCategories.map(e => e['_id']);
        data.username = user.username ? user.username : user._id;
        // data.slug = data.collection_name.toLowerCase().replace(/\s+/, "");
        data.token_standard = TokenStandard[1];
        data.network = selectedNetwork;
        data.payout = allPayout;

        if (await create(data)) {
            notification.open({
                message: "Collcetion",
                description: "Collection successfully created!",
                icon: <SmileOutlined style={{ color: "#108ee9" }} />,
            });

            setAllLinks({ link1: "", link2: "", link3: "", link4: "", link5: "" })
            setSelectedNetwork('')
            const clist = document.getElementsByClassName("__category-item-list");

            for (const el of clist) {
                el.checked = false;
            }
            setIsSxplicity(false)
            // await setCategory([])
            setSelectedCategories([])
            await setLogoSrc(previewIcon)
            await setFeaturSrc(previewIcon)
            await setBannerSrc(previewIcon)
            setImageAdd(false);
            setFeatureAdd(false);
            setBannerAdd(false);
            document.getElementById("collectionForm").reset();
            history.push(`/profile/${user?.username}`)
            setIndexes([]);
            setErrorIndex([]);
        } else {
            notification.open({
                message: "Error",
                description: "Something went wrong...!",
                icon: <CloseOutlined style={{ color: "#f4364c" }} />,
            });
        }
    };



    const connectWithMetaMask = async () => {
        // if (!islogin) {
        //     await authenticate({ signingMessage: "Log in using Moralis" })
        //         .then(function (user) {
        //             Moralis.User.currentAsync().then(async (user) => {
        //                 const response = await findOne("Collections", "address", user.get('ethAddress'))
        //                 if (response.length > 0) {
        //                     console.log("metamask", response);
        //                 }
        //                 setwalletVisibility(false)
        //             });
        //         })
        //         .catch(function (error) {
        //             console.log(error);
        //         });
        // }
    }
    const connectWithWallet = async () => {
        // if (!islogin) {
        //     await authenticate({
        //         provider: "walletconnect",
        //         mobileLinks: [
        //             "rainbow",
        //             "metamask",
        //             "argent",
        //             "trust",
        //             "imtoken",
        //             "pillar",
        //         ]
        //     })
        //         .then(async (user) => {
        //             console.log("logged in user:", user);
        //             Moralis.User.currentAsync().then(async (user) => {
        //                 const response = await findOne("Collections", "address", user.get('ethAddress'))
        //                 if (response.length >= 0) {
        //                     console.log("wallet data return", response);
        //                     // const mintwallet = {
        //                     //     crator_name: 'Demo User',
        //                     //     collection_name: 'Demo Collection',
        //                     //     images: [
        //                     //         'https://thumbor.forbes.com/thumbor/fit-in/900x510/…m/advisor/wp-content/uploads/2021/04/NFT.jpeg.jpg',
        //                     //         '	https://s3-prod.adage.com/s3fs-public/20220122_twitter_NFT_profile_images_3x2c.jpg'
        //                     //     ],
        //                     //     description: '',
        //                     //     category: 'Demo Category',
        //                     //     address: user.get('ethAddress')
        //                     // }
        //                     // const saveResponse = await saveData('Collections', mintwallet)
        //                 }
        //             });
        //             await setwalletVisibility(false)
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //         });
        // }
    }

    const walletShowhide = () => {
        if (walletVisibility === false) {
            setwalletVisibility(true);
        } else {
            setwalletVisibility(false);
        }
    }

    const getNetworks = (value) => {
        // console.log('select network', value);
        setSelectedNetwork(value);
    }


    const onSubmit = data => {
        return
    };
    const handleChange = e => {
        // console.log("e", e)
        getNetworks(e._id);
    }

    const addAddress = async () => {
        setIndexes(prevIndexes => [...prevIndexes, counter]);
        setCounter(prevCounter => prevCounter + 1);
        var prop = payoutAddress;
        prop.push(addressDefault)
        // await setAllPayout(prop)
        // console.log('index', prop);
    };

    const isInputNumber = (e) => {
        let ch = String.fromCharCode(e.which);
        if (!/[0-9]/.test(ch)) {
            e.preventDefault();
        }
    };


    const handelLevel = async (data, index) => {
        var all = allPayout
        var newData = ({ ...all[index], ...data })
        all[index] = newData
        let totalPercentage = 0;
        // let allWalletAddress = ''
        all.map((item, id) => {
            if (item?.wallet_address && item.wallet_address !== undefined) {
                if (!Web3.utils.isAddress(item?.wallet_address)) {       // web3 address validation
                    errorIndex[id] = false;
                } else {
                    errorIndex[id] = true;
                }
            }
            if (item?.percentage && item?.percentage !== undefined) {
                totalPercentage = totalPercentage + item?.percentage
            }

        })
        if (totalPercentage > PERCENTAGE_VALUE) {
            setPercentageError(true);
        } else {
            setPercentageError(false);
        }
        setShowPercentage(totalPercentage)
        // console.log('total', totalPercentage)
        forceUpdate()
        await setAllPayout(all);
    }

    const removeFriend = (index, id) => async () => {
        setIndexes((prevIndexes) => [...prevIndexes.filter((item) => item !== index)]);
        setCounter((prevCounter) => prevCounter - 1);
        let totalPercentage = 0;
        var old = allPayout
        var newArray = old.filter((value, key) => {
            if (key !== id) {
                return value
            }
        }).
            map((item, id) => {
                if (item?.percentage) {
                    totalPercentage = totalPercentage + item?.percentage
                }
            });
        if (totalPercentage > PERCENTAGE_VALUE) {
            setPercentageError(true);
        } else {
            setPercentageError(false);
        }
        setShowPercentage(totalPercentage)

        // console.log('remove', old, newArray);
        var errArray = errorIndex.filter((item, errId) => {
            if (errId !== id) {
                return item
            }
        })
        await setErrorIndex(errArray);
        await setAllPayout(newArray)
    };

    let reactHookForm = () => {
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                {indexes.map((index, key) => {
                    const fieldName = `address[${index}]`;
                    return (
                        <fieldset name={fieldName} key={fieldName}>
                            <div className='row reverse-row'>
                                <div className='col-8'>
                                    <div className='form-group'>
                                        <input type='text' placeholder='Please enter an address..' name={`${fieldName}.address`} onChange={((event) => handelLevel({ wallet_address: event.target.value }, key))} style={{ maxWidth: "100%" }} className="nft-form-control"></input>
                                        {/* {console.log('err', key, errorIndex[key])} */}
                                        {!errorIndex[key] && errorIndex[key] !== undefined ? (
                                            <div role='alert' className="ant-form-item-explain-error">
                                                Please enter valid address.
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className='form-group'>
                                        <div className='percentage-field'>
                                            <span>%</span>
                                            <input style={{ maxWidth: "100%" }} name={`${fieldName}.percentage`} onChange={((event) => handelLevel({ percentage: +event.target.value }, key))} className="nft-form-control" type="text" onKeyPress={(e) => isInputNumber(e)}></input>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-1 add-address-delete'>
                                    <button className="delete-btn" type='button' onClick={removeFriend(index, key)}>
                                        <img src={delete_icon}></img>
                                    </button>
                                </div>
                            </div>
                        </fieldset>
                    );
                })}
                {indexes.length > 0 ? (
                    <div className='row total-show reverse-row'>
                        <div className='col-8'>
                            {percentageError ? (
                                <div role='alert' className="ant-form-item-explain-error percentage-error">
                                    Total creator earnings must not exceed {PERCENTAGE_VALUE}%.
                                </div>) : ''
                            }
                        </div>
                        <div className='col-2 text-right'>
                            <h5 className='total-percent'>Total <span>{showPercentahe}%</span></h5>
                        </div>
                        <div className='col-1 text-center'>
                            <h6></h6>
                        </div>
                    </div>
                ) : null}
            </form>
        );
    }



    return (
        <>
            <div className="games__wrap">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-1 mx-0 px-0 d-none"></div>
                        <div className="col-2 row align-items-end flex-column text-left d-none"></div>
                        <div className="col-9 p-0 form-transp-bg">
                            <h3 className="oracleTextClass" style={{}}>Collection</h3>
                            {!islogin ?
                                <><p className="connect-wallet-message">*Please connect your wallet to continue.</p>
                                <button data-toggle="modal" data-target="#exampleModalCenter" className="connect-wallet-btn">Connect Your Wallet</button></> :

                                <Form
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{
                                        span: 16,
                                    }}
                                    initialValues={{
                                        remember: true,
                                    }}
                                    className="item-form createNftForm  no-hover"
                                    onFinish={onFinish}
                                    autoComplete="off"
                                    id="collectionForm"
                                >
                                    <div className="row">
                                        {/* <div className="col-12">
                                            <h3 className="connect-wallet-btn" onClick={walletShowhide}>CONNECT WALLET</h3>
                                        </div> */}
                                        <div className="col-12">
                                            <p className="required-text"><span className="required-class">*</span> Required Fields</p>
                                            {fileType !== '' ? <div role="alert" className="ant-form-item-explain-error">{fileType}</div> : ''}
                                            <Form.Item
                                                name="logo_image"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input file!",
                                                    },
                                                ]}
                                            >
                                                <div className="input-group form-group mt-4">
                                                    <div className="custom-file-old">
                                                        <label className="image-upload-lbl">Logo Collection Image <span className="required-class">*</span> </label>
                                                        <p>This image will also be used for navigation. {" "}(File types supported: jpg, jpeg, png, gif. Recommended Size: 350 x 350 px ){" "}

                                                        </p>
                                                        <label className="custom-file-label-old" htmlFor="inputGroupFile01">
                                                            <div className={`${imageAdd ? "image-preview-box coll-image add-image" : "image-preview-box coll-image"}`}>
                                                                <img src={logoSrc} />
                                                            </div>
                                                        </label>
                                                        <input
                                                            name="image"
                                                            type="file"
                                                            onChange={(event) => fileUpload(event, "main")}
                                                            className="custom-file-input-none"
                                                            id="inputGroupFile01"
                                                        />
                                                    </div>
                                                    <button className="create-btn new-upload-btn mt-3 mt-sm-4 mb-5" type="button">
                                                        <label for="inputGroupFile01">Upload Logo</label>
                                                    </button>
                                                </div>
                                            </Form.Item>
                                        </div>

                                        {/* <div className="col-12">
                                                    <div className="form-group mt-3">
                                                        <label>Item name* </label>
                                                        <input type="text" className="nft-form-control" name="item_name" />
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="form-group mt-3">
                                                        <label>Minter Wallet Address </label>
                                                        <input type="text" className="nft-form-control" name="item_name" />
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="form-group mt-3">
                                                        <label>Creator Name </label>
                                                        <input type="text" className="nft-form-control" name="item_name" />
                                                    </div>
                                                </div> */}

                                        <Form.Item
                                            name="featured_image"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please input file!",
                                                },
                                            ]}
                                        >
                                            <div className="col-12">
                                                <div className="input-group form-group mt-4">
                                                    <div className="custom-file-old">
                                                        <label className="image-upload-lbl">Feature Image <span className="required-class">*</span></label>
                                                        <p>(File types supported: jpg, jpeg, png, gif, Recommended Size: 180 x 180 px )</p>
                                                        <label className="custom-file-label-old" htmlFor="inputGroupFile02">
                                                            <div className={`${featureAdd ? "image-preview-box banner-coll add-feature" : "image-preview-box banner-coll"}`}>
                                                                <img src={featurSrc} />
                                                            </div>
                                                        </label>
                                                        <input
                                                            name="feature_image"
                                                            type="file"
                                                            onChange={(event) => fileUpload(event, "feature")}
                                                            className="custom-file-input-none"
                                                            id="inputGroupFile02"
                                                        />
                                                    </div>
                                                    <button className="create-btn new-upload-btn mt-3 mt-sm-4 mb-5" type="button">
                                                        <label for="inputGroupFile02">Upload Feature Image</label>
                                                    </button>
                                                </div>
                                            </div>
                                        </Form.Item>
                                        <Form.Item
                                            name="banner_image"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please input file!",
                                                },
                                            ]}
                                        >
                                            <div className="col-12">
                                                <div className="input-group form-group mt-4">
                                                    <div className="custom-file-old">
                                                        <label className="image-upload-lbl">Banner Collection Image <span className="required-class">*</span></label>
                                                        <p>This image will appear at the top of your collection page. Avoid including too much text in this banner, as the dimensions change on different devices. 1400 x 1400 px recommended. {" "}(File types supported: jpg, jpeg, png, gif, Recommended Size: 1000 x 430 px ){" "}</p>
                                                        <label className="custom-file-label-old" htmlFor="inputGroupFile03">
                                                            <div className={`${bannerAdd ? "image-preview-box banner-coll-image add-banner" : "image-preview-box banner-coll-image"}`}>
                                                                <img src={bannerSrc} />
                                                            </div>
                                                        </label>
                                                        <input
                                                            name="banner_image"
                                                            type="file"
                                                            onChange={(event) => fileUpload(event, "banner")}
                                                            className="custom-file-input-none"
                                                            id="inputGroupFile03"
                                                        />
                                                    </div>
                                                    <button className="create-btn new-upload-btn mt-3 mt-sm-4 mb-5" type="button">
                                                        <label for="inputGroupFile03">Upload Banner Image</label>
                                                    </button>
                                                </div>
                                            </div>
                                        </Form.Item>

                                        <div className="col-12">
                                            <div className="form-group mt-3">
                                                <label>Collection Name <span className="required-class">*</span></label>
                                                <Form.Item
                                                    name="collection_name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please input file!",
                                                        },
                                                    ]}
                                                >
                                                    <input
                                                        type="text"
                                                        className="nft-form-control"
                                                        name="collection_name"
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="form-group mt-3">
                                                <label>External link </label>
                                                <p>
                                                    LunaSky will included a link you can share and promote your
                                                    NFT. You can link your own webpage.
                                                </p>
                                                <Form.Item
                                                    name="external_link"
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "Please input External link!",
                                                        },
                                                    ]}
                                                >
                                                    <input
                                                        type="url"
                                                        className="nft-form-control"
                                                        name="external_link"
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="form-group mt-3">
                                                <label>Description <span className="required-class">*</span></label>
                                                <p>This will be included on your item's detail.</p>
                                                <Form.Item
                                                    name="description"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please input description!",
                                                        },
                                                    ]}
                                                >
                                                    <textarea name="description"></textarea>
                                                </Form.Item>
                                            </div>
                                        </div>


                                        <div className="nftform-action-item col-12 d-flex justify-content-between align-items-baseline">
                                            <div className="d-flex justify-content-between align-items-center">
                                                {/* <img className="imgw-30" src={categoryIcon} /> */}
                                                <div className="flex-column justify-content-between align-items-baseline">
                                                    <h3><i className="fa fa-th-list"></i> Select one or more categories*</h3>
                                                    <div style={{ color: 'red' }}>{categoryError}</div>
                                                    {!loading && selectedCategories.length > 0 ? selectedCategories.map((cat, key) => (
                                                        <span key={key}>{cat.name},</span>
                                                    ))
                                                        : ''} {selectedCategories.length > 0 ? '.etc' : ''}
                                                </div>
                                            </div>
                                            <div className="rounded-circle-bg d-flex align-items-center justify-content-center">
                                                <img className="imgw-30 cursor-pointer" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#categoryModal" src={GrouppopUpplus} onClick={reInitModal} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group mt-3">
                                                <label>Links</label>
                                                <Form.Item
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "Please input file!",
                                                        },
                                                    ]}
                                                >
                                                    <div className="links-nft-form-item d-flex justify-content-between align-items-center position-relative">
                                                        <div className="links-icons">
                                                            <svg className="CollectionForm--link-logo" fill="#8A939B" viewBox="1 1 22 22"><path clip-rule="evenodd" d="M2 12C2 6.48 6.47 2 11.99 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 11.99 22C6.47 22 2 17.52 2 12ZM15.97 8H18.92C17.96 6.35 16.43 5.07 14.59 4.44C15.19 5.55 15.65 6.75 15.97 8ZM12 4.04C12.83 5.24 13.48 6.57 13.91 8H10.09C10.52 6.57 11.17 5.24 12 4.04ZM4 12C4 12.69 4.1 13.36 4.26 14H7.64C7.56 13.34 7.5 12.68 7.5 12C7.5 11.32 7.56 10.66 7.64 10H4.26C4.1 10.64 4 11.31 4 12ZM5.08 16H8.03C8.35 17.25 8.81 18.45 9.41 19.56C7.57 18.93 6.04 17.66 5.08 16ZM5.08 8H8.03C8.35 6.75 8.81 5.55 9.41 4.44C7.57 5.07 6.04 6.34 5.08 8ZM12 19.96C11.17 18.76 10.52 17.43 10.09 16H13.91C13.48 17.43 12.83 18.76 12 19.96ZM9.5 12C9.5 12.68 9.57 13.34 9.66 14H14.34C14.43 13.34 14.5 12.68 14.5 12C14.5 11.32 14.43 10.65 14.34 10H9.66C9.57 10.65 9.5 11.32 9.5 12ZM14.59 19.56C15.19 18.45 15.65 17.25 15.97 16H18.92C17.96 17.65 16.43 18.93 14.59 19.56ZM16.5 12C16.5 12.68 16.44 13.34 16.36 14H19.74C19.9 13.36 20 12.69 20 12C20 11.31 19.9 10.64 19.74 10H16.36C16.44 10.66 16.5 11.32 16.5 12Z" fill-rule="evenodd" id="icon/action/language_24px"></path></svg>
                                                            {/* <img src={search_link_icon}></img> */}
                                                        </div>
                                                        <input
                                                            onChange={(event) =>
                                                                getLiks(event.target.value, "link_1")
                                                            }
                                                            type="text"
                                                            className="nft-form-control"
                                                            placeholder="www.website.com"
                                                            value={allLinks?.link1 || ''}

                                                        />

                                                    </div>
                                                    <div className="links-nft-form-item d-flex justify-content-between align-items-center position-relative">
                                                        <div className="links-icons"><img src={discord_icon}></img></div>
                                                        {/* <input
                                                            onBlur={(event) =>
                                                                getLiks(event.target.value, "link_2")
                                                            }
                                                            type="text"
                                                            className="nft-form-control"
                                                            placeholder="www.discord.gg/test"
                                                            "*": "[A-Za-z0-9]"
                                                        /> */}
                                                        <InputMask
                                                            onBlur={(event) =>
                                                                getLiks(event.target.value, "link_2")
                                                            }
                                                            mask="www.discord.gg\/*************************************************************************************
                                                            ***********************************************************************************************************"
                                                            formatChars={formatCharsAdd}
                                                            maskChar=""
                                                            type="text"
                                                            className="nft-form-control"
                                                            placeholder="www.discord.gg/YourDiscordHandle"
                                                        />
                                                    </div>
                                                    <div className="links-nft-form-item d-flex justify-content-between align-items-center position-relative">
                                                        <div className="links-icons"><img src={insta_icon}></img></div>
                                                        {/* <input
                                                            onChange={(event) =>
                                                                getLiks(event.target.value, "link_3")
                                                            }
                                                            type="text"
                                                            className="nft-form-control"
                                                            placeholder="www.instagram.com/test"
                                                            value={allLinks?.link3 || ''}
                                                        /> */}
                                                        <InputMask
                                                            onChange={(event) =>
                                                                getLiks(event.target.value, "link_3")
                                                            }
                                                            mask="www.instagram.com\/*************************************************************************************
                                                            ***********************************************************************************************************"
                                                            formatChars={formatCharsAdd}
                                                            maskChar=""
                                                            type="text"
                                                            className="nft-form-control"
                                                            placeholder="www.instagram.com/YourInstagramHandle"
                                                            value={allLinks?.link3 || ''}
                                                        />
                                                    </div>
                                                    <div className="links-nft-form-item d-flex justify-content-between align-items-center position-relative">
                                                        <div className="links-icons">
                                                            <img src='/img/tiktok.png'></img>
                                                        </div>
                                                        {/* <input
                                                            onChange={(event) =>
                                                                getLiks(event.target.value, "link_4")
                                                            }
                                                            type="text"
                                                            className="nft-form-control"
                                                            placeholder="www.tiktok.com"
                                                            value={allLinks?.link4 || ''}
                                                        /> */}
                                                        <InputMask
                                                            onChange={(event) =>
                                                                getLiks(event.target.value, "link_4")
                                                            }
                                                            mask="www.tiktok.com\/*************************************************************************************
                                                            ***********************************************************************************************************"
                                                            formatChars={formatCharsAdd}
                                                            maskChar=""
                                                            type="text"
                                                            className="nft-form-control"
                                                            placeholder="www.tiktok.com/YourTiktokHandle"
                                                            value={allLinks?.link4 || ''}
                                                        />
                                                    </div>
                                                    <div className="links-nft-form-item d-flex justify-content-between align-items-center position-relative">
                                                        <div className="links-icons">
                                                            <img src={twitter_icon}></img>
                                                        </div>
                                                        {/* <input
                                                            onChange={(event) =>
                                                                getLiks(event.target.value, "link_5")
                                                            }
                                                            type="text"
                                                            className="nft-form-control"
                                                            placeholder="www.t.me"
                                                            value={allLinks?.link5 || ''}
                                                        /> */}
                                                        <InputMask
                                                            onChange={(event) =>
                                                                getLiks(event.target.value, "link_5")
                                                            }
                                                            mask="www.twitter.com\/*************************************************************************************
                                                            ***********************************************************************************************************"
                                                            formatChars={formatCharsAdd}
                                                            maskChar=""
                                                            type="text"
                                                            className="nft-form-control"
                                                            placeholder="www.twitter.com/YourTwitterHandle"
                                                            value={allLinks?.link5 || ''}
                                                        />
                                                    </div>
                                                </Form.Item>
                                            </div>
                                        </div>


                                        <div className="flex-column w-100 px-3 mb-4">
                                            <h3 className="title-inner">Blockchain</h3>
                                            <p className="mb-3 mt-1 small-para">Select the blockchain where you'd like new items from this collection to be added by default.</p>
                                            <div className="d-flex justify-content-start align-items-start gap-10 cstom-select-box">

                                                {/* <select value={selectedNetwork} onChange={((event) => getNetworks(event.target.value))}>
                                                    
                                                    <option value="">Select Network</option>
                                                    {networkList.length > 0 ? networkList.map((value, key) => (
                                                        <option key ={ key } value={ value._id } data-thumbnail="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/4H_Emblem.svg/1000px-4H_Emblem.svg.png">
                                                             {value.network}</option>
                                                    )) : ''}
                                            </select> */}
                                                {/* <option value="1" class="imagebacked" style="background-image: url(/images/icons/bug.gif);" selected>Bug</option> */}
                                            </div>
                                            <Select
                                                // getOptionLabel={option => option.chain_name}
                                                getOptionLabel={(opt) => (
                                                    <div className="d-flex align-items-center">
                                                        <img src={chainImageHandler(opt?.network)} height="35px" width="35px" />
                                                        <span className="d-block ml-2">
                                                            {opt?.chain_label}
                                                        </span>


                                                    </div>
                                                )}
                                                // value={selectedNetwork}
                                                getOptionValue={option => option._id}
                                                options={networkList}
                                                onChange={handleChange}

                                            />
                                            {/* <div style={{ color: 'red' }}>{selectCollectionErr}</div> */}
                                        </div>


                                        <div className="col-12">
                                            <div className="form-group mt-3">
                                                <label>Creator Fee</label>
                                                <p>Earn a percentage of the sale price every time one of your items is sold.</p>
                                                <p>Adding multiple addresses may increase gas fees for buyers.</p>
                                                <br />
                                                {/* <Form.Item
                                                    name="creator_fee"
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "Please input the creator fee!",
                                                        },
                                                    ]}
                                                >
                                                    <input
                                                        style={{ maxWidth: "100%" }}
                                                        name="creator_fee"
                                                        type="number"
                                                        className="nft-form-control"
                                                        onChange={(e)=>checkCratorFess(e.target.value)}
                                                    />
                                                    {cratorFeesError !=='' ? <div role="alert" className="ant-form-item-explain-error">{cratorFeesError}</div>:''}
                                                </Form.Item> */}
                                                {/* ****************************************** */}


                                                {/* <div className='row'>
                                                    <div className='col-8'>
                                                        <input style={{ maxWidth: "100%" }} className="nft-form-control"></input>
                                                    </div>
                                                    <div className='col-2'>
                                                        <input style={{ maxWidth: "100%" }} className="nft-form-control"></input>
                                                    </div>
                                                    <div className='col-2'>
                                                        <button className="delete-btn" type='button'>
                                                            <img src={delete_icon}></img>
                                                        </button>
                                                    </div>
                                                </div> */}


                                                <div className="col-12 p-0">
                                                    {/* <Form.Item> */}
                                                    <button
                                                        className="create-btn mt-3 mt-sm-4"
                                                        type="button" onClick={addAddress}
                                                    >
                                                        Add address
                                                    </button>
                                                    {/* </Form.Item> */}
                                                </div>
                                                <br />
                                                {reactHookForm()}
                                            </div>
                                        </div>




                                        <div className="nftform-action-item col-12 d-flex justify-content-between align-items-baseline">
                                            <div className="d-flex justify-content-between align-items-center">
                                                {/* <img className="imgw-30" src={sensitiveIcon} /> */}
                                                <div className="flex-column justify-content-between align-items-baseline">
                                                    <h3><i class="fa fa-exclamation-triangle"></i>  Explicit & Sensitive Content</h3>
                                                    <p>Set as explicit and sensitive content.</p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <label className="switch switch-radio-class">
                                                    <Form.Item
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: "Please input file!",
                                                            },
                                                        ]}
                                                    >
                                                        <input
                                                            onChange={(event) => explicitData(event.target.checked)}
                                                            type="checkbox"
                                                            checked={isSxplicity}
                                                        />
                                                        <span className="slider round" />
                                                    </Form.Item>
                                                </label>
                                            </div>
                                        </div>



                                        {/* <div className="col-12">
                                                    <div className="form-group mt-3">
                                                        <label>Supply </label>
                                                        <p>Number of copies to be minted.</p>
                                                        <input type="text" className="nft-form-control" name="item_name" />
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="form-group mt-3">
                                                        <label>Chain </label>
                                                        <div className="dropdown coin-selectType">
                                                            <button className="btn btn-secondary dropdown-toggle d-flex justify-content-start align-items-center" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <img src={nftTypeIcon} />Another action
                                                            </button>
                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                <a className="dropdown-item" href="#"><img src={nftTypeIcon} />Another action</a>
                                                                <a className="dropdown-item" href="#">Something else here</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}


                                        <div className="col-12">
                                            <Form.Item>
                                                <button
                                                    className="create-btn mt-3 mt-sm-4"
                                                    htmlType="submit"
                                                >
                                                    SAVE COLLECTION
                                                </button>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Form>
                            }
                        </div>
                    </div>
                </div>
            </div >
            {
                walletVisibility && <div className="connect-wallet-box-main">
                    <h2 className="main-heading">CONNECT WALLET</h2>
                    <div className="wallet-infobox px-4">
                        <div className="d-flex justify-content-start align-items-center gap-10 connect-wallet-heading-box">
                            <img src={Userimage} />
                            <h3 className="m-0">My Wallet</h3>
                        </div>
                        <p className="mt-3 mb-4">Connect with one of our available wallet, or create a new one.</p>
                        <div className="connect-wallet-options-items-wrap">
                            <div className="flex-column gap-10">
                                <div onClick={(() => connectWithWallet())} className="d-flex justify-content-start align-items-center gap-10  cursor-pointer">
                                    <img src={EGC_Logo_wallet} />
                                    <h3>EGC Wallet</h3>
                                </div>
                                <div onClick={(() => connectWithMetaMask())} className="d-flex justify-content-start align-items-center gap-10 cursor-pointer">
                                    <img src={metamask__wallet} />
                                    <h3>Metamask</h3>
                                </div>
                                <div onClick={(() => connectWithWallet())} className="d-flex justify-content-start align-items-center gap-10 cursor-pointer">
                                    <img src={private_wallet} />
                                    <h3>Trust Wallet</h3>
                                </div>
                                <div onClick={(() => connectWithWallet())} className="d-flex justify-content-start align-items-center gap-10 cursor-pointer">
                                    <img src={coinBase_wallet} />
                                    <h3>Coinbase Wallet</h3>
                                </div>
                                <div onClick={(() => connectWithWallet())} className="d-flex justify-content-center align-items-center gap-10 show-more-options">
                                    <h3>Show more options</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <NftFormPopups
                // selectCategory={(event, status, name) => selectCategory(event, status, name)}
                returnCategory={(categories) => selectCategory(categories)}
                selectedCategories={selectedCategories}

                returnProperty={(property) => { }}
                allProperties={[]}

                allLevels={[]}
                returnLabel={(label) => { }}
            // setProperties={{}}
            // allProperties={[]}
            // addProperty={{}}
            // removeProperty={{}}
            // allLevels={[]}
            // addLevel={{}}
            // handelLevel={{}}
            // removeLevel={{}}
            // setIsSaveProperty={setIsSaveProperty}
            // setIsSaveLevel={setIsSaveLevel}
            />
        </>
    );

}
const mapStateToProps = state => ({
    islogin: state.auth.isLogin,
    auth: state.auth.auth
})

export default connect(mapStateToProps)(CreateCollectionsPage);

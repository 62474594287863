import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Spin, notification } from "antd";
import { CloseOutlined, SmileOutlined } from "@ant-design/icons";
import { IMAGE_PATHS } from "../../utils/app.constant";
import { useUserApi } from "../../hooks/api/userApiHook";

function LikeDislikeNft(props) {
  const { likeUnlikeNft, fetchLikedNft } = useUserApi();

  const { nftId, islogin, auth , successHandler} = props;
  const [showLoader, setShowLoader] = useState(false);
  const [isLikedByUser, setIsLikedByUser] = useState(false);

  useEffect(() => {
    if (islogin && nftId) {
      fetchLikeddBy();
    }
  }, [nftId, islogin]);

  const fetchLikeddBy = async () => {
    setShowLoader(true);
    fetchLikedNft(
      { nftId },
      (message, resp) => {
        if (resp?._id) {
          setIsLikedByUser(true);
        }else{
          setIsLikedByUser(false);
        }

        setShowLoader(false);
      },
      (message, error) => {
        setShowLoader(false);
      }
    );
  };

  const likeUnlikeHandler = async () => {
    try {
      if (showLoader) {
        return;
      }

      setShowLoader(true);
      likeUnlikeNft(
        { nftId },
        (message, resp) => {
          setShowLoader(false);

          if (resp?._id) {
            successHandler && successHandler(1)
            setIsLikedByUser(true);
          } else {
            successHandler && successHandler(-1)
            setIsLikedByUser(false);
          }
         /* notification.open({
            message: "Success",
            description: message,
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          });*/
        },
        (message, error) => {
          setShowLoader(false);
          notification.open({
            message: "Error",
            description: message,
            icon: <CloseOutlined style={{ color: "#f4364c" }} />,
          });
        }
      );
    } catch (error) {
      setShowLoader(false);
      notification.open({
        message: "Error",
        description: "Unable to process...!",
        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
      });
    }
  };
  if (!islogin || !nftId) {
    return null;
  }

  return (
    <>
      <div
        onClick={() => {
          likeUnlikeHandler();
        }}
        className={`fav-icon ${isLikedByUser ? "active" : ""}`}
      >
        {showLoader ? (
          <Spin />
        ) : (
          <img src={IMAGE_PATHS.FOLLOW_IMAGE} alt="Favorite"></img>
        )}
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  islogin: state.auth.isLogin,
  auth: state.auth.auth,
});

export default connect(mapStateToProps)(LikeDislikeNft);

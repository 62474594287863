import { useDispatch } from 'react-redux';
import BidServices from '../api/BidServices';
import { useCookies } from "react-cookie";
import { getnft,allNft }  from '../store/nft/action'
import { identity } from 'lodash';

export default function useBid() {
    const dispatch = useDispatch();

    return {
        makeBid: async (data) => {          
            var responseData = await BidServices.MakeBid(data);
            if (responseData.status === 200) {
                return responseData.data.data;
            }
            return false;
        },
        getBidWithPagination: async (data) => {          
            var responseData = await BidServices.GetBidWithPagination(data);
            if (responseData.status === 200) {
                return responseData.data.data;
            }
            return false;
        },
        cancelBid: async (data) => {
            var responseData = await BidServices.CancelBid(data);
            if (responseData.status === 200) {
                return responseData.data;
            }
            return false;
        },
        acceptBid: async (data) => {
            var responseData = await BidServices.AcceptBid(data);
            if (responseData.status === 200) {
                return responseData.data;
            }
            return false;
        },
        /*getWinnerOfBid: async (data) => {          
            var responseData = await BidServices.GetWinnerOfBid(data);
            if (responseData.status === 200) {
                return responseData.data.data;
            }
            return false;
        },*/
    }
}

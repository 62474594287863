import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Form, Button } from "antd";
import Web3 from "web3";

export default function NftOwnerList({ list, onCancel }) {
  return (
    <>
      <div className="connect-wallet-box-main make-offer-main">
        <h2 className="main-heading">Owner List</h2>
        <div className="wallet-infobox px-4">
          <div>
            <div className="container">
                  <div className="row items">
                      <div className="col-12 col-md-12 col-lg-12" style={{"overflowY": "scroll", "maxHeight": "400px"}}>
                          <ul className="lunanft-tab owner-list nav nav-tabs" id="nav-tab">
                              {
                                list?.owner_list?.length ? list.owner_list.map((e, key) => {
                                  return (
                                    <li key={key}>
                                      <div className="owner-list-user">
                                        <div className="owner-name by-author">
                                          <Link target={"_blank"} to={"/profile/" + e?.user}>{e?.user}</Link>
                                        </div>
                                        <div className="owner-address">{e?.wallet_address}</div>
                                      </div>
                                      <div className="owner-list-count">{e?.quantity} items</div>
                                    </li>
                                  )
                                }) : ""
                              }
                          </ul>
                      </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

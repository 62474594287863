import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function CratorSkeleton() {
  const dummyArr = [1, 2, 3, 4, 5, 6];

  return (
    <div className="row">
    <div className="col-3">
        <div className="category-mainmenu mt-10">
            <ul className="list-unstyled games-sidebar-main sidebarStyle1">
            <Skeleton
                style={{
                    width: "100%",
                    height: "40px",
                    objectFit: "cover",
                    borderRadius: "20px",
                }}
                count={4}
                />
            </ul>
        </div>
        <div className="filter-options-nft-details">
            {/* <SidebarFiltersAccordion /> */}
            {/* <div className="details-info nft-properties d-flex justify-content-between align-items-center my-3">
                <div className="d-flex align-items-center justify-content-between gap-18 ml-3">
                    <h4>Status</h4></div>
                <img src={SelectArrow} className="mr-3" />
            </div> */}

        </div>
    </div>
    <div className="col-9">
        <h3 className="oracleTextClass"><Skeleton count={1} /></h3>
        <div className="NftprofileWrap position-relative">
        <Skeleton
            animation="wave"
            height={300}
            width="100%"
            style={{ marginBottom: 6 }}
        />
        <Skeleton
            style={{
            position: "absolute",
            bottom: "-15%",
            left: "9%",
            width: "100px",
            height: "100px",
            zIndex: "1",
            borderRadius: "50%",
            objectFit: "cover",
            border: "2px solid #fff9f1",
            }}      
            animation="wave"
            variant="circular"
            width={150}
            height={150}
            />
        </div>
        <div className="profile-infobox-details d-flex justify-content-between">
            <div className="profile-tabs social-media-icons">
                <ul className="nav justify-content-start gap-18">
                   <h3 style={{ width: "100px"}}> <Skeleton /></h3>
                </ul>
            </div>
            <div className="profile-tabs profile-description">
                <h3 className="nft-auther-heading m-0 mb-2" style={{ width: "300px"}}><Skeleton/></h3>
            </div>
            <div className="profile-tabs">
                <ul className="nav justify-content-end gap-18">
                <Skeleton 
                animation="wave"
                height={30}
                width="34px"
                />
                 <Skeleton 
                animation="wave"
                height={30}
                width="34px"
                />
                </ul>
            </div>
        </div>
        <div className="ownerInfowindow">
            <div className="col-2"></div>
            <div className="col-10 " style={{ display: "flex", justifyContent: "end", }}>
               
                <Skeleton
                    animation="wave"
                    height={90}
                    width="174px"
                />
                <Skeleton
                    animation="wave"
                    height={90}
                    width="174px"
                />
                <Skeleton
                    animation="wave"
                    height={90}
                    width="174px"
                />
                <Skeleton
                    animation="wave"
                    height={90}
                    width="174px"
                />
                </div>
                <div className="col-2"></div>
                <div className="col-10 " >
                <p className="nft-auther-para" style={{ width: "100%"}}>
                <Skeleton
                    animation="wave"
                    height={30}
                    width="60%"
                />
                </p>
                </div>
        </div>

        <div className="filter-nft-details">
            <ul className="nav justify-content-end">
                <li className="nft-filter-lables">
                <Skeleton></Skeleton>
                </li>
                <li className="nft-filter-lables">
                <Skeleton></Skeleton>
                </li>
            </ul>
        </div>
        <div className="nft-details-filter-inputs">
            <div className="input-filter-item d-flex align-items-end justify-content-end gap-18">
                    <Skeleton
                     animation="wave"
                     height={50}
                     width="100%"
                    ></Skeleton>
                    <Skeleton
                     animation="wave"
                     height={50}
                     width="100%"
                    ></Skeleton>
            </div>
        </div>
        <div className="nft-detail-hr"></div>        
    </div>
</div>
  );
}

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import SidebarmenuComponent from '../../themes/SidebarmenuComponent';
import Loader from '../../components/Skeletons/TopCratorsSkeleton.js'
import Pagination from '@mui/material/Pagination';

//custom hooks
import useAuth from '../../hooks/useAuth'

function DropPage({ props, total, page, allUsers, loading }) {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const [pagination, setPagination] = useState(1)
    const [skip, setSkip] = useState(9)
    const [activePage, setActivePage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [userList, setUseList] = useState([])
    const [showList, setShowList] = useState([])
    //pagination logic
    let totalPage = parseInt((total + 9 - 1) / 9);
    // console.log('creators', allUsers, total)

    const { getCrators } = useAuth()

    useEffect(() => {
        getProfiles()
    }, [])

    useEffect(() => {
        setShowList(allUsers);
    }, [allUsers])

    const getProfiles = async () => {
        var res = await getCrators({ page: 1 })
        const { total, latestUsers, page, last_page } = res;
        // console.log('1st time ==>', latestUsers, last_page, res);
        setLastPage(last_page);
        setActivePage(+page);
        setPagination(total)
    }

    const next = async (e, page) => {
        var res = await getCrators({ page: page })
    }

    const _getProducts = async (pageNumber = 1) => {
        let arr = [];
        var res = await getCrators({ page: pageNumber })
        const { total, latestUsers, page, last_page } = res;
        if (userList.length === 0) {
            arr = [...allUsers, ...latestUsers];
        } else {
            arr = [...userList, ...latestUsers];
        }
        // console.log('pagination==>', latestUsers, last_page, res);
        setLastPage(last_page);
        setActivePage(pageNumber);
        setShowList(arr)
        setUseList(arr);

    }

    const handlePage = async () => {
        // console.log('active..', +activePage + 1);
        _getProducts(+activePage + 1)
    }

    if (loading) {
        return <Loader />
    }

    return (
        <div className="games__wrap CratoePageComponent">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-3">
                        <SidebarmenuComponent />
                    </div>
                    <div className="col-9">
                        <h3 className="oracleTextClass">TOP CREATORS</h3>
                        <div className="row">

                            {

                                // _.map(allUsers, (post, index) => {
                                _.map(showList, (post, index) => {

                                    const userProfileImage = post.profile_image || 'https://via.placeholder.com/200x200.png?text=User';
                                    const userProfileBanner = post.profile_banner || 'https://via.placeholder.com/326x333.png?text=User';
                                    return <div className="col-4 p-0 mb-3 position-relative" key={index}>
                                        <NavLink to={"/profile/" + post?.username}>
                                            <div className="game-box mr-3 pb-3">
                                                <div className="creator-profilebanner">
                                                    <img src={userProfileBanner} />
                                                </div>
                                                <div className="game-box-dark creators-data">
                                                    <div className="crator-user-profile-image">
                                                        <img src={userProfileImage} className='img_border_rd' alt='profile-image' />
                                                    </div>
                                                    <div className="nft-btn-wrap">
                                                        {/* <NavLink className="nft-game-go-gtn" to={"/profile/" + post?.username}>Go</NavLink> */}
                                                    </div>
                                                    <h2 className="nft-heading-1 m-0">{post?.username}</h2>
                                                    {post?.subtitle && <h3 className="nft-subheading m-0 mb-2">{post?.subtitle}</h3>}
                                                    <p className="nft-box-list-desc m-0 mb-2">{post?.bio.slice(0,30)+(post?.bio.length > 30 ? "..." : "")}</p>
                                                </div>
                                            </div>
                                        </NavLink>

                                    </div>
                                })
                            }
                        </div>
                        {(activePage !== lastPage) ? (
                            <div className="d-flex justify-content-center">
                                <button className="create-btn mt-3 mt-sm-4 mb-5" disabled={loading} onClick={(e) => { e.preventDefault(); handlePage(); }}>
                                    {!loading ? 'Load More' : 'Please Wait...'}</button>
                            </div>
                        ) : null}
                    </div>
                </div>
                {/* <div className='pagination_nft'>
                    <Pagination color="success" page={page} className="pagination_nft" count={totalPage} onChange={next} variant="outlined" shape="rounded" />
                </div> */}
            </div>
        </div>
    );
}


const mapStateToProps = state => ({
    islogin: state.auth.isLogin,
    auth: state.auth.auth,
    loading: state.crators?.isloading,
    allUsers: state.crators?.crators?.latestUsers,
    total: state.crators?.crators?.total,
    page: state.crators?.crators?.page
})

export default connect(mapStateToProps)(DropPage)
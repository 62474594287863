import React, { useState, useEffect } from "react";
import { Form, Button } from "antd";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { notification } from "antd";
import { SmileOutlined, CloseOutlined } from "@ant-design/icons";

import { useWeb3React } from "@web3-react/core";

import useMedia from "../../hooks/useMedia";
import useComman from "../../hooks/useComman";
import useNFT from "../../hooks/useNFT";
import useContractIntraction from "../../web3Setup/useContractIntraction";
import Select from "react-select";

import useCollections from "../../hooks/useCollection";
import useNetworks from "../../hooks/useNetwork";

import categoryIcon from "../../image/nft-form/Groupnft-form-icon-1.png";
import propIcon from "../../image/nft-form/Groupnft-form-icon-2.png";
import levelIcon from "../../image/nft-form/Groupnft-form-icon-3.png";
import unlockIcon from "../../image/nft-form/Groupnft-form-icon-4.png";
import sensitiveIcon from "../../image/nft-form/Groupnft-form-icon-5.png";
import GrouppopUpplus from "../../image/nft-form/GrouppopUpplus.png";
import nftTypeIcon from "../../image/nft-detail/current-time.png";
import previewIcon from "../../image/previewIcon.png";
import NftFormPopups from "../../themes/NftFormPopups";
import Web3 from "web3";
import loader from "../../image/loader.gif";
// import abi from "../../abi/nft.json";
import abi_1155 from "../../abi/ABI_Mint_1155.json";
import abi_721 from "../../abi/ABI_Mint_721.json";
import abitest from "../../abi/test.json";
import { smartChainUrl } from "../../abi/address";
import { TokenStandard, MetadataDisplayType, NFT_FILE_MAX_SIZE } from "../../utils/app.constant";
import * as address from "../../abi/address.json";
import { chainImageHandler, switchNetwork } from "../../utils/helper";
import binancePriceImage from "../../image/networkPrice/binance.jpg";
import maticPriceImage from "../../image/networkPrice/matic.jpg";
import ethereumPriceImage from "../../image/networkPrice/ethereum.jpg";
import { fileTypeCheck, fileExtensionCheck } from "../../utils/helper";
// import "@google/model-viewer";


var metaurl =
  "https://ss97p5m6hknh.usemoralis.com:2053/server/functions/getNftItemById?{ApplicationId}&{id}";

function Create({ props, islogin, auth }) {
  // Hooks functions

  const { library } = useWeb3React();

  const { FileUpload } = useMedia();
  const { collectionsOfUser, transaction } = useComman();
  const { create } = useNFT();
  const { verifyMessage, attemptSignature } = useContractIntraction();

  const [file, setFile] = useState(previewIcon);
  const [uploadedFile, setUploadedFile] = useState();
  // const [category, setCategory] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [allProperties, setAllProperties] = useState([]);
  const [allLevels, setAllLevels] = useState([]);

  const [catError, setCateError] = useState("");
  const [propertyError, setPropertyError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [explicity, setExplixity] = useState(false);
  const [enableLazyMinting, setEnableLazyMinting] = useState(false);
  const [unlockable, setUnlockable] = useState(false);
  const [formReset, setFormSet] = useState(false);
  const [collectionSelected, setCollectionSelected] = useState(false);
  const [networkSelected, setNetworkSelected] = useState(null);
  const [tokenStandardSelected, setTokenStandardSelected] = useState(
    TokenStandard[1]
  );
  const [collection, setCollection] = useState([]);
  // const [isSaveProperty, setIsSaveProperty] = useState(false);
  // const [isSaveLevel, setIsSaveLevel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileExtension, setFileExtension] = useState("");
  // const [categoryName, setCategoryName] = useState([]);
  const [selectCollectionErr, setselectCollectionErr] = useState("");
  const [fileType, setFileTypeError] = useState("");
  const [fileShowType, setFileShowType] = useState("image");
  const history = useHistory();
  const [web3, setWeb3] = useState();
  const [metaweb3, setMetaWeb3] = useState(new Web3(Web3.givenProvider));
  const [fileUrl, updateFileUrl] = useState(``);
  const [networkList, setNetworkList] = useState([]);
  const { getNetworkList } = useNetworks();
  const [addImage, setAddImage] = useState(false);
  const [freezeMetadata, setFreezeMetadata] = useState(false)
  const properties = { type: "", name: "" };
  const levelDefault = { name: "", value_one: 3, value_two: 5 };

  // useEffect(() => { }, [categoryName, allProperties, auth, islogin]);

  useEffect(() => {
    fetchCollection();
    fetchNetwork();
  }, [islogin]);

  const fetchCollection = async () => {
    var res = await collectionsOfUser();
    setCollection(res);
    if (res.length > 0) {
      setCollectionSelected(res[0]['_id']);
    }
  };

  const fetchNetwork = async () => {
    const fetchNetwork = await getNetworkList();
    setNetworkList(fetchNetwork.networks);
    if (fetchNetwork.networks.length) {
      // setNetworkSelected(fetchNetwork.networks[0]["_id"]);
      setNetworkSelected(fetchNetwork.networks[0]);
    }
  };

  const selectFile = async (e) => {
    setLoading(true);
    const reader = new FileReader();
    if (e.target.files && e.target.files.length > 0) {
      if (NFT_FILE_MAX_SIZE < e.target.files[0].size) {
        setFileTypeError("You can upload 100MB size file!");
        return;
      }
      // let fileType = fileTypeCheck.includes(e.target.files[0].type)
      let fileTypeName = e.target.files[0].name.split('.');
      let fileExt = fileTypeName[fileTypeName.length - 1].toLowerCase();
      if (!fileExtensionCheck.includes(fileExt)) {
        setFileTypeError("Not valid file!, Please upload (jpg, jpeg, png, gif, svg, mp4, mov, wmv, wav, avi, avchd, mkv, webm, mpeg, mp3, glb, gltf, ogg, glb, gltf,hevc, h.264 file type");
        return;
      }


      // if (
      //   !e.target.files[0].type.match(
      //     /.(jpg|jpeg|png|gif|svg+xml|mp4|mov|wmv|wav|avi|avchd|mkv|webm|mpeg|mp3|glb|gltf|ogg)$/i
      //   ) 
      // ) {
      //   setFileTypeError(
      //     "Not valid file!, Please upload (jpg, jpeg, png, gif, svg, mp4, mov, wmv, wav, avi, avchd, mkv, webm, mpeg, mp3, glb, gltf, ogg) file type"
      //   );
      //   return;
      // } 


      setFile(loader);
      setFileExtension(e.target.files[0].type);
      var form = new FormData();
      form.append("image", e.target.files[0]);
      var fileRes = await FileUpload(form);

      if (fileRes) {
        setFileShowType(e.target.files[0].type.slice(0, 5));
        reader.addEventListener("load", () => setFile(reader.result));
        setAddImage(true);
        await setUploadedFile(fileRes);
        setFileTypeError("");
      } else {
        notification.open({
          message: "Error",
          description: "This file not supported!",
          icon: <CloseOutlined style={{ color: "#f4364c" }} />,
        });
        setFileTypeError("This file not supported!");
        setFile(previewIcon);
        setLoading(false);
        setAddImage(false);
      }
    }
    reader.readAsDataURL(e.target.files[0]);
    setLoading(false);
  };

  /*const selectCategory = async (newCate, status, name) => {
    setLoading(true);
    if (!status) {
      var old = category.filter((value) => value !== newCate);
      var cate = categoryName.filter((valueName) => valueName !== name);
      await setCategory(old);
      await setCategoryName(cate);
    } else {
      var old = category;
      var cateName = categoryName;
      old.push(newCate);
      cateName.push(name);
      await setCategory(old);
      await setCategoryName(cateName);
    }
    setLoading(false);
  };*/

  /*const selectCategory = async (cat, status) => {
    const gotIndex = selectedCategories.findIndex(
      (e) => e["_id"] == cat["_id"]
    );
    if (gotIndex != -1) {
      if (!status) {
        const latestCats = selectedCategories.splice(gotIndex, 1);
        setSelectedCategories(latestCats);
      }
    } else {
      if (status) {
        setSelectedCategories((prevCats) => [
          ...prevCats,
          { _id: cat["_id"], name: cat["name"] },
        ]);
      }
    }
  };*/

  const selectCategory = async (categories) => {
    setSelectedCategories(categories);
    if (categories.length > 0) {
      setCateError('')
    } else {
      setCateError('Please select any one category!')
    }
  };
  const setProperty = async (property) => {
    setAllProperties(property.filter(item => item.type !== '' && item.name !== ''));
  };
  const setLabel = async (labels) => {
    setAllLevels(labels.filter(item => item.name !== '' && item.value_one > 0 && item.value_two > 0));
  };

  const reInitModal = () => {
    setSelectedCategories(JSON.parse(JSON.stringify(selectedCategories)));
    setAllProperties(JSON.parse(JSON.stringify(allProperties)));
    setAllLevels(JSON.parse(JSON.stringify(allLevels)));
  };

  const handelProperty = async (data) => {
    setIsLoading(true);
    await setAllProperties(data);
    setIsLoading(false);
  };

  const handelLavle = async (data) => {
    setIsLoading(true);
    await setAllLevels(data);
    setIsLoading(false);
  };

  const createNft = async (data) => {
    if (loading) {
      alert("Please Wait...");
      return;
    }
    if (!uploadedFile || ( uploadedFile && !uploadedFile['actual_file_path'] )) {
      return;
    }

    // console.log('create nft', data, collectionSelected);

    await setCateError("");
    let date = new Date();

    // let properties = allProperties;

    if (selectedCategories.length === 0) {
      setCateError("Please select any one category!");
      return;
    }
    setLoading(true);

    // await setIsLoading(true)
    let { user } = auth;
    let coll = collectionSelected;

    data.explicit = explicity;
    data.enable_lazy_minting = enableLazyMinting;
    data.unlockable = unlockable;
    data.properties = allProperties; //isSaveProperty ? properties : undefined;
    data.levels = allLevels;
    data.item_image = uploadedFile['actual_file_path']
    data.item_resized_image = uploadedFile['resized_path'] || null;
    data.user_id = user._id;
    data.owner_id = user._id;
    data.categories_id = selectedCategories.map((e) => e["_id"]);
    data.collection_id = !coll ? undefined : coll;
    data.network = networkSelected;
    // data.collection_name = undefined;
    data.isEditable = true;
    data.token_standard = tokenStandardSelected;
    data.file_extension = fileShowType;
    data.create_date = date.getTime();
    data.is_freezed_metadata = freezeMetadata;

    let responsedata;

    const attributeProperties =
      (data["properties"] &&
        data["properties"].length &&
        data["properties"].map((p) => {
          if (
            p["displayType"] &&
            ![MetadataDisplayType.TEXT, MetadataDisplayType.READ_ONLY].includes(
              p["displayType"]
            )
          ) {
            return {
              display_type: p["displayType"],
              trait_type: p["type"],
              value: p["name"],
            };
          } else {
            return {
              trait_type: p["type"],
              value: p["name"],
            };
          }
        })) ||
      [];
    const attributeLevels =
      (data["levels"] &&
        data["levels"].length &&
        data["levels"].map((p) => {
          if (
            p["displayType"] &&
            ![MetadataDisplayType.TEXT, MetadataDisplayType.READ_ONLY].includes(
              p["displayType"]
            )
          ) {
            return {
              display_type: p["displayType"],
              trait_type: p["name"],
              value: p["value_1"],
              max_value: p["value_2"],
            };
          } else {
            return {
              trait_type: p["name"],
              value: p["value_one"],
              max_value: p["value_two"],
            };
          }
        })) ||
      [];

    /*const attributeCategory = selectedCategories.map(cat => ({
      trait_type: "Category",
      value: cat['name'] || ""
    }))*/

    let metaurl = {
      name: data["item_name"],
      image: data["item_image"],
      description: data["description"],
      attributes: [...attributeProperties, ...attributeLevels],
    };
    data.metadata = metaurl;

    const inputNetwork = networkList.find((e) => e["_id"] == (networkSelected[0] ? networkSelected[0]['_id'] : networkSelected._id));
    const contractNetwork =
      inputNetwork["network"] + "_" + inputNetwork["chain_id"];
    const contractAddress =
      data.token_standard == TokenStandard[1]
        ? address["1155"][contractNetwork]["mint_address"]
        : address["721"][contractNetwork]["mint_address"];

    data.contract_address = contractAddress;
    let res = await create(data);
    console.log('create=>', res);
    if (res) {
      notification.open({
        message: "NFT",
        description: "NFT Successfully Created...!",
        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
      });
      setLoading(false);
      setIsLoading(false);
      history.push(`/assets/${res.nft_items.chain_name}/${res.nft_items.contract_address}/${res.nft_items.token_id}`);
    } else {
      notification.open({
        message: "Error",
        description: "Something went wrong...!",
        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
      });
      setLoading(false);
      setIsLoading(false);
    }
  };

  const createNft_30Aug = async (data) => {
    if (!library) {
      notification.open({
        message: "Error",
        description: "Invalid network selected",
        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
      });
      return;
    }
    const web3 = new Web3(library.provider);
    let currentChainId = await web3.eth.getChainId();
    if (!currentChainId) {
      notification.open({
        message: "Error",
        description: "Invalid network selected",
        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
      });
      return;
    }
    let findSelectChainId = networkList.find(
      (item) => item._id === networkSelected
    );

    if (
      findSelectChainId?._id &&
      findSelectChainId.chain_id !== currentChainId
    ) {
      switchNetwork(String(findSelectChainId.chain_id), library).then(() =>
        createNft(data)
      );
      return;
    }

    //console.log('called successfully')
    // console.log("data",data)
    // return;
    if (verifyMessage()) {
      attemptSignature();
    }
    if (loading) {
      alert("Please Wait...");
      return;
    }
    if (!uploadedFile) {
      return;
    }

    await setCateError("");
    let date = new Date();

    // let properties = allProperties;

    if (selectedCategories.length === 0) {
      setCateError("Please select any one category!");
      return;
    }
    if (window.localStorage.getItem("walletconnect")) {
      window.localStorage.removeItem(
        "Parse/Es3H86hNgsMPaXq1l4FPPvlB0XIj6PWtNjotptWS/installationId"
      );
    }
    setLoading(true);

    // await setIsLoading(true)
    let { user } = auth;
    let coll = collectionSelected;

    data.explicit = explicity;
    data.unlockable = unlockable;
    data.properties = allProperties; //isSaveProperty ? properties : undefined;
    data.levels = allLevels;
    data.item_image = uploadedFile;
    data.user_id = user._id;
    data.owner_id = user._id;
    data.categories_id = selectedCategories.map((e) => e["_id"]);
    data.collection_id = !coll ? undefined : coll;
    data.network = networkSelected;
    data.collection_name = undefined;
    data.isEditable = true;
    data.token_standard = tokenStandardSelected;
    data.file_extension = fileShowType;
    data.create_date = date.getTime();

    let responsedata;

    const attributeProperties =
      (data["properties"] &&
        data["properties"].length &&
        data["properties"].map((p) => {
          if (
            p["displayType"] &&
            ![MetadataDisplayType.TEXT, MetadataDisplayType.READ_ONLY].includes(
              p["displayType"]
            )
          ) {
            return {
              display_type: p["displayType"],
              trait_type: p["type"],
              value: p["name"],
            };
          } else {
            return {
              trait_type: p["type"],
              value: p["name"],
            };
          }
        })) ||
      [];
    const attributeLevels =
      (data["levels"] &&
        data["levels"].length &&
        data["levels"].map((p) => {
          if (
            p["displayType"] &&
            ![MetadataDisplayType.TEXT, MetadataDisplayType.READ_ONLY].includes(
              p["displayType"]
            )
          ) {
            return {
              display_type: p["displayType"],
              trait_type: p["name"],
              value: p["value_1"],
              max_value: p["value_2"],
            };
          } else {
            return {
              trait_type: p["name"],
              value: p["value_one"],
              max_value: p["value_two"],
            };
          }
        })) ||
      [];
    let metaurl = {
      name: data["item_name"],
      image: data["item_image"],
      description: data["description"],
      attributes: [...attributeProperties, ...attributeLevels],
    };
    data.metadata = metaurl;

    var jsonData = JSON.stringify(metaurl);
    //var web3 = new Web3(library.provider);
    const inputNetwork = networkList.find((e) => e["_id"] == networkSelected);
    const contractNetwork =
      inputNetwork["network"] + "_" + inputNetwork["chain_id"];
    const abi = data.token_standard == TokenStandard[1] ? abi_1155 : abi_721;
    const contractAddress =
      data.token_standard == TokenStandard[1]
        ? address["1155"][contractNetwork]["mint_address"]
        : address["721"][contractNetwork]["mint_address"];
    console.log(
      "contractAddress",
      contractAddress,
      "dataadd",
      user.wallet_addresss
    );
    try {
      const contract = new web3.eth.Contract(abi, contractAddress);
      responsedata = await contract.methods
        .mint(user.wallet_address, [1], [jsonData], [data.supply])
        .send({ from: user.wallet_address });
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      await setIsLoading(false);
      return;
    }
    try {
      const tokenId = responsedata.events.Mint.returnValues.id;
      data.contract_address = contractAddress;
      data.token_id = tokenId;
      let res = await create(data);
      res = res.nft_items;

      let dataTransaction = {
        saller_id: res.owner_id,
        nft_id: res._id,
        buyer_id: undefined,
        event: "Mint",
        seller_username: user.username ? user.username : user._id,
        price: undefined,
        url: smartChainUrl,
        tnxHasId: responsedata.transactionHash,
        date: new Date(),
        ready_for_sell: false,
      };

      // console.log("res nft=>", res);

      if (res) {
        notification.open({
          message: "NFT",
          description: "NFT Successfully Created...!",
          icon: <SmileOutlined style={{ color: "#108ee9" }} />,
        });
        let transtion = await transaction(dataTransaction);

        setLoading(false);
        await setIsLoading(false);
        history.push(`/assets/${res.nft_items.chain_name}/${contractAddress}/${tokenId}`);
      } else {
        notification.open({
          message: "Error",
          description: "Something went wrong...!",
          icon: <CloseOutlined style={{ color: "#f4364c" }} />,
        });
      }
    } catch (error) {
      console.log("error", error);
      notification.open({
        message: "Error",
        description:
          "Something went wrong... Make Sure You  Are Connected With Mainnet!",
        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
      });
      setLoading(false);
    }
  };

  const gotoProfile = () => {
    let { userDetails } = auth;
    history.push(`/profile/${userDetails.username}`);
  };
  const handleChange = (e) => {
    // console.log("e", e)
    setNetworkSelected(e._id);
  };

  // <video autoplay="" controls="" controlslist="nodownload" loop="" preload="auto" src="blob:https://testnets.opensea.io/51d1eb4f-7e8f-468e-ba99-28b90b93ac1b" style="width: 100%; height: calc(100% - 50px);"></video>
  return (
    <>
      <div className="games__wrap">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-1 mx-0 px-0 d-none"></div>
            <div className="col-2 row align-items-end flex-column text-left d-none"></div>
            <div className="col-9 p-0 res form-transp-bg">
              <h3 className="oracleTextClass">Create</h3>
              {!islogin ? (
                <div className="pt-lg-5">
                  <p className="connect-wallet-message">*Please connect your wallet to continue.</p>
                  <button
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    className="connect-wallet-btn"
                  >
                    Connect Your Wallet
                  </button>
                </div>
              ) : (
                <Form
                  className="item-form createNftForm  no-hover"
                  onFinish={createNft}
                  initialValues={
                    formReset
                      ? { item_name: "", external_link: "", description: "" }
                      : ""
                  }
                >
                  <div className="row">
                    <div className="col-12">
                      <p>
                        <span className="required-class">*</span> Required
                        Fields
                      </p>
                      <div className="input-group form-group mt-4 mb-0">
                        <div className="custom-file-old">
                          <label className="image-upload-lbl mb-2">
                            Image, Video, Audio, or 3D Model
                            <span className="required-class caution">
                              * Max size: 100 MB
                            </span>{" "}
                          </label>
                          <p>
                            {" "}
                            (File types supported:jpg, jpeg, png, gif, mp4, mov,
                            wmv, wav, avi, avchd, mkv, webm, mpeg, mp3, glb,
                            gltf ){" "}
                          </p>
                          <label
                            className="custom-file-label-old col-6"
                            htmlFor="inputGroupFile01"
                          >
                            {/* {console.log('fileshowtype=>', fileShowType)} */}
                            <div
                              className={`${addImage
                                ? "image-preview-box cnftimg add-image"
                                : "image-preview-box cnftimg"
                                }`}
                            >
                              {fileShowType === "image" ? (
                                <img src={file} />
                              ) : (
                                ""
                              )}
                              {fileShowType === "video" ? (
                                <video
                                  playsInline
                                  muted
                                  autoPlay={true}
                                  controlsList="nodownload"
                                  loop=""
                                  preload="auto"
                                  src={uploadedFile && uploadedFile['actual_file_path'] || ''}
                                  style={{
                                    width: "100%",
                                    height: "calc(100% - 50px",
                                  }}
                                ></video>
                              ) : (
                                ""
                              )}
                              {fileShowType === "audio" ? (
                                <audio controls>
                                  <source src={uploadedFile && uploadedFile['actual_file_path'] || ''} />
                                </audio>
                              ) : (
                                ""
                              )}

                              {fileShowType === "" ? (
                                
                                <model-viewer
                                  src={uploadedFile && uploadedFile['actual_file_path'] || ''}
                                  // src='https://nftstore-meta.glohtesting.com/3ec6d556-bded-4142-9ff1-71d7635407f9/ipfs-nft/6'
                                  ios-src=""
                                  // poster={poster}
                                  alt="A 3D model"
                                  shadow-intensity="1"
                                  camera-controls
                                  auto-rotate
                                  ar
                                ></model-viewer>
                              ) : (
                                ""
                              )}
                            </div>
                          </label>
                          <Form.Item
                            name="item_image"
                            className="preview-image-iterms-holder"
                            rules={[
                              {
                                required: true,
                                message: `Please input file!`,
                              },
                            ]}
                          >
                            <input
                              name="item_image"
                              onChange={(event) => selectFile(event)}
                              type="file"
                              className="custom-file-input-none"
                              id="inputGroupFile01"
                            />
                          </Form.Item>
                          <button className="create-btn new-upload-btn mb-5" type="button">
                            <label for="inputGroupFile01">Upload File</label>
                          </button>
                          {fileType !== "" ? (
                            <div
                              role="alert"
                              className="ant-form-item-explain-error"
                            >
                              {fileType}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group mt-3">
                        <label>
                          Item name<span className="required-class">*</span>{" "}
                        </label>
                        <Form.Item
                          name="item_name"
                          rules={[
                            {
                              required: true,
                              message: "Please input item name!",
                            },
                          ]}
                        >
                          <input
                            type="text"
                            className="nft-form-control"
                            name="item_name"
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-group mt-3">
                        <label>External link </label>
                        <p>
                          LunaSky will include a link you can share and promote
                          your NFT. You can link your own webpage.
                        </p>
                        <Form.Item
                          name="external_link"
                          rules={[
                            {
                              required: false,
                              message: "Please input external link!",
                            },
                          ]}
                        >
                          <input
                            type="url"
                            className="nft-form-control"
                            name="external_link"
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-group mt-3">
                        <label>Description</label>
                        <p>This will be included on your item's detail.</p>
                        <Form.Item
                          name="description"
                          rules={[
                            {
                              required: false,
                              message: "Please input file!",
                            },
                          ]}
                        >
                          <textarea name="description"></textarea>
                        </Form.Item>
                      </div>
                    </div>

                    <div className="flex-column w-100 px-3 mb-4">
                      <div className="row">
                        <div className="col-10 pl-0">
                          <h3>COLLECTION</h3>
                        </div>
                        <div className="col-1 d-flex justify-content-end pr-0">
                          <div
                            onClick={() => fetchCollection()}
                            className="rounded-circle-bg d-flex align-items-center justify-content-center"
                          >
                            {/* <img
                              className="imgw-30 cursor-pointer"
                              src='/img/Grouprefresh.png'
                            /> */}
                            <i className="fa fa-undo cursor-pointer"></i>
                          </div>
                        </div>

                        <div className="col-1 d-flex justify-content-end">
                          <div
                            onClick={() =>
                              window.open("/create-collections", "_blank")
                            }
                            className="rounded-circle-bg d-flex align-items-center justify-content-center"
                          >
                            <img
                              className="imgw-30 cursor-pointer"
                              src={GrouppopUpplus}
                            />
                          </div>
                        </div>
                      </div>
                      <p className="mb-3 mt-1 d-flex">
                        This is the collection where your item will appear.
                        <div className="tooltip-wrapper">
                          {/* <img src="/img/info-icon.png"></img> */}
                          <i className="fa  fa-info-circle"></i>
                          <div className="custom-tooltip">
                            You can organize your items in a collection by
                            selecting the
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  `/profile/${auth.user.username}`,
                                  "_blank"
                                );
                              }}
                            >
                              {" "}
                              collection here.
                            </a>
                          </div>
                        </div>
                      </p>
                      <div className="d-flex justify-content-start align-items-start gap-10 cstom-select-box">
                        <select value={collectionSelected}
                          onChange={(event) =>
                            setCollectionSelected(event.target.value)
                          }
                        >
                          {/* <option value="">Select Collection</option> */}
                          {collection.length > 0
                            ? collection.map((value, key) => (
                              <option
                                key={key}
                                selected={
                                  collectionSelected === value.id
                                    ? true
                                    : false
                                }
                                value={value._id}
                              >
                                {value.collection_name}
                              </option>
                            ))
                            : ""}
                        </select>
                      </div>
                      <div style={{ color: "red" }}>{selectCollectionErr}</div>
                    </div>

                    <div className="nftform-action-item col-12 d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-center">
                        {/* <img className="imgw-30" src={categoryIcon} /> */}

                        <div className="flex-column justify-content-between align-items-baseline">
                          <h3>
                            <i className="fa fa-th-list"></i>
                            Select one or more categories
                            <span className="required-class">*</span>
                          </h3>
                          <div style={{ color: "red" }}>{catError}</div>
                          <p>
                            {selectedCategories?.length > 0
                              ? selectedCategories?.map((cat, key) => (
                                <span key={key}>{cat.name},</span>
                              ))
                              : ""}{" "}
                            {selectedCategories.length > 0 ? ".etc" : ""}{" "}
                          </p>
                        </div>
                      </div>
                      <div className="rounded-circle-bg d-flex align-items-center justify-content-center">
                        <img
                          className="imgw-30 cursor-pointer"
                          data-backdrop="static"
                          data-keyboard="false"
                          data-toggle="modal"
                          data-target="#categoryModal"
                          src={GrouppopUpplus}
                          onClick={reInitModal}
                        />
                      </div>
                    </div>


                    <div className="nftform-action-item col-12 d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-center">
                        {/* <img className="imgw-30" src={propIcon} /> */}
                        <div className="flex-column justify-content-between align-items-baseline">
                          <h3>
                            <i className="fa fa-building"></i>Properties
                          </h3>
                          <p>Textual traits will show up as rectangles.</p>
                        </div>
                      </div>
                      <div className="rounded-circle-bg d-flex align-items-center justify-content-center">
                        <img
                          className="imgw-30 cursor-pointer"
                          src={GrouppopUpplus}
                          data-backdrop="static"
                          data-keyboard="false"
                          data-toggle="modal"
                          data-target="#propertyModal"
                          onClick={reInitModal}
                        />
                      </div>
                    </div>
                    <div className="nftcreateproperty">
                      {allProperties.map((value, key) => (
                        <div
                          key={key}
                          className="PropertyCellreact__DivContainer-sc-1smi6j9-0 esyMuQ item--property"
                        >
                          <div className="Property--type">{value.type}</div>
                          <div
                            className="Property--value"
                            data-testid="Property--value"
                          >
                            {value.name}
                          </div>
                        </div>
                      ))}
                      <div>{propertyError}</div>
                    </div>
                    <div className="nftform-action-item col-12 d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-center">
                        {/* <img className="imgw-30" src={levelIcon} /> */}
                        <div className="flex-column justify-content-between align-items-baseline">
                          <h3>
                            <i className="fa fa-list-ol"></i> Levels
                          </h3>
                          <p>Numerical traits will show as progress bar.</p>
                        </div>
                      </div>
                      <div className="rounded-circle-bg d-flex align-items-center justify-content-center">
                        <img
                          className="imgw-30 cursor-pointer"
                          src={GrouppopUpplus}
                          data-backdrop="static"
                          data-keyboard="false"
                          data-toggle="modal"
                          data-target="#levelsModal"
                          onClick={reInitModal}
                        />
                      </div>
                    </div>
                    <div className="nftlevel">
                      {allLevels.map((value, key) => (
                        <div key={key} className="nftcreatelevel">
                          <div className="NumericTraitCellreact__DivContainer-sc-1vpqa3a-0 fnTiVF item--numeric-trait">
                            <div className="NumericTrait--label">
                              <div className="NumericTrait--type">
                                {value.name}
                              </div>
                              <div className="NumericTrait--value">
                                {value.value_one} of {value.value_two}
                              </div>
                            </div>
                            <div className="NumericTrait--bar">
                              <div
                                className="NumericTrait--bar-fill"
                                style={{
                                  width: `${100 * value.value_one / value.value_two}%`,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="nftform-action-item col-12 d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-center">
                        {/* <imallPropertiesg className="imgw-30" src={unlockIcon} /> */}
                        <div className="flex-column justify-content-between align-items-baseline">
                          <h3>
                            <i className="fa fa-unlock-alt"></i>Unlockable
                            Content
                          </h3>
                          <p>Content that can only be seen by the owner.</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <label className="switch switch-radio-class">
                          <input
                            onChange={(event) =>
                              setUnlockable(event.target.checked)
                            }
                            name="unlockabl"
                            type="checkbox"
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </div>

                    {unlockable && (
                      <div className="col-12">
                        <div className="form-group mt-3">
                          <Form.Item
                            name="unlockable_link"
                            rules={[
                              {
                                required: false,
                                message: "Please input text!",
                              },
                            ]}
                          >
                            <textarea
                              name="unlockable_link"
                              placeholder="Enter content (access key, code to redeem, link to a file, etc.)"
                            ></textarea>
                          </Form.Item>
                        </div>
                      </div>
                    )}

                    <div className="nftform-action-item col-12 d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-center">
                        {/* <img className="imgw-30" src={sensitiveIcon} /> */}
                        <div className="flex-column justify-content-between align-items-baseline">
                          <h3>
                            <i className="fa fa-exclamation-triangle"></i>{" "}
                            Explicit & Sensitive Content
                          </h3>
                          <p>Set as explicit and sensitive content.</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <label className="switch switch-radio-class">
                          <input
                            onChange={(event) =>
                              setExplixity(event.target.checked)
                            }
                            name="explicit"
                            type="checkbox"
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </div>
                    <div className="nftform-action-item col-12 d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="flex-column justify-content-between align-items-baseline">
                          <h3>
                            <i className="fa fa-gavel" aria-hidden="true"></i>{" "}
                            Enable Lazy Minting
                          </h3>
                          <p>Set as enable lazy minting.</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <label className="switch switch-radio-class">
                          <input
                            onChange={(event) =>
                              setEnableLazyMinting(event.target.checked)
                            }
                            name="enable_lazy_minting"
                            type="checkbox"
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </div>

                    {/* ********************************************************************* */}
                    <div className="nftform-action-item col-12 d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="flex-column justify-content-between align-items-baseline">
                          <h3 className="top-adjust">                          
                            Freeze metadata {" "}
                            <i class="fa fa-info-circle"></i>
                          </h3>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <label className="switch switch-radio-class">
                          <input
                            onChange={(event) =>
                              setFreezeMetadata(event.target.checked)
                            }
                            name="freeze_metadata"
                            type="checkbox"
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </div>

                    {/* ********************************************************************** */}

                    {/* <div className="col-12">
                                            <div className="form-group mt-3">
                                                <label>Supply </label>
                                                <p>Number of copies to be minted.</p>
                                                <input type="text" className="nft-form-control" name="item_name" />
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="form-group mt-3">
                                                <label>Chain </label>
                                                <div className="dropdown coin-selectType">
                                                    <button className="btn btn-secondary dropdown-toggle d-flex justify-content-start align-items-center" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <img src={nftTypeIcon} />Another action
                                                    </button>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <a className="dropdown-item" href="#"><img src={nftTypeIcon} />Another action</a>
                                                        <a className="dropdown-item" href="#">Something else here</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                    <div className="col-12">
                      <div className="form-group mt-3">
                        <label>
                          Supply<span className="required-class">*</span>{" "}
                        </label>
                        <p>
                          The number of items that can be minted. No gas cost to
                          you!
                        </p>
                        <Form.Item
                          name="supply"
                          rules={[
                            {
                              required: true,
                              message: "Please input supply!",
                            },
                          ]}
                        >
                          <input
                            type="number"
                            className="nft-form-control"
                            name="supply"
                            onWheel={(e) => e.target.blur()}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="flex-column w-100 px-3 mb-4">
                      <h3 className="title-inner">Blockchain</h3>
                      <p className="mb-3 mt-1 small-para">Select the blockchain where you'd like new items from this collection to be added by default.</p>
                      <div className="d-flex justify-content-start align-items-start gap-10 cstom-select-box1">
                        {/* <select onChange={(event) => setNetworkSelected(event.target.value)} name="network">
                          {networkList.length > 0 ? networkList.map((value, key) => (
                            <option key={key} value={value._id}>{value.chain_label}
                              {value?.network == 'binance'}
                              {value?.network == 'polygon'}
                              {value?.network == 'ethereum'}
                              </option>
                          )) : ''}
                        </select> */}
                      </div>

                      <Select
                        // getOptionLabel={option => option.chain_name}
                        getOptionLabel={(opt) => (
                          <div className="d-flex align-items-center">
                            <img
                              src={chainImageHandler(opt?.network)}
                              height="35px"
                              width="35px"
                            />

                            <span className="d-block ml-2">
                              {opt?.chain_label}
                            </span>
                          </div>
                        )}
                        placeholder={'Select Network..'}
                        value={networkSelected}
                        getOptionValue={(option) => option._id}
                        options={networkList}
                        // onChange={handleChange}
                        onChange={(e) => { setNetworkSelected(e) }}
                        menuPosition="absolute"
                        menuPortalTarget={document.body}
                      />
                      {/* <div style={{ color: 'red' }}>{selectCollectionErr}</div> */}
                    </div>
                    <div className="flex-column w-100 px-3 mb-4 d-none">
                      <h3>Token Standard</h3>
                      <p className="mb-3 mt-1">Select the token type.</p>
                      <div className="d-flex justify-content-start align-items-start gap-10 cstom-select-box">
                        <select
                          onChange={(event) =>
                            setTokenStandardSelected(event.target.value)
                          }
                          name="token_standard"
                        >
                          {TokenStandard.map((value, key) => (
                            <option key={key} value={value}>
                              {value}
                            </option>
                          ))}
                        </select>
                      </div>
                      {/* <div style={{ color: 'red' }}>{selectCollectionErr}</div> */}
                    </div>
                    <div className="col-12">
                      <button
                        className="create-btn mt-3 mt-sm-4 mb-5"
                        type="submit"
                      >
                        {!loading ? "Create" : "Please Wait..."}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
      <NftFormPopups
        returnCategory={(categories) => selectCategory(categories)}
        selectedCategories={selectedCategories}
        returnProperty={(property) => setProperty(property)}
        allProperties={allProperties}
        allLevels={allLevels}
        returnLabel={(label) => setLabel(label)}

      // setProperties={(event) => handelProperty(event)}
      // allProperties={!isLoading ? allProperties : allProperties}
      // saveAllLevels={(data) => handelLavle(data)}
      // allLevels={!isLoading ? allLevels : allLevels}
      // setIsSaveProperty={setIsSaveProperty}
      // setIsSaveLevel={setIsSaveLevel}
      />
    </>
  );
}
const mapStateToProps = (state) => ({
  islogin: state.auth.isLogin,
  auth: state.auth.auth,
});

export default connect(mapStateToProps)(Create);

import { SAGA_ACTIONTYPES } from "../../store/nft/action";
import { useApiCall } from "./appApiCallHook";

export function useNftApi() {
  const callApi = useApiCall();

  const getNftListing = (data, onSuccess, onError) => {
    callApi(SAGA_ACTIONTYPES.NFT_LISTING, data, onSuccess, onError);
  };
  const revealMysteryBox = (data, onSuccess, onError) => {
    callApi(SAGA_ACTIONTYPES.REVEAL_MYSTERY_BOX, data, onSuccess, onError);
  };

  const getOwnerList = (data, onSuccess, onError) => {
    callApi(SAGA_ACTIONTYPES.GET_OWNER_LIST, data, onSuccess, onError);
  };
  const getRevealedList = (data, onSuccess, onError) => {
    callApi(SAGA_ACTIONTYPES.GET_REVEALED_LIST, data, onSuccess, onError);
  };
  const getMysteryBoxToken = (data, onSuccess, onError) => {
    callApi(SAGA_ACTIONTYPES.GET_MYSTERY_BOX_TOKEN, data, onSuccess, onError);
  };
  const reportItem = (data, onSuccess, onError) => {
    callApi(SAGA_ACTIONTYPES.REPORT_ITEM, data, onSuccess, onError);
  };
  const nftUnlockableContent = (data, onSuccess, onError) => {
    callApi(SAGA_ACTIONTYPES.UNLOCKABLE_CONTENT, data, onSuccess, onError);
  };
  
  return {
    getNftListing,
    getOwnerList,
    getRevealedList,
    revealMysteryBox,
    getMysteryBoxToken,
    reportItem,
    nftUnlockableContent
  };
}

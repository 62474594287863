import axios from "./config";

export class CallApi {
  static GET(url, params) {
    if (params.urlParams && typeof params.urlParams === "object") {
      let urlParams = params.urlParams;

      for (let key in urlParams) {
        if (urlParams.hasOwnProperty(key)) {
          url += `/${urlParams[key]}`;
        }
      }
      delete params.urlParams;
    }

    if (params.queryParams && typeof params.queryParams === "object") {
      let queryParams = params.queryParams;
      let queryString = []

      for (let key in queryParams) {
        queryString.push(`${key}=${queryParams[key]}`)
      }
      url += '?'+queryString.join('&')
      delete params.queryParams;
    }

    return new Promise((resolve, reject) => {
      return axios({
        method: "GET",
        url,
        params,
      })
        .then((success) => {
          return resolve(success);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  }

  static POST(url, data) {

    if (data.urlParams && typeof data.urlParams === "object") {
      let urlParams = data.urlParams;

      for (let key in urlParams) {
        if (urlParams.hasOwnProperty(key)) {
          url += `/${urlParams[key]}`;
        }
      }
      delete data.urlParams;
    }

    return new Promise((resolve, reject) => {
      return axios({
        method: "POST",
        url,
        data,
      })
        .then((success) => {
          return resolve(success);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  }
  static PUT(url, data) {
    return new Promise((resolve, reject) => {
      return axios({
        method: "PUT",
        url,
        data,
      })
        .then((success) => {
          return resolve(success);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  }
  static DELETE(url, data) {
    return new Promise((resolve, reject) => {
      return axios({
        method: "DELETE",
        url,
        data,
      })
        .then((success) => {
          return resolve(success);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  }
}

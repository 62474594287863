import { useDispatch } from "react-redux";
import { appLoaderStatus } from "../../store/comman/action";

export function useAppLoader() {
  const dispatch = useDispatch();

  const show = () => {
    dispatch(appLoaderStatus(true));
  };

  const hide = () => {
    dispatch(appLoaderStatus(false));
  };

  return { showLoader: show, hideLoader: hide };
}

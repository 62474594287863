import { call, put, takeEvery } from 'redux-saga/effects'
import cookies from "js-cookie";
import AuthService from '../../api/AuthService'
import { actionTypes } from './action';

async function login(params) {
  return await AuthService.UserLogin(params)
  .then((user) => user.data)
  .catch((error) => error);
}

function* fetchUsers({payload}) {
   try {
      const users = yield call(login,payload);
      cookies.set('token',users.token)
      yield put({type: actionTypes.SIGNATURE, payload:{user: users.data}});
   } catch (e) {
      console.log('e',e)
      yield put({type: 'GET_USERS_FAILED', message: e.message});
   }
}

function* userSaga() {
   yield takeEvery('LOGIN', fetchUsers);
}

export default userSaga;
import { SAGA_ACTIONTYPES } from "../../store/nft/action";
import { useApiCall } from "./appApiCallHook";

export function useImportApi() {
    const callApi = useApiCall();

    const importNft = (data, onSuccess, onError) => {
        // console.log('import api hook', data);
        callApi(SAGA_ACTIONTYPES.IMPORT.IMPORT_NFT, data, onSuccess, onError);
    }

    const confirmBulkUpload = (data, onSuccess, onError) => {
        callApi(SAGA_ACTIONTYPES.IMPORT.BULK_UPLOAD, data, onSuccess, onError);
    }

    const csvList = (data, onSuccess, onError) => {
        callApi(SAGA_ACTIONTYPES.IMPORT.CSV_LIST, data, onSuccess, onError);
    }

    const addFeedback = (data, onSuccess, onError) => {
        callApi(SAGA_ACTIONTYPES.FEEDBACK_ADD, data, onSuccess, onError);
    }

    return { importNft, confirmBulkUpload, csvList, addFeedback }
}
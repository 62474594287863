import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://lunasky.com/json-2/blog";

class Blog extends Component {
    state = {
        data: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data.blogData
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section className="blog-area">
                <div className="container">
                <h3 className="comming_soon_text mt-3 mb-0 text-center">Coming Soon </h3>
                </div>
            </section>
        );
    }
}

export default Blog;
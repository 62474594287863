import * as Yup from 'yup';

export const NFTSchema = Yup.object().shape({
  item_name: Yup.string()
    .max(50, 'Too Long!')
    .required('Please Enter Item Name!'),
  external_link: Yup.string(),
  collection_id: Yup.string().required('Collection required!.'),
});

export const CollectionSchema = Yup.object().shape({
  collection_name: Yup.string()
    .max(70, 'Too Long!')
    .required('Please Enter Collection Name!'),
    creator_fee: Yup.string()
    .required('Please Enter Fees!')
});

export const ProfileSchema = Yup.object().shape({
  username: Yup.string()
    .max(50, 'Too Long!')
    .required('Please Enter Username!'),
  bio: Yup.string().required('Please Enter Bio!'),
  email: Yup.string().email('Invalid email').required('Please Enter Email!'),
  
});

export const MysteryBoxSchema = Yup.object().shape({
  item_image: Yup.string().required('Please attach file.'),
  collection_id: Yup.string().required('Collection required!.'),
  box_name: Yup.string()
  .max(100, 'Too Long! Box name should be within 70 words.')
  .required('Please enter box name.'),
  // categories: Yup.array().min(1, "Category required!").required("Category required!"),
  description: Yup.string().required('Please enter some description.'),
  supply: Yup.string().required('Please enter supply.'),
  // network: Yup.string().required('Please select network!.'),
  // issue_quantity: Yup.string().required('Please enter quantity.')
});

export const FeedbackSchema = Yup.object().shape({
  userName: Yup.string().required('Please enter your name'),
  userEmail: Yup.string()
  .matches(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    "Please enter a valid email format")
  .required('Please enter your email'),
  userFeedback: Yup.string()
        .max(1000, 'Feedback should be within 1000 characters.')
        .min(50, 'Feedback should be over 50 characters.')
        .required('Please enter feedback or suggestions.'),
  userPhone: Yup.string().min(10, 'Phone number should be 10 digit.')
});
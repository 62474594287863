import React, { useState } from 'react';
// import useMoralisService from '../../hooks/useMoralisService';
import { notification } from "antd";
import { SmileOutlined, CloseOutlined } from "@ant-design/icons";

import './Footer.css';
import { NavLink } from 'react-router-dom';
import logo from './logo.png'
import firstImg from '../../image/Group.png'
import Img1 from '../../image/Group2.png'
import Img2 from '../../image/Group3.png'
import Img3 from '../../image/Group4.png'
import Img4 from '../../image/Group5.png'
import Img5 from '../../image/Group6.png'

import Insta from '../../image/IG.svg'
import Twitter from '../../image/Twitter.svg'
import Facebook from '../../image/FB.svg'

import Img6 from '../../image/Group7.png'
import TiktokImg from '../../image/DayMode_TikTok.png'
import nftLOGO from './NFT-Circle-logo.png'
import NFT_Logo from './lunasky.png'
import Circle from '../../image/Circle.png'

//custom hooks
import useAuth from '../../hooks/useAuth'

const Footer = () => {
    const [erorr, setError] = useState('')
    const { emailCheck, saveEmail } = useAuth()

    const checkEmail = async (email) => {
        var res = await emailCheck({ 'emails': email })
        res = res.emailCheck
        if (res) {
            return false
        } else {
            return true
        }
    }

    const SaveEmail = async (event) => {
        event.preventDefault()
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (event.target.email.value === '') {
            setError('Please Enter Your Email!')
            return
        }
        if (!event.target.email.value.match(validRegex)) {
            setError('Please Enter A Valid Email!')
            return
        }
        if (!await checkEmail(event.target.email.value)) {
            setError('We already have this email!')
            return
        }

        var data = { emails: event.target.email.value }

        var response = await saveEmail(data)
        if (response) {
            notification.open({
                message: "Email",
                description: "Thanks For Connecting With Us!",
                icon: <SmileOutlined style={{ color: "#108ee9" }} />,
            });
            setError('')
            document.getElementById("emailForm").reset();


        } else {
            notification.open({
                message: "Error",
                description: "Something went wrong...!",
                icon: <CloseOutlined style={{ color: "#f4364c" }} />,
            });
        }
    }
    return (
        <footer className="footer-area">
            {/* Footer Top */}
            <div className="footer-top">
                <div className="container-fluid ">
                    <div className="row">
                        <div className="col-sm-6 mobile-100">
                            <div className='forgetFomo'>
                                {/* <h2 className="nft-heading1 m-0">Stay Updated On LunaSky</h2>
                                    <p className="nft-paragraph-1 m-0 my-2">Join our mailing list for Alerts and Information: What's New, Feature Releases, NFT Drops, Tips, Tricks and more...
                                    </p> */}
                                <h2 className="nft-heading1 m-0">JOIN OUR COMMUNITY</h2>
                                <p className="nft-paragraph-1 m-0 my-2">LunaSky has an active growing community across Twitter, Facebook &amp; Instagram. We have technical support available via Twitter messenger 24/7. Please join our socials to connect with other users and the LunaSky team</p>
                            </div>
                            <div className="footer-right-part-wrap">
                                <div className='footer-social-section'>
                                    <div className='footer-social'>
                                        <ul>
                                            <li>
                                                <a href="https://twitter.com/lunaskynft" target="_blank">
                                                    <img className='footerIcon' src={Img4} />
                                                    <img className='footerIcon night_footerIcon' src={Twitter} />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/LunaSkyNFT/" target="_blank">
                                                    <img className='footerIcon' src={Img3} />
                                                    <img className='footerIcon night_footerIcon' src={Insta} />
                                                </a>
                                            </li>

                                            <li>
                                                <a href="https://www.facebook.com/LunaSkyNFT/" target="_blank">
                                                    <img className='footerIcon' src={Img5} />
                                                    <img className='footerIcon night_footerIcon' src={Facebook} />
                                                </a>
                                            </li>
                                            {/* <li>
                                                <a href="https://vt.tiktok.com/ZSdtjafoQ/" target="_blank">
                                                    <img className='footerIcon' src={TiktokImg} />
                                                    <img className='footerIcon night_footerIcon' src={TiktokImg} />
                                                </a>
                                            </li> */}
                                            {/* <li>
                                                    <a href="#" target="_blank">
                                                        <img className='footerIcon' src={Img6} />
                                                    </a>
                                                </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 mobile-100">
                            {/* Footer Items */}
                            <div className="footer-items mt-3">
                                {/* Footer Title */}
                                {/* Subscribe Form */}
                                <div className="subscribe-form d-flex align-items-center">
                                    <div className="subscribe-form-label">To keep up to date with news & information<br />please subscribe to our Newsletter.</div>
                                    <form id="emailForm" onSubmit={(e) => SaveEmail(e)}>
                                        <input type="email" name="email" className="nft-input-Email" placeholder="Your email address" />
                                        <button type="submit" className="nft-sub-btn">Sign up</button>
                                    </form>
                                </div>
                                {erorr !== '' ? <div role="alert" className="ant-form-item-explain-error">{erorr}</div> : ''}

                            </div>
                        </div>
                    </div>
                    <div className='border-bottom border-dark my-3 mx-3'></div>
                    {/* <div className='row reverse-row'>
                            <div className='col-sm-8 col-12 col-xl-8 col-md-7 mobile-100'>
                                <div className='footerEGCDesccls'>
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            <div className='footer-about-wrap'>
                                                <img className='footerLogoNft' src={NFT_Logo} />
                                                <p className="nft-footer-para-style-1 nft-footer-text">LunaSky.com Platform. A decentralized marketplace for easing liquidity in NFT DeFi. Built on BSC, our marketplace allow NFT owners to easily borrow against their NFTs as collateral on fair interest rates without having to sell them. This means you can earn from your NFTs, instead of having them sit there gathering dust in your wallet.</p>
                                            </div>
                                        
                                        </div>

                                        <div className='col-sm-10 m-0 p-0 pr-5'>
                                            <p className="nft-footer-para-style-1">EGC.NFT Platform. The first-ever decentralized marketplace for easing liquidity in NFT DeFi. Built on BSC, our marketplace allow NFT owners to easily borrow against their NFTs as collateral on fair interest rates without having to sell them. This means you can earn from your NFTs, instead of having them sit there gathering dust in your wallet.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 col-12 col-xl-4 col-md-5 mobile-100">
                                <div className="footer-right-part-wrap">
                                <div className='footer-social-section'>
                                    <h2 className="nft-heading1">Connect with the Community</h2>
                                    <div className='footer-social'>
                                        <ul>
                                            <li>
                                                <a href="#" target="_blank">
                                                    <img className='footerIcon' src={firstImg} />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" target="_blank">
                                                    <img className='footerIcon' src={Img1} />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" target="_blank">
                                                    <img className='footerIcon' src={Img2} />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/LunaSkyNFT/" target="_blank">
                                                    <img className='footerIcon' src={Img3} />
                                                    <img className='footerIcon night_footerIcon' src={Insta} />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://twitter.com/lunaskynft" target="_blank">
                                                    <img className='footerIcon' src={Img4} />
                                                    <img className='footerIcon night_footerIcon' src={Twitter} />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.facebook.com/LunaSkyNFT/" target="_blank">
                                                    <img className='footerIcon' src={Img5} />
                                                    <img className='footerIcon night_footerIcon' src={Facebook} />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://vt.tiktok.com/ZSdtjafoQ/" target="_blank">
                                                    <img className='footerIcon' src={TiktokImg} />
                                                    <img className='footerIcon night_footerIcon' src={TiktokImg} />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" target="_blank">
                                                    <img className='footerIcon' src={Img6} />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="footer-links-panel">
                                    <div className="row reverse-row w-100 justify-content-end">
                                        <div className="col-sm-6">
                                            <h2 className="nft-heading-1 m-0 p-0 mb-2">Resources</h2>
                                            <ul className="list-unstyled d-flex flex-column align-items-start">
                                                <li>
                                                    <NavLink to="/help-center">Help Center</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink  to="/blog">Blog</NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-6">
                                            <h2 className="nft-heading-1 m-0 p-0 mb-2">Company</h2>
                                            <ul className="list-unstyled d-flex flex-column align-items-start">
                                                <li>
                                                    <NavLink to="/about">About</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink  to="/contact">Contact</NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> 
                                </div>                              
                                
                            </div>
                        </div> */}
                </div>
            </div>
            {/* Footer Bottom */}
            <div className="footer-bottom">
                <div className="container-fluid">
                    <div className="footer-bottom-wrap">
                        <p>&copy; LunaSky All Rights Reserved 2022</p>
                        <div className='foot-links-wrap'>
                            <NavLink className="nav-link footer-link" to="/privacy-policy">Privacy Policy</NavLink>
                            <NavLink className="nav-link footer-link" to="/terms-of-service">Terms of Service</NavLink>
                        </div>
                    </div>
                </div>
                {/* <div className="container-fluid">
                        <div className="row">
                            <div className="col-5">
                                <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                    <div className="copyright-left mb-3"></div>
                                </div>
                            </div>
                            <div className="col-7 d-flex flex-wrap justify-content-end">
                                <div className="footer-links-panel">
                                <div className="row w-100 justify-content-end copyright-area">
                                    <div className="col-sm-6">
                                        
                                    </div>
                                    <div className="col-sm-6">
                                        
                                    </div>
                                </div>
                                </div>
                                <div className="copyright-area d-flex flex-wrap justify-content-end justify-content-sm-end text-center py-4">
                                    <ul className="list-unstyled d-flex gap-35">
                                        <li>
                                            <NavLink className="nav-link" to="/privacy-policy">Privacy Policy</NavLink>
                                        </li>
                                        <li>
                                            <NavLink className="nav-link" to="/terms-of-service">Terms of Service</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> */}
            </div>
        </footer>
    );
}

export default Footer;
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Spin, notification } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { useUserApi } from "../hooks/api/userApiHook";

const EmailVerifyPage = (props) => {
  const { appLoaderStatus } = props;
  const history = useHistory();
  const { verifySubscribedEmail } = useUserApi();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const [response, setResponse] = useState({});

  useEffect(() => {
    if (token) {
      verifyHandler(token);
    }
  }, [token]);

  const verifyHandler = (token) => {
    verifySubscribedEmail(
      { token },
      (message, resp) => {
        setResponse({ success: true, message });
        setTimeout(() => {
          history.push("/");
        }, 5000);
      },
      (message, error) => {
        setResponse({ success: false, message });
      }
    );
  };

  return (
    <div className="containerEmail">
      {appLoaderStatus ? <Spin /> : null}
      {response.hasOwnProperty("success") ? (
        <>
          {response.success ? (
            <div className="popup">
              <img src="/img/404-tick.png" alt="tickmark" />
              <h2>{response?.message}</h2>
            </div>
          ) : (
            <div className="popup">
              <img src="/img/cross.jpg" alt="tickmark" />
              <h2>{response?.message}</h2>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appLoaderStatus: state.comman.appLoaderStatus,
  };
};

export default connect(mapStateToProps)(EmailVerifyPage);

import React, { useState, useEffect } from "react";
import { Form, Button } from "antd";
import Web3 from 'web3';

export default function TransferNft({ authUser, islogin, ownerNft, loading, nft, nftTransfer }) {
  const transfer = (data) => {
    nftTransfer(data)
  };
  return (
    <>
      <div className="connect-wallet-box-main make-offer-main">
        <h2 className="main-heading">NFT Transfer</h2>
        <div className="wallet-infobox px-4">
          {!islogin ? (
            <div className="pt-lg-5">
              <p className="connect-wallet-message">*Please connect your wallet to continue.</p>
              <button
                data-toggle="modal"
                data-target="#exampleModalCenter"
                className="connect-wallet-btn"
              >
                Connect Your Wallet
              </button>
            </div>
          ) : (
            <>
              <div className="text-center col-sm-6">

              </div>
              <div>
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-1 mx-0 px-0 d-none"></div>
                    <div className="col-2 row align-items-end flex-column text-left d-none"></div>
                    <div className="col-12">
                      <img
                        className="rounded mt-4"
                        src={
                          nft && nft.item_resized_image
                            ? nft.item_resized_image : nft.item_image
                        }
                      />
                      {/* : "https://via.placeholder.com/80x121.png?text=Use" */}
                    </div>
                    <div className="col-12 p-0 res">
                      <Form
                        className="item-form createNftForm  no-hover"
                        onFinish={transfer}
                        initialValues={{
                          to_wallet_address: "",
                          quantity: 1,
                        }}
                      >
                        <div className="row row-default">
                          <div className="col-6">
                            <div className="form-group mt-3 mb-0">
                              <label>
                                Quantity
                                <span className="required-class">*</span>{" "}
                              </label>
                              <Form.Item
                                name="quantity"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input quantity!",
                                  },
                                  {
                                    message: 'Insufficient quantity!',
                                    validator: (_, value) => {
                                      if (value > (ownerNft['supply'] - ownerNft['revealed_count'])) {
                                        return Promise.reject();
                                      } else {
                                        return Promise.resolve();
                                      }
                                    }
                                  }
                                ]}
                              >
                                <input
                                  type="number"
                                  className="nft-form-control mb-1"
                                  name="quantity"
                                  placeholder="Quantity"
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group mt-3 mb-0">
                              <label>
                                Address
                                <span className="required-class">*</span>{" "}
                              </label>
                              <Form.Item
                                name="to_wallet_address"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input address!",
                                  },
                                  {
                                    message: 'Invalid wallet address!',
                                    validator: (_, value) => {
                                      if (!Web3.utils.isAddress(value)) {
                                        return Promise.reject();
                                      } else {
                                        return Promise.resolve();
                                      }
                                    }
                                  },
                                  {
                                    message: 'Cannot transfer to self!',
                                    validator: (_, value) => {
                                      if (value.toLowerCase() === authUser['wallet_address'].toLowerCase()) {
                                        return Promise.reject();
                                      } else {
                                        return Promise.resolve();
                                      }
                                    }
                                  }
                                ]}
                              >
                                <input
                                  type="text"
                                  className="nft-form-control mb-1"
                                  name="to_wallet_address"
                                  placeholder="Wallet address"
                                  autoComplete="off"
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-12 text-center">
                            <button className="create-btn w-100" type="submit">
                              {!loading ? "Transfer" : "Please Wait..."}
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function TopCratorsSkeleton() {
  const dummyArr = [1, 2, 3, 4, 5, 6];

  return (
    <div className="games__wrap CratoePageComponent">
            <div className="container-fluid">
                <div className="row">
                <div className="col-3 mt-10">
                  <div className="category-mainmenu">
                    <ul className="list-unstyled games-sidebar-main sidebarStyle1">
                      <Skeleton
                        style={{
                          width: "100%",
                          height: "40px",
                          objectFit: "cover",
                          borderRadius: "20px",
                        }}
                        count={4}
                      />
                    </ul>
                    <div className="category-sidebar">
                      <Skeleton
                        style={{
                          width: "100%",
                          height: "40px",
                          objectFit: "cover",
                          borderRadius: "20px",
                        }}
                        count={3}
                      />
                    </div>
                  </div>
                  <div className="subcategories-filters mt-4">
                    <ul className="list-unstyled categoryFilter">
                      <Skeleton
                        style={{
                          width: "100%",
                          height: "40px",
                          objectFit: "cover",
                          borderRadius: "20px",
                        }}
                        count={13}
                      />
                    </ul>
                  </div>
                  </div>
                    <div className="col-9">
                    <h3 className="oracleTextClass" style={{ width: "100%"}}> <Skeleton /></h3>
                        <div className="row">
                        {dummyArr.map((el) => {
                          return (
                          <div key={el} className="col-4 p-0 mb-3 position-relative" >
                                        <div className="game-box box-no-bg-color mr-3 " style={{ backgroundColor: "#333" }}>
                                            <div className="creator-profilebanner" style={{ zIndex: "0" }}>
                                                <Skeleton
                                                  animation="wave"
                                                  variant="circular"
                                                  width={"100%"}
                                                  height={200}
                                                />
                                            </div>
                                            <div className="game-box-dark creators-data">
                                                <div className="crator-user-profile-image" style={{ zIndex: "1" , borderColor: "#fff" }}>
                                                <div className="profile-circle"
                                                style={{ zIndex: "1" }}
                                              >
                                                <Skeleton
                                                  animation="wave"
                                                  variant="circular"
                                                  width={230}
                                                  height={230}
                                                />
                                              </div>
                                                </div>
                                                <div className="nft-btn-wrap">
                                                <Skeleton 
                                              style={{
                                                 width: "80px",
                                                 borderRadius: "20px",
                                               }}  height={40} ></Skeleton>
                                                </div>
                                                <h2 className="nft-heading-1 m-0" style={{ width: "94%", margin: "0 auto", textAlign: "center !important"}}>
                                                  <Skeleton
                                                    animation="wave"
                                                    height={30}
                                                    width="100%"
                                                    style={{ marginBottom: 6 , marginLeft: 10, textAlign: "center !important"}}
                                                  />
                                                </h2>
                                                <h3 className="nft-subheading m-0"  style={{ width: "100%" , margin: "0 auto", textAlign: "center !important"}}>
                                                <Skeleton
                                                    animation="wave"
                                                    height={10}
                                                    width="60%"
                                                    style={{ marginBottom: 6 , marginLeft: 10, textAlign: "center !important"}}
                                                  />
                                                </h3>
                                                <p className="nft-box-list-desc m-0 mb-2"></p>
                                            </div>
                                        </div>
                                    </div>
                              );
                            })}
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
  );
}

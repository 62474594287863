import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function TopCratorsSkeleton() {
  const dummyArr = [1, 2, 3, 4, 5, 6];

  return (
    <div className="games__wrap CratoePageComponent">
            <div className="container-fluid">
                <div className="row">
                <div className="col-3 mt-10">
                  <div className="category-mainmenu">
                    <ul className="list-unstyled games-sidebar-main sidebarStyle1">
                      <Skeleton
                        style={{
                          width: "100%",
                          height: "40px",
                          objectFit: "cover",
                          borderRadius: "20px",
                        }}
                        count={4}
                      />
                    </ul>
                    <div className="category-sidebar">
                      <Skeleton
                        style={{
                          width: "100%",
                          height: "40px",
                          objectFit: "cover",
                          borderRadius: "20px",
                        }}
                        count={3}
                      />
                    </div>
                  </div>
                  <div className="subcategories-filters mt-4">
                    <ul className="list-unstyled categoryFilter">
                      <Skeleton
                        style={{
                          width: "100%",
                          height: "40px",
                          objectFit: "cover",
                          borderRadius: "20px",
                        }}
                        count={13}
                      />
                    </ul>
                  </div>
                  </div>
                    <div className="col-9">
                    <h3 className="oracleTextClass" style={{ width: "100%"}}> <Skeleton /></h3>
                    <div className="explore-list-row">
                        <div className="row collections-list-items" >
                        {dummyArr.map((el) => {
                          return (
                          <div key={el} className="col-4 p-0 mb-3 nfts-list" >
                                        <div className="game-box mr-3">
                                              <div  style={{
                                                backgroundColor: "#333",
                                                 width: "100%",
                                                 height: "333px",
                                               }} >
                                               </div> 
                                            <div className="game-box-dark padding_game_box row position-relative flex-column">
                                                <div className="nft-go-btn"  style={{
                                                padding: "unset",
                                                zIndex: "1",
                                                backgroundColor: "unset",
                                                height: "48px",
                                               }} >
                                                <Skeleton 
                                              style={{
                                                bgcolor: '#121212',
                                                 width: "80px",
                                                 borderRadius: "20px",
                                                 textAlign: "center !important",
                                               }}  height={40} ></Skeleton>
                                                </div>
                                                <h2 className="nft-heading-1 m-0" style={{ width: "100%", margin: "0 auto", textAlign: "center !important"}}>
                                                  <Skeleton
                                                    animation="wave"
                                                    height={30}
                                                    width="100%"
                                                    style={{ marginBottom: 6 ,  textAlign: "center !important"}}
                                                  />
                                                </h2>
                                                <h3 className="nft-subheading m-0"  style={{ width: "100%" , margin: "0 auto", textAlign: "center !important"}}>
                                                <Skeleton
                                                    animation="wave"
                                                    height={10}
                                                    width="60%"
                                                    style={{ marginBottom: 6 , textAlign: "center !important"}}
                                                  />
                                                </h3>
                                            </div>
                                            </div>
                                        </div>
                              );
                            })}
                        </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
  );
}

export const actionTypes = {
    GET_COLLECTIONS: 'GET_COLLECTIONS',
    LOADING: 'LOADING',   
    GET_SINGLE_COLLECTIONS: 'GET_SINGLE_COLLECTIONS',   
    UPDATE_PROFILE:'UPDATE_PROFILE',
    BASIC_DETAILS:'COLLECTION/BASIC_DETAILS',
    REPORT_ITEM:"COLLECTION/REPORT_ITEM",
};

export function getcollections(payload) {
   return { type: actionTypes.GET_COLLECTIONS, payload };
}

export function getSinglecollections(payload) {
   return { type: actionTypes.GET_SINGLE_COLLECTIONS, payload };
}

export function loading() {
   return { type: actionTypes.LOADING };
}

export function GetSuccess(payload) {
   return { type: actionTypes.GET_NFT_SUCCESS, payload };
}

export function logout() {
   return { type: actionTypes.LOGOUT };
}

export function updateUserProfile(profile) {
   return { type: actionTypes.UPDATE_PROFILE ,profile};
}
import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function CratorSkeleton() {
  
  
  return (
    <div className="games__wrap m-0 NftDetailPageComponent">
    <div className="container-fluid">
        <div className="row">
            <div className="col-3">
            </div>
            <div className="col-9">
                <h3 className="oracleTextClass" style={{  width: "50%"}}> <Skeleton /></h3>
                <h3 className="nft-detail-hr"></h3>
                <div className="row">
                    <div className="col-6 p-0 pr-5">
                       
                        <Skeleton width={"100%"} height={400}/>
                        {/* <div className="details-info nft-properties d-flex justify-content-between align-items-center my-3">
                            <div className="d-flex align-items-center justify-content-between gap-18 ml-3">
                                <img src={transferImage} />
                                <h4>Properties</h4>
                            </div>
                            <img className="mr-3" src={SelectArrow} />
                        </div>
                        <div className="details-info nft-properties d-flex justify-content-between align-items-center mb-3">
                            <div className="d-flex align-items-center justify-content-between gap-18 ml-3">
                                <img src={transferImage} />
                                <h4>Details</h4>
                            </div>
                            <img className="mr-3" src={SelectArrow} />
                        </div> */}
                        <div id="accordion" className="SidebarAccordionFilters auction-edit-page mt-4">
                            <div className="card">
                            <h5 className="mb-0">
                                <Skeleton width={"100%"} height={50} borderRadius={10}/>
                                </h5>
                                <div id="collapseDesc" className="collapse show" aria-labelledby="headingDesc" data-parent="#accordion">
                                <h5 className="mb-0">
                                <Skeleton width={"100%"} height={100}/>
                                </h5>
                                </div>
                            </div>
                            <div className="card">
                            <h5 className="mb-0">
                                <Skeleton width={"100%"} height={50} borderRadius={10}/>
                                </h5>
                                <div id="collapseDesc" className="collapse show" aria-labelledby="headingDesc" data-parent="#accordion">
                                <h5 className="mb-0">
                                <Skeleton width={"100%"} height={100}/>
                                </h5>
                                </div>
                            </div>
                            <div className="card">
                            <h5 className="mb-0">
                                <Skeleton width={"100%"} height={50} borderRadius={10}/>
                                </h5>
                                
                            </div>
                            <div className="card">
                            <h5 className="mb-0">
                                <Skeleton width={"100%"} height={50} borderRadius={10}/>
                                </h5>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-6 p-0" >
                        <Skeleton 
                        width={"100%"}
                        height={100}/>
                        
                        <div className="nft-detail-informations flex-column align-center-start justify-content-start mt-70">
                            <h3><Skeleton width={"100%"}/></h3>
                            <h5 className="text-left"><Skeleton width={"80%"}/></h5>
                            <p className="mt-4"><Skeleton width={"60%"}/></p>
                            <div className="nft-detail-calltoac">
                            <Skeleton
                                    animation="wave"
                                    height={40}
                                    borderRadius={10}
                                    width="100%"
                                    className="mt-6"
                                    />
                                    <Skeleton
                                    className="mt-4"
                                    animation="wave"
                                    height={40}
                                    borderRadius={10}
                                    width="100%"
                                    />
                                    <Skeleton
                                    className="mt-4"
                                    animation="wave"
                                    height={40}
                                    borderRadius={10}
                                    width="100%"
                                    />
                                    <Skeleton
                                    className="mt-4"
                                    animation="wave"
                                    height={40}
                                    borderRadius={10}
                                    width="100%"
                                    />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid mb-5 p-0">
                    <div id="accordion" className="SidebarAccordionFilters auction-edit-page">
                        <div className="card">
                        <h5 className="mb-0">
                                <Skeleton width={"100%"} height={50} borderRadius={10}/>
                                </h5>
                            <div id="collapseActivityitems" className="collapse show" aria-labelledby="headingActivityitems" data-parent="#accordion">
                                <div className="card-body">
                                <h5 className="mb-0">
                                <Skeleton width={"100%"} height={200}/>
                                </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  );
}

import axios from "./config";
import cookies from "js-cookie";
var token = cookies.get("token");
// axios.defaults.headers.common["Authorization"] = "Bearer " + token;
class AuthRepository {
  
  async UserLogin(params) {
    const reponse = await axios.post(`/users/login/`, params)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }

  async AuthDetails() {
    const reponse = await axios.get(`/users/usercheck/`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }

  async GetUser(id) {
    const reponse = await axios.get(`/users/${id}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }
  async UserAuth(params) {
    const reponse = await axios.post(`/users/auth`, params)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }
  
  async GetAllCrators(data) {
    var url = 'users/get_crators';
    const queryString = Object.keys(data).map(key => `${key}=${data[key]}`).join('&')
    if ( queryString ) {
      url += `?${queryString}`
    }
    const reponse = await axios.get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }
  async GetSingleUser(data) {
    const reponse = await axios.post(`users/get_single_user`,data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }
  async UpdateUser(data,id) {
    const reponse = await axios.put(`/users/${id}`,data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }
  async SaveEmail(data) {
    const reponse = await axios.post(`/users/email`,data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }
  async EmailCheck(data) {
    const reponse = await axios.post(`/users/emailCheck`,data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }
}
export default new AuthRepository();

import React, { useState, useEffect, useRef } from "react";
import SwiperSlider from "./SwiperSlider";
import { NavLink } from 'react-router-dom';

const HomePageSlider = (props) => {
  const [sliderData, setSliderData] = useState()
  // const { sliderData } = props;
  const [currentSlider, setCurrentSlider] = useState({});

  useEffect(()=> {
    setSliderData([{name: 'Mystery Boxes', tag:'isMystery'}, ...props.sliderData])
  }, [])

  const childRef = useRef(null);

  useEffect(() => {
    if (sliderData?.length) {
      setCurrentSlider({ ...sliderData[0], index: 0 });
    }
  }, [sliderData?.length]);

  const changeCurrentSlider = (index) => {
    if ( index > sliderData.length ) {
      setCurrentSlider({ ...sliderData[0], index: 0 });
      childRef?.current?.slideTo(1);
    } else {
      const upadtedIndex= index === 0 ? sliderData.length : index;
      setCurrentSlider({ ...sliderData[upadtedIndex-1], index: upadtedIndex-1 });
      childRef?.current?.slideTo(upadtedIndex);
    }
    
    // if (sliderData[index]) {
    //   setCurrentSlider({ ...sliderData[index], index });
    //   childRef?.current?.slideTo(index);
    // }
  };

  if (!sliderData?.length) {
    return null;
  }
  return (
    <>
      <div className="container-fluid slider-container-custom">
        <h3 className="home-top-heading">
          {currentSlider?.name?.toUpperCase()} {/*TOP CREATORS */}
        </h3>
        <div className="row-banner">
          {/* <div className="col-12 form-transp-bg pt-3 pb-3"></div> */}
          <SwiperSlider
            tagChange={changeCurrentSlider}
            ref={childRef}
            sliderData={sliderData}
          />

          <div className="navitem-navname-panel w-100">
            {/* <div className=" itemName"> {JSON.stringify(currentSlider)} </div> */}
            <NavLink className="itemName view-all-link" to={`/assets?${currentSlider?.tag}=1`}>View all {currentSlider?.name}</NavLink>

            <div className="naviNames">
              {sliderData && sliderData.length
                ? sliderData.map((v, i) => {
                    return (
                        i == 0 ? 
                        <div key={i} className={ currentSlider?.index === i ? "active naviName" : "naviName" }>
                          <div onClick={()=> {changeCurrentSlider(i+1)}}>Mystery Boxes</div>
                        </div>
                         : 
                        <div key={i} className={ currentSlider?.index === i ? "active naviName" : "naviName" }>
                          <div onClick={()=> {changeCurrentSlider(i+1)}}>{v?.name}</div>
                          {/* <NavLink className="nft-game-go-gtn" to={`/assets?${sliderData[i]['tag']}=1`}>{v?.name}</NavLink> */}
                        </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePageSlider;

import React, { useEffect, useState, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import { connect } from "react-redux";
import SidebarmenuComponent from "../../themes/SidebarmenuComponent";
import NftList from "../NftList/";
import NFTloader from "../Loader/NftLoader";
import ExploreAllSkeleton from "../Skeletons/ExploreAllSkeleton";

//custom hooks
import useComman from "../../hooks/useComman";
import useNFT from '../../hooks/useNFT'
import * as qs from "query-string";

function ExplorePage({ props, routePath, allNft, loading, total, page }) {
  const { search: urlQueryString, pathname } = useLocation();
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [pagination, setPagination] = useState(1)
  const [skip, setSkip] = useState(9)

  //pagination logic
  let totalPage = parseInt((total + 9 - 1) / 9);
  const [activePage, setActivePage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [nftList, setNftList] = useState([])
  const [mysteryBox, setMysteryBox] = useState(false)

  //custom hooks functions
  const { categorieIds } = useComman()
  const { nftWithPagination } = useNFT()

  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  let mount = useRef(false)


  useEffect(() => {
    mount = true
    //const filter = {}
    //urlQueryString && urlQueryString.substring(1).split("&").reduce((a, c) => a[c.split('=')[0]] = c.split('=')[1], filter)

    const filter = qs.parse(urlQueryString);
    //console.log('filter: ', filter && filter['categories'] && filter['categories'].split(',') || [])
    //setSelectedCategory(filter && filter['categories'] && filter['categories'].split(',') || []);
    // changeArt(filter.categories && filter.categories.length ? filter.categories.split(',') : [] );

    _getProducts(1, true)
    return () => mount = false;
  }, [urlQueryString]);


  useEffect(() => {
    // console.log('allnft ==>', allNft, nftList, total);
  }, [allNft]);

  useEffect(() => {
    const qwery = qs.parse(urlQueryString);
    if (qwery.isMystery == 'true') {
      setMysteryBox(true);
    } else {
      setMysteryBox(false);
    }
  }, [])


  const changeArt = async (value = []) => {
    //const filter = {}
    //urlQueryString && urlQueryString.substring(1).split("&").reduce((a, c) => a[c.split('=')[0]] = c.split('=')[1], filter)
    const filter = qs.parse(urlQueryString);

    if (value.length) {

      filter['categories'] = value.join()
    } else {
      delete filter['categories']
    }

    // if ( filter['categories'] ) {
    //   let categories = await categorieIds({ categories_names: filter['categories'] });
    //   let nftFilter = {
    //     ...filter,
    //     categories: categories || "",
    //     page: 1
    //   }
    //   let { total, latestNFT_items, page, last_page } = await nftWithPagination(nftFilter);
    //   setPagination(total)
    //   setActivePage(page);
    //   setLastPage(last_page)
    // } else {
    //   let nftFilter = {
    //     ...filter,
    //     page: 1
    //   }
    //   let { total, latestNFT_items, page, last_page } = await nftWithPagination(nftFilter);
    //   setPagination(total)
    //   setActivePage(page);
    //   setLastPage(last_page)
    // }

    setSelectedCategory(value);
    const queryString = Object.keys(filter).map(e => `${e}=${filter[e]}`).join('&')
    props.history.push(`${pathname}?` + queryString);

    /*var search = props.location.search;
    var searchParams = new URLSearchParams(search);
    var params = searchParams.get("filter");
    if (params) {
      params = params.split(",");
      value = value === 'all' ? params : value
    } else {
      params = [""];
    }
    if (value !== 'all') {
      params = value
    }
    // Display the key/value pairs
    var concat = "";
    var count = 1;
    var isDelete = false;
    var allCategoryIDs = [];
    for (var pair of params) {
      if (count === 1) {
        concat += `${pair}`;
      } else {
        concat += `,${pair}`;
      }

      count++;
    }

    if (!isDelete && value !== "all") {
      concat += searchParams.get("filter") ? `` : ``;
    }
    const paramsString = concat
    if (concat !== "?" && value !== "all") {
      var search = new URLSearchParams(concat);
      if (params.length === 1) {
        if (params[0] === '') {
          var { total, latestNFT_items, page, last_page } = await nftWithPagination({ page: 1 });
          setPagination(total)
          setActivePage(page);
          setLastPage(last_page)
          await setSelectedCategory(params);

          // props.history.push(`/assets?filter=` + concat);
          return
        }
      }
      var categories = await categorieIds({ categories_names: paramsString });
      console.log('categories', categories)
      let catCount = categories.split(',')
      console.log('catCount', catCount)

      if (catCount.length > 0) {
        console.log('1 in')

        let filter = {
          categories,
          page: 1
        }
        var { total, latestNFT_items, page, last_page } = await nftWithPagination(filter);
        setPagination(count)
        setActivePage(page);
        setLastPage(last_page)
      } else {

        var { total, latestNFT_items, page, last_page } = await nftWithPagination({ page: 1 });
        setActivePage(page);
        setLastPage(last_page)
      }

    } else {
      var { total, latestNFT_items, page, last_page } = await nftWithPagination({ page: 1 });
      setPagination(total)
      setActivePage(page);
      setLastPage(last_page)
    }
    await setSelectedCategory(params);

    props.history.push(`/assets?filter=` + concat);
    */
  };

  const next = async (e, page) => {
    const filter = {}
    urlQueryString && urlQueryString.substring(1).split("&").reduce((a, c) => a[c.split('=')[0]] = c.split('=')[1], filter)
    if (filter['categories']) {
      var sk = page === 1 ? 0 : (9 * (page - 1))
      var categories = await categorieIds(filter['categories']);
      let filter = {
        categories,
        page: 1
      }
      var { total, latestNFT_items } = await nftWithPagination(filter);
      setPagination(total)
      setSkip(sk)
    }
    else {
      // console.log('no params', params, page);
      var sk = page === 1 ? 0 : (9 * (page - 1))
      var { total, latestNFT_items } = await nftWithPagination({ page: page });
      setPagination(total)
      setSkip(sk)
    }

    /*var search = props.location.search;
    var searchParams = new URLSearchParams(search);
    var params = searchParams.get("filter");
    var allCategoryIDs = []
    if (params) {
      params = params.split(",");
    }
    if (params) {
      // console.log('params', params, page);
      var sk = page === 1 ? 0 : (9 * (page - 1))
      var categories = await categorieIds(params);
      let filter = {
        categories,
        page: 1
      }
      var { total, latestNFT_items } = await nftWithPagination(filter);
      setPagination(total)
      setSkip(sk)
    }
    else {
      // console.log('no params', params, page);
      var sk = page === 1 ? 0 : (9 * (page - 1))
      var { total, latestNFT_items } = await nftWithPagination({ page: page });
      setPagination(total)
      setSkip(sk)
    }
    */

  }

  const _getProducts = async (pageNumber = 1, resetData = false) => {
    // console.log('load', pageNumber, nftList.length);
    // var search = props.location.search;
    // var searchParams = new URLSearchParams(search);
    // var params = searchParams.get("filter");
    //var allCategoryIDs = []
    let filter = qs.parse(urlQueryString);
    filter = { ...filter, page: pageNumber }
    setSelectedCategory(filter && filter['categories'] && filter['categories'].split(',') || []);
    //urlQueryString && urlQueryString.substring(1).split("&").reduce((a, c) => a[c.split('=')[0]] = c.split('=')[1], filter)

    let filterArr = [];
    var sk = page === 1 ? 0 : (10 * (page - 1))
    if (filter['categories']) {
      var categories = await categorieIds({ categories_names: filter['categories'] });
      filter['categories'] = categories
    }

    /*let filter = {
      categories,
      page: pageNumber,
      ...urlFilters
    }*/
    filter['explore'] = 1; // redirect to another function
    var { total, latestNFT_items, page, last_page } = await nftWithPagination(filter);
    if (nftList.length === 0) {
      filterArr = [...latestNFT_items];
    } else {
      if (resetData) {
        filterArr = [...latestNFT_items];
      } else {
        filterArr = [...nftList, ...latestNFT_items];
      }

    }
    setNftList(filterArr);
    setPagination(total);
    setSkip(sk);
    setActivePage(pageNumber);
    setLastPage(last_page)
    // console.log('params', nftList, total, page, last_page);

    /*if (params) {
      let filterArr = [];
      var sk = page === 1 ? 0 : (9 * (page - 1))
      var categories = await categorieIds({categories_names: params});
      let filter = {
        categories,
        page: pageNumber,
        ...urlFilters
      }
      var { total, latestNFT_items, page, last_page } = await nftWithPagination(filter);
      if (nftList.length === 0) {
        filterArr = [...allNft, ...latestNFT_items];
      } else {
        filterArr = [...nftList, ...latestNFT_items];
      }
      setNftList(filterArr);
      setPagination(total);
      setSkip(sk);
      setActivePage(pageNumber);
      setLastPage(last_page)
      console.log('params', nftList, total, page, last_page);
    }
    else {
      let arr = [];
      var sk = page === 1 ? 0 : (9 * (page - 1))
      var { total, latestNFT_items, page, last_page } = await nftWithPagination({ page: pageNumber });
      if (nftList.length === 0) {
        arr = [...allNft, ...latestNFT_items];
      } else {
        arr = [...nftList, ...latestNFT_items];
      }
      setNftList(arr)
      setPagination(total)
      setSkip(sk)
      setActivePage(pageNumber);
      setLastPage(last_page)
      console.log('no params', pageNumber, arr, nftList, total);
    }*/
  }

  const handlePage = async () => {
    _getProducts(activePage + 1)
  }

  if (loading) {
    return <ExploreAllSkeleton />
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-3">
          <div className="left-bar-wrap">
            <SidebarmenuComponent
              selectedCategory={selectedCategory}
              isvisibale={true}
              changeArt={(value) => changeArt(value)}
            />
          </div>
        </div>
        <div className="col-9">
          {mysteryBox ? (
            <h3 className="oracleTextClass">MYSTERY BOXES</h3>
          ) : (
            <h3 className="oracleTextClass">EXPLORE ALL</h3>
          )}
          <div className="explore-list-row">
            {!loading && allNft ? <NftList allnft={nftList.length === 0 ? allNft : nftList} /> : <NFTloader />}
          </div>

          {(activePage !== lastPage) ? (
            <div className="d-flex justify-content-center">
              <button className="create-btn mt-3 mt-sm-4 mb-5" disabled={loading} onClick={(e) => { e.preventDefault(); handlePage(); }}>
                {!loading ? 'Load More' : 'Please Wait...'}</button>
            </div>
          ) : null}


        </div>
        {/* <Pagination color="success" page={page} className="pagination_nft" count={totalPage} onChange={next} variant="outlined" shape="rounded" /> */}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  islogin: state.auth.isLogin,
  auth: state.auth.auth,
  loading: state.NFTDetails?.isloading,
  allNft: state.NFTDetails?.nfts?.latestNFT_items,
  total: state.NFTDetails?.nfts?.total,
  page: state.NFTDetails?.nft?.page
})

export default connect(mapStateToProps)(ExplorePage)
import CoinGeckoApi from '@crypto-coffee/coingecko-api'
import Web3 from 'web3'
import axios from 'axios'
import { CHAINS } from '../web3Setup/connectors';
import moment from 'moment'
import binancePriceImage from "../../src/image/networkPrice/binance.jpg";
import maticPriceImage from '../../src/image/networkPrice/matic.jpg';
import ethereumPriceImage from '../../src/image/networkPrice/ethereum.jpg';

const web3 = new Web3(Web3.givenProvider)

export async function currentPrice(payload) {
  try {
    return fetch("https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT")
      .then(response => response.json())
      .then(data => {
        return data.price
      });
  } catch (error) {

  }
}

export async function switchNetworkMumbai() {
  var chainId = 56
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: web3.utils.toHex(chainId) }]
    });
  } catch (err) {
    // This error code indicates that the chain has not been added to MetaMask
    if (err.code === 4902) {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainName: 'Polygon Mainnet',
            chainId: web3.utils.toHex(chainId),
            nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
            rpcUrls: ['https://polygon-rpc.com/']
          }
        ]
      });
    }
  }
}

export async function isMainNet() {
  var id = await web3.eth.net.getId()
  if (id === 56) {
    return true
  }
  return false
}

export const truncateAddress = (address) => {
  if (!address) return "No Account";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const toHex = (num) => {
  const val = Number(num);
  return "0x" + val.toString(16);
};

export const switchNetwork = (network, library) => {
  return new Promise((resolve, reject) => {
    library.provider
      .request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: toHex(network || "") }],
      })
      .then(() => {
        resolve(true);
      })
      .catch((switchError) => {
        if (switchError.code === 4902) {
          let provider = library.provider;
          provider
            .request({
              method: "wallet_addEthereumChain",
              params: [CHAINS[network]],
            })
            .then(() => resolve(true))
            .catch(() => {
              reject(false);
            });
        } else {
          reject(false);
        }
      });
  });
};

export const concatHostUrl = (routePath) => {

  const host = window.location.origin;

  return `${host}${routePath}`

}

export function getPercentageAmount(
  amount,
  percentage,
  addIt = false
) {
  if (amount == 0) {
    return amount;
  }
  let percentageAmount = (amount * percentage) / 100;
  if (addIt) {
    percentageAmount = percentageAmount + amount;
  }
  return percentageAmount;
}

export const stringCapitalize = (str) => {
  return str && str[0].toUpperCase() + str.slice(1) || ""
}

export const calculateDutchAuctionPrice = (startDate, endDate, startPrice, endPrice) => {
  const priceDiff = startPrice - endPrice;
  startDate = moment(startDate);
  endDate = moment(endDate);
  const timeDiff = endDate.diff(startDate, 'seconds')

  const priceInterval = priceDiff / timeDiff;
  const elapsedTime = moment().utc().diff(startDate, 'seconds')

  return startPrice - (priceInterval * elapsedTime)
}


export const chainImageHandler = (chanId) => {

  let imagePath = ''

  if (chanId === "polygon") {
    imagePath = maticPriceImage
  } else if (chanId === "binance") {
    imagePath = binancePriceImage
  } else if (chanId === "ethereum") {
    imagePath = ethereumPriceImage


  }

  return imagePath
}

export const addHttps = (link) => {
  let modifyLink = ''
  if (link.indexOf("https://") == 0) {
    modifyLink = link
  } else {
    modifyLink = `https://${link}`
  }
  return modifyLink;
}

export const splitUrl = (getUrl) => {
  let modifyUrl = ''
  // console.log('.getUrl', JSON.stringify(getUrl), getUrl.indexOf('.gg/'));
  if (getUrl.indexOf('.com/') > -1) {
    modifyUrl = JSON.stringify(getUrl.split('.com/'))
    // console.log('.com', modifyUrl);
    return modifyUrl[1];
  } else if (getUrl.indexOf('.gg') > -1) {
    modifyUrl = JSON.stringify(getUrl.split('.com/'))
    // console.log('.gg', modifyUrl);
    return modifyUrl[1];
  } else if (getUrl.indexOf('.org/') > -1) {
    modifyUrl = getUrl.split('.com/')
    // console.log('.org', modifyUrl);
    return modifyUrl[1];
  }
}

export const isMobile = () => {
  // return true;
  console.log(navigator.userAgent, "navigator.userAgent");
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

// export const imgTypes = ['image/svg+xml','image/jpeg', 'image/png', 'image/gif','image/webp'];
// export const videoTypes = ['video/webm','video/mp4', 'video/ogg', 'video/webm'];
// export const audioTypes = ['audio/webm','audio/mpeg', 'audio/wav', 'audio/ogg'];
// export const THREE_D = ['model/gltf-binary','model/gltf+json'];

export const fileTypeCheck = ['image/svg+xml','image/jpeg', 'image/png', 'image/gif','image/webp','video/webm','video/mp4', 'video/ogg', 'video/webm',
'audio/webm','audio/mpeg', 'audio/wav', 'audio/ogg'];

export const fileExtensionCheck = ['jpg','jpeg','png','gif','svg','mov', 'mp4','webm','mp3', 'wmv', 'wav','avi', 'avchd', 'mkv','mpeg','ogg','glb', 'gltf','hevc', 'h264']
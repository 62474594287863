import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { useAlert } from "react-alert";
import { useHistory } from 'react-router';
import WalletConnectProvider from "@walletconnect/web3-provider"
import { AutoComplete } from 'antd';
import gamesLogo from './Games_Logo.png';
import MoonSvg from './moon.svg';
import SunSvg from './sun.svg';
import DarkSvg from './day-night-sun-moon-cycle-svgrepo-com.svg';
import SettingSvg from './setting-svgrepo-com.png';
import MessagesSvg from './Message.png';
import CreateIcon from './create-icon.png';
import CheckIcon from './check-icon.png';
import AccountSvg from './account-pin-box-svgrepo-com.svg';
import cratorLogo from './LOGO_Crator.png';
import walletImage from './Wallet_Icon_white.png'
import logoutIcon from './logoutIcon.png'
// import logoutSvg from './logout-svgrepo-com.svg';
import logoutSvg from './logout-icon.png';
import NFT_Logo from './lunasky.png'
import NFT_Logo_normal from './lunasky-normal.png';
import humbergerIcon from './humberger-icon.png'
import { NavLink, useLocation } from 'react-router-dom';
import bellIcon from './Message_Bell.svg'
import chatIcon from './chat.svg'
// import profileIcon from './Profile_PopUp Menu.png'
import profileIcon from '../../image/no-image-profile.jpg';
import networkIcon from '../../image/networkIcon.png'
// import { useMoralis, useNewMoralisObject } from "react-moralis";
import LoginPopup from './LoginPopup'
import MobileMenuHeader from '../Header/MobileMenuHeader'
import WelcomeVisibility from '../Modal/WelcomeVisitor'
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import { UserOutlined } from '@ant-design/icons';
import useContractIntraction from '../../web3Setup/useContractIntraction';
import metamask__wallet from '../../image/metamask__wallet.png'
import Wallet_Logo_wallet from '../../image/wallet-svg.svg'
import Search_Icon from './search-icon.png'
import $ from 'jquery';
import { Modal } from 'antd'
import cookies from "js-cookie";

// import useMoralisService from '../../hooks/useMoralisService';
// import { switchNetworkMumbai } from '../../utils/helper.js'
import { isMobile } from "../../utils/helper"
import Web3 from 'web3'
import { useWeb3React } from "@web3-react/core";
import { connectors } from "../../web3Setup/connectors";

//custom hooks
import useAuth from '../../hooks/useAuth';
import useNFT from '../../hooks/useNFT';
import GlobalSearchBar from '../Common/GlobalSearchBar';
import WalletAddress from '../WalletAddress/WalletAddress';
import FeedbackModal from '../Modal/FeedbackModal';

const Header = ({ darkMode, isDarkMode, islogin, user, isSignature }) => {
    const [mToggleSearch, setmToggleSearch] = useState('');
    // const [profile, setProfile] = useState(false)
    const [profile, setProfile] = useState('')
    const [profilePic, setProfilePic] = useState(profileIcon)
    const [mMenuVisibility, setmMenuVisibility] = useState('invisible-mobile-menu');
    //assigning location variable
    const location = useLocation();
    const history = useHistory()
    //destructuring pathname from location
    const { pathname } = location;
    //Moralis
    // const { deactivate, library, account } = useWeb3React();

    const { auth, logout } = useAuth()
    const { nftWithPagination } = useNFT()
    const { signMessage, attemptSignature } = useContractIntraction()
    const [attemptLogin, setAttemp] = useState(false)
    const { activate, active, deactivate, account, library } = useWeb3React();

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    const [walletVisibility, setwalletVisibility] = useState(false);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();
    const [activeSubMenu, setSubmenu] = useState(false);
    const [signature, setSignature] = useState('');
    const { authonticateUser } = useAuth();
    const [useProvider, setUseProvider] = useState('')
    const [connectedAccount, setConnectedAccount] = useState('');
    const [welcomeVisibility, setWelcomeVisibility] = useState(false)
    const [openFeedbackModal, setOpenFeedbackModal] = useState(false)

    useEffect(() => {
        const isFirstTimeVisit = cookies.get('_lunarsky_firstTimeVisit')
        if (isFirstTimeVisit) {
            cookies.set('_lunarsky_firstTimeVisit', false)
        } else {
            setWelcomeVisibility(true);
            cookies.set('_lunarsky_firstTimeVisit', true)
        }
    }, [])

    useEffect(() => {
        getUserDetails()
        if (user) {
            setProfile(user.user)
            // console.log('user?.user?.profile_image', user.user)
            setProfilePic(user?.user?.profile_image)
        }
        setUseProvider(window.localStorage.getItem("provider"));
        // switchNetworkMumbai()
        // changeNetwork()
    }, [islogin])
    // console.log('islogin header', darkMode, isDarkMode, user)

    history.listen((location, action) => {
        // console.log(location.pathname)
    })

    useEffect(() => {
        if (active) {
            // let deeplink = window.localStorage.getItem('WALLETCONNECT_DEEPLINK_CHOICE');
            // if(deeplink) {
            //     deeplink = JSON.parse(deeplink);
            //     window.localStorage.setItem(
            //     'WALLETCONNECT_DEEPLINK_CHOICE',
            //     JSON.stringify({ name: 'Trust Wallet', href: deeplink.href.replace('wc:','trust://') })
            //     )
            // }
            //{"name":"Unknown","href":"wc:56ed85eb-65cc-479b-86d6-3e4941bafba0@1"}

            setTimeout(() => {
                login();
                // setAttemp(true);
            }, 300);
        }
    }, [active, attemptLogin])

    useEffect(() => {
        // console.log(connectedAccount, account, " account header1");
        // if the user account changed from the previous valid account then logout the user
        if (account && connectedAccount) {
            if (account.toLowerCase() != connectedAccount.toLowerCase()) {
                Logout();
            }
        } else if (connectedAccount && !account) { // disconnect event
            Logout();
        }
        setConnectedAccount(account)
    }, [account])

    useEffect(() => {
        if (isMobile()) {
            // do nothing
        } else {
            sing()
        }
    }, [isSignature])

    const getUserDetails = async () => {
        if (!islogin) {
            await auth(account)
        }
        // console.log('user=>', user);
    }

    const filter = async (e) => {
        var array = []
        setSelectedValue(e)
        if (e !== '') {
            var res = await nftWithPagination({ search: e })
            res.map((value, key) => {
                var data = {
                    label: value.item_name,
                    value: value._id
                }
                array.push(data)
            })
            setOptions(array)
        }
    }

    const onSelect = (data) => {
        var res = options.filter((value) => value.value === data)
        setSelectedValue(selectedValue)
        history.push(`/assets/${data}`)
    };
    const refreshState = async () => {
        window.localStorage.clear();
    };

    const connectMetamask = () => {
        if (window && typeof window.ethereum === 'undefined') {
            window.open('https://metamask.app.link/dapp/' + window.location.hostname, "_blank");
            return
        }
        setAttemp(true)
        activate(connectors.injected);
        setProvider("injected");
    }

    const Logout = async () => {
        await refreshState();
        await deactivate();
        await logout();
        window.location.reload();
    }
    const mToggleSearchHandler = () => {
        if (mToggleSearch === '') {
            setmToggleSearch('visible-search-input');
        } else {
            setmToggleSearch('');
        }
    }
    const mToggleMenuHandler = () => {
        if (mMenuVisibility === 'invisible-mobile-menu') {
            setmMenuVisibility('visible-mobile-menu');
        } else {
            setmMenuVisibility('invisible-mobile-menu');
        }
    }

    const verify = async () => {
        let web3 = new Web3(library.provider)
        const verif = await library.provider.request({
            method: "personal_ecRecover",
            params: ['signedMessage', 'signature']
        });
    }

    const setProvider = (type) => {
        window.localStorage.setItem("provider", type);
    };

    const login = async () => {
        try {
            if (!isSignature && attemptLogin) { //
                let res = await signMessage()
                setSignature(res)
            }

        } catch (error) {

        }
    }

    const sing = async () => {
        // console.log('isSignature', isSignature)
        try {
            if (!islogin && isSignature) {

                let nonce = signature
                let sing = await attemptSignature(`Welcome To LunaSky Your Nonce Id ${nonce}`)
                let userResponse = await authonticateUser({ sing, publicAddress: account })
                $('.close').click()
                // localStorage.setItem("signature",JSON.stringify(sing)) 
                localStorage.setItem("signature", (sing))


            }

        } catch (error) {

        }
    }

    const handleCancel = () => {
        setOpenFeedbackModal(false)
    }

    // if (window.ethereum) {
    //     window.ethereum.on("accountsChanged", accounts => {
    //         Logout()
    //     });
    //     window.ethereum.on('disconnect', clearAccount=> {
    //         // Logout()
    //         console.log('clearAccount',clearAccount)
    //     });   

    //   }

    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('#dynamic').addClass('dynamic-header-class');
        } else {
            $('#dynamic').removeClass('dynamic-header-class');
        }
    });


    return (
        <>
            <header id='dynamic' className="nft-header-main menu-pleca sticky-top">
                <div className="container-fluid header">
                    <div className="mobile-header">
                        <div className="mobile-header-top">
                            <div className='lbogo-box'>
                                <div className={` ${splitLocation[1] === "" ? "active" : ""}`}>
                                    <NavLink className='mb_night_logo' to="/"><img src={NFT_Logo} /></NavLink>
                                    {/* <NavLink className='mb_normal_logo' to="/"><img src={NFT_Logo_normal} /></NavLink> */}
                                </div>
                            </div>
                            <div className="humberger-menu" onClick={mToggleMenuHandler}>
                                <img className='humbrager' src={humbergerIcon} />
                            </div>
                            <div className='searchbar-section'>
                                {/* <AutoComplete
                                    className='searchbar-section'
                                    options={options}
                                    style={{
                                        width: 200,
                                        fontFamily: 'Futura',
                                    }}
                                    value={selectedValue}
                                    onChange={(e) => filter(e)}
                                    onSelect={(e) => onSelect(e)}
                                    placeholder=""

                                /> */}
                                <GlobalSearchBar />
                                {/* <input onKeyUp={(e)=>filter(e)} type="text" className={`header-search-input ${mToggleSearch} `} placeholder="" /> */}
                                <div className='search-icon' onClick={mToggleSearchHandler}>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path></svg>
                                </div>
                            </div>

                            <div className="mobile-wallet">
                                {/* onClick={walletShowhide} */}
                                {/* {!isAuthenticated ?
                                    <div className="wallet-button" data-toggle="modal" data-target="#exampleModalCenter">
                                        <img src={walletImage} />
                                    </div>
                                    : <span className="wallet-button" onClick={(() => Logout())}><img src={logoutIcon} /></span>} */}

                                {/* mobile_ profile icon */}
                                {islogin ?
                                    <div className={activeSubMenu ? 'wallet-button inner_profile' : 'wallet-button'}>
                                        <div className="half_dropdown" >

                                            {user?.user?.profile_image ?
                                                <a href='#' className='profile_actual_icon profile_imf_icon'>
                                                    <img src={user?.user?.profile_image}></img>
                                                </a> :
                                                <a href='#' className='profile_imf_icon'>
                                                    <img src={profileIcon}></img>
                                                </a>
                                            }

                                            <div className='profile_drop_menu'>
                                                <ul className='inner_list_wrp'>
                                                    {islogin ?
                                                        <>
                                                            {profile ? <li><NavLink className="nav-link profile-link" to={`/profile/${user?.user?.username}`}> <span><img src={profileIcon} /></span>Profile</NavLink></li> : ''}
                                                            <li><NavLink to="/setting"> <img src={SettingSvg}></img>Settings</NavLink></li>

                                                        </>
                                                        : ''}
                                                    {/* <li><NavLink to="/Messages"> <img src={MessagesSvg}></img>Messages</NavLink></li> */}



                                                    {islogin ?
                                                        <li>
                                                            <a onClick={() => Logout()} ><img src={logoutSvg} />Logout</a>
                                                        </li>
                                                        :
                                                        <li>
                                                            <a>
                                                                <img data-toggle="modal" data-target="#exampleModalCenter" src={logoutSvg} /> <span data-toggle="modal" data-target="#exampleModalCenter">Login</span>
                                                            </a>
                                                        </li>
                                                    }
                                                </ul>
                                            </div>
                                        </div>

                                    </div>

                                    :
                                    <div className="wallet-button cursor-pointer" data-toggle="modal" data-target="#exampleModalCenter">
                                        <img src={walletImage} />
                                    </div>
                                }

                            </div>
                            <div className='wallet-button night_mode_nft'>
                                <button className='toggole_btn_dark' onClick={() => darkMode(!isDarkMode)}>{isDarkMode ? <img src={MoonSvg} className="menuIconclassNft" /> : <img src={MoonSvg} className="menuIconclassNft" />}</button>
                            </div>
                        </div>
                        <div className="mobile-header-bottom">
                            {/* <div className={`siteLogo ${splitLocation[1] === "" ? "active" : ""}`}>
                                <NavLink to="/"><img src={NFT_Logo} /></NavLink>
                            </div> */}
                        </div>
                        <MobileMenuHeader mToggleMenuHandler={() => mToggleMenuHandler()} mMenuVisibility={mMenuVisibility} menuToggle={mToggleMenuHandler} />
                    </div>
                    <div className="row justify-content-start desktop-header">
                        <div className="col-1 mx-0 px-0 position-relative">
                            <div className={`siteLogo ${splitLocation[1] === "" ? "active" : ""}`}>
                                <NavLink className='night_logo' to="/"><img src={NFT_Logo} /></NavLink>
                                {/* <NavLink className='normal_logo' to="/"><img src={NFT_Logo_normal} /></NavLink> */}
                            </div>
                            {/* <div className='lbogo-bg-box'>
                                <img src={networkIcon} />
                            </div> */}
                        </div>

                        <div className="col-8 main-menu">
                            <ul className="list-unstyled row align-items-end justify-content-start nftMenulist">
                                <li className={`nav-link ${splitLocation[1] === "collections" ? "active" : ""}`}>
                                    <div className='image-link'>&nbsp;</div>
                                    <NavLink className="nav-link" to="/assets">Explore</NavLink>
                                </li>
                                {/* <li className={`nav-link ${splitLocation[1] === "games-oracle" ? "active" : ""}`} >
                                    <div className='image-link'>
                                        <img src={gamesLogo} className="menuIconclassNft" />
                                    </div> 
                                    <NavLink className="nav-link" to="/games-oracle">Games</NavLink>
                                </li>
                                <li className={`nav-link ${splitLocation[1] === "crator" ? "active" : ""}`}>
                                 <div className='image-link'>
                                        <img src={cratorLogo} className='menuIconclassNft' />
                                    </div> 

                                     <NavLink className="nav-link" to="/crator">Crator</NavLink>  
                                    <a className="nav-link" style={{ textDecoration: "none" }} href="https://crator.com/" target="_blank">Crator</a>
                                </li>
                                <li className={`nav-link ${splitLocation[1] === "lending-page" ? "active" : ""}`}>
                                    <div className='image-link'>&nbsp;</div>
                                    <NavLink className="nav-link" to="/lending-page">Lending</NavLink>
                                </li>*/}
                                <li className={`nav-item dropdown nav-link ${splitLocation[1] === "create" || splitLocation[1] === "create-nft" ? "active" : ""}`}>
                                    <div className='image-link'>&nbsp;</div>
                                    {/* <NavLink className="nav-link dropdown-toggle" to="#" splitLocation[1] === "create-collections" ? "active" : "" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Create
                                    </NavLink>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <NavLink className="dropdown-item" to="/create-collections">Collection</NavLink>
                                        <NavLink className="dropdown-item" to="/create">NFT</NavLink>
                                    </div> */}
                                    {/* below class name : dropdown-toggle */}
                                    <NavLink className="nav-link" to="/create-nft">
                                        Create
                                    </NavLink>
                                    {/* <div className="dropdown-menu">
                                        <ul>
                                            <li>
                                                <NavLink className={`dropdown-item`} to="/create-collections">Collection</NavLink>
                                            </li>
                                            <li>
                                                <NavLink className="dropdown-item" to="/create">NFT</NavLink>
                                            </li>
                                        </ul>
                                    </div> */}
                                </li>
                                {/* data-toggle="modal" data-target="#feedbackModal" */}
                                <li className='nav-item nav-link'>
                                    <div className='image-link'>&nbsp;</div>
                                    <a className="nav-link feedback-color" href='javascript:void(0)' onClick={event => { event.preventDefault(); setOpenFeedbackModal(true) }}>Beta Suggestions</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-3 justify-content-center d-flex align-end px-0">
                            <div className=" col-12 d-flex align-items-baseline gap-18">
                                <div className='col-7 searchbar-section p-lg-0 desktop_search'>
                                    {/* <AutoComplete
                                        className='searchbar-section'
                                        options={options}
                                        style={{
                                            width: '100%',
                                            fontFamily: 'Futura',
                                        }}
                                        value={selectedValue}
                                        onChange={(e) => filter(e)}
                                        onSelect={(e) => onSelect(e)}
                                        placeholder=""
                                    /> */}
                                    <GlobalSearchBar />
                                    <div className='search-icon' onClick={mToggleSearchHandler}>
                                        {/* <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path></svg> */}
                                        <img src={Search_Icon}></img>
                                    </div>
                                </div>
                                {/* <div className='search-icon'>
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path></svg>
                                </div>*/}
                                {/* onClick={walletShowhide} */}

                                {!islogin ?
                                    // data-toggle="modal" data-target="#exampleModalCenter"
                                    <div className="col- wallet-button cursor-pointer">
                                        <img src={walletImage} />

                                        <div className='wallet-wrapper'>
                                            <p>Connect with available wallet, or create new one.</p>
                                            <ul className='wallet-list'>
                                                <li className='wallet-link' onClick={() => {
                                                    connectMetamask();
                                                }} ><img src={metamask__wallet} />Metamask</li>
                                                <li onClick={() => {
                                                    setAttemp(true)
                                                    activate(connectors.walletConnect);
                                                    setProvider("walletConnect");
                                                }}><img src={Wallet_Logo_wallet} />Wallet connect</li>
                                                {/* <li className='wallet-show-more'>Show more options</li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    : ''}


                                {/* {//     : <span className="wallet-button cursor-pointer" onClick={(() => Logout())}><img src={logoutIcon} /></span>
                                // }  */}


                                {islogin ?
                                    <div className={activeSubMenu ? 'wallet-button inner_profile' : 'wallet-button'}>
                                        <div className="half_dropdown" >

                                            {user?.user?.profile_image ?
                                                <a href={`/profile/${user?.user?.username}`} className='profile_actual_icon profile_imf_icon'>
                                                    <img src={user?.user?.profile_image}></img>
                                                </a> :

                                                <a href={`/profile/${user?.user?.username}`} className='profile_imf_icon'>
                                                    <img src={profileIcon}></img>
                                                </a>
                                            }

                                            <div className='profile_drop_menu'>
                                                <ul className='inner_list_wrp'>
                                                    {islogin ?
                                                        <>
                                                            {/* {console.log('pro=>', profile)} */}
                                                            {profile ?
                                                                <li><NavLink className="nav-link profile-link" to={`/profile/${user?.user?.username}`}>
                                                                    <span><img src={user?.user?.profile_image || profileIcon} /></span>
                                                                    Profile</NavLink>
                                                                </li>
                                                                : ''}
                                                            <li><NavLink to="/setting"> <img src={SettingSvg}></img>Settings</NavLink></li>

                                                        </>
                                                        : ''}
                                                    {/* <li><NavLink to="/Messages"> <img src={MessagesSvg}></img>Messages</NavLink></li> */}


                                                    {/* <li><NavLink to="/create-collections"> <img src={CreateIcon}></img>New collection</NavLink></li> */}
                                                    <li>

                                                        <a href={`/profile/${user?.user?.username}`} >
                                                            <img src={CreateIcon}></img>My Collections
                                                        </a>

                                                        {/* <NavLink to="/create-collections"> <img src={CreateIcon}></img>New collection</NavLink> */}



                                                    </li>

                                                    {useProvider === 'injected' && (
                                                        <li className='wallet-link wallte-icons wallet-connect wallet-address'>
                                                            <a href="#" onClick={(e) => e.preventDefault()}><span><img src={metamask__wallet} /><WalletAddress wallet={connectedAccount} /></span></a>
                                                        </li>
                                                    )}
                                                    {useProvider === 'walletConnect' && (
                                                        <li className='wallet-link wallte-icons wallet-address'>
                                                            <a href="#" onClick={(e) => e.preventDefault()}><span><img src={Wallet_Logo_wallet} /><WalletAddress wallet={connectedAccount} /></span></a>
                                                        </li>
                                                    )}

                                                    {islogin ?
                                                        <li>
                                                            <a onClick={() => Logout()} ><img src={logoutSvg} />Logout</a>
                                                        </li>
                                                        :
                                                        <li>
                                                            <a>
                                                                <img data-toggle="modal" data-target="#exampleModalCenter" src={logoutSvg} /> <span data-toggle="modal" data-target="#exampleModalCenter">Login</span>
                                                            </a>
                                                        </li>
                                                    }

                                                </ul>

                                            </div>
                                        </div>

                                    </div>


                                    : ''}
                                <div className='night_mode_nft'>
                                    <button className='toggole_btn_dark' onClick={() => darkMode(!isDarkMode)}>{isDarkMode ? <img src={MoonSvg} className="menuIconclassNft" /> : <img src={MoonSvg} className="menuIconclassNft" />}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <LoginPopup settHeaderAttempLog={setAttemp} headerNonce={signature} />
            {/* <FeedbackModal /> */}

            <Modal
                title="Beta Suggestion Box"
                visible={openFeedbackModal}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
                destroyOnClose={true}
                width={750}
            >
                <FeedbackModal
                handleCancel={() => { handleCancel() }}
                />
            </Modal>

            <Modal
                title="Welcome To LunaSky"
                visible={welcomeVisibility}
                onCancel={() => setWelcomeVisibility(false)}
                footer={null}
                maskClosable={false}
                destroyOnClose={true}
                width={1000}
            >
                <WelcomeVisibility />
            </Modal>
        </>
    );
};

const mapStateToProps = state => ({
    islogin: state.auth.isLogin,
    user: state.auth.auth,
    isSignature: state.auth.isSignature
})

export default connect(mapStateToProps)(Header);
import React from 'react';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import LendingComponent from '../components/Lending/LendingComponent';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';


export default function LendingPage() {
    return (
        <div id="lendingPage" className="games__wrap auctionComponent LendingPageComponent">

            <Breadcrumb title="Lending" subpage="Community" page="Lending" />
            <section className="help-center-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <h3 className="comming_soon_text mt-3 mb-0 text-center">Coming Soon </h3>
                    </div>

                </div>
            </section>

            {/* <LendingComponent />
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <Scrollup /> */}
        </div>
    );
}

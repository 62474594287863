import React from "react";
import _ from "lodash";
import Footer from "../components/Footer/Footer";
import ModalSearch from "../components/Modal/ModalSearch";
import ModalMenu from "../components/Modal/ModalMenu";
import Scrollup from "../components/Scrollup/Scrollup";
import CollectionsComponent from "../components/Collections/CollectionsComponent";

export default function CollectionsPage(props) {
  return (
    <div id="collectionpages" className="main_wrp games__wrap CratoePageComponent CollectionsPageComponent">
      <CollectionsComponent props={props} />
      <Footer />
      <ModalSearch />
      <ModalMenu />
      <Scrollup />
    </div>
  );
}

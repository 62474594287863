import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import CSS

const ConfirmAlert = ({
  title,
  message,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
  closeOnEscape,
  closeOnClickOutside,
}) =>
  confirmAlert({
    title: title || "Are you sure?",
    message:
      message || "This operation cannot be undone. Do you wish to continue?",
    buttons: [
      {
        label: confirmButtonText || "Yes",
        onClick: () => onConfirm && onConfirm(),
      },
      {
        label: cancelButtonText || "Cancel",
        onClick: () => onCancel && onCancel(),
      },
    ],
    closeOnEscape: !!closeOnEscape,
    closeOnClickOutside: !!closeOnClickOutside,
  });

export default ConfirmAlert;

import axios from "./config";

class OffersServices {  

  async GetOffers(id='') {
    const reponse = await axios.get(`/offers/${id}`,)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }

  async Create(data) {
    const reponse = await axios.post(`/offers`,data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }

  async Update(data,id) {
    const reponse = await axios.put(`/offers/${id}`,data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log('update',error);
        return error.response;
      });
    return reponse;
  }

  async GetOfferFilter(data) {
    const reponse = await axios.post(`/offers/get_offers/`,data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
    return reponse;
  }
}
export default new OffersServices();

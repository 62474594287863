import { USER_SAGA_ACTION_TYPES } from "../../store/user/userAction";
import { useApiCall } from "./appApiCallHook";

export function useUserApi() {
  const callApi = useApiCall();

  const followUnfollowUser = (data, onSuccess, onError) => {
    callApi(
      USER_SAGA_ACTION_TYPES.FOLLOW.FOLLOW_UNFOLLOW,
      data,
      onSuccess,
      onError
    );
  };

  const fetchFollowData = (data, onSuccess, onError) => {
    callApi(
      USER_SAGA_ACTION_TYPES.FOLLOW.FETCH_FOLLOW_DATA,
      data,
      onSuccess,
      onError
    );
  };

  const likeUnlikeNft = (data, onSuccess, onError) => {
    callApi(
      USER_SAGA_ACTION_TYPES.NFT.LIKE_UNLIKE_NFT,
      data,
      onSuccess,
      onError
    );
  };

  const fetchLikedNft = (data, onSuccess, onError) => {
    callApi(
      USER_SAGA_ACTION_TYPES.NFT.FETCH_LIKED_NFT,
      data,
      onSuccess,
      onError
    );
  };

  const getCards = (data, onSuccess, onError) => {
    callApi(USER_SAGA_ACTION_TYPES.CARD.GET_CARDS, data, onSuccess, onError);
  };

  const addCard = (data, onSuccess, onError) => {
    callApi(USER_SAGA_ACTION_TYPES.CARD.ADD_CARD, data, onSuccess, onError);
  };

  const markCardAsDefault = (data, onSuccess, onError) => {
    callApi(
      USER_SAGA_ACTION_TYPES.CARD.MARK_CARD_AS_DEFAULT,
      data,
      onSuccess,
      onError
    );
  };

  const deleteCard = (data, onSuccess, onError) => {
    callApi(USER_SAGA_ACTION_TYPES.CARD.DELETE_CARD, data, onSuccess, onError);
  };

  const globalSearch = (data, onSuccess, onError) => {
    callApi(USER_SAGA_ACTION_TYPES.GLOBAL_SEARCH, data, onSuccess, onError);
  };
  const verifySubscribedEmail = (data, onSuccess, onError) => {
    callApi(
      USER_SAGA_ACTION_TYPES.VERIFY_SUBSCRIBED_EMAIL,
      data,
      onSuccess,
      onError
    );
  };
  const buyCryptoOnboarding = (data, onSuccess, onError) => {
    callApi(
      USER_SAGA_ACTION_TYPES.BUY_CRYPTO_ONBOARDING,
      data,
      onSuccess,
      onError
    );
  };
  const wyreOrderSave = (data, onSuccess, onError) => {
    callApi(USER_SAGA_ACTION_TYPES.WYRE_ORDER_SAVE, data, onSuccess, onError);
  };

  const getNonce = (data, onSuccess, onError) => {
    callApi(USER_SAGA_ACTION_TYPES.GET_NONCE, data, onSuccess, onError);
  };
  return {
    followUnfollowUser,
    fetchFollowData,
    likeUnlikeNft,
    fetchLikedNft,
    getCards,
    addCard,
    markCardAsDefault,
    deleteCard,
    globalSearch,
    verifySubscribedEmail,
    buyCryptoOnboarding,
    wyreOrderSave,
    getNonce
  };
}

import React,{useState,useEffect} from 'react'
import DateTimePicker from 'react-datetime-picker';

export default function MakeOfferPopup({nft,expiration,datetime,setDateTime,saveOffer,setOfferPrice,setExpiration}) {
    const [error,setError] = useState('')
    const [amoutn,setAmount]=useState(false)

    useEffect(()=>{

    },[datetime])

    const getPrice=(price)=>{
        setError('')
        if(price <= 0){
            setError('Price should be greater than 0!');
            return
        }
        if(nft.start_price){
            if(price < nft.start_price){
                setError('Price should be greater than nfts current price !');
                return
            }
        }
        setOfferPrice(price)
        setAmount(price)
    }

    const handelSubmit =()=>{        
        if(amoutn ===''){
            setError('Please enter you offer price!');
            return
        }
        if(nft.start_price){
            if(amoutn < nft.start_price){
                setError('Price should be greater than nfts current price !');
                return
            }
        }
        saveOffer()
    }


    return (
        <>
            <div className="connect-wallet-box-main make-offer-main">
                <h2 className="main-heading">OFFER</h2>
                <div className="wallet-infobox px-4">
                    <div className="flex-column gap-10 mb-3">
                        <h3 className="m-0">Make an Offer</h3>
                        <select>
                            <option value="">BNB</option>
                        </select>
                        <input onChange={(event)=>getPrice(event.target.value)}/>
                        {error !== '' ? <div>{error}</div>:''}
                    </div>
                    <div className="flex-column gap-10">
                        <h3 className="m-0">Offer Expiration</h3>
                        <select onClick={((event)=>setExpiration(event.target.value))}>
                            <option value="1">1 Days</option>
                            <option value="2">2 Days</option>
                            <option value="3">3 Days</option>
                            <option value="4">4 Days</option>
                            <option value="custom">Custom</option>
                        </select>
                            
                        {expiration ==='custom' ? <DateTimePicker onChange={(date)=>setDateTime(date)} value={datetime} />:''}
                    </div>
                    <div className="d-flex justify-content-start align-content-baseline gap-10 my-2">
                        <input  type="checkbox" />
                        <p>By checking this box, I agree to EGC Infinite Canvas's Terms of Service.</p>
                    </div>
                </div>
                <div className="make-offer-btn" onClick={()=>handelSubmit()}>MAKE OFFER</div>
            </div>
        </>
    )
}

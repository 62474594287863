export const actionTypes = {
    GET_CATEGORY: 'GET_CATEGORY',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',   
    LOGOUT: 'LOGOUT',   
    APP_LOADER_STATUS: 'APP_LOADER_STATUS',   
    UPDATE_PROFILE:'UPDATE_PROFILE'
};

export function getCategory(payload) {
   return { type: actionTypes.GET_CATEGORY, payload };
}

export function loginSuccess(payload) {
   return { type: actionTypes.LOGIN_SUCCESS, payload };
}

export function logout() {
   return { type: actionTypes.LOGOUT };
}

export function updateUserProfile(profile) {
   return { type: actionTypes.UPDATE_PROFILE ,profile};
}
export function appLoaderStatus(payload=false) {
   return { type: actionTypes.APP_LOADER_STATUS, payload:payload };
}
import { useDispatch } from 'react-redux';
import { login, logout,loginSuccess,signature } from '../store/auth/action';
import { getUserProfile } from '../store/profile/action'
import { getCrators } from '../store/crators/action'
import AuthService from '../api/AuthService';
import cookies from "js-cookie";


export default function useAdmin() {
    const dispatch = useDispatch();

    return {
        login: async (data) => {          
            var responseData = await AuthService.UserLogin(data);
            if (responseData.status === 200) {
                dispatch(signature({user:responseData.data.data}))
                return responseData.data;
            }
            return false;
        },
        authonticateUser:async(data)=>{
            let authResponse = await AuthService.UserAuth(data)
            if(authResponse.status === 200){
                cookies.set('token',authResponse.data.token)
                dispatch(loginSuccess({user:authResponse.data.data}))
                return true
            }
            return false 
        },
        logout: async () => {          
            dispatch(logout())
            cookies.remove('token')
            return true;
        },
        auth: async (data) => {          
            var responseData = await AuthService.AuthDetails();
            if (responseData.status === 200) {
                dispatch(loginSuccess(responseData.data.data))
                return responseData.data;
            }
            return false;
        },
        getUser: async (id) => {          
            var responseData = await AuthService.GetUser(id);
            if (responseData.status === 200) {
                return responseData.data.data.user;
            }
            return false;
        },
        getCrators: async (data) => {          
            var responseData = await AuthService.GetAllCrators(data);
            if (responseData.status === 200) {
                dispatch(getCrators(responseData.data.data))              
                return responseData.data.data;
            }
            return false;
        },
        getSingleUser: async (data) => {          
            var responseData = await AuthService.GetSingleUser(data);
            if (responseData.status === 200) {     
                dispatch(getUserProfile(responseData.data.data.user))         
                return responseData.data.data;
            }
            return false;
        },
        userUpdate: async (data,id) => {          
            var responseData = await AuthService.UpdateUser(data,id);
            if (responseData.status === 200) {
                return responseData.data.data.user;
            }
            return false;
        },
        saveEmail: async (data) => {          
            var responseData = await AuthService.SaveEmail(data);
            if (responseData.status === 200) {
                return responseData.data.data;
            }
            return false;
        },
        emailCheck: async (data) => {          
            var responseData = await AuthService.EmailCheck(data);
            if (responseData.status === 200) {
                return responseData.data.data;
            }
            return false;
        },
    }
}

import React, { Component } from 'react';
import Footer from '../components/Footer/Footer';
import MyProfileComponent from '../components/Profile/MyProfileComponent'

export default function MyProfile() {
    return (
        <>
            <div id="myprofile" className="games__wrap myprofileComponent pb-5">
                <MyProfileComponent />
            </div>
            <Footer />
        </>
    );
}

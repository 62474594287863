import React, { Component } from 'react';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import EditCollections from '../components/Create/EditCollection';
import { PropertySafetyFilled } from '@ant-design/icons';

function CollectionEdit (props) {
        return (
            <div className="main" id="allcol">
                <EditCollections props={props}/> 
                <Footer />
                <ModalSearch />
                <ModalMenu />
                <Scrollup />
            </div>
        );
}

export default CollectionEdit;
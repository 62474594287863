import React, { useEffect, useState } from 'react'
import Web3 from 'web3'
import nft from '../../abi/nft.json'
import { addrs } from '../../abi/address'
import { Link } from 'react-router-dom';
import { useMoralisQuery, useMoralis } from "react-moralis";
import GridBoxItem from '../../themes/gridBoxItem'
import CategorySidebar from '../../themes/categorySidebar';




function Allasset({ allnft, acc, web3main }) {
    const [assetist, setassetlist] = useState()
    const [allcolllist, allsetcolllist] = useState([])
    const [accountid, setaccountid] = useState()
    const [chainid, setchainid] = useState()



    useEffect(async () => {
        if (acc && web3main) {
            const accounts1 = await window.ethereum.request({ method: 'eth_requestAccounts' });
            setaccountid(accounts1)
            const chainId = await window.ethereum.request({ method: 'eth_chainId' });
            setchainid(chainId)
            totalnft()

        }
    }, [acc, web3main])
    const dataa = useMoralisQuery('NEWNFT')
    // const del = dataa?.data?.find(v=>v?.attributes?.nftId==="2")
    // console.log('ffind',del)
    // const dd = del?.destroy()
    // const del = dataa?.data?.map((v)=>v.destroy())
    const { Moralis } = useMoralis();




    const totalnft = async () => {
        if (acc && web3main) {


            // const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const accounts = await web3main.eth.getAccounts();

            let userwalletaddresss = accounts[0];
            // window.web3 = new Web3(window.ethereum);
            let swaping = new web3main.eth.Contract(nft, addrs)

            swaping.methods.tokenidmint().call({ from: userwalletaddresss })
                .then((length) => {


                    setassetlist(length)

                })
                .catch()

        }
    }
    useEffect(() => {
        for (let i = 1; i <= assetist; i++) {
            nftinfo(i);


        }


    }, [assetist])

    const nftinfo = async (id) => {
        if (acc && web3main) {
            // const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const accounts = await web3main.eth.getAccounts();

            let userwalletaddresss = accounts[0];
            // window.web3 = new Web3(window.ethereum);
            let swaping = new web3main.eth.Contract(nft, addrs)

            swaping.methods.nftInformation(id).call({ from: userwalletaddresss })
                .then(async (fees) => {
                    swaping.methods.properties(id).call({ from: userwalletaddresss })
                        .then((recipt) => {
                            getallasset({ ...fees, 20: recipt ? (JSON.parse(recipt[recipt?.length - 1])[1]) : null })
                        })




                    // const GameScore = Moralis.Object.extend("NEWNFT");
                    // const gameScore = new GameScore();
                    // gameScore?.set("nftName",fees[1] );
                    // gameScore?.set("ownerName", fees[3]);
                    // gameScore?.set("nftDescription", fees[5]);
                    // gameScore?.set("nftImg", fees[6]);
                    // gameScore?.set("collectionId", fees[7]);
                    // gameScore?.set("cath", fees[9]);
                    // gameScore?.set("copy", "0");
                    // gameScore?.set("nftId", fees[0]);
                    // gameScore?.save()

                }).catch()

        }
    }
    const getallasset = async (data) => {
        allsetcolllist(old => [
            ...old, data
        ])

    }
    
    return (
        <>

            <div className="games__wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-2 mx-0 px-0">
                            <CategorySidebar active_cat="allnft" />
                        </div>
                        <div className="col-1 row align-items-end flex-column text-left">

                        </div>
                        <div className="col-9 p-0">
                            <h3 className="oracleTextClass">ALL NFTs</h3>
                            {
                                accountid && chainid == 0x61 ?

                                    <div className="row items  ">
                                        {allnft === "allnft" ?
                                            allcolllist?.map((item, idx) => {
                                                <GridBoxItem
                                                    link={`/item-details/${item[0]}`}
                                                    title={item[1]}
                                                    image={item[6]}
                                                    imageType={item[20]}
                                                    Isauction={true}
                                                    ownerName={item[3]}
                                                />
                                                // <Link to={`/item-details/${item[0]}`} >
                                                // <h6>cc {(dataa?.data?.find(p => p?.attributes?.nftId === item[0])?.attributes?.type)}</h6>
                                                // <h6 className="mb-0 ml-2">{item ? item[3] : null} aaa</h6>
                                                // </Link>
                                            }) : allcolllist?.slice(0, 4).map((item, idx) => {
                                                <GridBoxItem
                                                    link={`/item-details/${item[0]}`}
                                                    title={item[1]}
                                                    image={item[6]}
                                                    imageType={item[20]}
                                                    Isauction={true}
                                                    ownerName={item[3]}
                                                />
                                            })}

                                    </div> :
                                    <div className="row items  ">
                                        {allnft === "allnft" ?
                                            dataa?.data?.map((val, idx) => {
                                                return (
                                                    <GridBoxItem
                                                        link={`/item-details/${val?.attributes?.nftId}`}
                                                        title={val?.attributes?.nftName}
                                                        image={val?.attributes?.nftImg}
                                                        imageType={val?.attributes?.datatype}
                                                        Isauction={true}
                                                        ownerName={val?.attributes?.ownerName}
                                                    />
                                                );
                                            }) : dataa?.data?.slice(0, 4)?.map((val, idx) => {
                                                return (
                                                    <GridBoxItem
                                                        link={`/item-details/${val?.attributes?.nftId}`}
                                                        title={val?.attributes?.nftName}
                                                        image={val?.attributes?.nftImg}
                                                        imageType={val?.attributes?.datatype}
                                                        Isauction={true}
                                                        ownerName={val?.attributes?.ownerName}
                                                    />
                                                );
                                            })}
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default Allasset;
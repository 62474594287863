import React, { useEffect, useState, useRef } from 'react'
import collectionPost1 from '../../image/nft-detail/pasted-Image.png'
import ethereumIcon from '../../image/category/ethereum.svg'
import Music_img from '../../image/category/LunaSky_Music_Icon.png'
import { NavLink } from 'react-router-dom';

import binancePriceImage from '../../image/networkPrice/binance.jpg'
import maticPriceImage from '../../image/networkPrice/matic.jpg'
import ethereumPriceImage from '../../image/networkPrice/ethereum.jpg'
// import InfiniteScroll from "react-infinite-scroll-component";
import { MAX_TEXT_LENGTH } from '../../utils/app.constant';

import moment from 'moment';
import NftDownTimer from '../Timer/NftList';

const NftList = ({ allnft }) => {
    const [countTime, setCountTime] = useState({ hours: 1, minutes: 20, seconds: 40 })
    const [isLoadMore, setIsLoadMore] = useState(true)

    // console.log('nft list Index', allnft);

    // console.log('ends in=>', moment.utc("2022-09-07T08:32:55.000Z").local().startOf('seconds').fromNow("2022-08-29T08:32:55.000Z"));
    // const countDown = (date) => {
    //     if(!date){
    //         return false
    //     }
    //     var start = new Date()
    //     var end = new Date(date)
    //     var state = { startDate: start.getTime(), timeEnd: end.getTime() } // example

        // const startDate = moment(state.startDate);
        // const timeEnd = moment(state.timeEnd);
        // const diff = timeEnd.diff(startDate);
        // const diffDuration = moment.duration(diff);

    //     var timer = {
    //         day: diffDuration.days(),
    //         hours: diffDuration.hours(),
    //         minutes: diffDuration.minutes(),
    //         seconds: diffDuration.seconds()
    //     }
    //     console.log('timer',date)
    //     if (timer.day === 0 && timer.hours === 0 && timer.minutes === 0 && timer.seconds === 0) {
    //         return false
    //     }
    //     if (timer.seconds < 0) {
    //         return false
    //     }
    //     return timer
    //     setCountTime(timer)
    // }

    const fetchMoreData = () => {
        setIsLoadMore(true)
    }

    return (
        <>
            <div className='row collections-list-items' >
                {/*   <LoadingInfiniteScroll
                        hasMore={()fetchMoreData}
                        isLoading={isLoadMore}
                        useWindow
                        className='row'
                    > */}
                {allnft && allnft.length ? allnft.map((value, key) => (
                    <div className="col-4 p-0 mb-3 nfts-list" key={key}>
                        <NavLink to={`/assets/${value.chain_name}/${value.contract_address}/${value.token_id}`}>
                            {/* {console.log('value', value)} */}
                            <div className="hover_show_price game-box mr-3">
                                {value.file_extension === 'video' ?
                                    <div className='explore_upper_div'>
                                        <video playsInline muted autoPlay={true} controlsList="nodownload" loop="" preload="auto" src={value && value.item_resized_image ? value.item_resized_image : value.item_image}
                                         style={{ width: "100%", height: 'calc(100% - 50px' }}></video>
                                    </div>
                                    : ''}
                                {value.file_extension === 'image' ?
                                    <div className='explore_upper_div'>
                                        <img src={value && value.item_resized_image ? value.item_resized_image : value.item_image} /> 
                                        {/* : 'https://via.placeholder.com/80x121.png?text=Use' */}
                                    </div>
                                    : ''}
                                {!value.file_extension ?
                                    <div className='explore_upper_div'>
                                        <img src={value && value.item_resized_image ? value.item_resized_image : value.item_image} />
                                        {/* 'https://via.placeholder.com/80x121.png?text=Use' */}
                                    </div>
                                    : ''}
                                {value.file_extension === 'audio' ?
                                    <div className='explore_upper_div audio_page'>
                                        <img src={Music_img}></img>
                                        <audio controls >
                                            <source src={value && value.item_resized_image ? value.item_resized_image : value.item_image} />
                                        </audio>


                                    </div>
                                    : ''}
                                <div className="game-box-dark row position-relative flex-column">
                                    {/* <NavLink to={`/assets/${value._id}`} className="nft-go-btn">Go</NavLink> */}
                                    <h2 className="nft-heading-1 m-0 mt-3">{value.item_name.slice(0, MAX_TEXT_LENGTH) + (value.item_name.length > MAX_TEXT_LENGTH ? "..." : "")}</h2>
                                    <h3 className="nft-subheading mt-1 mb-0">{value.collection_name}</h3>
                                    {/* {value?.price_details ? 
                                    <div>
                                        <span className="nft-paragraph-1">Price</span>
                                        {value?.network == 'binance' && <img src={binancePriceImage} />}
                                        {value?.network == 'polygon' && <img src={maticPriceImage} />}
                                        {value?.network == 'ethereum' && <img src={ethereumPriceImage} />}
                                        <div className="nft-price-wrap-right">
                                            <h5>{parseFloat(value?.price_details?.start_price)}</h5>
                                            <span>Ends {moment.utc(value?.price_details?.end_date).local().startOf('seconds').fromNow(value?.price_details?.start_date)}</span>
                                        </div>
                                    </div> : ''} */}

                                </div>
                                {value?.price_details ?
                                    <div className={`w-100 hide_class_hover nft-price-wrap d-flex align-items-center justify-content-between my-2 no-border `}>
                                        <div className="d-flex gap-5">
                                        <div className="nft-price-wrap-left d-flex">
                                            {value?.item_image && <img src={collectionPost1} />}
                                            
                                            {/* {value?.network == 'binance' && <img src={binancePriceImage} />}
                                            {value?.network == 'polygon' && <img src={maticPriceImage} />}
                                            {value?.network == 'ethereum' && <img src={ethereumPriceImage} />} */}
                                        </div>
                                        <div className={`nft-price-wrap-right`}>
                                            <h5><span className="nft-paragraph-1">Price </span> 
                                                {
                                                    value?.price_details?.price_unit === 'busd' ?
                                                    <em>{parseFloat(value?.price_details?.start_price)} BUSD</em> :
                                                    <em className={`${value.network}-icon`}>{parseFloat(value?.price_details?.start_price)}</em>
                                                }
                                            </h5>
                                        </div>
                                            </div>
                                        <span className="nft-paragraph-1">Ends {moment.utc(value?.price_details?.end_date).local().startOf('seconds').fromNow(value?.price_details?.start_date)}</span>
                                    </div>
                                    : ''}
                                {/* {value.ready_for_sell ? countDown(value.end_date) ? <NftDownTimer hoursMinSecs={countDown(value.end_date)} /> : '' : ''} */}
                            </div>
                        </NavLink>
                    </div>
                )) : <h3 className='no-data-found'>No data found </h3>}

                {/* </LoadingInfiniteScroll>*/}
            </div>
        </>
    )
}

export default NftList;
import { all } from 'redux-saga/effects'
import authSaga from './auth/userSaga'
import NFTSaga from './nft/saga'
import NetworkSaga from './networks/saga'
import userSaga from './user/userSaga'
import MysterySaga from './mysterybox/saga'
import collectionSaga from './collections/collectionSaga'



export default function* rootSaga() {
  yield all([
    authSaga(),
    NFTSaga(),
    userSaga(),
    MysterySaga(),
    collectionSaga()
  ])
}
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import QRCodeModal from "@walletconnect/qrcode-modal";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";

const POLLING_INTERVAL = 12000;
const RPC_URLS = {
  1: "https://mainnet.infura.io/v3/",
  4: "https://rinkeby.infura.io/v3/"
};
var walletList = [
  // "rainbow",
  "metamask",
  // "argent",
  // "safepal",
  "trust",
  // "imtoken",
  // "pillar",
  // "gnosis",
  // "crypto",
  // "onto",
  // "tokenpocket",
  // "math",
  // "bitpay",
  // "ledger",
  // "spot",
  // "1inch",
  // "huobi",    
  // "steak",
  // "wall",
  // "authereum",
  // "eidoo",
  // "mykey",
  // "loopring",
  // "atomic",
  // "coin98",
  // "cool",
  // "alice",
  // "alpha",
  // "dcent",
  // "zelcore",
  // "nash",
  // "coinomi",
  // "gridplus",
  // "cybavo",
  // "tokenary",
  // "torus",
  // "spatium",
  // "infinito",
  // "wallet",
  // "ownbit",
  // "easypocket",
  // "bridge",
  // "spark",
  // "via",
  // "bitkeep",
  // "vision",
  // "peakdefi",
  // "unstoppable",
  // "halodefi",
  // "dok",
  // "at",
  // "midas",
  // "ellipal",
  // "talken",
  // "xinfin",
  // "flare",
  // "kyber",
  // "atoken",
  // "r",
  // "plasma",
  // "o3",
  // "hashkey",
  // "jade",
  // "guard",
  // "defiant",
  // "trustee",
  // "coinus",
  // "cmorq",
  // "valora",
  // "quiverx",
  // "celo",
  // "elastos",
  // "fusecash",
  // "rabby",
  // "stasis",
  // "jul",
  // "fx",
  // "bull",
  // "anybit",
  // "bitpie",
  // "minerva",
  // "archipage",
  // "tangem",
  // "chainge",
  // "iopay",
  // "coinhub",
  // "gopocket",
  // "wallet3",
  // "yitoken",
  // "did",
  // "starbase",
  // "shinobi",
  // "gd",
  // "binana",
  // "airgap",
  // "paytube",
  // "secux",
  // "blockbank",
  // "orange",
  // "avacus",
  // "krystal",
  // "linen",
  // "ambire",
  // "uvtoken",
  // "frontier",
  // "vision",
  // "monarch",
  // "status",
  // "apollox",
  // "rich",
  // "simplehold",
  // "sahal",
  // "xfun",
  // "qubic",
  // "keyring",
  // "ancrypto",
  // "ttm",
  // "thor",
  // "pl",
  // "okx",
  // "coinstats",
  // "dentacoin",
  // "fundamenta",
  // "helix",
  // "talk",
  // "opera",
  // "realt",
  // "card",
  // "paybolt",
  // "abra",
  // "bitcoin",
  // "first",
  // "arculus",
  // "localtrade",
  // "oxalus",
  // "now",
  // "slope",
  // "masknetwork",
  // "coincircle",
  // "uniblow",
  // "kelever",
  // "lead",
  // "punk",
  // "edge",
  // "cosmostation",
  // "lz",
  // "bcertin",
  // "kryptogo",
  // "autonomy",
  // "nefti",
  // "goldbit",
  // "boo",
  // "mywallid",
  // "uvtoken",
  // "brish",
  // "wattme",
  // "coingrig",
  // "bycoin",
  // "snowball",
  // "assure",
  // "fireblock",
  // "safemoon",
  // "cryptnoxapp",
  // "ballet",
  // "filwallet",
  // "bitski",
  // "tk",
] 
// const injected = new InjectedConnector({
//   supportedChainIds: [1, 3, 4, 5, 42,97,56]
// });

const walletconnect = new WalletConnectConnector({
  rpcUrl: `https://mainnet.infura.io/v3/`,
  bridge: "https://opensea.bridge.walletconnect.org",
  qrcode: true,
  infuraId:'d45b891571014af08050aa7e8976b32a',
  supportedChainIds: [1, 3, 4, 5, 42, 56, 97, 137],
  chainId: 56,
  rpc: {
    56: "https://bsc-dataseed1.binance.org/",
    97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    137: "https://polygon-rpc.com/",
    80001: "https://rpc-mumbai.maticvigil.com/",
    1:"https://mainnet.infura.io/v3/d45b891571014af08050aa7e8976b32a",
    5:"https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
    // ...
  },
  qrcodeModalOptions: {
    desktopLinks: [
      'ledger',
      'tokenary',
      'wallet',
      'wallet 3',
      'secuX',
      'ambire',
      'wallet3',
      'apolloX',
      'zerion',
      'sequence',
      'punkWallet',
      'kryptoGO',
      // 'nft',
      'riceWallet',
      'vision',
      'keyring'
    ],
    mobileLinks: [
      "rainbow",
      // "metamask",
      "argent",
      "trust",
      "imtoken",
      "pillar",
    ],
  }
  // rpc: { 1: RPC_URLS[1] }
});

// const walletconnect = new WalletConnectConnector({
//   rpcUrl: `https://mainnet.infura.io/v3/${'d45b891571014af08050aa7e8976b32a'}`,
//   bridge: "https://bridge.walletconnect.org",
//   qrcode: true,
//   infuraId:'d45b891571014af08050aa7e8976b32a',
// });

// export const walletconnect = new WalletConnectConnector({
//   rpc: { 1: RPC_URLS[1] },
//   bridge: "https://bridge.walletconnect.org",
//   qrcode: true,
//   pollingInterval: POLLING_INTERVAL,
//   infuraId:'d45b891571014af08050aa7e8976b32a',
// });

// // const walletconnect = new WalletConnectProvider({
// //   rpc: {
// //       56: "https://bsc-dataseed1.binance.org",
// //       // 97:"https://data-seed-prebsc-1-s1.binance.org:8545/",
// //   },
// //   chainId: 56,
// //   network: "binance",
// //   qrcode: true,
// //   furaId:'d45b891571014af08050aa7e8976b32a',
// //   qrcodeModalOptions: {
// //       mobileLinks: [
// //       "metamask",
// //       "trust",
// //       ]
// //   }
// // });


// export const connectors = {
//   injected: injected,
//   walletConnect: walletconnect,
// };

const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42,97,56, 137]
});

// const walletconnect = new WalletConnectConnector({
//   rpcUrl: `https://mainnet.infura.io/v3/${'d45b891571014af08050aa7e8976b32a'}`,
//   bridge: "https://bridge.walletconnect.org",
//   qrcode: true
// });

const walletlink = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/${'d45b891571014af08050aa7e8976b32a'}`,
  appName: "web3-react-demo"
});

export const connectors = {
  injected: injected,
  walletConnect: walletconnect,
  coinbaseWallet: walletlink
};

export const CHAINS = {
  // Polygon
  137: {
    chainId: '0x89',
    chainName: 'Matic Mainnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://polygon-rpc.com/'],
    blockExplorerUrls: ['https://polygonscan.com/'],
  },
  80001: {
    chainId: '0x13881',
    chainName: 'Polygon Testnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
    blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
  },
  // Avalanche
  43113: {
    chainId: '0xA869',
    chainName: 'Avalanche Testnet',
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://testnet.snowtrace.io'],
  },
  43114: {
    chainId: '0xA86A',
    chainName: 'Avalanche Mainnet',
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://snowtrace.io'],
  },
  97: {
    chainId: '0x61',
    chainName: 'BNB Smart Chain Testnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    blockExplorerUrls: ['https://testnet.bscscan.com'],
  },
  56: {
    chainId: '0x38',
    chainName: 'BNB Smart Chain',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed1.binance.org'],
    blockExplorerUrls: ['https://bscscan.com'],
  },
  1: {
    chainId: '0x1',
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://eth-mainnet.unifra.io/v1/d157f0245608423091f5b4b9c8e2103e'],
    blockExplorerUrls: ['https://etherscan.com'],
  },
};
import {
  call,
  put,
  fork,
  take,
  takeLatest,
  all,
  takeEvery,
  takeLeading,
} from "redux-saga/effects";
import { CallApi } from "../../api/CallApi";
import { API_URLS } from "../../utils/app.constant";
import { USER_ACTION_TYPES, USER_SAGA_ACTION_TYPES } from "./userAction";

function* followUnfollowUser(action) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URLS.USER.FOLLOW_UNFOLLOW, data);

    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.success
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (err) {
    action &&
      action.callbackError &&
      action.callbackError(err && err.data ? err.data : err);
  }
}

function* fetchFollowData(action) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URLS.USER.FETCH_FOLLOW_DATA, data);

    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.success
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (err) {
    action &&
      action.callbackError &&
      action.callbackError(err && err.data ? err.data : err);
  }
}

function* likeUnlikeNft(action) {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.POST,
      API_URLS.USER.NFT.LIKE_UNLIKE_NFT,
      data
    );

    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.success
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (err) {
    action &&
      action.callbackError &&
      action.callbackError(err && err.data ? err.data : err);
  }
}

function* fetchLikedNft(action) {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_URLS.USER.NFT.FETCH_LIKED_NFT,
      data
    );

    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.success
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (err) {
    action &&
      action.callbackError &&
      action.callbackError(err && err.data ? err.data : err);
  }
}

function* getCards(action) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URLS.USER.CARD.GET_CARDS, data);

    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.success
    ) {
      yield put({
        type: USER_ACTION_TYPES.CARD.GET_CARDS,
        payload: resp.data?.data,
      });

      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (err) {
    action &&
      action.callbackError &&
      action.callbackError(err && err.data ? err.data : err);
  }
}

function* addCard(action) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URLS.USER.CARD.ADD_CARD, data);

    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.success
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (err) {
    action &&
      action.callbackError &&
      action.callbackError(err && err.data ? err.data : err);
  }
}

function* markCardAsDefault(action) {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.POST,
      API_URLS.USER.CARD.MARK_CARD_AS_DEFAULT,
      data
    );

    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.success
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (err) {
    action &&
      action.callbackError &&
      action.callbackError(err && err.data ? err.data : err);
  }
}

function* deleteCard(action) {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.DELETE,
      API_URLS.USER.CARD.DELETE_CARD,
      data
    );

    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.success
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (err) {
    action &&
      action.callbackError &&
      action.callbackError(err && err.data ? err.data : err);
  }
}

function* globalSearch(action) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URLS.GLOBAL_SEARCH, data);

    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.success
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (err) {
    action &&
      action.callbackError &&
      action.callbackError(err && err.data ? err.data : err);
  }
}

function* verifySubscribedEmail(action) {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.POST,
      API_URLS.USER.VERIFY_SUBSCRIBED_EMAIL,
      data
    );

    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.success
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (err) {
    action &&
      action.callbackError &&
      action.callbackError(err && err.data ? err.data : err);
  }
}

function* buyCryptoOnboarding(action) {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.POST,
      API_URLS.USER.BUY_CRYPTO_ONBOARDING,
      data
    );

    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.success
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (err) {
    action &&
      action.callbackError &&
      action.callbackError(err && err.data ? err.data : err);
  }
}

function* wyreOrderSave(action) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URLS.USER.WYRE_ORDER_SAVE, data);

    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.success
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (err) {
    action &&
      action.callbackError &&
      action.callbackError(err && err.data ? err.data : err);
  }
}

function* getNonce(action) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URLS.USER.GET_NONCE, data);

    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.success
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (err) {
    action &&
      action.callbackError &&
      action.callbackError(err && err.data ? err.data : err);
  }
}

function* userSaga() {
  yield takeLatest(
    USER_SAGA_ACTION_TYPES.FOLLOW.FOLLOW_UNFOLLOW,
    followUnfollowUser
  );
  yield takeLatest(
    USER_SAGA_ACTION_TYPES.FOLLOW.FETCH_FOLLOW_DATA,
    fetchFollowData
  );

  yield takeLatest(USER_SAGA_ACTION_TYPES.NFT.LIKE_UNLIKE_NFT, likeUnlikeNft);
  yield takeLatest(USER_SAGA_ACTION_TYPES.NFT.FETCH_LIKED_NFT, fetchLikedNft);

  yield takeLatest(USER_SAGA_ACTION_TYPES.CARD.GET_CARDS, getCards);
  yield takeLatest(USER_SAGA_ACTION_TYPES.CARD.ADD_CARD, addCard);
  yield takeLatest(
    USER_SAGA_ACTION_TYPES.CARD.MARK_CARD_AS_DEFAULT,
    markCardAsDefault
  );
  yield takeLatest(USER_SAGA_ACTION_TYPES.CARD.DELETE_CARD, deleteCard);

  yield takeLatest(USER_SAGA_ACTION_TYPES.GLOBAL_SEARCH, globalSearch);
  yield takeLatest(
    USER_SAGA_ACTION_TYPES.VERIFY_SUBSCRIBED_EMAIL,
    verifySubscribedEmail
  );
  yield takeLatest(
    USER_SAGA_ACTION_TYPES.BUY_CRYPTO_ONBOARDING,
    buyCryptoOnboarding
  );
  yield takeLatest(USER_SAGA_ACTION_TYPES.WYRE_ORDER_SAVE, wyreOrderSave);
  yield takeLatest(USER_SAGA_ACTION_TYPES.GET_NONCE, getNonce);
}

export default userSaga;

import React ,{useEffect,useState} from 'react'
import {  Table } from 'antd';

const List = ({activity,key}) =>{
    const columns = [
        {
            title: 'Detail',
            render:((value)=>{
                return <a  href={`${value?.url + value?.tnxHasId}`}>details</a>
            })
          },
        {
          title: 'NFT Image',
          render:((value)=>{
              return <img style={{ height: '50px',width: '50px'}} src={value && value.nft_id.item_resized_image? value.nft_id.item_resized_image : value.nft_id.item_image}/>
          })
        },
        {
          title: 'NFT Name',
          render:((value)=>{
              return value?.nft_id?.item_name
          })
        },
        {
          title: 'Event',
          render:((value)=>{
                return value?.event
            })
        },
        {
         title: 'Price',
         render:((value)=>{
            return value?.price
            })
        },
      ];
    var data = []
    return (
        <Table  
            columns={columns} dataSource={activity} 
            pagination={{ defaultPageSize: 4}}
        />
        // <table>
        //     <thead>
        //         <tr>
        //             <th>#</th>
        //             <th>NFT Image</th>
        //             <th>NFT Name</th>
        //             <th>Event</th>
        //             <th>Price</th>
        //         </tr>
        //     </thead>
        //     {activity.length > 0 ? 
        //         <tbody>
        //             { activity.map((value,key)=>(
        //                 <tr>
        //                     <td>{key+1}</td>
        //                     <td><img style={{ height: '69px',width: '79px'}} src={value?.get('nft_id')?.get('item_image')}/></td>
        //                     <td>{value?.get('nft_id')?.get('item_name')}</td>
        //                     <td>{value?.get('event')}</td>
        //                     <td>{value?.get('price')}</td>
        //                 </tr>    
        //             ))}    
        //         </tbody>
        //     :
        //         <tbody>
        //             <tr>
        //                 No Record Found!
        //             </tr>
        //         </tbody>
        //     }    
        // </table>
    )
}

export default List;
import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash/debounce";
import { AutoComplete } from "antd";
import { useUserApi } from "../../hooks/api/userApiHook";
import { useHistory } from "react-router";
import { URLS } from "../../utils/app.constant";

const GlobalSearchBar = (props) => {
  const history = useHistory();
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState();
  const { globalSearch } = useUserApi();

  const onSearchHandler = useCallback(
		debounce((val) => {
      if (val && val.length > 1) {
        searchDataHandler({ search: val });
      } else {
        setOptions([]);
      }
    }, 1000),
		[]
	);

  const searchDataHandler = (queryParams = {}) => {
    globalSearch(
      { ...queryParams, getAll: true },
      (message, resp) => {
        if (resp?.data?.length) {
          let tempArr = [];
          const responseData = resp.data;
          responseData.map((v, i) => {
            tempArr.push({
              ...v,
              label: v.name,
              value: v._id,
            });
          });
          setOptions(tempArr);
        } else {
          setOptions([{label: 'No results found..'}]);
        }
      },
      (message, error) => {
        setOptions([]);
      }
    );
  };

  const selectHandler = (value) => {
    const findSelected = options.find((item) => item?.value === value);
    setSelectedValue(findSelected?.label || selectedValue);
    if (findSelected?.type === "nft") {
      history.push(`${URLS.NFT_DETAILS}/${findSelected.chain_name}/${findSelected.contract_address}/${findSelected.token_id}`);
    } else if (findSelected?.type === "collection" && findSelected?.slug) {
      history.push(`${URLS.COLLECTION_DETAILS}/${findSelected.slug}`);
    }
  };

  return (
    <>
      <AutoComplete
        className="searchbar-section"
        allowClear={true}
        options={options}
        style={{
          width: "100%",
          fontFamily: "Futura",
        }}
        value={selectedValue}
        onChange={(e) => setSelectedValue(e)}
        onSearch={(e) => onSearchHandler(e)}
        onSelect={(e) => {
          selectHandler(e);
        }}
        placeholder=""
      />
    </>
  );
};

export default GlobalSearchBar;
